/*************************************
 * author: katherine marschner
 * cfd copyright 2020
 *************************************/

import {
  FETCH_PATIEN_PROGRAM_INFO,
  STORE_PATIENT_PROGRAM,
  START_PROGRAM,
  STOP_PROGRAM,
  START_EDIT_PROGRAM,
  STOP_EDIT_PROGRAM,
  TOGGLE_ADD_EDIT_FLAG,
  SAVE_EXERCISE,
  SAVE_EXERCISE_FREQUENCY,
  SAVE_FREQUENCY,
  SAVE_SET_FREQUENCY,
  SAVE_SETTING_FIRST,
  SAVE_SETTING_SECOND,
  ADD_PATIEN_PROGRAM_INFO,
  EDIT_PATIEN_PROGRAM_INFO,
} from "../actions/index";
const INITIAL_STATE = {
  patientProgram: {},
  startProgram: false,
  startEditProgram: false,
  ExerciseAdded: false,
  Exercise: {
    VORx1_Yaw: true,
    VORx1_Pitch: true,
    Balance_Weight_Shift: true,
    Balance_Sitting_Standing: true,
  },
  ExerciseFrequency: {
    Sunday: true,
    Monday: true,
    Tuesday: true,
    Wednesday: true,
    Thursday: true,
    Friday: true,
    Saturday: true,
  },
  Frequency: {
    NumberOfSessionPerDay: 1,
    sessionsPerDay: 1,
    // ExerciseDurationPerDay: "00:00",
  },
  SetFrequency: {
    DataArray: {},
    ExerciseDurationPerDay: "00:00",
    Num_Of_sets_VORx1_Yaw: 0,
    Num_Of_sets_VORx1_Pitch: 0,
    Num_Of_sets_Balance_Weight_Shift: 0,
    Num_Of_sets_Balance_Sitting_Standing: 0,
    Duration_VORx1_Yaw: 0,
    Duration_VORx1_Pitch: 0,
    Duration_Balance_Weight_Shift: 0,
    Duration_Balance_Sitting_Standing: 0,
  },
  SettingsFirst: {},

  SettingsSecond: {
    VORx1_Yaw_HeadTarget: "",
    VORx1_Yaw_VelocityTarget: "",
    VORx1_Pitch_HeadUpTarget: "",
    VORx1_Pitch_HeadDownTarget: "",
    VORx1_Pitch_VelocityTarget: "",
  },
  patientProgramAdd: {},
};
export default function appReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    //case FETCH_OVERVIEW_DATA:
    //  return action.payload.data.overviewUserData;
    case FETCH_PATIEN_PROGRAM_INFO:
      return {
        ...state,
        patientProgram: action.payload.data.prescriptions,
      };
    case STORE_PATIENT_PROGRAM:
      return {
        ...state,
        ...action.payload,
      };
    case START_PROGRAM:
      return {
        ...state,
        startProgram: action.payload,
      };
    case STOP_PROGRAM:
      return {
        ...state,
        startProgram: action.payload,
      };
    case START_EDIT_PROGRAM:
      return {
        ...state,
        ExerciseAdded: false,
        startEditProgram: action.payload,
      };
    case STOP_EDIT_PROGRAM:
      return {
        ...state,
        ExerciseAdded: false,
        startEditProgram: action.payload,
      };
    case TOGGLE_ADD_EDIT_FLAG:
      return {
        ...state,
        ExerciseAdded: true,
      };
    case SAVE_EXERCISE:
      return {
        ...state,
        Exercise: action.payload,
      };
    case SAVE_EXERCISE_FREQUENCY:
      return {
        ...state,
        ExerciseFrequency: action.payload,
      };
    case SAVE_FREQUENCY:
      return {
        ...state,
        Frequency: action.payload,
      };
    case SAVE_SET_FREQUENCY:
     return {
        ...state,
        SetFrequency: action.payload,
      };
    case SAVE_SETTING_FIRST:
      return {
        ...state,
        SettingsFirst: action.payload,
      };
    case SAVE_SETTING_SECOND:
      return {
        ...state,
        SettingsSecond: action.payload,
      };
    case ADD_PATIEN_PROGRAM_INFO:
      return {
        ...state,
        startProgram: false,
        patientProgramAdd: action.payload.data.prescriptions,
      };
    case EDIT_PATIEN_PROGRAM_INFO:
      return {
        ...state,
        startEditProgram: false,
        patientProgramAdd: action.payload.data.prescriptions,
        patientProgram: action.payload.data.prescriptions,
      };
    default:
      return state;
  }
}
