import IconButton from "@material-ui/core/IconButton";
import Pagination from "@material-ui/lab/Pagination";
import DriveFileRenameOutlineSharpIcon from "@mui/icons-material/DriveFileRenameOutlineSharp";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { DataGrid } from "@mui/x-data-grid";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchLogList } from "../../../actions/index";
import * as routes from "../../../constants/routes";
import { UAParser } from "ua-parser-js";
import moment from "moment";

class LogList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      perPage: 10,
      totalItems: 0,
      sortColumn: "lastName",
      sortOrder: "asc",
      isTrue: false,
      totalPages: 0,
      AddEditUserModal: false,
      EditElement: null,
      mode: "Add",
    };
  }

  componentDidMount() {
    this.props.fetchLogList(
      this.state.page,
      this.state.perPage,
      this.state.sortColumn,
      this.state.sortOrder
    );
    this.handleChangePage = this.handleChangePage.bind(this);

    let currentUser = this.props.currentUser;

    if (currentUser == '' || Object.keys(currentUser).length == 0) {
      window.location.href = '/';
      return false;
    }
    else {
      let currentUserRole = currentUser.role;
      // console.log('LOG LIST ROLE===>',currentUserRole)
      if (currentUserRole.toLowerCase() != 'tvh admin' && currentUserRole.toLowerCase() != 'tvh super admin') {
        //window.location.href = '/patients';
        this.props.history.push(routes.PATIENT);
        return false;
      }
    }

  }

  componentDidUpdate(prevProps) {
    if (this.props.auditLog && prevProps.auditLog !== this.props.auditLog) {
      // console.log("this.props.provider::::::", this.props.provider);
      if (this.props.auditLog.page) {
        this.setState({
          ...this.state,
          totalItems: this.props.auditLog.page.totalItems,
          page: this.props.auditLog.page.currentPage,
          perPage: this.props.auditLog.page.pageSize,
          totalPages: this.props.auditLog.page.totalPages,
          mode: "Add",
          EditElement: null,
        });
      }
    }
    // console.log("this.props.currentUser:::::", this.props.currentUser);
    if (
      this.props.currentUser &&
      prevProps.currentUser !== this.props.currentUser
    ) {
      let currentUser = this.props.currentUser;
      if (currentUser == "" || currentUser.role != "TVH ADMIN" && currentUser.role != "TVH SUPER ADMIN") {
      }
    }
  }

  handleChangePage(page) {
    this.setState({ page: page });
    setTimeout(() => {
      this.getAuditLogList();
      window.scrollTo(0, 0)
    }, 100);
  }

  getAuditLogList() {
    let page = this.state.page;
    let perPage = this.state.perPage;

    this.props.fetchLogList(
      page,
      perPage,
      this.state.sortColumn,
      this.state.sortOrder
    );
  }

  render() {
    const RawHTML = ({ children, tag = 'div', nl2br = true, ...rest }) =>
      React.createElement(tag, {
        dangerouslySetInnerHTML: {
          __html: nl2br
            ? children && children.replace(/\n/g, '<br />')
            : children,
        },
        ...rest,
      });
    const NoResultsOverlay = () => {
      return <div className="no-result">0 results found</div>;
    };

    const CustomPagination = () => {
      return (
        <Pagination
          count={this.state.totalPages}
          page={this.state.page}
          variant="outlined"
          onChange={(event, value) => this.handleChangePage(value)}
        />
      );
    };

    function RowMenuCell(props) {


      //const { api, id } = props;

      const handleEditClick = (event) => {
        // console.log("props::", props.row);
        //console.log("event::::",event);
        //event.stopPropagation();
        //api.setRowMode(id, 'edit');
      };
      return (
        <div className="">
          <IconButton
            color="inherit"
            className=""
            size="small"
            aria-label="edit"
            onClick={handleEditClick}
          >
            <DriveFileRenameOutlineSharpIcon fontSize="small" />
          </IconButton>
        </div>
      );
    }

    let rows = [];
    const columns = [
      // {field: 'id',headerName: 'Id'},
      {
        field: "logDateTime",
        headerName: "DateTime(CT)",
        flex: 1,
        sortable: false,
        //width: 80
      },
      {
        field: "username",
        headerName: "Email",
        flex: 1,
        sortable: false,
        minWidth: 250,
      },
      {
        field: "deviceType",
        headerName: "Browser",
        flex: 1,
        sortable: false,
      },
      {
        field: "os",
        headerName: "OS",
        flex: 1,
        sortable: false,
      },
      {
        field: "additionalLogMessage",
        headerName: "Log Message",
        flex: 1,
        sortable: false,
        minWidth: 400,
        renderCell: (params) => {
          return (
            <>
              <div title={params.value}>
                <RawHTML>{params.value}</RawHTML>
              </div>
            </>
          );
        }
      },
    ];

    if (this.props.auditLog != null) {
      let { rowList } = this.props.auditLog;
      if (rowList && rowList !== null) {
        rowList.forEach((row, index) => {
          let parser = new UAParser(row.deviceType);
          let parserResults = parser.getResult();
          let additionalLogMessage = row.additionalLogMessage;
          // additionalLogMessage = additionalLogMessage.replace("<",'&lt;');
          // additionalLogMessage = additionalLogMessage.replace(">",'&gt;');
          // additionalLogMessage = additionalLogMessage.replace("/",' /');
          additionalLogMessage = additionalLogMessage.replace("</br>", '\n')
          rows.push({
            id: index,
            username: row.username,
            deviceType: parserResults.browser.name + '/' + parserResults.browser.version,
            os: parserResults.os.name + ' ' + parserResults.os.version,
            //role: row.role,
            //clinicName: row.clinicName,
            additionalLogMessage: additionalLogMessage,
            logDateTime: moment(row.logDateTime).format("MM/DD/YYYY HH:mm:ss")
          });
        });
      }
    }
    return (
      <div className="p-3">
        <Grid container className="MuiToolbar-gutters align-items-end">
          <Grid item xs={12} md={6} className="mb-3">
            <h2 className="pd-top-6 push-mt-30">
              <strong>Audit Logs</strong>
            </h2>
          </Grid>

          <Grid item xs={12} >
            <Box sx={{ height: "780px", width: "100%" }} className="datatable">
              <DataGrid
                sx={{
                  '.MuiDataGrid-iconButtonContainer': {
                    visibility: 'visible',
                  },
                  '.MuiDataGrid-sortIcon': {
                    opacity: 'inherit !important',
                  },
                  m: 0
                }}
                rows={rows}
                rowCount={this.state.totalItems}
                rowsPerPageOptions={this.state.page.perPage}
                pagination
                page={this.state.page}
                pageSize={5}
                paginationMode="server"
                columns={columns}
                disableColumnMenu="true"
                onCellClick={this.handleOnCellClick}
                components={{
                  NoRowsOverlay: NoResultsOverlay,
                  Pagination: CustomPagination,
                }}


                disableRowSelectionOnClick={true}
              />
            </Box>
          </Grid>
        </Grid>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auditLog: state.auditLog.logList,
    currentUser: state.security.user
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchLogList,
    },
    dispatch
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(LogList);
