import {
  VERIFY_EMAIL,
  VALIDATE_PASSWORD_TOKEN,
  SET_PASSWORD,
} from "../actions/index";

export default function forgotPasswordReducer(state = {}, action) {
  switch (action.type) {
    case VERIFY_EMAIL:
      console.log("action.payload::",action.payload.data.token);
      return {
        verifiedEmail: action.payload.data,
      };
    case VALIDATE_PASSWORD_TOKEN:
      return {
        validPassToken: action.payload,
      };
    case SET_PASSWORD:
      return {
        setPassword: action.payload,
      };
    default:
      return state;
  }
}
