import { FETCH_HELP_DATA } from "../actions/index";

const INITIAL_STATE = {
  helpData: {},
};
export default function helpReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_HELP_DATA:
      let helpData = action.payload.data.faqs;
      helpData = [...helpData].sort((a, b) => a.displayOrder - b.displayOrder);
      return {
        helpData: helpData,
      };
    default:
      return state;
  }
}
