import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
// import OutlinedInput from '@mui/material/OutlinedInput';
// import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormControl from '@mui/material/FormControl';
// import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';

export class FilterManager extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filter: {
                incompiance: false,
                atrisk: false,
                outofcompiance: false
            },
            Providers: [],
            providerName: this.props.providerName ? this.props.providerName : ['All providers'],
            selProviderId: this.props.filterData.providers,
            filterCount: this.props.filterCount,
            providerName: this.props.providerName ? this.props.providerName : ['All providers'],
            isProviderFilterApplied: false,
            ptname: '',
            names: []
        };

        //this.handleClickOutside = this.handleClickOutside.bind(this);
    }
    componentDidMount() {
        //alert('in mount');
        // this.props.fetchNotifications();
        this.setState({
            patient: this.props.patientInfo,
            filter: this.props.filterData,
            selProviderId: this.props.filterData.providers,
            isProviderFilterApplied: this.props.filterData.providers.indexOf('all') < 0 ? true : false,
        })
        this.setState({ notificationListData: this.props.notificationData });
        //document.addEventListener("mousedown", this.handleClickOutside);

        //this.getProviders();
        this.setState({ Providers: this.props.Providers });
        if (this.props.showFilter) {
            this.setState({ showFilter: this.props.showFilter });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.showFilter != this.props.showFilter) {
            let showFilter = this.props.showFilter;
            this.setState({ showFilter: showFilter });
        }

    }

    handleChangeSelectPT(event) {
        // console.log("event===>",event.target);
        //let selVal = val[0];

        let providerName = event.target.name;
        let isChecked = event.target.checked;
        let selId = event.target.value;
        // let value = selVal.name;
        // let id = selVal.id;
        let selProviderList = this.state.providerName;
        let selProviderIds = this.state.selProviderId;
        

        if (!isChecked) {
            let index = selProviderList.indexOf(providerName);
            if (index > -1) {
                selProviderList.splice(index, 1);
            }
            this.setState({
                providerName: [...selProviderList]
            });

            index = selProviderIds.indexOf(selId);
            //console.log("this.state.filterCount:::", selProviderIds);

            if (index > -1) {
                selProviderIds.splice(index, 1);
            }

            if (selProviderIds.length == 0) {


                let filterCount = this.state.filterCount;
                if (this.state.filterCount > 0) {
                    
                    //if (this.state.selProviderId.length > 0 && this.state.selProviderId.indexOf('all') < 0) {
                    if (this.state.selProviderId.indexOf('all') < 0) {
                        filterCount = this.state.filterCount - 1;
                    }
                }

                this.setState({
                    providerName: ['All providers'],
                    isProviderFilterApplied: false,
                    filterCount: filterCount
                });
                selProviderIds = ['all'];
            }
            setTimeout(()=>{
                console.log("selProviderIds:::", selProviderIds);
                this.setState({
                    selProviderId: [...selProviderIds]
                });
            },200)
            

        } else {

            if (providerName != 'All providers') {
                let index = selProviderList.indexOf('All providers');
                if (index > -1) {
                    selProviderList.splice(index, 1);
                }
                this.setState({
                    providerName: [...selProviderList]
                });
                index = selProviderIds.indexOf('all');
                if (index > -1) {
                    selProviderIds.splice(index, 1);
                }
                this.setState({
                    selProviderId: [...selProviderIds]
                });
                this.setState({
                    providerName: [...selProviderList, providerName],
                    selProviderId: [...selProviderIds, selId],
                });

                if (!this.state.isProviderFilterApplied) {

                    this.setState({ filterCount: this.state.filterCount + 1 });
                    this.setState({ isProviderFilterApplied: true });
                }


            } else {

                this.setState({
                    providerName: ['All providers'],
                    selProviderId: ['all'],
                });
                if (this.state.isProviderFilterApplied) {
                    this.setState({ filterCount: this.state.filterCount - 1 })
                }
                this.setState({ isProviderFilterApplied: false })
            }
        }

    }
    handleComplianceFilter = (event) => {
        let filterName = event.target.name;
        let isChecked = event.target.checked;
        this.setState({
            filter: { ...this.state.filter, [filterName]: isChecked }
        });
        if (isChecked) {
            this.setState({ filterCount: this.state.filterCount + 1 })
        } else {
            this.setState({ filterCount: this.state.filterCount - 1 })
        }
        setTimeout(() => {
            //console.log("this.state.filte new:::", this.state.filter);
        }, 200)

    }
    applyFilter() {
        let filter = this.state.filter;
        
        filter['providers'] = this.state.selProviderId;
        console.log("filter::::",filter);
        this.props.handleFilterSubmit(filter, this.state.filterCount, this.state.providerName);
    }

    clearFilter() {
        this.setState({
            ...this.state,
            filter: {
                incompiance: false,
                atrisk: false,
                outofcompiance: false,
                providers: ['all']
            },
            //Providers: [],
            providerName: ['All providers'],
            selProviderId: ['all'],
            filterCount: 0,
            isProviderFilterApplied: false,
        });
        // setTimeout(() => {
        //     this.props.handleFilterSubmit(this.state.filter, this.state.filterCount, '',false);
        // }, 200);
    }
    render() {

        const ITEM_HEIGHT = 48;
        const ITEM_PADDING_TOP = 8;
        const MenuProps = {
            PaperProps: {
                style: {
                    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                    width: 250,
                },
            },
        };

        const handleChange = (event) => {
            let selVal = event.target.value[0];
        };

        return (
            <>
                <div className=''> {/*ref={this.setWrapperRef}*/}
                    <div className='p-3'>
                        <h6 className='mb-1 font-weight-bold'> Compliance </h6>
                        <div>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={this.state.filter.incompiance ? 'checked' : ''}
                                            onClick={(e) => {
                                                this.handleComplianceFilter(e);
                                            }}
                                            name="incompiance"
                                            value={this.state.filter.incompiance}
                                        />
                                    }
                                    label="In Compliance"
                                    className="mb-0 font-500"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={this.state.filter.atrisk}
                                            onClick={(e) => {
                                                this.handleComplianceFilter(e);
                                            }}
                                            name="atrisk"
                                            value={this.state.filter.atrisk}
                                        />
                                    }
                                    label="At Risk"
                                    className="mb-0 font-500"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={this.state.filter.outofcompiance}
                                            onClick={(e) => {
                                                this.handleComplianceFilter(e);
                                            }}
                                            name="outofcompiance"
                                            value={this.state.filter.outofcompiance}
                                        />
                                    }
                                    label="Out of Compliance"
                                    className="mb-0 font-500"
                                />
                            </FormGroup>
                        </div>
                    </div>
                    <hr className='m-0'></hr>
                    <div className=''>
                        <div className='p-3'>
                            <h6 className='mb-1 font-weight-bold'> Provider Name</h6>
                            <FormControl sx={{ width: 300 }}>
                                <Select
                                    value={this.state.providerName}
                                    multiple={true}
                                    displayEmpty
                                    renderValue={() => this.state.providerName.length > 1 ? this.state.providerName.join(' | ') : this.state.providerName}
                                    MenuProps={MenuProps}
                                    variant="outlined"
                                >
                                    <MenuItem key={"all"} value={'all'}>

                                        <FormControlLabel
                                            control={
                                                <Checkbox checked={(this.state.selProviderId.indexOf("all") > -1) ? true : false} value={"all"} name={"All providers"} onClick={(e) => {
                                                    this.handleChangeSelectPT(e);
                                                }} />
                                            }
                                            label={"All providers"}
                                            className="mb-0 font-500"
                                        />
                                    </MenuItem>
                                    {this.state.Providers.map((val, key) => (
                                        <MenuItem key={val.id} value={val.id} >

                                            <FormControlLabel
                                                control={
                                                    <Checkbox checked={(this.state.selProviderId.indexOf(val.id) > -1) ? true : false} value={val.id} name={val.name} onClick={(e) => {
                                                        this.handleChangeSelectPT(e);
                                                    }} />
                                                }
                                                label={val.name}
                                                className="mb-0 font-500"
                                            />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                        <div className='row'>
                        </div>
                        <hr className='m-0' />
                    </div>

                    <div className='bg-light-gray p-3 d-flex align-items-center justify-content-between'>

                        <button
                            className="btn cursor-pointer rounded-20"
                            variant="contained"
                            disabled={this.state.filterCount > 0 ? false : true}
                            onClick={(e) => {
                                e.preventDefault();
                                this.clearFilter()
                            }}
                        >
                            Clear {this.state.filterCount > 0 ? <span className='bg-primary p-1 text-white rounded-3'>{this.state.filterCount}</span> : ''}
                        </button>
                        <div>
                            <button
                                className="mr-2 btn border bg-white px-3 border-primary-theme cursor-pointer rounded-14"
                                variant="contained"
                                onClick={(e) => {
                                    e.preventDefault();
                                    this.props.handleFilterClose();
                                }}
                            >
                                Cancel
                            </button>
                            <button
                                className="bg-primary-theme border-0 px-3 text-white pointer rounded-14 btn theme-btn"
                                //disabled={this.state.filterCount > 0 ? false : true}
                                variant="contained"
                                onClick={(e) => {
                                    this.applyFilter()
                                }}
                            >
                                Apply
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {

        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(FilterManager);