//import axios from "axios";
import { handleError } from "./Actions";

//set program actions
export const START_PROGRAM = "START_PROGRAM";
export const STOP_PROGRAM = "STOP_PROGRAM";
export const START_EDIT_PROGRAM = "START_EDIT_PROGRAM";
export const STOP_EDIT_PROGRAM = "STOP_EDIT_PROGRAM";
export const TOGGLE_ADD_EDIT_FLAG = "TOGGLE_ADD_EDIT_FLAG";
export const SAVE_EXERCISE = "SAVE_EXERCISE";
export const SAVE_EXERCISE_FREQUENCY = "SAVE_EXERCISE_FREQUENCY";
export const SAVE_FREQUENCY = "SAVE_FREQUENCY";
export const SAVE_SET_FREQUENCY = "SAVE_SET_FREQUENCY";
export const SAVE_SETTING_FIRST = "SAVE_SETTING_FIRST";
export const SAVE_SETTING_SECOND = "SAVE_SETTING_SECOND";

//const qualifier = "cap";

export const startPragram = () => async (dispatch) => {
  try {
    dispatch({
      type: START_PROGRAM,
      payload: true,
    });
  } catch (error) {
    handleError(error, dispatch);
  }
};

export const stopPragram = () => async (dispatch) => {
  try {
    dispatch({
      type: STOP_PROGRAM,
      payload: false,
    });
  } catch (error) {
    handleError(error, dispatch);
  }
};

export const startEditPragram = () => async (dispatch) => {
  try {
    dispatch({
      type: START_EDIT_PROGRAM,
      payload: true,
    });
  } catch (error) {
    handleError(error, dispatch);
  }
};

export const stopEditPragram = () => async (dispatch) => {
  try {
    dispatch({
      type: STOP_EDIT_PROGRAM,
      payload: false,
    });
  } catch (error) {
    handleError(error, dispatch);
  }
};

export const SaveExerciseAdded = () => async (dispatch) => {
  try {
    dispatch({
      type: TOGGLE_ADD_EDIT_FLAG,
    });
  } catch (error) {
    handleError(error, dispatch);
  }
};

export const SaveExercise = (payload) => async (dispatch) => {
  // console.log("payload:::",payload);
  try {
    dispatch({
      type: SAVE_EXERCISE,
      payload: payload,
    });
  } catch (error) {
    handleError(error, dispatch);
  }
};

export const SaveExerciseFrequency = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: SAVE_EXERCISE_FREQUENCY,
      payload: payload,
    });
  } catch (error) {
    handleError(error, dispatch);
  }
};

export const SaveFrequency = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: SAVE_FREQUENCY,
      payload: payload,
    });
  } catch (error) {
    handleError(error, dispatch);
  }
};

export const SaveSetFrequency = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: SAVE_SET_FREQUENCY,
      payload: payload,
    });
  } catch (error) {
    handleError(error, dispatch);
  }
};

export const SaveSettingsFirst = (payload) => (dispatch) => {
  try {
    dispatch({
      type: SAVE_SETTING_FIRST,
      payload: payload,
    });
  } catch (error) {
    handleError(error, dispatch);
  }
};

export const SaveSettingsSecond = (payload) => (dispatch) => {
  try {
    dispatch({
      type: SAVE_SETTING_SECOND,
      payload: payload,
    });
  } catch (error) {
    handleError(error, dispatch);
  }
};
