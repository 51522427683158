import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import React, { Component } from "react";
import moment from "moment";

const d = new Date();

export class ResultChartCalendarCompliance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      previousDate: "",
      currentDate: "",
      month: "",
      preMonth: "",
    };
  }

  componentDidMount() {
    if (this.props.complianceCurrentDate) {
      this.setState({
        currentDate: this.props.complianceCurrentDate,
      });
    }
    if (this.props.compliancePreviousDate) {
      this.setState({
        previousDate: this.props.compliancePreviousDate,
      });
    }
    if (this.props.month) {
      this.setState({
        month: this.props.month,
      });
    }
    if (this.props.selValue) {
      this.setState({
        month: this.props.selValue,
      });
    }

    // let month = moment(currentDate).subtract(3,'days').format('MMMM YYYY');
    // this.setState({
    //   ...this.state,
    //   month:month
    // })
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state !== prevState) {
      //this.props.changeDates(this.state.currentDate,this.state.previousDate)
    }
    //console.log("this.props.complianceCurrentDate::::", this.props.complianceCurrentDate, prevProps.complianceCurrentDate);
    //console.log("this.state.currentDate::::",this.state.currentDate,prevState.currentDate);
    //console.log("this.props.compliancePreviousDate::::", this.props.compliancePreviousDate, prevProps.compliancePreviousDate);
    if (
      this.props.complianceCurrentDate &&
      this.props.complianceCurrentDate != prevProps.complianceCurrentDate
    ) {
      this.setState({
        currentDate: this.props.complianceCurrentDate,
      });
    }

    let previousMonth = '';
    if (
      this.props.compliancePreviousDate &&
      this.props.compliancePreviousDate != prevProps.compliancePreviousDate
    ) {
      previousMonth =
        moment(this.props.compliancePreviousDate).month() !==
          moment(this.props.complianceCurrentDate).month()
          ? moment(this.props.compliancePreviousDate).format("MMM")
          : "";

      this.setState({
        previousDate: this.props.compliancePreviousDate,
        preMonth: previousMonth,
      });
    }
    
    if (this.props.month && this.props.month != prevProps.month) {
      let month = moment(this.props.month).format('MMM') + ' ' + moment(this.props.compliancePreviousDate).format('DD') + '-' + moment(this.props.complianceCurrentDate).format('DD');
      
      if (previousMonth != '') {
        month = previousMonth + ' ' + moment(this.props.compliancePreviousDate).format('DD') + '-' + moment(this.props.complianceCurrentDate).format('MMM') + ' ' + moment(this.props.complianceCurrentDate).format('DD');
      }

      setTimeout(() => this.setState({
        month: month,
      }))
    }

    if (this.props.selValue && this.props.selValue != prevProps.selValue) {
      // console.log("this.props.selValue::::",this.props.selValue)
      this.setState({ month: this.props.selValue })
      setTimeout(() => {
        //console.log("this.state.month::::",this.state.month);

      }, 300);
    }

  }

  prevWeek() {
    // console.log("this.state.currentDate prev:::", this.state.currentDate);
    
    let newCurrentDate = moment(this.state.currentDate).subtract(7, "days").format("YYYY-MM-DD");
    let newPreviousDate = moment(newCurrentDate).subtract(6, "days").format("YYYY-MM-DD");
    let newMonth = moment(newCurrentDate).format("MMM"); 
    let previousMonth =
      moment(newPreviousDate).month() !== moment(newCurrentDate).month()
        ? moment(newPreviousDate).format("MMM")
        : "";        
    let month = moment(newMonth).format('MMM') + ' ' + moment(newPreviousDate).format('DD') + '-' + moment(newCurrentDate).format('DD');
     

    if (previousMonth != '') {
      month = previousMonth + ' ' + moment(newPreviousDate).format('DD') + '-' + moment(newCurrentDate).format('MMM') + ' ' + moment(newCurrentDate).format('DD');
    }
   
    setTimeout(() => {
      this.setState({
        currentDate: newCurrentDate,
        previousDate: newPreviousDate,
        //month: newMonth,
        month: month,
        preMonth: previousMonth,
      });
      console.log("newCurrentDate:::", newCurrentDate);
      this.props.changeDates(newCurrentDate, newPreviousDate, month);
      //console.log("this.state.month:::", this.state.month);
    }, 200)



  }

  nextWeek() {
    let newCurrentDate = moment(this.state.currentDate).add(7, "days").format("YYYY-MM-DD");
    let newPreviousDate = moment(newCurrentDate).subtract(6, "days").format("YYYY-MM-DD");
    let newMonth = moment(newCurrentDate).subtract(3, "days").format("MMM");
    let previousMonth = moment(newPreviousDate).month() !== moment(newCurrentDate).month()? moment(newPreviousDate).format("MMM"): "";
    if (
      !moment(this.state.currentDate).isBefore(moment(Date.now()).subtract(1, "days"))
      //--------------------------- Uncomment the code for original changes & remove this code ( Staging )--------------------// 
      // moment(Date.now()).subtract(0, "days").format("YYYY-MM-DD")
/*-------------------------END - TVH-349 Change the start-date to Today's date & end-date to days-6 ------------------*/
    ) {
      return;
    } else {
      // console.log("previousMonth:::", previousMonth);
      // console.log("newCurrentDate::::", newCurrentDate);
      // console.log("newPreviousDate", newPreviousDate);
      // console.log("newMonth", newMonth);
      let month = moment(newMonth).format('MMM') + ' ' + moment(newPreviousDate).format('DD') + '-' + moment(newCurrentDate).format('DD');

      if (previousMonth != '') {
        month = previousMonth + ' ' + moment(newPreviousDate).format('DD') + '-' + moment(newCurrentDate).format('MMM') + ' ' + moment(newCurrentDate).format('DD');
      }
      console.log("month::", month);
      setTimeout(() => {
        this.setState({
          currentDate: newCurrentDate,
          previousDate: newPreviousDate,
          month: month,
          preMonth: previousMonth,
        });
        this.props.changeDates(newCurrentDate, newPreviousDate, month);
      }, 200);
    }

  }
  render() {
    return (
      <div className="font-figtree font-700 font-20 text-theme-gray-dark text-center">
        <span className="pointer">
          <ArrowBackIosNewOutlinedIcon
            onClick={() => {
              this.prevWeek();
            }}
          />
        </span>

        {/* {this.state.preMonth !== "" ? this.state.preMonth + " - " : ""} */}
        <span className="mx-5">{this.state.month}</span>
        <span className={moment(this.state.currentDate).format("YYYY-MM-DD") < moment(Date.now()).subtract(1, "days").format("YYYY-MM-DD") ? 'pointer' : 'disabled' }>
          <ArrowForwardIosIcon
            onClick={() => {
              this.nextWeek();
            }}
          />
        </span>

      </div>
    );
  }
}

export default ResultChartCalendarCompliance;
