import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import classnames from "classnames";
import { verifyEmailAction } from "../../../actions/forgotPasswordActions";
import * as routes from "../../../constants/routes";
import { alertService } from "./../../GlobalComponent/alertService";

class ForgotPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      errors: "",
      successMessage: "",
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.verifiedEmail !== prevProps.verifiedEmail) {
      if (this.props.verifiedEmail.data && this.props.verifiedEmail.token != '' && this.props.verifiedEmail.token !== null) {
        // alertService.success(
        //   `Reset password link sent to ${this.state.email} email address!`,
        //   { autoClose: true }
        // );
        this.setState({
          errors: '',
          successMessage: `Reset password link sent to ${this.state.email}.`,
        });
        //this.setState({"email":''});
      } else {
        if (this.props.verifiedEmail.data!==undefined) {
          this.setState({
            successMessage: '',
            errors:
              "Sorry, we do not recognize this email address. Please double check your spelling.",
          });
        }
      }
    }
  }

  onSubmit = async (e) => {
    e.preventDefault();

    var validEmailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,6})+$/;
    let email_domain = 'theravista.health';
    //var validEmailRegex = '^[A-Za-z0-9._%+-]+@' + email_domain + '$'
    if (!this.state.email.match(validEmailRegex)) {
      this.setState({
        errors: "Invalid email. Please enter your valid email address",
      });
      return false;
    }

    const Request = {
      username: this.state.email,
    };

    await this.props.verifyEmailAction(this.state.email);
  };

  render() {
    return (
      <div>
        <section className="user-section item-center">
          <div className="wave-canvas"></div>
          <div className="user-container">
            <figure className="brand-ui">
              <img src="images/Logo.png" alt="VestRx" style={{ maxHeight: 191 }} />
            </figure>
            <h2 className="pd-top-6 mb-5 pb-2 font-figtree font-700 font-46">Forgot your password?</h2>
            {this.state.errors !== "" && (
              <div className="error-msg-holder text-danger error-msg-forg-pass">
                {this.state.errors}
              </div>
            )}
            {this.state.successMessage !== "" && (
              <div className="error-msg-holder text-success">
                {this.state.successMessage}
              </div>
            )}
            <div className="mb-3 text-left">
              Enter your email address below and we'll send you a link <br />
              to your email to reset your password.
            </div>
            <div className="form-card">
              <label for="exampleInputEmail1" className="font-figtree font-700 font-15 mb-1">EMAIL</label>
              <input
                type="text"
                autoComplete="off"
                className={classnames("form-control font-18", {
                  "is-invalid": this.state.errors,
                })}
                placeholder="Enter your email address"
                name="email"
                value={this.state.email}
                onChange={(e) =>
                  this.setState({ email: e.target.value, errors: "" })
                }
              />
              {/* {this.state.errors !== "" && (
                  <div className="invalid-feedback">{this.state.errors}</div>
                )} */}
            </div>
            <button
              type="submit"
              disabled={this.state.email == "" ? true : false}
              className={`btn-all btn-color push-mt-30 ${this.state.email == "" ? "" : "active"
                }`}
              onClick={(e) => {
                this.onSubmit(e);
              }}
            >
              Request Link
            </button>
            <div
              onClick={() => this.props.history.push(routes.LOGIN)}
              className="text-secondary-theme py-3 font-figtree font-500 font-18 pointer text-left"
            >
              Back to login
            </div>
          </div>
        </section>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    verifiedEmail: state.forgotPassword.verifiedEmail,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      verifyEmailAction,
    },
    dispatch
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
