import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import Box from "@mui/material/Box";
import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  updatePatientProgram,
  updatePatientProgramFrequency,
} from "../../../../actions/patientProgramActions";
import { SaveSettingsSecond } from "./../../../../actions/setPatientProgramActions";
import { alertService } from "./../../../GlobalComponent/alertService";
import Progress from "./Progress";
import RightCornerPanel from "./RightCornerPanel";
const NumberOFSetsArray = [
  "VORx1_Yaw",
  "VORx1_Pitch",
  "Balance_weight_shift",
  "Balance_Sitting_Standing",
];
export class SettingsSecond extends Component {
  constructor(props) {
    super(props);
    this.state = {
      SettingsSecond: {
        VORx1_Yaw: [],
        VORx1_Pitch: [],
      },
      patientProgram: "",
      patientUsername: "",
      degreeError: "",
      DegreeErrorElement: [],
    };
  }

  componentDidMount() {
    if (
      this.props.patientProgram &&
      this.props.patientProgram != "" &&
      this.props.patientProgram != {}
    ) {
      this.storeDefaultValue();
    }

    if (this.props.patientUsername && this.props.patientUsername != "") {
      this.setState({ patientUsername: this.props.patientUsername });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      (this.props.patientProgram &&
        this.props.patientProgram !== {} &&
        this.props.patientProgram != prevProps.patientProgram) ||
      this.props.ProgramStatus !== prevProps.ProgramStatus ||
      this.props.EditProgramStatus !== prevProps.EditProgramStatus ||
      this.props.Exercise != prevProps.Exercise
    ) {
      this.setState({
        patientProgram: this.props.patientProgram,
        Exercise: this.props.Exercise,
        SettingsSecond: {
          VORx1_Yaw: [],
          VORx1_Pitch: [],
        },
        degreeError: "",
        DegreeErrorElement: [],
      });
      this.storeDefaultValue();
    }

    if (
      this.props.patientUsername &&
      this.props.patientUsername != prevProps.patientUsername
    ) {
      this.setState({ patientUsername: this.props.patientUsername });
    }
  }

  storeDefaultValue() {
    let patientProgram = this.props.patientProgram;
    if (
      Object.keys(this.props.patientProgram).length > 0 &&
      patientProgram != ""
    ) {
      patientProgram.prescriptionList.forEach((ele) => {
        switch (ele.name) {
          case "VORx1 Yaw": {
            if (this.props.Exercise && this.props.Exercise["VORx1_Yaw"]) {
              let prescriptionDisplayList = ele.prescriptionDisplayList;
              prescriptionDisplayList.forEach(async (val) => {
                if (
                  val.name == "Head Right Target" ||
                  val.name == "Yaw Velocity Target"
                ) {
                  let VORx1_Yaw = this.state.SettingsSecond.VORx1_Yaw;
                  VORx1_Yaw[val.name] = val.value;
                  await this.setState({
                    SettingsSecond: {
                      ...this.state.SettingsSecond,
                      VORx1_Yaw,
                    },
                  });
                  if (val.name == "Head Right Target") {
                    VORx1_Yaw["Head Left Target"] = val.value;
                    await this.setState({
                      SettingsSecond: {
                        ...this.state.SettingsSecond,
                        VORx1_Yaw,
                      },
                    });
                  }
                }
              });
            }
            break;
          }
          case "VORx1 Pitch": {
            if (this.props.Exercise && this.props.Exercise["VORx1_Pitch"]) {
              let prescriptionDisplayList = ele.prescriptionDisplayList;
              prescriptionDisplayList.forEach((val) => {
                if (
                  val.name == "Head Up Target" ||
                  val.name == "Head Down Target" ||
                  val.name == "Pitch Velocity Target"
                ) {
                  let VORx1_Pitch = this.state.SettingsSecond.VORx1_Pitch;
                  VORx1_Pitch[val.name] = val.value;

                  this.setState({
                    SettingsSecond: {
                      ...this.state.SettingsSecond,
                      VORx1_Pitch,
                    },
                  });
                }
              });
            }
            break;
          }
        }
      });
    }
  }

  onChangeValueHandlerVORx1_Yaw = (e) => {
    let VORx1_Yaw = this.state.SettingsSecond.VORx1_Yaw;
    VORx1_Yaw[e.target.name] = e.target.value.trim();
    this.setState({
      SettingsSecond: {
        ...this.state.SettingsSecond,
        VORx1_Yaw,
      },
      [e.target.id]: e.target.value.trim(),
      degreeError: "",
      DegreeErrorElement: [],
    });
    if (e.target.id == "VORx1_Yaw_Head_Right_Target") {
      VORx1_Yaw["Head Left Target"] = e.target.value.trim();
      this.setState({
        SettingsSecond: {
          ...this.state.SettingsSecond,
          VORx1_Yaw,
        },
        [e.target.id]: e.target.value.trim(),
      });
    }
  };

  onChangeValueHandlerVORx1_Pitch = (e) => {
    let VORx1_Pitch = this.state.SettingsSecond.VORx1_Pitch;
    VORx1_Pitch[e.target.name] = e.target.value.trim();

    this.setState({
      SettingsSecond: {
        ...this.state.SettingsSecond,
        VORx1_Pitch,
      },
      [e.target.id]: e.target.value.trim(),
      degreeError: "",
      DegreeErrorElement: [],
    });
  };

  OnSaveHandler = async () => {
    let valid = await this.validateData();
    if (valid) {
      await this.props.SaveSettingsSecond(this.state.SettingsSecond);
      if (this.props.ProgramStatus == true) {
        this.saveAllData();
      } else {
        this.saveUpdateData();
      }
    } else {
      return;
    }
  };

  saveAllData = async () => {
    let Exercises = this.props.Exercise;
    let ExerciseFrequency = this.props.ExerciseFrequency;
    let Frequency = this.props.Frequency;
    let SetFrequency = this.props.SetFrequency.DataArray;
    let SettingsFirst = this.props.SettingsFirst;
    let SettingsSecond = this.props.SettingsSecond;
    this.props.handlerAddPatientProgram(
      Exercises,
      ExerciseFrequency,
      Frequency,
      SetFrequency,
      SettingsFirst,
      SettingsSecond
    );
  };

  saveUpdateData = async () => {
    let Exercises = this.props.Exercise;
    let ExerciseFrequency = this.props.ExerciseFrequency;
    let Frequency = this.props.Frequency;
    let SetFrequency = this.props.SetFrequency.DataArray;
    let SettingsFirst = this.props.SettingsFirst;
    let SettingsSecond = this.props.SettingsSecond;
    let postPlayload = [];
    let obj = {};
    if (this.props.ExerciseAdded) {
      Object.keys(Exercises).map((key) => {
        let name = key.replace(/_/g, " ");
        obj = {
          name: name.replace("Balance Sitting Standing", "Balance Sitting/Standing"),
          value: Exercises[key],
          type: "Object",
        };
        postPlayload.push(obj);
      });
      Object.keys(ExerciseFrequency).map((key) => {
        obj = { name: key, value: ExerciseFrequency[key], type: "Object" };
        postPlayload.push(obj);
      });
      obj = {
        name: "sessionsPerDay",
        value: Frequency.sessionsPerDay ? Frequency.sessionsPerDay : 3,
        type: "Object",
      };
      postPlayload.push(obj);
      Object.keys(SetFrequency).map((key) => {
        let programVal = SetFrequency[key];
        Object.keys(programVal).map((progmKey) => {
          obj = {
            name: progmKey,
            value: programVal[progmKey],
            type: key.replace(/_/g, " ").replace("Balance Sitting Standing", "Balance Sitting/Standing"),
          };
          postPlayload.push(obj);
          if(progmKey=="Current"){
            obj = {
              name: 'At Intake',
              value: programVal[progmKey],
              type: key.replace(/_/g, " ").replace("Balance Sitting Standing", "Balance Sitting/Standing"),
            };
            postPlayload.push(obj);
            obj = {
              name: 'Goal',
              value: programVal[progmKey],
              type: key.replace(/_/g, " ").replace("Balance Sitting Standing", "Balance Sitting/Standing"),
            };
            postPlayload.push(obj);
          }
        });
      });
    }
    Object.keys(SettingsFirst).map((key) => {
      let programVal = SettingsFirst[key];
      Object.keys(programVal).map((progmKey) => {
        obj = {
          name: progmKey,
          value: programVal[progmKey],
          type: key.replace(/_/g, " ").replace("Balance Sitting Standing", "Balance Sitting/Standing"),
        };
        postPlayload.push(obj);
      });
    });
    Object.keys(SettingsSecond).map((key) => {
      let programVal = SettingsSecond[key];
      Object.keys(programVal).map((progmKey) => {
        obj = {
          name: progmKey,
          value: programVal[progmKey],
          type: key.replace(/_/g, " ").replace("Balance Sitting Standing", "Balance Sitting/Standing"),
        };
        postPlayload.push(obj);
      });
    });
    await this.props.updatePatientProgramFrequency(
      postPlayload,
      this.state.patientUsername
    );
    alertService.success("Record updated successfully!", {
      autoClose: true,
    });
  };
  validateData = () => {
    let DegreeErrorElement = [];
    let degreeError = "";
    if (this.state.SettingsSecond.VORx1_Yaw["Head Right Target"] < 10 || this.state.SettingsSecond.VORx1_Yaw["Head Right Target"] > 90) {
      degreeError = "Please enter a value between 10 and 90 for all targets";
      DegreeErrorElement.push('VORx1_Yaw_Head_Right_Target');

    }
    if (this.state.SettingsSecond.VORx1_Yaw["Yaw Velocity Target"] < 10 || this.state.SettingsSecond.VORx1_Yaw["Yaw Velocity Target"] > 90) {
      degreeError = "Please enter a value between 10 and 90 for all targets";
      DegreeErrorElement.push('VORx1_Yaw_Velocity_Target');

    }

    if (this.state.SettingsSecond.VORx1_Pitch["Head Up Target"] < 10 || this.state.SettingsSecond.VORx1_Pitch["Head Up Target"] > 90) {
      degreeError = "Please enter a value between 10 and 90 for all targets";
      DegreeErrorElement.push('VORx1_Pitch_Head_Up_Target');

    }
    if (this.state.SettingsSecond.VORx1_Pitch["Head Down Target"] < 10 || this.state.SettingsSecond.VORx1_Pitch["Head Down Target"] > 90) {
      degreeError = "Please enter a value between 10 and 90 for all targets";
      DegreeErrorElement.push('VORx1_Pitch_Head_Down_Target');

    }
    if (this.state.SettingsSecond.VORx1_Pitch["Pitch Velocity Target"] < 10 || this.state.SettingsSecond.VORx1_Pitch["Pitch Velocity Target"] > 90) {
      degreeError = "Please enter a value between 10 and 90 for all targets";
      DegreeErrorElement.push('VORx1_Pitch_Velocity_Target');

    }
    console.log("DegreeErrorElement:::", DegreeErrorElement);

    if (degreeError !== "") {
      this.setState({
        ...this.state,
        DegreeErrorElement: DegreeErrorElement,
        degreeError: degreeError,
      });
      return false;
    } else {
      return true;
    }
  };
  render() {
    return (
      <Modal
        show={this.props.showSettingsSecondModal}
        size={"xl"}
        className="theme-modal-1101 pt-5"
      >
        {this.props.patientInfo.newPatient === true &&
          <div className="p-1 absolute w-100 -top-23 bg-white rounded-20">
            <Progress value={100} />
          </div>
        }
        <Modal.Body>
          <Box
            component="form"
            autoComplete="off"
            className="p-3 rounded-20 border-0"
          >
            <div className="d-flex">
              <div className="w-75">
                <div
                  className="text-primary-theme font-figtree font-700 font-18 pointer d-inline-flex align-items-center mb-3"
                  onClick={() => {
                    this.props.handleCloseSettingsSecondModal();
                    this.props.handleOpenSettingsFirstModal();
                  }}
                >
                  <ArrowBackIosNewIcon fontSize="small" />
                  Back
                </div>
                <h4 className="font-figtree font-500 font-38">Settings</h4>
                <div className="mx-auto ml-2 ml-md-5 pl-0 pl-md-5 mt-5">
                  <div className="ml-md-5 pl-md-5 ml-auto pl-auto">
                    <h6 className="font-figtree font-600 font-18 text-theme-gray-dark mb-4">
                      Select the target for the following exercise(s):
                    </h6>
                    {this.props.Exercise.VORx1_Yaw && (
                      <>
                        <p className="font-figtree font-400 font-16 text-secondary-theme">
                          VORX1 YAW:
                        </p>
                        <p
                          className="frequency-section font-figtree font-400 font-16 d-flex align-items-center max-w-300px"
                          key=""
                        >
                          <span className="mr-auto">Head Target (deg):</span>
                          <input
                            type="text"
                            min="0"
                            autoComplete="off"
                            name={"Head Right Target"}
                            id={"VORx1_Yaw_Head_Right_Target"}
                            value={
                              this.state.SettingsSecond.VORx1_Yaw[
                              "Head Right Target"
                              ]
                            }
                            className={
                              this.state.DegreeErrorElement.includes(
                                'VORx1_Yaw_Head_Right_Target'
                              )
                                ? "border-danger"
                                : ""
                            }
                            onChange={(e) =>
                              this.onChangeValueHandlerVORx1_Yaw(e)
                            }
                          />
                        </p>
                        <p
                          className="frequency-section font-figtree font-400 font-16 d-flex align-items-center max-w-300px"
                          key=""
                        >
                          <span className="mr-auto">
                            Yaw Velocity Target (deg/sec):
                          </span>
                          <input
                            type="text"
                            min="0"
                            autoComplete="off"
                            name={"Yaw Velocity Target"}
                            id={`VORx1_Yaw_Velocity_Target`}
                            value={
                              this.state.SettingsSecond.VORx1_Yaw[
                              "Yaw Velocity Target"
                              ]
                            }
                            className={
                              this.state.DegreeErrorElement.includes(
                                'VORx1_Yaw_Velocity_Target'
                              )
                                ? "border-danger"
                                : ""
                            }
                            onChange={(e) =>
                              this.onChangeValueHandlerVORx1_Yaw(e)
                            }
                          />
                        </p>
                      </>
                    )}
                    {this.props.Exercise.VORx1_Pitch && (
                      <div className="mt-5">
                        <p className="font-figtree font-400 font-16 text-secondary-theme">
                          VORX1 PITCH:
                        </p>
                        <p
                          className="frequency-section font-figtree font-400 font-16 d-flex align-items-center max-w-300px"
                          key=""
                        >
                          <span className="mr-auto">
                            Head Target up (deg):
                          </span>
                          <input
                            type="text"
                            min="0"
                            autoComplete="off"
                            name={"Head Up Target"}
                            id={`VORx1_Pitch_Head_Up_Target`}
                            value={
                              this.state.SettingsSecond.VORx1_Pitch[
                              "Head Up Target"
                              ]
                            }
                            className={
                              this.state.DegreeErrorElement.includes(
                                'VORx1_Pitch_Head_Up_Target'
                              )
                                ? "border-danger"
                                : ""
                            }
                            onChange={(e) =>
                              this.onChangeValueHandlerVORx1_Pitch(e)
                            }
                          />
                        </p>
                        <p
                          className="frequency-section font-figtree font-400 font-16 d-flex align-items-center max-w-300px"
                          key=""
                        >
                          <span className="mr-auto">
                            Head Target down (deg):
                          </span>
                          <input
                            type="text"
                            min="0"
                            autoComplete="off"
                            name={"Head Down Target"}
                            id={`VORx1_Pitch_Head_Down_Target`}
                            value={
                              this.state.SettingsSecond.VORx1_Pitch[
                              "Head Down Target"
                              ]
                            }
                            className={
                              this.state.DegreeErrorElement.includes(
                                'VORx1_Pitch_Head_Down_Target'
                              )
                                ? "border-danger"
                                : ""
                            }
                            onChange={(e) =>
                              this.onChangeValueHandlerVORx1_Pitch(e)
                            }
                          />
                        </p>
                        <p
                          className="frequency-section font-figtree font-400 font-16 d-flex align-items-center max-w-300px"
                          key=""
                        >
                          <span className="mr-auto">
                            Pitch Velocity Target (deg/sec):
                          </span>
                          <input
                            type="text"
                            min="0"
                            autoComplete="off"
                            name={`Pitch Velocity Target`}
                            id={`VORx1_Pitch_Velocity_Target`}
                            value={
                              this.state.SettingsSecond.VORx1_Pitch[
                              "Pitch Velocity Target"
                              ]
                            }
                            className={
                              this.state.DegreeErrorElement.includes(
                                'VORx1_Pitch_Velocity_Target'
                              )
                                ? "border-danger"
                                : ""
                            }
                            onChange={(e) =>
                              this.onChangeValueHandlerVORx1_Pitch(e)
                            }
                          />
                        </p>
                      </div>
                    )}
                    <div className="text-danger text-center">
                      {this.state.degreeError !== ""
                        ? this.state.degreeError
                        : ""}
                    </div>
                  </div>
                </div>
              </div>
              <RightCornerPanel
                step={6}
                patientProgram={this.props.patientProgram}
              />
            </div>
            <div className="d-flex my-2 mt-md-3 mb-md-3">
              <button
                className="ml-auto mr-2 btn border border-primary-theme cursor-pointer rounded-14 btn-lg"
                variant="contained"
                onClick={(e) => {
                  e.preventDefault();
                  this.props.handleOpenCancelModal();
                }}
              >
                Cancel
              </button>
              <button
                className="bg-primary-theme text-white pointer rounded-14 btn-lg theme-btn border-0"
                variant="contained"
                disabled={
                  this.state.SettingsSecond.VORx1_Pitch["Head Up Target"] ==
                  "" ||
                  this.state.SettingsSecond.VORx1_Pitch["Head Down Target"] ==
                  "" ||
                  this.state.SettingsSecond.VORx1_Pitch[
                  "Pitch Velocity Target"
                  ] == "" ||
                  this.state.SettingsSecond.VORx1_Yaw["Head Right Target"] ==
                  "" ||
                  this.state.SettingsSecond.VORx1_Yaw["Yaw Velocity Target"] ==
                  ""
                }
                onClick={(e) => {
                  e.preventDefault();
                  this.OnSaveHandler();
                }}
              >
                Submit
              </button>
            </div>
          </Box>
        </Modal.Body>
      </Modal>
    );
  }
}
const style = {
  modal: {
    p: 4,
  },
};
function mapStateToProps(state) {
  return {
    Exercise: state.patientProgram.Exercise,
    ExerciseFrequency: state.patientProgram.ExerciseFrequency,
    Frequency: state.patientProgram.Frequency,
    SetFrequency: state.patientProgram.SetFrequency,
    SettingsFirst: state.patientProgram.SettingsFirst,
    SettingsSecond: state.patientProgram.SettingsSecond,
    ProgramStatus: state.patientProgram.startProgram,
    EditProgramStatus: state.patientProgram.startEditProgram,
    ExerciseAdded: state.patientProgram.ExerciseAdded,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      SaveSettingsSecond,
      updatePatientProgram,
      updatePatientProgramFrequency,
      SaveSettingsSecond,
    },
    dispatch
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(SettingsSecond);
