import React, { Component } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classnames from "classnames";
import { login } from "../../../actions/securityActions";
import * as routes from "../../../constants/routes";
import {  userLogout, awaituserLogout } from "../../../actions/index";
//import { styles } from '../mainStyles.js';
import Progress from "react-progress-2";
class Login extends Component {
  constructor() {
    super();
    this.state = {
      username: "", //doctor@gmail.com
      password: "", //password
      timezone: Intl.DateTimeFormat().resolvedOptions(),
      errors: {},
      validLoginInfo: false,
      timeout: new URLSearchParams(window.location.search).get("timeout"),
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    if (this.props.security && this.props.security.validToken) {
      this.props.history.push(routes.PATIENT);
    }
   
    if(localStorage.getItem("jwtToken") && localStorage.getItem("jwtToken")!=''){
      
      awaituserLogout();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.security && nextProps.security.validToken) {
      if (nextProps.security && nextProps.security.user.role) {
        console.log("nextProps.security.user.role::::",nextProps.security.user.role);
        if (nextProps.security.user.role == 'TVH ADMIN') {
          this.props.history.push(routes.USER);
          //window.location.href = routes.USER;
        } else {
          this.props.history.push(routes.PATIENT);
          //this.props.history.push(routes.USER);
          //window.location.href = routes.PATIENT;
        }
      }
    }
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }

  onSubmit(e) {
    e.preventDefault();
    const LoginRequest = {
      username: this.state.username,
      password: this.state.password,
    };

    this.props.login(LoginRequest);
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
    setTimeout((e) => {
      // console.log("this.state.username:::", this.state.username);
      // console.log("this.state.password:::", this.state.password);
      if (this.state.username == "" || this.state.password == "") {
        this.setState({ validLoginInfo: false });
      } else {
        this.setState({ validLoginInfo: true });
      }
      // console.log("validLoginInfo:::", this.state.validLoginInfo)
    }, 200);
  }

  render() {
    const { errors } = this.state;

    return (
      <div>
        <section className="user-section item-center">
          <div className="wave-canvas"></div>
          <div className="user-container">
            <figure className="brand-ui">
              <img src="images/Logo.png" alt="VestRx" style={{maxHeight:191}} />
            </figure>
            <h2 className="pd-top-6 font-figtree font-700 font-46">Login</h2>
            <div className="error-msg-holder">
              {errors.errorMessage && (
                <div className="error">{errors.errorMessage}</div>
              )}
              {!errors.errorMessage && this.state.timeout && (
                <div className="error">
                  You've been logged out due to inactivity
                </div>
              )}
            </div>
            <form onSubmit={this.onSubmit} className="d-flex flex-column">
              <div className="form-card">
                <label htmlFor="exampleInputEmail1" className="font-figtree font-700 font-15 mb-1">E-MAIL</label>
                <input
                  type="text"
                  autoComplete="off"
                  className={classnames("form-control font-18", {
                    "is-invalid": errors.username,
                  })}
                  placeholder="Enter your email address"
                  name="username"
                  value={this.state.username}
                  onChange={this.onChange}
                />
                {errors.username && (
                  <div className="invalid-feedback">{errors.username}</div>
                )}
              </div>

              <div className="form-card">
                <label htmlFor="exampleInputPassword1" className="font-figtree font-700 font-15 mb-1">PASSWORD</label>
                <input
                  type="password"
                  autoComplete="off"
                  className={classnames("form-control font-18", {
                    "is-invalid": errors.password,
                  })}
                  placeholder="Enter your password"
                  name="password"
                  value={this.state.password}
                  onChange={this.onChange}
                />
                {errors.password && (
                  <div className="invalid-feedback">{errors.password}</div>
                )}
              </div>
              <button type="submit" disabled={!this.state.validLoginInfo} className={`btn-all btn-color my-3 ${this.state.validLoginInfo ? "active" : ""}`}>Log in</button>
              <div onClick={() => this.props.history.push(routes.FORGOTPASSWORD)} className="text-theme-gray-dark font-figtree font-400 font-14 pointer d-inline-flex ml-auto">Forgot Password?</div>
            </form>
          </div>
        </section>
      </div>
    );
  }
}

Login.propTypes = {
  login: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  security: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  security: state.security,
  errors: state.errors,
});

export default connect(mapStateToProps, { login })(Login);
