import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import "fontsource-roboto";
import jwt_decode from "jwt-decode";
import React, { useEffect, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { connect, useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { bindActionCreators } from "redux";
import { SET_CURRENT_USER } from "../actions";
import { fetchNotifications, userLogout, awaituserLogout } from "./../actions/index";
import ProviderList from "./mainTabs/provider/ProviderList.js";
import KitList from "./mainTabs/kit/KitList.js";
import { toggleModal } from "../actions/index";
import * as routes from "../constants/routes";
import setJWTToken from "../securityUtils/setJWTToken";
import { Alert } from "./GlobalComponent/Alert";
import Login from "./mainTabs/authorize/Login";
import Error404 from "./mainTabs/error/error404";
import HeaderInactive from "./mainTabs/header/header-nav-invalid";
import HeaderActive from "./mainTabs/header/header-nav-valid";
import Help from "./mainTabs/Help/Help";
import PatientDetail from "./mainTabs/patient/patientDetail.js";
import PatientView from "./mainTabs/patient/patientView.js";
import PatientViewSuperAdmin from "./mainTabs/patient/patientViewSuperAdmin";
import theme from "./ui/Theme";
import NotificationManager from "./mainTabs/patient/NotificationManager";
import ForgotPassword from "./mainTabs/authorize/ForgotPassword";
import SetResetPassword from "./mainTabs/authorize/SetResetPassword";
import LogList from './mainTabs/log/LogList';
import { isMobile } from 'react-device-detect';
import TermsCondition from './mainTabs/TermsCondition/TermsCondition';

//import { useBeforeunload } from 'react-beforeunload';

//import useWindowFocus from 'use-window-focus';

// function getWindowDimensions() {
//   const { innerWidth: width, innerHeight: height } = window;
//   return {
//     width,
//     height,
//   };
// }

// function useWindowDimensions() {
//   const [windowDimensions, setWindowDimensions] = useState(
//     getWindowDimensions()
//   );

//   useEffect(() => {
//     function handleResize() {
//       setWindowDimensions(getWindowDimensions());
//     }

//     window.addEventListener("resize", handleResize);
//     return () => window.removeEventListener("resize", handleResize);
//   }, []);

//   return windowDimensions;
// }

function App({ fetchNotifications }) {
  let user = useSelector((state) => state.security.user);
  //const context = useSelector((state) => state.context);
  //const validToken = useSelector((state) => state.security.validToken);
  //const validLogin = validToken && user !== null;
  const validLogin = sessionStorage.getItem("jwtToken");
  setJWTToken(sessionStorage.getItem("jwtToken"));
  let Header = null;
  Header = validLogin ? HeaderActive : HeaderInactive;
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [value, setValue] = useState(0);
  //const { height, width } = useWindowDimensions();
  const [appStyle, setAppStyle] = useState({ pointerEvents: "auto" });
  const [showNotifications, setShowNotifications] = useState(false);
  // const [fetchInterval,setFetchInterval] = useState(5000);

  const dispatch = useDispatch();

  const showNot = useSelector((state) => state.notifications.notifications);

  let timeout = false;
  useEffect(() => {
    setShowNotifications(showNot);
  }, [showNot]);

  // useBeforeunload(() => {
  //   if (window.confirm("Are you sure to close this tab?")) {
  //   }
  //   return '';
  // });
  //const windowFocused = useWindowFocus();
  useEffect(() => {
    window.addEventListener("focusout", function (e) {
      // console.log("out side window::::");
      localStorage.setItem('outSideWindow', "yes");
      //sessionStorage.setItem('outSideWindow', "yes");
    });
    window.addEventListener("focus", function (e) {
      // console.log("out side window::::");
      localStorage.setItem('outSideWindow', "no");
      //sessionStorage.setItem('outSideWindow', "no");
    });

    let rDown = false;
    window.addEventListener("keydown", event => {
      if (event.key == 'r')
        rDown = true;
    })
    window.addEventListener("keyup", event => {
      if (event.key == 'r')
        rDown = false;
    })
    window.onbeforeunload = function (e) {
      localStorage.setItem('pageLoded', 'yes');
      if (sessionStorage.getItem('jwtToken')) {
        //userLogout();
        //if (redirection) {
        if (!e) e = window.event;
        var message = 'You may logout.';
        e.cancelBubble = true;
        e.returnValue = message;
        //window.location.href='<?php echo ''; ?>';

        return message;
        if (e.stopPropagation) {
          e.stopPropagation();
          e.preventDefault();
        }
      }
      //}
    };

    window.addEventListener("unload", event => {
      
      localStorage.setItem('on unload:', 'done');
      localStorage.setItem('islogout', 'yes');
      awaituserLogout(timeout);
      const time = Date.now();
      while ((Date.now() - time) < 700) {

      }
      return true;
    });
    window.addEventListener("load", event => {

      if (localStorage.getItem('islogout') == 'yes') {
        userLogout();
      }
      if (localStorage.getItem('pageLoded') == 'yes') {
        awaituserLogout(timeout);
      }
    })
    window.addEventListener("mousedown", event => {
      //alert(event.button);
      if (event.button == '2')
        rDown = true;
    })
    if (isMobile) {
      document.addEventListener("visibilitychange", function logData() {
        if (document.visibilityState === "hidden") {
          userLogout();
        }
      });
    }
  }, [])



  const handleOnIdle = (event) => {
    const modalProps = {};
    if (
      window.location.pathname !== "/" &&
      window.location.pathname !== "/login" &&
      window.location.pathname !== "/register"
    ) {
      timeout=true;
      awaituserLogout(timeout);
      //localStorage.removeItem("jwtToken");
      // window.location.href = "/login?timeout=true";
      //setAppStyle({ pointerEvents: "none" });
      // dispatch(
      //   toggleModal({
      //     modalProps: modalProps,
      //     type: "SHOW_MODAL",
      //     modalType: "Timeout",
      //   })
      // );
    }
  };

  const handleOnActive = (event) => { };

  const handleOnAction = (event) => { };
  /// added by Jeremy I think not sure what these are supposed to do: remember to ask
  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * 15,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500,
  });

  const jwtToken = sessionStorage.jwtToken;
  if (jwtToken) {
    setJWTToken(jwtToken);
    const decoded_jwtToken = jwt_decode(jwtToken);
    // console.log("decoded_jwtToken::::", Object.values(user).length);
    if (Object.values(user).length == 0) {
      dispatch({
        type: SET_CURRENT_USER,
        payload: decoded_jwtToken,
      });
    }

    // const currentTime = Date.now() / 1000
    // if (decoded_jwtToken.exp < currentTime) {
    //     store.dispatch(logout())
    //     window.location.href = '/'
    // }
  }

  useEffect(() => {
    if (jwtToken) {
      // console.log('Fetch Data')
      fetchNotifications();
      // setInterval(() => {
      //   fetchNotifications();
      // }, 10000);
    }
  }, [jwtToken]);

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <CssBaseline />
        <div className="h-100" style={appStyle}>
          <Header
            authUser={null}
            version={"Patient"}
            value={value}
            setValue={setValue}
            selectedIndex={selectedIndex}
            setSelectedIndex={setSelectedIndex}
          />
          {validLogin ? <Alert></Alert> : null}

          <Switch>
            <Route exact strict path={routes.LANDING} component={Login} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/forgotPassword" component={ForgotPassword} />
            <Route
              exact
              path={routes.SETPASSWORD}
              component={SetResetPassword}
            />
            <Route
              exact
              path={routes.RESETPASSWORD}
              component={SetResetPassword}
            />
            <Route
              exact
              path={routes.HELP}
              render={(props) => (
                <>
                  <Help
                    {...props}
                    setValue={setValue}
                    setSelectedIndex={setSelectedIndex}
                  />
                  {showNotifications && <NotificationManager />}
                </>
              )}
            />
            <Route
              exact
              path={routes.PATIENT}
              render={(props) => (
                <>
                  <PatientView
                    {...props}
                    setValue={setValue}
                    setSelectedIndex={setSelectedIndex}
                  />
                  {/* <PatientViewSuperAdmin 
                    {...props}
                    setValue={setValue}
                    setSelectedIndex={setSelectedIndex}
                    /> */}
                  {showNotifications && <NotificationManager />}
                </>
              )}
            />
            
            <Route
              exact
              path={routes.LOGS}
              render={(props) => (
                <>
                  <LogList
                    {...props}
                    setValue={setValue}
                    setSelectedIndex={setSelectedIndex}
                  />
                </>
              )}
            />
            <Route
              exact
              path={routes.PATIENTDETAIL}
              render={(props) => (
                <>
                  <PatientDetail
                    {...props}
                    setValue={setValue}
                    setSelectedIndex={setSelectedIndex}
                  />
                  {showNotifications && <NotificationManager />}
                </>
              )}
            />
            <Route
              exact
              path={routes.USER}
              render={(props) => (
                <>
                  <ProviderList
                    {...props}
                    setValue={setValue}
                    setSelectedIndex={setSelectedIndex}
                  />
                  {showNotifications && <NotificationManager />}
                </>
              )}
            />
            <Route
              exact
              path={routes.KITS}
              render={(props) => (
                <>
                  <KitList
                    {...props}
                    setValue={setValue}
                    setSelectedIndex={setSelectedIndex}
                  />
                  {showNotifications && <NotificationManager />}
                </>
              )}
            />
            <Route
              exact
              path={routes.SUPERADMINPATIENTS}
              render={(props) => (
                <>
                  <PatientViewSuperAdmin 
                    {...props}
                    setValue={setValue}
                    setSelectedIndex={setSelectedIndex}
                    />
                  {showNotifications && <NotificationManager />}
                </>
              )}
            />
            <Route
              exact
              path={routes.TERMSCONDITIONS}
              render={(props) => (
                <>
                  <TermsCondition
                    {...props}
                    setValue={setValue}
                    setSelectedIndex={setSelectedIndex}
                  />
                </>
              )}
            />
            <Route component={Error404} />
          </Switch>
        </div>
      </BrowserRouter>
    </ThemeProvider>
  );
}

function mapStateToProps(state) {
  return {
    modalState: state.modalState,
    notifications: state.notifications,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      toggleModal,
      fetchNotifications,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
