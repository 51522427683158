import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  fetchPatientProgram,
  fetchUserDetail,
  storingDefaultPatientProgramToRedux,
} from "../../../../actions/index";
import { updatePatientProgram } from "../../../../actions/patientProgramActions";
import { alertService } from "../../../GlobalComponent/alertService";
import CancelProgram from "../SetProgram/CancelProgram";
import ExerciseFrequency from "../SetProgram/ExerciseFrequency";
import Frequency from "../SetProgram/Frequency";
import SetFrequency from "../SetProgram/SetFrequency";
import SettingsFirst from "../SetProgram/SettingsFirst";
import SettingsSecond from "../SetProgram/SettingsSecond";
import { startPragram } from "./../../../../actions/setPatientProgramActions";
import Exercise from "./../SetProgram/Exercise";
import PatientProgramInfo from "./patientProgramInfo";

class PatientProgress extends Component {
  constructor(props) {
    super(props);
    this.state = {
      patientId: "",
      patientUsername: "",
      showExerciseModal: false,
      showExerciseFrequencyeModal: false,
      showFrequencySecondModal: false,
      showSetFrequencyModal: false,
      showSettingsFirstModal: false,
      showSettingsSecondModal: false,
      patientInfo: "",
      showCancelProgramModal: false,
      patientProgram: "",
    };
  }

  componentDidMount() {
    let patientId = this.props.patientId;
    this.setState({
      patientId: patientId,
      patientUsername: this.props.patientUsername,
    });

    if (this.props.patientUsername) {
      this.setState({ patientUsername: this.props.patientUsername });
    }

    if (this.props.patientInfo) {
      this.setState({ patientInfo: this.props.patientInfo });
    }
    setTimeout(() => {
      this.props.fetchPatientProgram(this.props.patientUsername);
    }, 200);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.patientProgram &&
      prevProps.patientProgram != this.props.patientProgram
    ) {
      this.setState({ patientProgram: this.props.patientProgram });
      this.props.storingDefaultPatientProgramToRedux(this.props.patientProgram);

      this.setState({ oldProgram: this.props.patientProgram });
    }

    if (
      this.props.patientProgramAdd &&
      prevProps.patientProgramAdd != this.props.patientProgramAdd
    ) {
      this.setState({ patientProgram: this.props.patientProgramAdd });
      this.props.fetchUserDetail(this.props.patientId);
      this.handleCloseSettingsSecondModal();
    }
    if (
      this.props.patientInfo &&
      prevProps.patientInfo != this.props.patientInfo
    ) {
      this.setState({ patientInfo: this.props.patientInfo });
    }
  }

  //Exercise modal
  handleOpenExerciseModal = () => this.setState({ showExerciseModal: true });
  handleCloseExerciseModal = () => {
    this.setState({ showExerciseModal: false });
  };
  //Exercise frequency modal
  handleOpenExerciseFrequencyModal = () => {
    this.setState({ showExerciseFrequencyeModal: true });
  };
  handleCloseExerciseFrequencyModal = () => {
    this.setState({ showExerciseFrequencyeModal: false });
  };
  //Frequency modal
  handleOpenFrequencyModal = () =>
    this.setState({ showFrequencySecondModal: true });
  handleCloseFrequencyModal = () =>
    this.setState({ showFrequencySecondModal: false });

  //setFrequency modal
  handleOpenSetFrequencyModal = () =>
    this.setState({ showSetFrequencyModal: true });
  handleCloseSetFrequencyModal = () =>
    this.setState({ showSetFrequencyModal: false });

  //SettingsFirstModal
  handleOpenSettingsFirstModal = () => {
    this.setState({ showSettingsFirstModal: true });
  };
  handleCloseSettingsFirstModal = () => {
    this.setState({ showSettingsFirstModal: false });
  };

  //SettingsSecondModal
  handleOpenSettingsSecondModal = () => {
    this.setState({ showSettingsSecondModal: true });
  };
  handleCloseSettingsSecondModal = () => {
    this.setState({ showSettingsSecondModal: false });
  };

  handleOpenCancelModal = () => {
    this.setState({ showCancelProgramModal: true });
  };
  handleCloseCancelModal = () => {
    this.setState({ showCancelProgramModal: false });
  };

  handleStopProgramEvent = () => {
    this.props.storingDefaultPatientProgramToRedux(this.props.patientProgram);
    this.setState({
      showExerciseModal: false,
      showExerciseFrequencyeModal: false,
      showFrequencySecondModal: false,
      showSetFrequencyModal: false,
      showSettingsFirstModal: false,
      showSettingsSecondModal: false,
      showCancelProgramModal: false,
    });
  };

  async handlerAddPatientProgram(
    Exercises,
    ExerciseFrequency,
    Frequency,
    SetFrequency,
    SettingsFirst,
    SettingsSecond = ""
  ) {
    let postPlayload = [];
    let obj = {};
    Object.keys(Exercises).map((key) => {
      let name = key.replace(/_/g, " ");
      obj = {
        name: name.replace("Balance Sitting Standing", "Balance Sitting/Standing"),
        value: Exercises[key],
        type: "Object",
      };
      postPlayload.push(obj);
    });
    Object.keys(ExerciseFrequency).map((key) => {
      obj = { name: key, value: ExerciseFrequency[key], type: "Object" };
      postPlayload.push(obj);
    });
    obj = {
      name: "sessionsPerDay",
      value: Frequency.sessionsPerDay ? Frequency.sessionsPerDay : 3,
      type: "Object",
    };
    postPlayload.push(obj);
    Object.keys(SetFrequency).map((key) => {
      let programVal = SetFrequency[key];
      Object.keys(programVal).map((progmKey) => {
        obj = {
          name: progmKey,
          value: programVal[progmKey],
          type: key.replace(/_/g, " ").replace("Balance Sitting Standing", "Balance Sitting/Standing"),
        };
        postPlayload.push(obj);
        if (progmKey == "Current") {
          obj = {
            name: 'At Intake',
            value: programVal[progmKey],
            type: key.replace(/_/g, " ").replace("Balance Sitting Standing", "Balance Sitting/Standing"),
          };
          postPlayload.push(obj);
          obj = {
            name: 'Goal',
            value: programVal[progmKey],
            type: key.replace(/_/g, " ").replace("Balance Sitting Standing", "Balance Sitting/Standing"),
          };
          postPlayload.push(obj);
        }
      });
    });
    Object.keys(SettingsFirst).map((key) => {
      let programVal = SettingsFirst[key];
      Object.keys(programVal).map((progmKey) => {
        obj = {
          name: progmKey,
          value: programVal[progmKey],
          type: key.replace(/_/g, " ").replace("Balance Sitting Standing", "Balance Sitting/Standing"),
        };
        postPlayload.push(obj);
      });
    });
    if (SettingsSecond && SettingsSecond != "") {
      Object.keys(SettingsSecond).map((key) => {
        let programVal = SettingsSecond[key];
        Object.keys(programVal).map((progmKey) => {
          obj = {
            name: progmKey,
            value: programVal[progmKey],
            type: key.replace(/_/g, " ").replace("Balance Sitting Standing", "Balance Sitting/Standing"),
          };
          postPlayload.push(obj);
        });
      });
    }
    await this.props.updatePatientProgram(
      postPlayload,
      this.state.patientUsername
    );
    alertService.success("Record added successfully!", {
      autoClose: true,
    });
  }

  render() {
    return (
      <>
        <Exercise
          //patient program
          patientProgram={this.state.patientProgram}
          patientUsername={this.state.patientUsername}
          //Exercise modal
          showExerciseModal={this.state.showExerciseModal}
          handleOpenExerciseModal={this.handleOpenExerciseModal}
          handleCloseExerciseModal={this.handleCloseExerciseModal}
          //Exercise frequency modal
          showExerciseFrequencyeModal={this.state.showExerciseFrequencyeModal}
          handleOpenExerciseFrequencyModal={
            this.handleOpenExerciseFrequencyModal
          }
          handleCloseExerciseFrequencyModal={
            this.handleCloseExerciseFrequencyModal
          }
          //Frequency modal
          showFrequencySecondModal={this.state.showFrequencySecondModal}
          handleOpenFrequencyModal={this.handleOpenFrequencyModal}
          handleCloseFrequencyModal={this.handleCloseFrequencyModal}
          //setFrequency modal
          showSetFrequencyModal={this.state.showSetFrequencyModal}
          handleOpenSetFrequencyModal={this.handleOpenSetFrequencyModal}
          handleCloseSetFrequencyModal={this.handleCloseSetFrequencyModal}
          handleOpenCancelModal={this.handleOpenCancelModal}
          patientInfo={this.state.patientInfo}
        />
        <ExerciseFrequency
          //patient program
          patientProgram={this.state.patientProgram}
          patientUsername={this.state.patientUsername}
          //Exercise modal
          showExerciseModal={this.state.showExerciseModal}
          handleOpenExerciseModal={this.handleOpenExerciseModal}
          handleCloseExerciseModal={this.handleCloseExerciseModal}
          //Exercise frequency modal
          showExerciseFrequencyeModal={this.state.showExerciseFrequencyeModal}
          handleOpenExerciseFrequencyModal={
            this.handleOpenExerciseFrequencyModal
          }
          handleCloseExerciseFrequencyModal={
            this.handleCloseExerciseFrequencyModal
          }
          //Frequency modal
          showFrequencySecondModal={this.state.showFrequencySecondModal}
          handleOpenFrequencyModal={this.handleOpenFrequencyModal}
          handleCloseFrequencyModal={this.handleCloseFrequencyModal}
          //setFrequency modal
          showSetFrequencyModal={this.state.showSetFrequencyModal}
          handleOpenSetFrequencyModal={this.handleOpenSetFrequencyModal}
          handleCloseSetFrequencyModal={this.handleCloseSetFrequencyModal}
          handleOpenCancelModal={this.handleOpenCancelModal}
          patientInfo={this.state.patientInfo}
        />
        <Frequency
          //patient program
          patientProgram={this.state.patientProgram}
          //Exercise modal
          showExerciseModal={this.state.showExerciseModal}
          handleOpenExerciseModal={this.handleOpenExerciseModal}
          handleCloseExerciseModal={this.handleCloseExerciseModal}
          //Exercise frequency modal
          showExerciseFrequencyeModal={this.state.showExerciseFrequencyeModal}
          handleOpenExerciseFrequencyModal={
            this.handleOpenExerciseFrequencyModal
          }
          handleCloseExerciseFrequencyModal={
            this.handleCloseExerciseFrequencyModal
          }
          //Frequency modal
          showFrequencySecondModal={this.state.showFrequencySecondModal}
          handleOpenFrequencyModal={this.handleOpenFrequencyModal}
          handleCloseFrequencyModal={this.handleCloseFrequencyModal}
          //setFrequency modal
          showSetFrequencyModal={this.state.showSetFrequencyModal}
          handleOpenSetFrequencyModal={this.handleOpenSetFrequencyModal}
          handleCloseSetFrequencyModal={this.handleCloseSetFrequencyModal}
          handleOpenCancelModal={this.handleOpenCancelModal}
          //patient username
          patientUsername={this.state.patientUsername}
          patientInfo={this.state.patientInfo}
        />
        <SetFrequency
          //patient program
          patientProgram={this.state.patientProgram}
          //Exercise modal
          showExerciseModal={this.state.showExerciseModal}
          handleOpenExerciseModal={this.handleOpenExerciseModal}
          handleCloseExerciseModal={this.handleCloseExerciseModal}
          //Exercise frequency modal
          showExerciseFrequencyeModal={this.state.showExerciseFrequencyeModal}
          handleOpenExerciseFrequencyModal={
            this.handleOpenExerciseFrequencyModal
          }
          handleCloseExerciseFrequencyModal={
            this.handleCloseExerciseFrequencyModal
          }
          //Frequency modal
          showFrequencySecondModal={this.state.showFrequencySecondModal}
          handleOpenFrequencyModal={this.handleOpenFrequencyModal}
          handleCloseFrequencyModal={this.handleCloseFrequencyModal}
          //setFrequency modal
          showSetFrequencyModal={this.state.showSetFrequencyModal}
          handleOpenSetFrequencyModal={this.handleOpenSetFrequencyModal}
          handleCloseSetFrequencyModal={this.handleCloseSetFrequencyModal}
          // settingsFirst Modal - Step.5 - On Next button Click
          showSettingsFirstModal={this.state.showSettingsFirstModal}
          handleOpenSettingsFirstModal={this.handleOpenSettingsFirstModal}
          // handleCloseSettingsFirstModal={this.handleCloseSettingsFirstModal}
          // handleCloseSettingsFirstModal={this.handleCloseSettingsFirstModal}
          //Cancel Modal
          handleOpenCancelModal={this.handleOpenCancelModal}
          //patient username
          patientUsername={this.state.patientUsername}
          patientInfo={this.state.patientInfo}
        />
        <SettingsFirst
          // ----- setFrequency Modal - on Back button click
          showSetFrequencyModal={this.state.showSetFrequencyModal}

          handleOpenSetFrequencyModal={this.handleOpenSetFrequencyModal}
          handleCloseSetFrequencyModal={this.handleCloseSetFrequencyModal}
          // ----- settingsFirst Modal - Step.5
          showSettingsFirstModal={this.state.showSettingsFirstModal}
          handleOpenSettingsFirstModal={this.handleOpenSettingsFirstModal}
          handleCloseSettingsFirstModal={this.handleCloseSettingsFirstModal}
          // ----- settingsSecond Modal - Step.6 - On Next button Click
          handleOpenSettingsSecondModal={this.handleOpenSettingsSecondModal}
          // ----- Cancel Program Modal
          handleOpenCancelModal={this.handleOpenCancelModal}
          // handleCloseCancelModal={this.handleCloseCancelModal}
          // ----- Prescription Data
          patientProgram={this.state.patientProgram}
          patientUsername={this.state.patientUsername}
          handlerAddPatientProgram={(
            Exercises,
            ExerciseFrequency,
            Frequency,
            SetFrequency,
            SettingsFirst
          ) =>
            this.handlerAddPatientProgram(
              Exercises,
              ExerciseFrequency,
              Frequency,
              SetFrequency,
              SettingsFirst
            )
          }
          patientInfo={this.state.patientInfo}
        />

        <SettingsSecond
          handleOpenSettingsFirstModal={this.handleOpenSettingsFirstModal}
          handleOpenSettingsSecondModal={this.handleOpenSettingsSecondModal}
          handleCloseSettingsSecondModal={this.handleCloseSettingsSecondModal}
          showSettingsSecondModal={this.state.showSettingsSecondModal}
          patientProgram={this.state.patientProgram}
          handleOpenCancelModal={this.handleOpenCancelModal}
          patientUsername={this.state.patientUsername}
          handlerAddPatientProgram={(
            Exercises,
            ExerciseFrequency,
            Frequency,
            SetFrequency,
            SettingsFirst,
            SettingsSecond
          ) =>
            this.handlerAddPatientProgram(
              Exercises,
              ExerciseFrequency,
              Frequency,
              SetFrequency,
              SettingsFirst,
              SettingsSecond
            )
          }
          patientInfo={this.state.patientInfo}
        />

        <CancelProgram
          showCancelProgramModal={this.state.showCancelProgramModal}
          handleOpenCancelModal={this.handleOpenCancelModal}
          handleCloseCancelModal={this.handleCloseCancelModal}
          handleStopProgramEvent={this.handleStopProgramEvent}
        />
        {this.state.patientInfo != "" && (
          <Grid item xs={12} md={12} className="mt-4">
            <div className="patient-detail-holder cust-card">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <div className="name-edit-btn">
                    <h3 className="font-figtree font-600 font-24">Program</h3>
                    {this.props.currentUser.role === "PROVIDER" && this.state.patientInfo.newPatient == true && (
                      <Button
                        variant="contained"
                        onClick={() => {
                          this.props.startPragram();
                          this.setState({
                            showExerciseModal: true,
                          });
                        }}
                        className="bg-primary-theme text-white pointer rounded-14 btn-lg theme-btn font-figtree font-700 font-18 border-0 py-2 text-normal"
                      >
                        Start new program
                      </Button>
                    )}

                    {this.state.patientUsername &&
                      this.state.patientInfo.newPatient === false &&
                      this.state.patientProgram && (
                        <PatientProgramInfo
                          patientId={this.state.patientId}
                          patientUsername={this.state.patientUsername}
                          patientProgram={this.state.patientProgram}
                          //Exercise Modal
                          handleOpenExerciseModal={this.handleOpenExerciseModal}
                          handleCloseExerciseModal={
                            this.handleCloseExerciseModal
                          }
                          //Exercise frequency modal
                          handleOpenExerciseFrequencyModal={
                            this.handleOpenExerciseFrequencyModal
                          }
                          handleCloseExerciseFrequencyModal={
                            this.handleCloseExerciseFrequencyModal
                          }
                          //Frequency modal
                          handleOpenFrequencyModal={
                            this.handleOpenFrequencyModal
                          }
                          handleCloseFrequencyModal={
                            this.handleCloseFrequencyModal
                          }
                          //setFrequency modal
                          handleOpenSetFrequencyModal={
                            this.handleOpenSetFrequencyModal
                          }
                          handleCloseSetFrequencyModal={
                            this.handleCloseSetFrequencyModal
                          }
                          //Settings First modal
                          handleOpenSettingsFirstModal={
                            this.handleOpenSettingsFirstModal
                          }
                          handleCloseSettingsFirstModal={
                            this.handleCloseSettingsFirstModal
                          }
                          //cancel program modal
                          handleOpenCancelModal={this.handleOpenCancelModal}
                        />
                      )}
                  </div>
                  {this.props.currentUser.role === "TVH SUPER ADMIN" && this.state.patientInfo.newPatient == true && (
                    <div className="font-500 font-size-16 mt-1">No program to show</div>
                  )}
                </Grid>
              </Grid>
            </div>
          </Grid>
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    patientProgram: state.patientProgram.patientProgram,
    patientProgramAdd: state.patientProgram.patientProgramAdd,
    currentUser: state.security.user,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      startPragram,
      fetchPatientProgram,
      storingDefaultPatientProgramToRedux,
      updatePatientProgram,
      fetchUserDetail,
    },
    dispatch
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(PatientProgress);
