import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
// import Modal from "@mui/material/Modal";
import Modal from "react-bootstrap/Modal";
import classnames from "classnames";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { PatternFormat, NumericFormat } from "react-number-format";
import TextField from "@mui/material/TextField";
import {
  addPatient,
  editPatient,
  fetchAllProviders,
  fetchSelectUser,
  filterUserTableData,
  fetchUserTableData,
  fetchKitsByLocation,
  fetchKitsByClinicReadyforPatients
} from "../../../../actions";
import { alertService } from "../../../GlobalComponent/alertService";

class PatientAdd extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lastName: "",
      firstName: "",
      email: "",
      EmailHelperText: "",
      Pin: "",
      PinHelperText: "",
      Kit: "",
      KitHelperText: "",
      MedicalRecordNumber: "",
      PhoneNumber: "",
      PhoneHelperText: "",
      doctorID: "",
      ptName: "",
      PatientAdded: false,
      AddedPatientDetails: null,
      Providers: [],
      Kits: [],
      KitDetail: ""
    };
  }

  componentDidMount() {
    if (this.props.mode === "Edit" && this.props.PatientDetails) {

      this.getProviders();
    }
    this.storeUserValues();
    this.getKitsByLocation();
    this.setState({ AddPatientModal: this.props.AddPatientModal });
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.PatientDetails &&
      prevProps.PatientDetails !== this.props.PatientDetails
    ) {
      this.storeUserValues();
      this.getProviders();
    }
    if (this.props.updatedpatientInfo !== prevProps.updatedpatientInfo) {
      this.setState({
        PatientAdded: false,
      });
      //this.props.handleClose();

    }
    if (this.props.patientEdit && this.props.patientEdit !== prevProps.patientEdit) {
      alertService.success("Patient updated successfully!", { autoClose: true });
      this.props.handleClose();
    }
    if (this.props.patientAdd !== prevProps.patientAdd) {
      this.props.handleClose();
    }
    if (this.props.errors && this.props.errors !== prevProps.errors) {
      alertService.error(this.props.errors.errorMessage);
     // this.setState({ AddPatientModal: true });
    }
    if (this.props.kit !== prevProps.kit) {
      console.log("this.props.kit:::", this.props.kit);
    }
    if (this.props.AddPatientModal!==undefined && this.props.AddPatientModal !== prevProps.AddPatientModal) {
      this.setState({ AddPatientModal: this.props.AddPatientModal });
      this.storeUserValues();
      this.getKitsByLocation();
    }
  }

  getProviders = async () => {
    let providersData = await this.props.fetchAllProviders();
    this.setState({ Providers: providersData.data });
  };

  getKitsByLocation = async () => {
    let kitsData = await this.props.fetchKitsByLocation();
    // let kitsData = this.props.currentUser.role=='TVH SUPER ADMIN'?await this.props.fetchKitsByClinicReadyforPatients() : await this.props.fetchKitsByLocation();
    this.setState({ Kits: kitsData.data.kits });
  };

  storeUserValues = () => {
    if (this.props.PatientDetails && this.props.PatientDetails.id) {
      this.setState({
        lastName: this.props.PatientDetails.lastName,
        firstName: this.props.PatientDetails.firstName,
        email: this.props.PatientDetails.email,
        Pin: this.props.PatientDetails.pin,
        Kit: this.props.PatientDetails.kit !== null ? this.props.PatientDetails.kit.id : '',
        MedicalRecordNumber: parseInt(
          this.props.PatientDetails.medicalRecordNumber
        ),
        PhoneNumber: this.props.PatientDetails.phoneNumber,
        doctorID: this.props.PatientDetails.ptId,
        ptName: this.props.PatientDetails.ptName,
        KitDetail: this.props.PatientDetails.kit,
      });
    } else {
      this.setState({
        lastName: "",
        firstName: "",
        email: "",
        Pin: "",
        Kit: '',
        MedicalRecordNumber: "",
        PhoneNumber: "",
        doctorID: "",
        ptName: "",
        KitDetail: "",
      });
    }
    setTimeout(() => {
      console.log("this.state.kitDetail:::", this.state.KitDetail);
    }, 500)

  };

  SubmitHandler = async (e) => {
    this.setState({
      EmailHelperText: "",
      PinHelperText: "",
      PhoneHelperText: "",
    });
    var validEmailRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    var validPhoneRegex = /^\d{10}$/;
    // var validPinRegex = /^\d{4}$/;

    // if (!this.state.email.match(validEmailRegex)) {
    //   this.setState({ EmailHelperText: "Invalid email" });
    //   return false;
    // }
    if (this.state.Pin.toString().length !== 4) {
      this.setState({ PinHelperText: "PIN must be 4 digits" });
      return false;
    }
    if (!this.state.PhoneNumber.replace(/\W/g, "").match(validPhoneRegex)) {
      this.setState({ PhoneHelperText: "Invalid phone number" });
      return false;
    }
    let payload = {
      email: this.state.email,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      pin: this.state.Pin,
      kitId: this.state.Kit,
      medicalRecordNumber: this.state.MedicalRecordNumber,
      phoneNumber: this.state.PhoneNumber,
    };

    if (this.props.mode === "Add") {
      this.AddPatientHandler(e, payload);
    } else {

      this.EditPatientHandler(payload);
    }
    // this.setState({
    //   lastName: "",
    //   firstName: "",
    //   email: "",
    //   EmailHelperText: "",
    //   Pin: "",
    //   PinHelperText: "",
    //   Kit: "",
    //   KitHelperText: "",
    //   MedicalRecordNumber: "",
    //   PhoneNumber: "",
    //   PhoneHelperText: "",this.props.patientId
    // });
  };

  AddPatientHandler = async (e, payload) => {

    let res = await this.props.addPatient(payload);
    if (res) {
      if ((this.props.patientId && this.props.patientId != '') || (this.props.isTrue)) {
        await this.props.fetchUserTableData(
          "information",
          this.props.page,
          this.props.perPage,
          this.props.sortColumn,
          this.props.sortOrder,
          this.props.isTrue,
          this.props.patientId
        );
      } else {
        await this.props.filterUserTableData(
          "information",
          this.props.page,
          this.props.perPage,
          this.props.sortColumn,
          this.props.sortOrder,
          this.props.isTrue,
          this.props.patientId,
          this.props.complianceFilter,
          this.props.providersFilter,
        );
      }
      this.setState({
        PatientAdded: true,
        AddedPatientDetails: res.data.selectedUser,
      });
    }
  };

  EditPatientHandler = async (payload) => {
    payload.doctorId = this.state.doctorID;
    let res = await this.props.editPatient(payload);
    if (res) {
    }
  };

  onChangeValueHandler = (e) => {
    let value = e.target.value.trim();
    if (e.target.id == 'MedicalRecordNumber') {
      value = value.replace(/[^0-9]/g, '');
    }
    if (e.target.id == 'firstName' || e.target.id == 'lastName') {
      value = value.replace(/[^a-zA-Z0-9]/g, '');
    }
    this.setState({ [e.target.id]: value });
  };
  accessNewPatient(id) {
    alert(id);
    this.props.history.push('patientdetail/' + id);
  }
  render() {
    const { history } = this.props;
    return (
      <>

        {this.state.PatientAdded && this.props.mode === "Add" ? (
          <Alert
            className="d-flex position-absolute z-9999 left-0 right-0 mx-auto max-w-750 alert-theme text-white rounded-20"
            severity="success"
            onClose={() => {
              this.setState({ PatientAdded: false });
            }}
          >
            <div className="d-flex w-100 align-items-center justify-content-center">
              <img src="images/check-white.svg" alt="check" className="mr-3" />
              <span className="font-figtree font-700 font-26px">
                Patient added successfully!{" "}
              </span>
            </div>
            <div
              className="text-white font-figtree font-500 font-22px text-decoration-underline"
              onClick={() => history.push('/patientdetail/' + this.state.AddedPatientDetails?.id)}
            >
              View Patient
            </div>
          </Alert>
        ) : null}
        <Modal
          show={this.state.AddPatientModal}
          onHide={this.props.handleClose}
          className="theme-modal"
          backdrop="static"
          keyboard={false}
        >
          <Modal.Body>
            <Box
              sx={style.modal}
              component="form"
              autoComplete="off"
              className="p-0 w-100 border-0 max-646">
              <div className="border-solid border-4 border-primary-theme rounded-30 position-relative modal-internal">
                <div className="position-absolute bg-white p-1 -top-23 left-40 text-primary-theme font-figtree font-700 font-24px">
                  {this.props.mode === "Add"
                    ? "Add New Patient"
                    : "Edit Patient Profile"}
                </div>
                <div className="bg-white p-2 position-absolute -top-23 -right-20 rounded-20">
                  <img
                    src={
                      this.props.mode === "Add"
                        ? "images/modal-close.svg"
                        : "../images/modal-close.svg"
                    }
                    alt="close"
                    className="pointer"
                    onClick={(e) => {
                      e.preventDefault();
                      this.props.handleClose();
                    }}
                  />
                </div>

                <div className="form-card no-border mb-4">
                  <label className="font-figtree font-700 font-15 mb-1" for="exampleInputEmail1">LAST NAME</label>
                  <input
                    type="text"
                    autoComplete="off"
                    className={classnames("form-control font-18")}
                    placeholder="Enter last name"
                    name="lastName"
                    id="lastName"
                    value={this.state.lastName}
                    onChange={(e) => this.onChangeValueHandler(e)}
                    maxLength='20'
                  />
                </div>

                <div className="form-card no-border mb-4">
                  <label
                    className="font-figtree font-700 font-15 mb-1"
                    for="exampleInputEmail1"
                  >
                    FIRST NAME
                  </label>
                  <input
                    type="text"
                    autoComplete="off"
                    className={classnames("form-control font-18")}
                    placeholder="Enter first name"
                    name="firstName"
                    id="firstName"
                    value={this.state.firstName}
                    onChange={(e) => this.onChangeValueHandler(e)}
                    maxLength='20'
                  />
                </div>

                {/* <div className={"form-card no-border mb-4 " + (this.props.mode == "Edit" ? 'd-none' : '')}>
                  <label
                    className="font-figtree font-700 font-15 mb-1"
                    for="exampleInputEmail1"
                  >
                    EMAIL
                  </label>
                  <input
                    type="text"
                    autoComplete="off"
                    className={classnames("form-control font-18", {
                      "is-invalid": this.state.EmailHelperText,
                    })}
                    placeholder="Enter email address"
                    name="email"
                    id="email"
                    value={this.state.email}
                    onChange={(e) => this.onChangeValueHandler(e)}

                  />
                  {this.state.EmailHelperText && (
                    <div className="invalid-feedback">
                      {this.state.EmailHelperText}
                    </div>
                  )}
                </div> */}

                <div className="form-card no-border mb-4">
                  <label
                    className="font-figtree font-700 font-15 mb-1"
                    for="exampleInputEmail1"
                  >
                    PIN
                  </label>
                  {/* <input
                  type="number"
                  autoComplete="off"
                  className={classnames("form-control font-18", {
                    "is-invalid": this.state.PinHelperText,
                  })}
                  placeholder="Enter PIN"
                  maxlength="4"
                  name="Pin"
                  id="Pin"
                  value={this.state.Pin}
                  onChange={(e) => this.onChangeValueHandler(e)}
                /> */}
                  <PatternFormat
                    customInput={TextField}
                    format="####"
                    mask=""
                    className={classnames("form-control font-18 h-auto", {
                      "is-invalid": this.state.PinHelperText,
                    })}
                    placeholder="Enter 4 digit pin#"
                    name="Pin"
                    id="Pin"
                    onChange={(e) => this.onChangeValueHandler(e)}
                    value={this.state.Pin}
                  />
                  {this.state.PinHelperText && (
                    <div className="invalid-feedback mt-3">
                      {this.state.PinHelperText}
                    </div>
                  )}
                </div>

                <div className="form-card no-border mb-4 select-custom-arrow">
                  <label
                    className="font-figtree font-700 font-15 mb-1"
                    for="exampleInputEmail1"
                  >
                    KIT #
                  </label>
                  {/* <PatternFormat 
                    format="##########"
                    customInput={TextField}
                    className={classnames("form-control font-18 h-auto")}
                    placeholder="Enter kit number"
                    name="Kit"
                    id="Kit"
                    onChange={(e) => this.onChangeValueHandler(e)}
                    value={this.state.Kit}
                  /> */}
                  <select
                    value={this.state.Kit}
                    id="Kit"
                    onChange={(e) => this.onChangeValueHandler(e)}
                    className={classnames(
                      "form-control font-18 rounded-14 border-dark border h-auto p-2"
                    )}
                    disabled={this.state.KitDetail && this.state.KitDetail !== '' ? "disabled" : false}

                  >
                    {this.state.KitDetail && this.state.KitDetail !== '' &&
                      <option value="">{this.state.KitDetail.kitNumber}</option>
                    }
                    <option value="">-</option>
                    {this.state.Kits.map((item) => {
                      return <option value={item.id}>{item.kitNumber}</option>;
                    })}
                  </select>
                </div>

                <div className="form-card no-border mb-4">
                  <label
                    className="font-figtree font-700 font-15 mb-1"
                    for="exampleInputEmail1"
                  >
                    MEDICAL RECORD # (MRN)
                  </label>
                  <PatternFormat
                    format="##########"
                    customInput={TextField}
                    className={classnames("form-control font-18 h-auto")}
                    placeholder="Enter medical record number"
                    name="MedicalRecordNumber"
                    id="MedicalRecordNumber"
                    value={this.state.MedicalRecordNumber}
                    onChange={(e) => this.onChangeValueHandler(e)}

                  />
                </div>

                <div className="form-card no-border mb-4">
                  <label
                    className="font-figtree font-700 font-15 mb-1"
                    for="exampleInputEmail1"
                  >
                    PHONE NUMBER
                  </label>

                  <PatternFormat
                    customInput={TextField}
                    format="(###) ###-####"
                    mask="_"
                    className={classnames("form-control font-18 h-auto", {
                      "is-invalid": this.state.PhoneHelperText,
                    })}
                    placeholder="Enter phone number"
                    id="PhoneNumber"
                    name="PhoneNumber"
                    onChange={(e) => this.onChangeValueHandler(e)}
                    value={this.state.PhoneNumber}
                  />
                  {this.state.PhoneHelperText && (
                    <div className="invalid-feedback mt-3">
                      {this.state.PhoneHelperText}
                    </div>
                  )}
                </div>

                {this.props.mode === "Edit" && (
                  <div className="form-card no-border mb-4 select-custom-arrow">
                    <label
                      className="font-figtree font-700 font-15 mb-1"
                      for="exampleInputEmail1"
                    >
                      PROVIDER NAME
                    </label>
                    <select
                      value={this.state.doctorID}
                      onChange={(e) =>
                        this.setState({ doctorID: e.target.value })
                      }
                      className={classnames(
                        "form-control font-18 rounded-14 border-dark border h-auto p-2"
                      )}
                    >
                      {this.state.Providers.map((item) => {
                        return <option value={item.id}>{item.name}</option>;
                      })}
                    </select>
                  </div>
                )}
                <div className="d-flex mt-5 pt-4">
                  <button
                    className="ml-auto mr-2 bg-transparent border cursor-pointer rounded-20 font-figtree font-700 font-26 px-4 py-2 text-primary-theme"
                    variant="contained"
                    onClick={(e) => {
                      e.preventDefault();
                      this.props.handleClose();
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="bg-primary-theme text-white pointer rounded-20 theme-btn border-0 font-figtree font-700 font-26 px-4 py-2"
                    disabled={
                      this.state.firstName == "" ||
                      this.state.lastName == "" ||
                      this.state.Pin == "" ||
                      this.state.MedicalRecordNumber == "" ||
                      this.state.PhoneNumber == ""
                    }
                    variant="contained"
                    onClick={(e) => {
                      e.preventDefault();
                      this.SubmitHandler(e);
                    }}
                  >
                    {this.props.mode === "Add" ? "Add" : "Save"}
                  </button>
                </div>
              </div>
            </Box>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
const style = {
  modal: {
    p: 7,
  },
  inputFeild: {
    width: "100%",
    marginBottom: 2,
  },
};

function mapStateToProps(state) {
  return {
    updatedpatientInfo: state.userTableData,
    patientEdit: state.patient.editPatient,
    patientAdd: state.patient.addPatient,
    errors: state.errors,
    kit: state.kit.kitList,
    currentUser: state.security.user,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addPatient,
      editPatient,
      fetchAllProviders,
      fetchSelectUser,
      filterUserTableData,
      fetchUserTableData,
      fetchKitsByLocation,
      fetchKitsByClinicReadyforPatients
    },
    dispatch
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(PatientAdd);