import { createTheme } from "@material-ui/core";

const vestBlue = "#0B7289";
const vestBlack = "#000000";
const vestOrange = "#FFBA60";
const vestWhite = "#FFFFFF";
const vestGrey = "#EEEEEE";
const themeType = "light";
//const themeType = "dark";


export default createTheme({
 
  typography: {
    type: themeType,
  },
  paperRoot: {
    backgroundColor: 'red'
  },
  AppBar:{
    backgroundColor: "#472E74",
  },
  tab: {
    fontWeight: 800,
    textTransform: "none",
    color: `${vestBlue}`,
   // backgroundColor: `${vestBlue}`,
    fontSize: "1.5rem",
    minWidth: 10,
    marginLeft: "25px",
  },
  header: {
    backgroundColor: `${vestWhite}`,
  },

  palette: {
    common: {
      blue: `${vestBlue}`,
      orange: `${vestOrange}`,
      grey: `${vestGrey}`,
      black: `${vestBlack}`,
      bluer: `${vestBlue}`,
    },
    primary: {
      main: `${vestWhite}`,
    },
    secondary: {
      main: `${vestOrange}`,
    },
   //background: {
   // default:`${vestBlue}`
  //},
    type: themeType,
  },

 
  /** 
  root: {
    type: themeType,
    background: "linear-gradient(45deg, #FE688B, #FF8E53)",
    border: 0,
    marginBottom: 15,
    borderRadius: 15,
    color: "purple",
    padding: "5px 30px",
  },
  **/
});
