import axios from "axios";
import setJWTToken from "../securityUtils/setJWTToken";
import jwt_decode from "jwt-decode";
import { localhost, GET_ERRORS } from "../actions/index";
//for authorization
export const EMAIL_CHANGED = "email_changed";
export const PASSWORD_CHANGED = "password_changed";
export const LOGIN_USER_FAIL = "login_user_fail";
export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_STATUS = "LOGIN_STATUS";
export const LOGOUT_USER = "LOGOUT_USER";
export const USER_NAME_SESSION_ATTRIBUTE_NAME = "authenticatedUser";
export const SET_CURRENT_USER = "SET_CURRENT_USER";

export const createNewUser = (newUser, callback) => async (dispatch) => {
  try {
    await axios.post(`${localhost}/cap/api/users/register`, newUser);
    callback.push("/login");
    dispatch({
      type: GET_ERRORS,
      payload: {},
    });
  } catch (err) {
    if (err.response && err.response.data) {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    }
  }
};

export const login = (LoginRequest) => async (dispatch) => {
  try {
    // post => Login Request
    const res = await axios.post(
      `${localhost}/cap/api/users/v1/login`,
      LoginRequest,
      {
        headers: {
          isWeb: true,
        }
      }
    );
    console.log(" res.data:::", res.data);
    // extract token from res.data
    let { token } = res.data;
    let decoded = '';
    if (token !== '' && token !== null) {

      // store the token in the sessionStorage
      sessionStorage.setItem("jwtToken", token);
      console.log("token::::", sessionStorage.getItem('jwtToken'));
      //localStorage.setItem('login time::', Date())
      localStorage.setItem("jwtToken", token);
      // set our token in header ***
      //setJWTToken(token);
      //console.log(token);
      //setupAxiosInterceptors(token);
      // decode token on React
      decoded = jwt_decode(token);
      // dispatch to our securityReducer
      dispatch({
        type: SET_CURRENT_USER,
        payload: decoded,
      });
    }
    else {
      // dispatch({
      //   type: SET_CURRENT_USER,
      //   payload: decoded,
      // }); 
    }
  } catch (err) {
    if (err.response && err.response.data) {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    }
  }
};

export function setupAxiosInterceptors(token) {
  axios.interceptors.request.use((config) => {
    if (token) {
      config.headers.authorization = token;
    }
    return config;
  });
}

export async function userLogout(dispatch) {
  if (sessionStorage.getItem("jwtToken") && sessionStorage.getItem("jwtToken") !== null) {


    axios.get(
      `${localhost}/cap/logout/audit`,
    );
    //localStorage.setItem("in if:::","yes");
    localStorage.removeItem('pageLoded');
    localStorage.removeItem('islogout');
    localStorage.removeItem('jwtToken');
    localStorage.setItem("Api called:::", "yes");

    sessionStorage.removeItem("jwtToken");
    setJWTToken(false);
    window.location.href = "/";

  }
  else {
    localStorage.removeItem('islogout');
    localStorage.setItem('inelse', 'yes');
    //localStorage.removeItem('jwtToken');
  }


  //const { token } = res.data;


  //setValue(0);

  // dispatch({
  //   type: SET_CURRENT_USER,
  //   payload: {},
  // });
};


export async function awaituserLogout(timeout) {
  if (localStorage.getItem("jwtToken") && localStorage.getItem("jwtToken") !== null) {

    let browserSupportsKeepalive = 'keepalive' in new Request('')

    // if (browserSupportsKeepalive === true) {
      fetch(`${localhost}/cap/logout/audit`, {
        keepalive: true,
        method: 'get',
        headers: new Headers({
          'Authorization': localStorage.getItem("jwtToken"),
          'Content-Type': 'application/x-www-form-urlencoded'
        }),
      }).then(() => {
        localStorage.removeItem('pageLoded');
        localStorage.removeItem('jwtToken');
        sessionStorage.removeItem("jwtToken");
        setJWTToken(false);
        
        window.location.href = timeout?"/login?timeout=true":"/";
      });
    // } else {
    //     let client = new XMLHttpRequest();
    //     client.open("get", `${localhost}/cap/logout/audit`);
    //     client.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    //     client.setRequestHeader("Connection", "Keep-Alive");
    //     client.setRequestHeader("Keep-Alive", "timeout=5, max=1000");
    //     client.send();
     
    // }
      


    
    // await axios.get(
    //   `${localhost}/cap/logout/audit`,
    // ).then(() => {
    //   localStorage.removeItem('pageLoded');
    //   localStorage.removeItem('jwtToken');
    //   sessionStorage.removeItem("jwtToken");
    //   setJWTToken(false);
    //   window.location.href = "/";
    // }).catch(() => {
    //   fetch(`${localhost}/cap/logout/audit`, {
    //     method: 'get',
    //     headers: new Headers({
    //       'Authorization': localStorage.getItem("jwtToken"),
    //       'Content-Type': 'application/x-www-form-urlencoded'
    //     }),
    //   }).then(() => {
    //     window.location.href = "/";
    //     localStorage.removeItem('jwtToken');
    //   });
    // });
    //localStorage.setItem("in if:::","yes");



  }



};
