import { localhost } from "./Actions";
import axios from "axios";
import { SHOW_NOTIFICATION, FETCH_NOTIFICATIONS, UNREAD_NOTIFICATION_COUNT, SHOW_NOTIFICATION_BLOCK, FETCH_NOTIFICATION_STATUS_BLOCK_DATA, READ_NOTIFICATION } from './../actions/index';
//import data from './../components/mainTabs/patient/patientDetail/test-Notifications';

//const qualifier = 'cap';

export const showNotificationList = (showNotificationStatus) => async (dispatch) => {
    // console.log('SHOW NOTIFICATIONS ====> > >', showNotificationStatus)
    try {
        dispatch({
            type: SHOW_NOTIFICATION,
            payload: showNotificationStatus
        });
    } catch (err) {
        console.log('Noti Error=>', err)
    }
}

export const fetchNotifications = () => async (dispatch) => {
    let res;

    const url = `${localhost}/cap/notification`;
    let params = {
        page: -1,
    };
    let notificationResults = await axios.get(url, { params });
    res = notificationResults.data.notificationList;

    const countUnread = res.filter(m=>m.notificationRead===false).length;
    dispatch({
        type: FETCH_NOTIFICATIONS,
        payload: res
    });
    dispatch({
        type: UNREAD_NOTIFICATION_COUNT,
        payload: countUnread
    })
}

export const showNotificationBlock = (status) => async (dispatch) => {
    dispatch({
        type: SHOW_NOTIFICATION_BLOCK,
        payload: status,
    })
}

export const fetchNotificationStatusBlockData = (data) => (dispatch) => {

    dispatch({
        type: FETCH_NOTIFICATION_STATUS_BLOCK_DATA,
        payload: data
    })
}

export const fetchPatientNotifications = (patient_Id) => async (dispatch) => {
    let res;
    let url = `${localhost}/cap/notification/patient`;
    let params = {
        patientId: patient_Id,
        page: -1
    }

    res = await axios.get(url, { params });
    // console.log('Patient Notifications ===>', res.data.notificationList);

    dispatch({
        type: FETCH_NOTIFICATION_STATUS_BLOCK_DATA,
        payload: res.data.notificationList
    })
}

// export const fetchUnreadNotifications = (pId) => (dispatch) => {
//     let res;
//     // const url = `${localhost}/cap/notification/patient`;
//     // const params = {
//     //     page: -1
//     // }
//     // res = axios.get(url,{params});

//     res = data.filter(m=> m.user===pId)
//     console.log('Data for Change',res)
//     dispatch({
//         type: FETCH_NOTIFICATION_STATUS_BLOCK_DATA,
//         payload:res
//     })

//     // return res;
// }

// export const clickOutsideBox = (status) => (dispatch) => {
//     dispatch({
//         type: CLICK_OUTSIDE_BOX,
//         payload:status
//     })
// }

export const readNotification = (patientId, notificationId) => async (dispatch) => {
    //  console.log('Read Notification: ',notificationId,' for patient: ',patientId);
    let res;

    //  console.log('Read Notification',patientId,notificationId);
    //  res = {
    //     status:200,
    //     data:{
    //         successMessage:"Successfully Readed Notification"
    //     }
    //  }
    const url = `${localhost}/cap/notification/read`;
    let params = {
        patientId: patientId,
        notificationId: notificationId

    };
    res = await axios.get(url, { params });
    if (res.status === 200 && res.data.successMessage === "Successfully Readed Notification") {
        dispatch({
            type: READ_NOTIFICATION,
            payload: res
        });
    }
    return res;

 };

//  return res;
