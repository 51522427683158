import axios from "axios";
import { localhost, handleError } from "./Actions";
//import Helpdata from "./../components/mainTabs/Help/HelpData.js";

//set program actions
export const FETCH_HELP_DATA = "FETCH_HELP_DATA";

const qualifier = "cap";

export const fetchHelpData = () => async (dispatch) => {
  try {
    const FETCH_URL = `${localhost}/${qualifier}/getFaqsByStatus?status=true`;
    let url = `${FETCH_URL}`;
    let res = await axios.get(url);
    // console.log("Helpdata", Helpdata);
    dispatch({
      type: FETCH_HELP_DATA,
      payload: res,
    });
  } catch (error) {
    handleError(error, dispatch);
  }
};
