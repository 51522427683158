import React, { Component } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { connect } from "react-redux";
import { fetchUserTableData, searchPatientData } from "../../../actions/index";
import { bindActionCreators } from "redux";
const WAIT_INTERVAL = 500
let timerID
export class AutoSearchBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchList: [],
      page: 1,
      perPage: 10,
      totalItems: 10,
      sortColumn: "lastName",
      sortOrder: "desc",
      patientId: "",
      onlyMe: "",
      searchDataOptions: [],
      searchString: null,
    };
  }

  componentDidMount() {
    this.setState({
      onlyMe: this.props.onlyMe,
      filterCount: this.props.filterCount
    });
  }

  componentDidUpdate(prevState, prevProps) {
    if (this.props.onlyMe !== this.state.onlyMe) {
      this.setState({
        searchString: "",
        onlyMe: this.props.onlyMe,
      });
    }
    if (this.props.filterCount && this.props.filterCount !== this.state.filterCount) {
      if (this.props.filterCount > 0) {
        this.setState({
          searchString: "",
          filterCount: this.props.filterCount
        });
      }
    }
  }

  componentWillReceiveProps(props) {
    this.setState({
      sortColumn: props.sortColumn,
      sortOrder: props.sortOrder,
    });
  }
  async autoSearchHandler(e, v) {
    clearTimeout(timerID)
    let onlyMe = this.props.onlyMe;
    this.setState({ searchString: v });
    if (e !== null && (e.type == "click" || e.key == "Enter")) {
      const patient = this.state.searchList.filter((m) => {
        return m.title == v.trim();
      });

      let patientId = patient && patient.length > 0 ? patient[0].id : "";
      // console.log("Patient length ===>", patientId);
      // console.log("Click Vallue ==>", patientId);
      this.props.fetchUserTableData(
        "information",
        this.state.page,
        this.state.perPage,
        this.state.sortColumn,
        this.state.sortOrder,
        onlyMe,
        patientId
      );
      this.setState({ searchList: [] });
      this.props.setPatientId(patientId);
      return;
    }
    if (v.length > 2) {
      timerID = setTimeout(() => {
        this.props.clearFilter();
        searchPatientData(this.state.searchString, onlyMe)
          .then((res) => {
            // console.log('Auto Search API => ',res);
            if (res.length == 0 && this.state.searchString != "") {
              this.setState({ searchList: [] });
              this.props.fetchUserTableData(
                "information",
                this.state.page,
                this.state.perPage,
                this.state.sortColumn,
                this.state.sortOrder,
                onlyMe,
                "undefined"
              );
              return;
            }

            this.setState({ searchList: res });
            this.props.setPatientId("");
          })
          .catch((error) => console.log(error));
      }, WAIT_INTERVAL)

    } else {
      this.setState({ searchList: [] });
      if (v.length == 0) {
        this.props.fetchUserTableData(
          "information",
          this.state.page,
          this.state.perPage,
          this.state.sortColumn,
          this.state.sortOrder,
          onlyMe,
          ""
        );
        this.props.setPatientId("");
      }
    }
  }
  async autoSearchChangeHandler(e) {
    if (e !== null && e.type == "change" && e.target.value == "") {
      // console.log("this.state.patientId:::", this.state.patientId);
      this.props.fetchUserTableData(
        "information",
        this.state.page,
        this.state.perPage,
        this.state.sortColumn,
        this.state.sortOrder,
        this.state.onlyMe,
        ""
      );
      this.props.setPatientId("");
    }
  }

  render() {
    return (
      <div>
        <Stack spacing={2} sx={{ width: "100%", maxWidth: "550px" }}>
          <Autocomplete
            freeSolo
            id="free-solo-demo"
            value={this.state.searchString}

            className="custm-autocomplete"         
            filterOptions={(x) => x}     
            options={this.state.searchList.map((option) => option.title + ' ')}
            renderInput={(params) => (
              <TextField
                {...params}
                label=""
                placeholder="Search Patient Name"

              />
            )}
            onInputChange={(e, v) => this.autoSearchHandler(e, v)}
            onChange={(e, v) => this.autoSearchChangeHandler(e, v)}
          //onHighlightChange={(e, v) => this.autoSearchHandler(e, v)}
          // onClose={()=>{this.props.fetchUserTableData("information", this.state.page, this.state.perPage, this.state.sortColumn, this.state.sortOrder);}}
          />

        </Stack>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchUserTableData,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AutoSearchBar);
