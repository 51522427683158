import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import React, { Component } from "react";
import moment from "moment";

const d = new Date();

export class ResultChartCalendarCompliance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      previousDate: "",
      currentDate: "",
      month: "",
      preMonth: "",
    };
  }

  componentDidMount() {
    if (this.props.dizzynessCurrentDate) {
      this.setState({
        currentDate: this.props.dizzynessCurrentDate,
      });
    }
    if (this.props.dizzynessPreviousDate) {
      this.setState({
        previousDate: this.props.dizzynessPreviousDate,
      });
    }
    if (this.props.dizzynessMonth) {
      this.setState({
        month: this.props.dizzynessMonth,
      });
    }
    //console.log("this.props.dizzynessMonth::::", this.props.dizzynessMonth);
    // let month = moment(currentDate).subtract(3,'days').format('MMMM YYYY');
    // this.setState({
    //   ...this.state,
    //   month:month
    // })
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state !== prevState) {
      //this.props.changeDates(this.state.currentDate,this.state.previousDate)
    }

    if (
      this.props.dizzynessCurrentDate &&
      this.props.dizzynessCurrentDate != prevProps.dizzynessCurrentDate
    ) {
      this.setState({
        currentDate: this.props.dizzynessCurrentDate,
      });
    }
    let previousMonth = ""
    if (
      this.props.dizzynessPreviousDate &&
      this.props.dizzynessPreviousDate != prevProps.dizzynessPreviousDate
    ) {
      previousMonth = 
        moment(this.props.dizzynessPreviousDate).month() !==
          moment(this.props.dizzynessCurrentDate).month()
          ? moment(this.props.dizzynessPreviousDate).format("MMM")
          : "";
      this.setState({
        previousDate: this.props.dizzynessPreviousDate,
        preMonth: previousMonth,
      });
    }
    if (
      this.props.dizzynessMonth &&
      this.props.dizzynessMonth != prevProps.dizzynessMonth
    ) {
      console.log("this.state.previousDate:::", this.state.previousDate);
      let dizzynessMonth = this.state.month ? this.state.month : this.props.dizzynessMonth;
      let dizzynessPreviousDate = this.state.previousDate ? this.state.previousDate : this.props.dizzynessPreviousDate;
      let dizzynessCurrentDate = this.state.currentDate ? this.state.currentDate : this.props.dizzynessCurrentDate;

      let month = moment(dizzynessMonth).format('MMM') + ' ' + moment(dizzynessPreviousDate).format('DD') + '-' + moment(dizzynessCurrentDate).format('DD')
      
      if (previousMonth != '') {
        month = previousMonth + ' ' + moment(this.props.dizzynessPreviousDate).format('DD') + '-' + moment(this.props.dizzynessCurrentDate).format('MMM') + ' ' + moment(this.props.dizzynessCurrentDate).format('DD');
      }
      this.setState({
        //month: this.props.dizzynessMonth,
        month: month,
      });
    }
    if (this.props.dizzynessSelValue && this.props.dizzynessSelValue!=prevProps.dizzynessSelValue) {
      this.setState({
        month: this.props.dizzynessSelValue,
      });
    }
  }

  prevWeek() {
    let newCurrentDate = moment(this.state.currentDate)
      .subtract(7, "days")
      .format("YYYY-MM-DD");
    let newPreviousDate = moment(newCurrentDate)
      .subtract(6, "days")
      .format("YYYY-MM-DD");
    let newMonth = moment(newCurrentDate)
      .subtract(3, "days")
      .format("YYYY-MM-DD");
    let previousMonth =
      moment(newPreviousDate).month() !== moment(newCurrentDate).month()
        ? moment(newPreviousDate).format("MMM")
        : "";

    let month = moment(newMonth).format('MMM') + ' ' + moment(newPreviousDate).format('DD') + '-' + moment(newCurrentDate).format('DD');

    if (previousMonth != '') {
      month = previousMonth + ' ' + moment(newPreviousDate).format('DD') + '-' + moment(newCurrentDate).format('MMM') + ' ' + moment(newCurrentDate).format('DD');
    }
    console.log('month::::', month);
    setTimeout(() => {
      this.setState({
        currentDate: newCurrentDate,
        previousDate: newPreviousDate,
        //month: newMonth,
        month: month,
        preMonth: previousMonth,
      });
    }, 200);
    this.props.changeDatesDizzyness(newCurrentDate, newPreviousDate,month);
  }

  nextWeek() {
    let newCurrentDate = moment(this.state.currentDate)
      .add(7, "days")
      .format("YYYY-MM-DD");
    let newPreviousDate = moment(newCurrentDate)
      .subtract(6, "days")
      .format("YYYY-MM-DD");
    let newMonth = moment(newCurrentDate)
      .subtract(3, "days")
      .format("YYYY-MM-DD");
    let previousMonth =
      moment(newPreviousDate).month() !== moment(newCurrentDate).month()
        ? moment(newPreviousDate).format("MMM")
        : "";
    if (
      !moment(this.state.currentDate).isBefore(moment(Date.now()).subtract(1, "days"))
/*-------------------------START - TVH-349 Change the start-date to Today's date & end-date to days-6 ( UAT )------------------*/

      
      
      //--------------------------- Uncomment the code for original changes & remove this code (Staging) --------------------// 
      // moment(Date.now()).subtract(0, "days").format("YYYY-MM-DD")
/*-------------------------END - TVH-349 Change the start-date to Today's date & end-date to days-6 ------------------*/
    ) {
      return;
    } else {
      let month = moment(newMonth).format('MMM') + ' ' + moment(newPreviousDate).format('DD') + '-' + moment(newCurrentDate).format('DD');

      if (previousMonth != '') {
        month = previousMonth + ' ' + moment(newPreviousDate).format('DD') + '-' + moment(newCurrentDate).format('MMM') + ' ' + moment(newCurrentDate).format('DD');
      }
      console.log("month::", month);
      setTimeout(() => {
        this.setState({
          currentDate: newCurrentDate,
          previousDate: newPreviousDate,
          month: month,
          preMonth: previousMonth,
        });
        this.props.changeDatesDizzyness(newCurrentDate, newPreviousDate,month);
      }, 200);
    }

    
  }
  render() {
    return (
      <div className="font-figtree font-700 font-20 text-theme-gray-dark text-center">
        <span className="pointer">
          <ArrowBackIosNewOutlinedIcon
            onClick={() => {
              this.prevWeek();
            }}
          />
        </span>
        {/* {this.state.preMonth !== "" ? this.state.preMonth + " - " : ""} */}
        <span className="mx-5">{this.state.month}</span>
        <span className={moment(this.state.currentDate).format("YYYY-MM-DD") <
          moment(Date.now()).subtract(1, "days").format("YYYY-MM-DD") ? 'pointer' : 'disabled'}>
          <ArrowForwardIosIcon
            onClick={() => {
              this.nextWeek();
            }}
          />
        </span>
      </div>
    );
  }
}

export default ResultChartCalendarCompliance;
