//export const SIGN_OUT = '/signup';
export const LANDING = "/";
export const HOME = "/home";
export const HELP = "/help";
//export const PASSWORD_FORGET = '/pw-forget';
//export const DATA = '/data';
export const EXPLORE = "/patientTracking";
export const PATIENT = "/patients";
//export const LOGS = "/logData";
export const CONFIGURATION = "/configuration";
export const PRESCRIPTIONS = "/prescriptions";
export const LOGIN = "/login";
export const FORGOTPASSWORD = "/forgotPassword";
export const SETPASSWORD = "/setPassword/token/:token";
export const RESETPASSWORD = "/resetPassword/token/:token";
export const REGISTER = "/register";
export const PATIENTDETAIL = "/patientdetail/:id";
export const USER = "/users";
export const LOGS = "/logs";
export const KITS = "/kits";
export const TERMSCONDITIONS = "/termscondition";
export const SUPERADMINPATIENTS = "/superadminpatients"
