import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Popover from "@mui/material/Popover";
import Modal from "react-bootstrap/Modal";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import {
  stopPragram,
  stopEditPragram,
} from "../../../../actions/setPatientProgramActions";

export class CancelProgram extends Component {
  componentDidMount() {}

  render() {
    return (
      <Modal
        show={this.props.showCancelProgramModal}
        centered
        className="cancel-modal"
      >
        <Box sx={{ ...style }} className="border-0">
          <h4>Are you sure you want to cancel?</h4>
          <p>All changes you have made will not be saved.</p>
          <div className="d-flex my-2 mt-md-3 mb-md-3">
            <Button
              className="ml-auto mr-2 btn border-1 border-primary-theme cursor-pointer rounded-14 btn-lg font-figtree font-700 text-primary-theme pt-2"
              variant="outlined"
              onClick={(e) => {
                e.preventDefault();
                this.props.handleCloseCancelModal();
              }}
            >
              Keep Editing
            </Button>
            <Button
              className="bg-primary-theme text-white pointer rounded-14 btn-lg theme-btn border-0 pt-2"
              variant="contained"
              onClick={(e) => {
                e.preventDefault();
                if (this.props.ProgramStatus == true) {
                  this.props.stopPragram();
                } else {
                  this.props.stopEditPragram();
                }

                this.props.handleStopProgramEvent();
              }}
            >
              Yes
            </Button>
          </div>
        </Box>
      </Modal>
    );
  }
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, 0%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  "border-radius": "10px",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

function mapStateToProps(state) {
  return {
    ProgramStatus: state.patientProgram.startProgram,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      stopPragram,
      stopEditPragram,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(CancelProgram);
