/*************************************
 * author: katherine marschner
 * cfd copyright 2020
 *************************************/

import {
  FETCH_USER_TABLE_DATA,
  FETCH_ALL_USERS,
  MANAGE_USERS,
  FETCH_TIMELIST_DUMMY,
  FETCH_TIMELIST_RESET,
  UPDATE_COMPLIANCE,
  FETCH_SELECT_USER,
  FETCH_ALL_PROVIDERS,
  FETCH_USER_INFO,
  //FETCH_OVERVIEW_DATA
} from "../actions/index";

export default function appReducer(state = null, action) {
  switch (action.type) {
    //case FETCH_OVERVIEW_DATA:
    //  return action.payload.data.overviewUserData;
    case FETCH_USER_TABLE_DATA:
      return action.payload.data.overviewUserData;
    case FETCH_ALL_USERS:
      return action.payload.data.overviewUserData;
    case UPDATE_COMPLIANCE:
      return action.payload.data.overviewUserData;
    case MANAGE_USERS:
      return action.payload.data.overviewUserData;
    case FETCH_TIMELIST_DUMMY:
      return action.payload.data.overviewUserData;
    case FETCH_TIMELIST_RESET:
      return action.payload.data.overviewUserData;
    case FETCH_ALL_PROVIDERS:
      return state;
    case FETCH_SELECT_USER:
      return state;
    //   case SELECT_USER:
    //   return action.payload.data.overviewUserData;
    case FETCH_USER_INFO:
      return action.payload.data.patient;
    //   case SELECT_USER:
    //   return action.payload.data.overviewUserData;
    default:
      return state;
  }
}
