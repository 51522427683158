import React, { Component } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { FetchClinicList, fetchUserTableData, changeClinic, setSelectedClinic } from './../../../actions/index';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

export class SelectClininc extends Component {
    constructor(props){
        super(props);
        this.state={
            clinic:'',
            clinicListData:[],
            page:1,
            perPage:10,
            sortColumn:'lastname',
            sortOrder:'asc',
            isTrue:false
        }
        this.handleChange = this.handleChange.bind(this);
    }
    componentDidMount(prevProps,prevState){
      this.props.setSelectedClinic(this.props.clinicList[0]);
      this.setState({
        clinicListData: this.props.clinicList,
      });
    }
    
    componentDidUpdate(prevProps,prevState){
      if(prevProps.selectedClinic !== this.props.selectedClinic){
      }
    }

    handleChange = (e)=>{
        this.setState({ clinic:e.target.value });
        this.setSelectedClinicValue(e.target.value,e.target.name)
    }

    setSelectedClinicValue(clinicId,clinicName){
      const param = {
        id:clinicId,
        name:clinicName
      }
      this.props.setSelectedClinic(param);
    }
  render() {
    return (
      <div>
      <FormControl sx={{ m: 1, minWidth: 200, width:400 }}>
        <Select
          value={this.props.selectedClinic.id}
          onChange={this.handleChange}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
          className='font-14 rounded-14 border-dark border h-auto'
        >
          { this.state.clinicListData.map(data=>{ return <MenuItem value={data.id} >{data.name}</MenuItem> }) }
        </Select>
      </FormControl>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    clinicList : state.superAdminPatientData.allClinicsList,
    selectedClinic: state.superAdminPatientData.selectedClinic
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      FetchClinicList,
      fetchUserTableData,
      changeClinic,
      setSelectedClinic
    },
    dispatch
  );
}


export default connect(mapStateToProps, mapDispatchToProps)(SelectClininc)