import { combineReducers } from "redux";
import errorReducer from "./errorReducer";
import patientResultsReducer from "./patient-results-reducer";
import reducerPatientProgram from "./reducer-patient-program";
import ModalReducer from "./reducer-modals";
import patientReducer from "./reducer-patient";
import reducerNotifications from "./reducer-notifications";
import providerReducer from "./reducer-provider";
import userTableDataReducer from "./reducer-user-table-data";
import securityReducer from "./securityReducer";
import forgotPasswordReducer from "./reducer-forgotPassword";
import helpReducer from "./reducer-help";
import logReducer from "./reducer-auditlog";
import kitReducer from "./reducer-kit";
import superAdminPatientDataReducer from "./reducer-superadminpatients"

//import setPatientProgramReducer from "./reducer-setPatientProgram";

const allReducers = combineReducers({
  modalState: ModalReducer,
  errors: errorReducer,
  // errors: errorReducer,
  userTableData: userTableDataReducer,
  security: securityReducer,
  forgotPassword: forgotPasswordReducer,
  patientResultsCharts: patientResultsReducer,
  // errors: errorReducer,
  // userTableData: userTableDataReducer,
  // security: securityReducer,
  patient: patientReducer,
  patientProgram: reducerPatientProgram,
  helpSection: helpReducer,
  notifications: reducerNotifications,
  provider: providerReducer,
  auditLog:logReducer,
  kit:kitReducer,
  superAdminPatientData:superAdminPatientDataReducer,
});

export default allReducers;
