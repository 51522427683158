import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { BsFillCaretDownFill } from "react-icons/bs";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchHelpData } from "./../../../actions/helpActions";
import parse from 'html-react-parser';
export class Help extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      helpData: [],
    };
  }

  componentDidMount() {
    let currentUser = this.props.currentUser;

    if (currentUser == '' || Object.keys(currentUser).length == 0) {
      window.location.href = '/';
      return false;
    }
    this.props.fetchHelpData();

  }

  componentDidUpdate(prevProps) {
    if (this.props.helpData !== prevProps.helpData) {
      this.setState({
        ...this.state,
        helpData: this.props.helpData,
      });
    }
  }

  handleChange = (panel) => (event, isExpanded) => {
    this.setState({
      expanded: isExpanded ? panel : false,
    });
  };

  render() {
    return (
      <div className="bg-help-theme h-100">
        <div className="pd-top-6 push-mt-30">
          <div className="help-header text-white rounded-20 font-figtree font-700 font-42 d-flex align-items-center justify-content-center p-3 mx-auto">
            Your questions answered
          </div>
          <div className="help-description rounded-20 font-figtree mx-auto px-5 py-3 mt-3 bg-white">
            {this.state.helpData.length !== 0
              ? this.state.helpData.map((item) => {
                return (
                  <>
                    <Accordion
                      expanded={this.state.expanded === item.id}
                      onChange={this.handleChange(item.id)}
                      className="p-0 shadow-0"
                    >
                      <AccordionSummary
                        expandIcon={<BsFillCaretDownFill />}
                        aria-controls="panel4bh-content"
                        id="panel4bh-header"
                        className="font-600 font-24 text-secondary-theme"
                      >
                        {item.question}
                      </AccordionSummary>
                      <AccordionDetails className="font-400 font-18">
                        {parse(item.answer)}
                      </AccordionDetails>
                    </Accordion>
                  </>
                );
              })
              : "No data found"}
          </div>
        </div>
        <div className="text-center my-4 pb-5">
          <div>If there are any other questions, please feel free to email:</div>
          <div className="mb-3"><a className="text-dark text-decoration-underline font-bold" href="mailto:clientsuccess@theravista.health" target="_blank">clientsuccess@theravista.health</a></div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    helpData: state.helpSection.helpData,
    currentUser: state.security.user,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchHelpData,
    },
    dispatch
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(Help);
