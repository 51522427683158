import React, { Component } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { connect } from 'react-redux';
import { showNotificationBlock, fetchNotificationStatusBlockData, readNotification, fetchNotifications } from './../../../../actions/index';
import { bindActionCreators } from 'redux';

export class NotificationStatusBlock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showBlock: true,
      notificationListData: [],
      patientDetails:[],
    }
  }
  componentDidMount() {
    //this.setNotifications();
  }
  componentDidUpdate(prevState,prevProps){
     if(this.props.readNotificationData !== '' && this.props.readNotificationData !== prevProps.readNotificationData){
            this.props.fetchNotifications();
        }
   }

  setNotifications(){
    this.props.fetchNotifications();
    const patient = this.props.patientInfo;
    this.setState({patientDetails:patient});
  }
  closeBlock(m) {
    this.props.readNotification(m.user,m.id);
    const notificationListData = this.props.notificationStatusBlockData.filter(a=> a.id!==m.id);
    this.props.fetchNotificationStatusBlockData(notificationListData)
  }
  render() {
    return (
      <>
        {this.props.notificationStatusBlockData.map(m => {
          if (m.notificationRead == false ) {
            return <div className='notification-status-block rounded-20 p-4 d-flex align-items-start'>
              <CloseIcon className='notification-status-block-close-icon text-white pointer square-35' onClick={() => { this.closeBlock(m) }}></CloseIcon>
              {m.notificationType !==''?<button disabled={true} style={{"cursor":"default","opacity":"1"}} className='w-250px m-0 btn btn-light notification-status-block-missing-button text-theme-red font-figtree font-700 font-18 rounded-12 p-2 text-nowrap'><ErrorOutlineIcon className='square-35 notification-status-block-missing-button-text' /> {m.notificationType}</button>:''}
              <div className='text-center text-white w-100'>
                <div className='font-figtree font-700 font-24'>New Results</div>
                <p className='font-figtree font-500 font-18'>Daily compliance & Dizziness score:  {m.notificationDateStr.split(',')[0]}</p>
              </div>
              <div className='w-200px'></div>
            </div>
          }
        })}

      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    showNotificationBlock: state.notifications.showNotificationBlock,
    notificationData: state.notifications.notificationData,
    patientInfo: state.security.user.role=='TVH SUPER ADMIN'?state.superAdminPatientData.superAdminPatients:state.userTableData,
    notificationStatusBlockData: state.notifications.notificationStatusBlockData,
    readNotificationData: state.notifications.readNotification,
  }
}


const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    showNotificationBlock,
    fetchNotificationStatusBlockData,
    readNotification,
    fetchNotifications
  }, dispatch)

}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationStatusBlock);