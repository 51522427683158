import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import ReduxThunk from "redux-thunk";
import allReducers from "./reducers";
import App from "./components/App";
import { BrowserRouter } from "react-router-dom";
import "react-progress-2/main.css";
import Progress from "react-progress-2";
import "bootstrap/dist/css/bootstrap.min.css";
const createStoreWithMiddleware = applyMiddleware(ReduxThunk)(createStore);

export const store = createStoreWithMiddleware(allReducers);
// test
ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <Progress.Component />
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById("MyContainer")
);
