import axios from "axios";
import moment from "moment";

import {
  localhost,
  handleError,
  FETCH_PATIEN_PROGRAM_INFO,
  STORE_PATIENT_PROGRAM,
  ADD_PATIEN_PROGRAM_INFO,
  EDIT_PATIEN_PROGRAM_INFO,
} from "../actions/index";
const qualifier = "cap";

const ExercisesList = [
  "VORx1_Yaw",
  "VORx1_Pitch",
  "Balance_Weight_Shift",
  "Balance_Sitting_Standing",
];
const DaysOfWeek = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
const marks = [
  {
    value: 1,
    label: "1",
  },
  {
    value: 25,
    label: "2",
  },
  {
    value: 50,
    label: "3",
  },
  {
    value: 75,
    label: "4",
  },
  {
    value: 100,
    label: "5",
  },
];

export const fetchPatientProgram = (patientUsername) => async (dispatch) => {
  try {
    //const FETCH_LOG_URL = `${localhost}/${qualifier}/fetchPatient?patientId=${patientId}`;
    const FETCH_LOG_URL = `${localhost}/${qualifier}/getPrescriptions?username=${patientUsername}`;
    let url = `${FETCH_LOG_URL}`;
    //url = 'https://vestibular-api.health-map.net/cap/getPrescriptions?username=good@gmail.com';
    const res = await axios.get(url);

    dispatch({
      type: FETCH_PATIEN_PROGRAM_INFO,
      payload: res,
    });
    return res;
  } catch (error) {
    handleError(error, dispatch);
  }
};

export const storingDefaultPatientProgramToRedux =
  (patientProgram) => async (dispatch) => {
    try {
      let CompleteData = {};
      // console.log("patientProgram", patientProgram);
      //Storing exercise data
      let exerciseDataObj = {};
      ExercisesList.map((item) => {
        let filteredRecord = patientProgram?.prescriptionList?.filter(
          option =>{ 
            let programName = item.replace(/_/g, " ");
            let prescriptionId = option.id.prescriptionId
           
            if(prescriptionId.replace('/', " ") == programName.replace('/', " ")){
              return option
            }
          }
        );
        exerciseDataObj[item] =
          filteredRecord !== undefined ? filteredRecord[0]?.selected : false;
      });
      CompleteData.Exercise = exerciseDataObj;
      // console.log("Exercise data stored", CompleteData);

      //storing exercise frequency data
      let ExfrequencyDataObj = {};
      DaysOfWeek.map((item) => {
        let filteredRecord = patientProgram?.scheduleList?.filter(
          (option) => option.name == item
        );
        ExfrequencyDataObj[item] =
          filteredRecord !== undefined ? filteredRecord[0]?.value : false;
      });
      CompleteData.ExerciseFrequency = ExfrequencyDataObj;
      // console.log("Exercisefrequency data stored", CompleteData);

      //storing frequency data
      let filteredRecord = marks.filter(
        (option) => option.label === patientProgram?.sessionsPerDay?.value
      );
      CompleteData.Frequency = {
        NumberOfSessionPerDay:
          filteredRecord !== undefined ? filteredRecord[0]?.value : 1,
        sessionsPerDay: parseInt(patientProgram?.sessionsPerDay?.value),
      };
      // console.log("Frequency data stored", CompleteData);

      //storing set frequency data
      let setFrequencydataObj = {};
      ExercisesList.map((item) => {
        let filteredRecord = patientProgram?.prescriptionList?.filter(
         
          option =>{ 
            let programName = item.replace(/_/g, " ");
            let prescriptionId = option.id.prescriptionId
           
            if(prescriptionId.replace('/', " ") == programName.replace('/', " ")){
              return option
            }
          }
        );
        if (filteredRecord !== undefined) {
          let prescriptionDuration =
            filteredRecord[0]?.prescriptionDisplayList?.filter(
              (option) => option.id == "Current"
            );

          let prescriptionNumberOfSessions =
            filteredRecord[0]?.prescriptionDisplayList?.filter(
              (option) => option.id == "Number of Sets in Session"
            );

          setFrequencydataObj[`Num_Of_sets_${item}`] =
            prescriptionNumberOfSessions !== undefined
              ? prescriptionNumberOfSessions[0]?.value
              : 0;
          setFrequencydataObj[`Duration_${item}`] =
            prescriptionDuration !== undefined
              ? prescriptionDuration[0]?.value
              : 0;
        }
      });
      let totalTime = 0;
      ExercisesList.forEach((item) => {
        if (CompleteData.Exercise[item] == true) {
          totalTime =
            totalTime +
            setFrequencydataObj[`Num_Of_sets_${item}`] *
              setFrequencydataObj[`Duration_${item}`];
        }
      });
      totalTime = totalTime * CompleteData.Frequency.sessionsPerDay;
      totalTime = moment.duration(totalTime, "seconds").format("mm:ss");
      setFrequencydataObj.ExerciseDurationPerDay = totalTime;
      CompleteData.SetFrequency = setFrequencydataObj;
      // console.log("set Frequency data stored", CompleteData);

      //storing setting first and settings second modal data
      let SettingsFirst = {
        VORx1_Yaw: [],
        VORx1_Pitch: [],
      };
      let SettingsSecond = {
        VORx1_Yaw: [],
        VORx1_Pitch: [],
      };
      patientProgram.prescriptionList.forEach((ele) => {
        switch (ele.name) {
          case "VORx1 Yaw": {
            if (ele.selected) {
              let prescriptionDisplayList = ele.prescriptionDisplayList;
              prescriptionDisplayList.forEach((val) => {
                if (
                  val.name == "Head Right Target" ||
                  val.name == "Yaw Velocity Target"
                ) {
                  let VORx1_Yaw = SettingsSecond.VORx1_Yaw;
                  VORx1_Yaw[val.name] = val.value;
                  SettingsSecond = {
                    ...SettingsSecond,
                    VORx1_Yaw,
                  };

                  if (val.name == "Head Right Target") {
                    VORx1_Yaw["Head Left Target"] = val.value;
                    SettingsSecond = {
                      ...SettingsSecond,
                      VORx1_Yaw,
                    };
                  }
                }
                if (val.name == "Current Level") {
                  SettingsFirst.VORx1_Yaw["Current Level"] = val.value;
                }
              });
            }
            break;
          }
          case "VORx1 Pitch": {
            if (ele.selected) {
              let prescriptionDisplayList = ele.prescriptionDisplayList;

              prescriptionDisplayList.forEach((val) => {
                if (
                  val.name == "Head Up Target" ||
                  val.name == "Head Down Target" ||
                  val.name == "Pitch Velocity Target"
                ) {
                  let VORx1_Pitch = SettingsSecond.VORx1_Pitch;
                  VORx1_Pitch[val.name] = val.value;
                  SettingsSecond = {
                    ...SettingsSecond,
                    VORx1_Pitch,
                  };
                }
                if (val.name == "Current Level") {
                  SettingsFirst.VORx1_Pitch["Current Level"] = val.value;
                }
              });
            }
            break;
          }
          case "Balance Sitting/Standing": {
            if (ele.selected) {
              let prescriptionDisplayList = ele.prescriptionDisplayList;
              let temp = {};
              prescriptionDisplayList.forEach((val) => {
                if (val.name == "Current Assist") {
                  temp["Current Assist"] = val.value;
                }
                SettingsFirst["Balance Sitting/Standing"] = temp;
              });
            }
            break;
          }
          case "Balance Weight Shift": {
            if (ele.selected) {
              let prescriptionDisplayList = ele.prescriptionDisplayList;
              let arr = {};
              prescriptionDisplayList.forEach((val) => {
                if (val.name == "Left-Right") {
                  arr["Left-Right"] = val.selected;
                }
                if (val.name == "Heel-Toe") {
                  arr["Heel-Toe"] = val.selected;
                }
                if (val.name == "Omni-Directional") {
                  arr["Omni-Directional"] = val.selected;
                }
                if (val.name == "Randomized Left-Right and Heel-Toe") {
                  arr["Randomized Left-Right and Heel-Toe"] = val.selected;
                }
                // console.log("arr", arr);
                SettingsFirst["Balance Weight Shift"] = arr;
              });
            }
            break;
          }
        }
      });
      CompleteData.SettingsFirst = SettingsFirst;
      CompleteData.SettingsSecond = SettingsSecond;
      // console.log("second settings data stored", CompleteData);

      dispatch({
        type: STORE_PATIENT_PROGRAM,
        payload: CompleteData,
      });
      return CompleteData;
    } catch (error) {
      handleError(error, dispatch);
    }
  };

export const updatePatientProgram = (values, username) => async (dispatch) => {
  try {
    //const FETCH_LOG_URL = `${localhost}/${qualifier}/fetchPatient?patientId=${patientId}`;
    const FETCH_LOG_URL = `${localhost}/${qualifier}/updatePrescriptions`;
    let url = `${FETCH_LOG_URL}`;
    //url = 'https://vestibular-api.health-map.net/cap/getPrescriptions?username=good@gmail.com';
    let transfer = {
      time: Date.now(),
      username: username,
      topic: "vestibular",
      items: values,
    };
    const res = await axios.post(url, transfer);

    //await axios.post(`${localhost}/cap/api/users/register`, newUser);
    dispatch({
      type: ADD_PATIEN_PROGRAM_INFO,
      payload: res,
    });
    return res;
  } catch (error) {
    handleError(error, dispatch);
  }
};

export const updatePatientProgramFrequency =
  (values, username) => async (dispatch) => {
    try {
      const FETCH_LOG_URL = `${localhost}/${qualifier}/updatePrescriptions`;
      let url = `${FETCH_LOG_URL}`;
      let transfer = {
        time: Date.now(),
        username: username,
        topic: "vestibular",
        items: values,
      };
      const res = await axios.post(url, transfer);
      //await axios.post(`${localhost}/cap/api/users/register`, newUser);
      dispatch({
        type: EDIT_PATIEN_PROGRAM_INFO,
        payload: res,
      });
      return res;
    } catch (error) {
      handleError(error, dispatch);
    }
  };
