import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import React, { Component } from "react";
import { connect } from "react-redux";

export class Progress extends Component {
  render() {
    return (
      <Box sx={{ width: "100%" }}>
        <LinearProgress variant="determinate" value={this.props.value} />
      </Box>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Progress);
