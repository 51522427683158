import React, { useState, useEffect } from "react";
import { alpha, makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
//import IconButton from "@material-ui/core/IconButton";
//import MenuIcon from "@material-ui/icons/Menu";
//import Typography from "@material-ui/core/Typography";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import { fetchContext, getImage } from "../../../actions";
import Button from "@material-ui/core/Button";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import * as routes from "../../../constants/routes";

const useStyles = makeStyles((theme) => ({
  toolbarMargin: {
    ...theme.mixins.toolbar,
    marginBottom: "3em",
    [theme.breakpoints.down("lg")]: {
      marginBottom: "1em",
    },
    [theme.breakpoints.down("md")]: {
      marginBottom: "1em",
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: "1.25em",
    },
  },
  grow: {
    flexGrow: 1,
  },
  logo: {
    height: "10em",
  },
  logoContainer: {
    padding: 0,
    "&:hover": {
      backgroundColor: "transparent",
      flexGrow: 1,
    },
  },
  tab: {
    fontWeight: 800,
    textTransform: "none",
    color: "#00000",
    fontSize: "1.5rem",
    minWidth: 10,
    marginLeft: "25px",
  },

  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

export default function Header(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  //dispatch(fetchContext());

  //tabs
  const [value, setValue] = useState(0);

  // mobile sets not being used yet
  //const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  //const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleChange = (e, value) => {
    setValue(value);
  };

  useEffect(() => {
    if (window.location.pathname === "/login" && value !== 0) {
      setValue(0);
    } else if (window.location.pathname === "/register" && value !== 1) {
      setValue(1);
    }
  }, [value]);

  //const user = useSelector((state) => state.security.user);
  //const validToken = useSelector((state) => state.security.validToken);
  //const validLogin = validToken && user !== null;
  const image = getImage("HeaderImage");
  //const context = useSelector((state) => state.context);
/**
  const logout = () => {
    //dispatch(logout());
    setValue(0);
    window.location.href = "/";
  };
**/
  const loginToolbar = (
    <div style={{ width: "100%", marginRight: "2%" }} className="Hello">
    
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div className={classes.grow} />
       
        <Tabs
          className={classes.tabContainer}
          onChange={handleChange}
          value={value}
          aria-label="simple tabs example"
        >
          <Tab
            className={classes.tab}
            component={Link}
            label="sign in"
            to={routes.LOGIN}
          />
          <Tab
            className={classes.tab}
            component={Link}
            label="sign up"
            to={routes.REGISTER}
          />
        </Tabs>
      </div>
    </div>
  );

  return (
    <React.Fragment>
      
      
    </React.Fragment>
  );
}
//<div className={classes.toolbarMargin} />
