import IconButton from "@material-ui/core/IconButton";
import Pagination from "@material-ui/lab/Pagination";
import DriveFileRenameOutlineSharpIcon from "@mui/icons-material/DriveFileRenameOutlineSharp";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { DataGrid } from "@mui/x-data-grid";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchProviderList, fetchAllClinics } from "../../../actions/index";
import AddEditUser from "./AddEditUser";
import AutoSearchBarUsers from "./AutoSearchBarUsers";
import * as routes from "../../../constants/routes";

class ProviderList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      perPage: 10,
      totalItems: 0,
      sortColumn: "lastName",
      sortOrder: "asc",
      isTrue: false,
      totalPages: 0,
      AddEditUserModal: false,
      EditElement: null,
      mode: "Add",
      userId:"",
    };
  }

  componentDidMount() {
    this.props.fetchProviderList(
      this.state.page,
      this.state.perPage,
      this.state.sortColumn,
      this.state.sortOrder
    );
    this.handleChangePage = this.handleChangePage.bind(this);

    let currentUser = this.props.currentUser;

    if (currentUser == '' || Object.keys(currentUser).length == 0) {
      window.location.href = '/';
      return false;
    }
    else {
      let currentUserRole = currentUser.role;
      if (currentUserRole.toLowerCase() != 'tvh admin' && currentUserRole.toLowerCase() != 'tvh super admin') {
        //window.location.href = '/patients';
        this.props.history.push(routes.PATIENT);
        return false;
      }
    }
    this.props.fetchAllClinics();
  }

  componentDidUpdate(prevProps) {
    if (this.props.provider && prevProps.provider !== this.props.provider) {
      // console.log("this.props.provider::::::", this.props.provider);
      if (this.props.provider.page) {
        this.setState({
          ...this.state,
          totalItems: this.props.provider.page.totalItems,
          page: this.props.provider.page.currentPage,
          perPage: this.props.provider.page.pageSize,
          totalPages: this.props.provider.page.totalPages,
          mode: "Add",
          EditElement: null,
        });
      }
    }
    // console.log("this.props.currentUser:::::", this.props.currentUser);
    if (
      this.props.currentUser &&
      prevProps.currentUser !== this.props.currentUser
    ) {
      let currentUser = this.props.currentUser;
      if (currentUser == "" || currentUser.role != "TVH ADMIN") {
        alert("here");
      }
    }
    if (this.props.editProvider && this.props.editProvider != prevProps.editProvider) {

    }
  }

  handleChangePage(page) {
    this.setState({ page: page });
    setTimeout(() => {
      this.getProviderList();
      window.scrollTo(0, 0)
    }, 100);
  }

  getProviderList() {
    let page = this.state.page;
    let perPage = this.state.perPage;
    let userId = this.state.userId;
    this.props.fetchProviderList(
      page,
      perPage,
      this.state.sortColumn,
      this.state.sortOrder,
      userId
    );
  }

  handleSortModelChange(sortModel) {
    if (sortModel.length > 0) {
      let page = 1;
      let perPage = this.state.perPage;
      let sortColumn = sortModel[0].field;
      let sortOrder = sortModel[0].sort;
      let userId = this.state.userId;
      if(sortColumn=="isActive"){
        if(sortOrder==='asc'){
          sortOrder = 'desc'
        }else{
          sortOrder="asc"
        }
      }
      this.setState({
        sortColumn: sortColumn,
        sortOrder: sortOrder,
        page: 1,
      });
      this.props.fetchProviderList(page, perPage, sortColumn, sortOrder, userId);
    }
  }

  handleOpen = () => this.setState({ AddEditUserModal: true });
  handleClose = () =>
    this.setState({ AddEditUserModal: false, mode: "Add", EditElement: null });

  handleOnCellClick = (params) => {
    if (params.field === "actions") {
      this.setState({
        EditElement: params.row,
        mode: "Edit",
      });
      this.handleOpen();
    }
  };
  setSearchUserId = (userId) => {
    this.setState({ userId: userId });

  }
  render() {
    const NoResultsOverlay = () => {
      return <div className="no-result">0 results found</div>;
    };

    const CustomPagination = () => {
      return (
        <Pagination
          count={this.state.totalPages}
          page={this.state.page}
          variant="outlined"
          onChange={(event, value) => this.handleChangePage(value)}
        />
      );
    };

    function RowMenuCell(props) {
      //const { api, id } = props;

      const handleEditClick = (event) => {
        // console.log("props::", props.row);
        //console.log("event::::",event);
        //event.stopPropagation();
        //api.setRowMode(id, 'edit');
      };
      return (
        <div className="">
          <IconButton
            color="inherit"
            className=""
            size="small"
            aria-label="edit"
            onClick={handleEditClick}
          >
            <DriveFileRenameOutlineSharpIcon fontSize="small" />
          </IconButton>
        </div>
      );
    }

    let rows = [];
    const columns = [
      // {field: 'id',headerName: 'Id'},
      {
        field: "lastName",
        headerName: "Name",
        flex: 1,
        sortingOrder: ["desc", "asc"],
      },
      {
        field: "role",
        headerName: "Role",
        flex: 1,
        sortingOrder: ["desc", "asc"],
      },

      {
        field: "clinicName",
        headerName: "Clinic Name",
        sortingOrder: ["desc", "asc"],
        flex: 1,
      },
      {
        field: "location",
        headerName: "Location",
        flex: 1,
        sortingOrder: ["desc", "asc"],
      },

      {
        field: "username",
        headerName: "Email Address",
        flex: 1,
        sortingOrder: ["desc", "asc"],
      },
      {
        field: "isActive",
        headerName: "Status",
        flex: 1,
        sortingOrder: ["asc","desc"],
      },
      {
        field: "actions",
        headerName: "",
        renderCell: RowMenuCell,
        sortable: false,
        width: 100,
        headerAlign: "center",
        filterable: false,
        align: "center",
        disableColumnMenu: true,
        disableReorder: true,
      },
    ];

    if (this.props.provider != null) {
      let { rowList } = this.props.provider;
      if (rowList && rowList !== null) {
        rowList.forEach((row, index) => {
          rows.push({
            id: row.user.userId,
            lastName: row.user.fullName,
            role: row.role,
            clinicName: row.clinicName,
            location: row.location,
            username: row.username,
            isActive: row.active ? "Active" : "Inactive",
          });
        });
      }
    }
    return (
      <div className="p-3">
        <Grid container className="MuiToolbar-gutters align-items-end">
          <Grid item xs={12} md={6} className="mb-3">
            <h2 className="pd-top-6 push-mt-30">
              <strong>Users</strong>
            </h2>
            <AutoSearchBarUsers
              tableData={this.props.tableData}
              sortColumn={this.state.sortColumn}
              sortOrder={this.state.sortOrder}
              editProvider={[]}
              setUserId={(userId) => this.setSearchUserId(userId)}
            />
          </Grid>
          <Grid item xs={12} md={6} className="mb-3 text-right">
            {<div className="d-flex w-100 align-items-center">
              <button
                className="ml-auto bg-primary-theme pointer py-2 px-4 border-0 rounded-14 text-white font-figtree font-700 font-18"
                variant="contained"
                onClick={() => this.handleOpen()}
              >
                Add User
              </button>
            </div>}
          </Grid>
          {this.state.AddEditUserModal &&
            <AddEditUser
              mode={this.state.mode}
              EditElement={this.state.EditElement}
              handleClose={this.handleClose}
              AddEditUserModal={this.state.AddEditUserModal}
              page={this.state.page}
              perPage={this.state.perPage}
              sortColumn={this.state.sortColumn}
              sortOrder={this.state.sortOrder}
              userId={this.state.userId}
            />
          }
          <Grid item xs={12}>
            <Box sx={{ height: "780px", width: "100%" }} className="datatable">
              <DataGrid
                sx={{
                  '.MuiDataGrid-iconButtonContainer': {
                    visibility: 'visible',
                  },
                  '.MuiDataGrid-sortIcon': {
                    opacity: 'inherit !important',
                  },
                }}
                rows={rows}
                rowCount={this.state.totalItems}
                rowsPerPageOptions={this.state.page.perPage}
                pagination
                page={this.state.page}
                pageSize={5}
                paginationMode="server"
                columns={columns}
                disableColumnMenu="true"
                onCellClick={this.handleOnCellClick}
                components={{
                  NoRowsOverlay: NoResultsOverlay,
                  Pagination: CustomPagination,
                }}
                initialState={{
                  sorting: {
                    sortModel: [
                      {
                        field: this.state.sortColumn,
                        sort: this.state.sortOrder,
                      },
                    ],
                  },
                }}
                sortingMode="server"
                onSortModelChange={(newSortModel) =>
                  this.handleSortModelChange(newSortModel)
                }
                disableRowSelectionOnClick={true}
              />
            </Box>
          </Grid>
        </Grid>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    provider: state.provider.providerList,
    currentUser: state.security.user
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchProviderList,
      fetchAllClinics
    },
    dispatch
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(ProviderList);
