import axios from 'axios';
import { handleError } from "./Actions";
import { localhost } from "./index";

import superAdminData from '../components/mainTabs/patient/patientDetail/test';

import { FETCH_KIT } from './index';

export const FETCH_SUPERADMIN_PATIENTS_BY_CLINIC = "FETCH_SUPERADMIN_PATIENTS_BY_CLINIC";
export const FETCH_ALL_CLINICS = "FETCH_ALL_CLINICS";
export const SET_SELECTED_CLINIC = "SET_SELECTED_CLINIC";
export const FETCH_ALL_PROVIDERS_BY_CLINIC = "FETCH_ALL_PROVIDERS_BY_CLINIC";


export const fetchAllClinics = () => async(dispatch) => {
    let url = `${localhost}/cap/clinic`;
    let params = {};
    let res = await axios.get(url ,{params});
    // console.log('CLinicList===>',res.data.clinics)
    let allClinicsData = res.data.clinics;
    let selectedClinicData = {
        id:allClinicsData[0].id,
        name:allClinicsData[0].clinic
    }
    dispatch({
        type: FETCH_ALL_CLINICS,
        payload: allClinicsData
    });
    dispatch({
        type:SET_SELECTED_CLINIC,
        payload: selectedClinicData
    })    
}

export const fetchSuperAdminPatientsByClinic = (page,perPage,sortColumn,sortOrder,clinicId,patientId="") => async (dispatch) => {
    // console.log('clinicId===>',clinicId)
    let url = `${localhost}/cap/fetchUserTableDataByClinic`;
    let params = {
        topic: "information",
        subtopic: "topic",
        clinicId: clinicId,
        page: page,
        size: perPage,
        sort: `${sortColumn},${sortOrder}`,
        patientId: patientId,
    }
    let res = await axios.get(url,{params});
    const superAdminPatientsData = res.data.overviewUserData;
    dispatch({
        type: FETCH_SUPERADMIN_PATIENTS_BY_CLINIC,
        payload: superAdminPatientsData
    })
}

export const searchPatientByClinic = (searchString , clinicId) => async (dispatch) => {
    // console.log('SearchData====>',searchString,clinicId)
    let url = `${localhost}/cap/searchPatientByClinic`;
    let params = {
        searchString: searchString,
        clinicId: clinicId
    }
    let dataObject = await axios.get(url,{params});
    let res = Object.keys(dataObject.data).map(key=>{
        const obj = {
            id:key,
            title:dataObject.data[key],
        }
        return obj;
    });
    return res;
    
}


export const filterPatientByClinic = (
    page,
    perPage,
    sortColumn,
    sortOrder,
    compliance, 
    providers="all",
    clinicId
) => async (dispatch) => {
    // console.log('Filter Providers===>',providers.join(''))
    let url = `${localhost}/cap/filterUserTableDataByClinic`;
    let params = {
        topic: "information",
        subtopic: "log",
        page: page,
        size: perPage,
        sort:`${sortColumn},${sortOrder}`,
        compliance: compliance,
        providers: providers.join(','),
        clinicId: clinicId,
    }
    // console.log('Filter params===>',params)
    let res = await axios.get(url ,{params});
    
    let filteredData = res.data.overviewUserData;

    dispatch({
        type:FETCH_SUPERADMIN_PATIENTS_BY_CLINIC,
        payload: filteredData
    });   
}

export const fetchAllProvidersByClinic = ( clinicId ) => async( dispatch ) => {
    // console.log('Fetch patients by clinic===>',clinicId)
    let url = `${localhost}/cap/fetchAllProvidersByClinic`;
    let params = {
        clinicId: clinicId
    }
    let res = await axios.get(url,{params});

    dispatch({
        type: FETCH_ALL_PROVIDERS_BY_CLINIC,
        payload: res,
    })
    return res;
}

export const setSelectedClinic = (clinic) =>async(dispatch)=> {
    let selectedClinicData = {
        id:clinic.id,
        name:clinic.name
    }
    dispatch({
        type:SET_SELECTED_CLINIC,
        payload: selectedClinicData
    })
}


export const fetchSuperAdminPatientsByPatientId = ( patientId ) => async(dispatch)=>{
    const url = `${localhost}/cap/fetchPatient`;
    const params = {
        patientId:patientId
    }

    let res = await axios.get(url,{params});

    dispatch({
        type:FETCH_SUPERADMIN_PATIENTS_BY_CLINIC,
        payload:res.data.patient
    })
}

export const fetchKitsByClinicReadyforPatients = (clinicId) => async(dispatch) => {
    const url = `${localhost}/cap/kits/fetchKitsByProviderClinic`;
    const params = {
        clinicId:clinicId
    }
    let res = await axios.get(url,{params});

    dispatch({
        type:FETCH_KIT,
        payload:{},
    })
}
