import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

const DaysOfWeek = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

class RightCornerPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Frequency: 0,
    };
  }

  componentDidMount() {
    if (this.props.ExerciseFrequency) {
      let totalSessionDays = 0;
      DaysOfWeek?.forEach((element) => {
        if (this.props.ExerciseFrequency[element] == true) {
          totalSessionDays = totalSessionDays + 1;
        }
      });
      if (totalSessionDays < 7) {
        this.setState({
          Frequency: totalSessionDays + " times per week",
        });
      } else {
        this.setState({
          Frequency: "Daily",
        });
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.ExerciseFrequency !== this.props.ExerciseFrequency) {
      let totalSessionDays = 0;
      DaysOfWeek?.forEach((element) => {
        if (this.props.ExerciseFrequency[element] == true) {
          totalSessionDays = totalSessionDays + 1;
        }
      });
      if (totalSessionDays < 7) {
        this.setState({
          Frequency: totalSessionDays + " times per week",
        });
      } else {
        this.setState({
          Frequency: "Daily",
        });
      }
    }
  }

  render() {
    return (
      <div className="w-25 text-right font-figtree font-14">
        {this.state.Frequency !== "" && this.props.step > 2 ? (
          <p className="mb-0 font-400">
            Frequency: <span className="font-600">{this.state.Frequency}</span>
          </p>
        ) : (
          ""
        )}
        {this.props.Frequency.sessionsPerDay !== 0 && this.props.step > 3 ? (
          <p className="mb-0 font-400">
            Sessions/day:{" "}
            <span className="font-600">{this.props.Frequency.sessionsPerDay}</span>
          </p>
        ) : (
          ""
        )}
        {this.props.SetFrequency.ExerciseDurationPerDay !== "00:00" &&
        this.props.step > 4 ? (
          <p className="">
            Exercise duration/day (min:sec):{" "}
            <span className="font-600">{this.props.SetFrequency.ExerciseDurationPerDay}</span>
          </p>
        ) : (
          ""
        )}
        {(this.props.SetFrequency.Num_Of_sets_VORx1_Yaw !== 0 ||
          this.props.SetFrequency.Num_Of_sets_VORx1_Pitch !== 0 ||
          this.props.SetFrequency.Num_Of_sets_Balance_Weight_Sift !== 0 ||
          this.props.SetFrequency.Num_Of_sets_Balance_Sitting_Standing !== 0) &&
        this.props.step > 4 ? (
          <>
            <p className="mb-0 text-secondary-theme mt-2">Number of sets:</p>
            {this.props.Exercise.VORx1_Yaw && (
              <p className="mb-0 font-400">
                VORx1 Yaw:{" "}
                <span className="font-600">{this.props.SetFrequency.Num_Of_sets_VORx1_Yaw}</span>
              </p>
            )}
            {this.props.Exercise.VORx1_Pitch && (
              <p className="mb-0 font-400">
                VORx1 Pitch:{" "}
                <span className="font-600">
                  {this.props.SetFrequency.Num_Of_sets_VORx1_Pitch}
                </span>
              </p>
            )}
            {this.props.Exercise.Balance_Weight_Shift && (
              <p className="mb-0 font-400">
                Balance weight shift:{" "}
                <span className="font-600">
                  {this.props.SetFrequency.Num_Of_sets_Balance_Weight_Shift}
                </span>
              </p>
            )}
            {this.props.Exercise.Balance_Sitting_Standing && (
              <p className="mb-0 font-400">
                Balance Sitting/Standing:{" "}
                <span className="font-600">
                  {this.props.SetFrequency.Num_Of_sets_Balance_Sitting_Standing}
                </span>
              </p>
            )}
            <p className="mb-0 text-secondary-theme mt-2">Duration of sets:</p>
            {this.props.Exercise.VORx1_Yaw && (
              <p className="mb-0 font-400">
                VORx1 Yaw:{" "}
                <span className="font-600">{this.props.SetFrequency.Duration_VORx1_Yaw}</span>
              </p>
            )}
            {this.props.Exercise.VORx1_Pitch && (
              <p className="mb-0 font-400">
                VORx1 Pitch:{" "}
                <span className="font-600">{this.props.SetFrequency.Duration_VORx1_Pitch}</span>
              </p>
            )}
            {this.props.Exercise.Balance_Weight_Shift && (
              <p className="mb-0 font-400">
                Balance weight shift:{" "}
                <span className="font-600">
                  {this.props.SetFrequency.Duration_Balance_Weight_Shift}
                </span>
              </p>
            )}
            {this.props.Exercise.Balance_Sitting_Standing && (
              <p className="mb-0 font-400">
                Balance Sitting/Standing:{" "}
                <span className="font-600">
                  {this.props.SetFrequency.Duration_Balance_Sitting_Standing}
                </span>
              </p>
            )}{" "}
          </>
        ) : (
          ""
        )}
        {this.props.step == 6 ? (
          <>
            <p className="mb-0 text-secondary-theme mt-2">
              Levels/Instructions:
            </p>
            {this.props.Exercise.VORx1_Yaw && (
              <p className="mb-0 font-400">
                VORx1 Yaw:{" "}
                <span className="font-600">
                  {this.props.SettingsFirst["VORx1 Yaw"] !== undefined
                    ? this.props.SettingsFirst["VORx1 Yaw"]["Current Level"]
                    : ""}
                </span>
              </p>
            )}
            {this.props.Exercise.VORx1_Pitch && (
              <p className="mb-0 font-400">
                VORx1 Pitch:{" "}
                <span className="font-600">
                  {this.props.SettingsFirst["VORx1 Pitch"] !== undefined
                    ? this.props.SettingsFirst["VORx1 Pitch"]["Current Level"]
                    : ""}
                </span>
              </p>
            )}

            {this.props.Exercise.Balance_Sitting_Standing &&
              this.props.patientProgram != "" && (
                <p className="mb-0 mt-2">
                  Balance Sitting/Standing:{" "}
                  <span className="font-600">
                    <div>
                      {
                        this.props.patientProgram.prescriptionList
                          .filter((m) => m.name === "Balance Sitting/Standing")[0]
                          .prescriptionDisplayList.filter(
                            (m) => m.id === "Current Assist"
                          )[0].choiceVal[
                          this.props.SettingsFirst["Balance Sitting/Standing"][
                            "Current Assist"
                          ]
                        ]
                      }
                    </div>
                  </span>
                </p>
              )}
            {this.props.Exercise.Balance_Weight_Shift && (
              <p className="mb-0 mt-2">
                Balance weight shift:{" "}
                <span className="font-600">
                  {Object.keys(
                    this.props.SettingsFirst["Balance Weight Shift"]
                  ).map((key) => {
                    if (this.props.SettingsFirst["Balance Weight Shift"][key]) {
                      return <div>{key.replace("-", " ")}</div>;
                    }
                  })}
                </span>
              </p>
            )}
          </>
        ) : (
          ""
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    Exercise: state.patientProgram.Exercise,
    ExerciseFrequency: state.patientProgram.ExerciseFrequency,
    Frequency: state.patientProgram.Frequency,
    SetFrequency: state.patientProgram.SetFrequency,
    SettingsFirst: state.patientProgram.SettingsFirst,
    SettingsSecond: state.patientProgram.SettingsSecond,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(RightCornerPanel);
