import axios from "axios";

import { localhost, handleError } from "../actions/index";
//Admin user
export const FETCH_AUDITLOG = "FETCH_AUDITLOG";

const qualifier = "cap";
export const fetchLogList =
  (page, perPage, sortColumn = "", sortOrder = "", userId = "") =>
  async (dispatch) => {
    try {
      const FETCH_LOG_URL = `${localhost}/${qualifier}/fetchAuditLogs?page=${page}&size=${perPage}`;
      const url = `${FETCH_LOG_URL}`;
      let res;
      res = await axios.get(url);
      console.log("res::::",res);
      dispatch({
        type: FETCH_AUDITLOG,
        payload: res.data,
      });
    } catch (error) {
      handleError(error, dispatch);
    }
  };