import { STATUS_LIST, ADD_KIT, EDIT_KIT, FETCH_KIT, CLINIC_LIST,KIT_STATUS_REPORT,CHANGE_CLINIC_VALUE } from "../actions/index";

const INITIAL_STATE = {
  statusList: [],
  addkit: [],
  editKit: [],
  clinicList: [],
  selectedClinic:''
};
export default function kitReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_KIT:
      return {
        ...state,
        kitList: action.payload.data.overviewKitsData,
      }
    case STATUS_LIST:
      return {
        ...state,
        statusList: action.payload.data,
      };
    case CLINIC_LIST:
      console.log("action.payload.data.clinics::::",action.payload.data.clinics);
      return {
        ...state,
        clinicList: action.payload.data.clinics,
      }
    case CHANGE_CLINIC_VALUE:
      return {
        ...state,
        selectedClinic: action.payload
      }

    case ADD_KIT:
      return {
        ...state,
        addkit: action.payload.data,
      }
    case EDIT_KIT:
      return {
        ...state,
        editKit: action.payload.data,
      }
    case KIT_STATUS_REPORT:
      return {
        ...state,
        kitReport: action.payload.data,
      }  
    default:
      return state;
  }
}
