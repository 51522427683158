import { FETCH_PROVIDER, ADD_USER, EDIT_USER } from "../actions/index";

const INITIAL_STATE = {
  providerList: [],
  addProvider: [],
  editProvider: [],
};
export default function providerReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_PROVIDER:
      return {
        ...state,
        providerList: action.payload.overviewUserData,
      };
    case ADD_USER:
      return {
        ...state,
        addProvider: action.payload.data,
      }
    case EDIT_USER:
      return {
        ...state,
        editProvider: action.payload.data,
      }
    default:
      return state;
  }
}
