import React, { Component } from 'react';
import Modal from "react-bootstrap/Modal";
import { connect } from "react-redux";
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";
import BarChart from './patientDetail/BarChart';
import moment from 'moment-timezone';
import { bindActionCreators } from 'redux';


const options = {
  animation: false,
  plugins: {
    tooltip: {
      callbacks: {
        title: function (xDatapoint) {
          return xDatapoint[0].label;
        },
        label: function (yDatapoint) {
          if (yDatapoint.raw == '100.00001') {
            return '0';
          } else {
            return yDatapoint.raw;
          }
        }
      }
    },

    datalabels: {
      display: true,
      color: "white",

      anchor: "center",
      offset: 0,
      align: "top",
      font: {
        size: 18,
        weight: 'bold',
        family: 'Figtree',
      },
      formatter: function (value, context) {
        if (value == 100.00001) {
          return '?';
        }

      }
    },
    legend: {
      boxWidth: 0,
      display: false
    },
  },

  scales: {
    x: {
      max: 15,
      grid: {
        display: false
      },
      ticks: {
        font: {
          family: 'Figtree', // Your font family
        },
      },
    },
    y: {
      max: 100,
      color: '#000',
      grid: {
        drawOnChartArea: false
      },

      layout: {
        padding: {
          bottom: 20
        }
      },
      ticks: {
        font: {
          family: 'Figtree', // Your font family
        },
      },
    }
  }
}

export class exportModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: []
    }
  }

  componentDidMount() {
    if (this.props.data) {
      this.updateComplianceData();
    }
  }

  updateComplianceData() {
    this.setState({
      data: {
        labels: this.props.data.map(date => date.stringTime.toUpperCase()),
        datasets: [{

          data: this.props.data.map(date => {
            if (date.compliance == 0) {
              if ((moment(date.id.time).format('yyyy-MM-DD') < moment(this.props.patientInfo.stringCreateAt).format('yyyy-MM-DD'))?true:( !date.hasVORx1YawGame && !date.hasVORx1PitchGame && !date.hasBalanceWeightShiftGame && !date.hasSingleLegBalanceGame )?true:false  || date.stringTime==' ' || this.props.patientInfo.newPatient) {
                return '0';
              } else {
                return '100.00001';
              }
            } else {
              return Math.round(date.compliance)
            }
          }),
          label: '',
          backgroundColor: this.props.data.map(date => { return Math.round(date.compliance) == 0 ? '#fff' : Math.round(date.compliance) > 65 ? '#099A80' : Math.round(date.compliance) > 50 ? '#D18819' : '#EB1E4E' }),
          fill: false,
          borderColor: this.props.data.map(date => { return Math.round(date.compliance) == 0 ? 'red' : '' }),
          borderWidth: this.props.data.map(date => { return Math.round(date.compliance) == 0 ? '3' : '' }),
          datalabels: {
            labels: {
              value: {
                color: this.props.data.map(date => { return Math.round(date.compliance) == 0 ? 'red' : "#fff" })
              }
            },
            display: function (context) {
              if (Math.round(context.dataset.data[context.dataIndex]) < 20) {
                return false; // display labels with an odd index
              } else {
                return true; // display labels with an odd index
              }
            }
          },
        }]
      }
    })
  }
  render() {
    return (
      <div className='mt-3 w-100'>
        <div className='w-100 d-flex'>
          <div className='w-90'>
            <div className='font-figtree font-700 font-20 text-center'>
              DAILY COMPLIANCE (%)
            </div>
            <div className='w-100 d-flex align-items-center'>
              <span className='font-figtree font-700 font-14 text-nowrap'>Compliance (%)</span>
              {/* <div className='text-center w-100'> <ResultChartCalendarCompliance changeDates={this.changeDates.bind(this)} complianceCurrentDate={this.state.complianceCurrentDate} compliancePreviousDate={this.state.compliancePreviousDate} month={this.state.month} selValue={this.state.selValue} /> </div> */}
            </div>
            
            <BarChart title='' data={this.state.data} options={options} ></BarChart>
          </div>
          <span className='font-figtree font-700 font-14 align-self-end -top-2 position-relative ml-4'>Day</span>
        </div>
        <div className='position-relative mt-4 justify-items-center'>
          <div className='font-figtree font-700 font-15 mb-2 text-center'>
            DAILY COMPLIANCE (%)
          </div>
          <table className='table'>
            <tr>
              <th className='text-center font-600 border-top border-dark'>Date</th>
              <th className='text-center font-600 border-top border-dark'>VORx1 Yaw</th>
              <th className='text-center font-600 border-top border-dark'>VORx1 Pitch</th>
              <th className='text-center font-600 border-top border-dark'>Balance Weight Shift</th>
              <th className='text-center font-600 border-top border-dark'>Balance Sitting/Standing</th>
            </tr>
            {this.props.data.length !== 0 &&
              this.props.data.map((date)=>{
              if(date.stringTime !== ' '){
            return <tr>
              <td className='text-center font-600 font-14'>{moment(date.id.time).tz(this.props.patientInfo.timezone).format('MM/DD/YYYY')}</td>
              <td className='text-center'>{date && date.hasVORx1YawGame && !this.props.patientInfo.newPatient?!(moment.tz(date.id.time,date.timezone).format('yyyy-MM-DD') < moment(this.props.patientInfo.stringCreateAt).format('yyyy-MM-DD'))?Math.round(date.compliance_vorx1_yaw):'-':'-'}</td>
              <td className='text-center'>{date && date.hasVORx1PitchGame && !this.props.patientInfo.newPatient?!(moment.tz(date.id.time,date.timezone).format('yyyy-MM-DD') < moment(this.props.patientInfo.stringCreateAt).format('yyyy-MM-DD'))?Math.round(date.compliance_vorx1_pitch):'-':'-'}</td>
              <td className='text-center'>{date && date.hasBalanceWeightShiftGame && !this.props.patientInfo.newPatient?!(moment.tz(date.id.time,date.timezone).format('yyyy-MM-DD') < moment(this.props.patientInfo.stringCreateAt).format('yyyy-MM-DD'))?Math.round(date.compliance_balance_weight_shift):'-':'-'}</td>
              <td className='text-center'>{date && date.hasSingleLegBalanceGame && !this.props.patientInfo.newPatient?!(moment.tz(date.id.time,date.timezone).format('yyyy-MM-DD') < moment(this.props.patientInfo.stringCreateAt).format('yyyy-MM-DD'))?Math.round(date.compliance_balance_single_leg):'-':'-'}</td>
              {/* <td className='text-center'>{date && date.hasVORx1PitchGame?Math.round(date.compliance_vorx1_pitch):'-'}</td>
              <td className='text-center'>{date && date.hasBalanceWeightShiftGame?Math.round(date.compliance_balance_weight_shift):'-'}</td>
              <td className='text-center'>{date && date.hasSingleLegBalanceGame?Math.round(date.compliance_balance_single_leg):'-'}</td> */}
            </tr>
              }
              })
           } 
          </table>
        </div>
        <hr></hr>
        <footer className='mt-3 text-right'>Page <span className='font-600'>{this.props.pageNumber}</span> of <span className='font-600'>{this.props.totalPages}</span></footer>
        {this.props.pageNumber!==this.props.totalPages && <div className="html2pdf__page-break"></div>}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    complianceChartData: state.patientResultsCharts.compliance,
    patientInfo: state.security.user.role=='TVH SUPER ADMIN'?state.superAdminPatientData.superAdminPatients:state.userTableData,
  }
}
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({

  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(exportModal)

// export default exportModal