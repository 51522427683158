import IconButton from "@material-ui/core/IconButton";
import Pagination from "@material-ui/lab/Pagination";
import DriveFileRenameOutlineSharpIcon from "@mui/icons-material/DriveFileRenameOutlineSharp";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { DataGrid } from "@mui/x-data-grid";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchKitList, fetchKitStatusReport } from "../../../actions/index";
import AddEditKit from "./AddEditKit";
import AutoSearchBarKits from "./AutoSearchBarKits";
import * as routes from "../../../constants/routes";

class KitListAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      perPage: 10,
      totalItems: 0,
      sortColumn: "",
      sortOrder: "",
      isTrue: false,
      totalPages: 0,
      AddEditKitModal: false,
      EditElement: null,
      mode: "Add",
      kitReport: {
        Available: 0,
        Deployed: 0,
        Tablets: 0
      },
      kitId:"",
    };
  }

  componentDidMount() {
    this.props.fetchKitList(
      this.state.page,
      this.state.perPage,
      this.state.sortColumn,
      this.state.sortOrder
    );
    this.props.fetchKitStatusReport();
    this.handleChangePage = this.handleChangePage.bind(this);

    let currentUser = this.props.currentUser;

    if (currentUser == '' || Object.keys(currentUser).length == 0) {
      window.location.href = '/';
      return false;
    }
    else {
      let currentUserRole = currentUser.role;
      if (currentUserRole.toLowerCase() != 'tvh admin' && currentUserRole.toLowerCase() != 'tvh super admin') {
        //window.location.href = '/patients';
        this.props.history.push(routes.PATIENT);
        return false;
      }
    }
    let intervalId  = setInterval(() => {
      this.props.fetchKitList(
        this.state.page,
        this.state.perPage,
        this.state.sortColumn,
        this.state.sortOrder,
        this.state.kitId,
        "true");
      this.props.fetchKitStatusReport("true");
    }, 120000);
    this.setState({ intervalId })
  }

  componentWillUnmount(){
    clearInterval(this.state.intervalId)
  }
  componentDidUpdate(prevProps) {
    if (this.props.kit && prevProps.kit !== this.props.kit) {

      if (this.props.kit.page) {
        this.setState({
          ...this.state,
          totalItems: this.props.kit.page.totalItems,
          page: this.props.kit.page.currentPage,
          perPage: this.props.kit.page.pageSize,
          totalPages: this.props.kit.page.totalPages
        });
      }
    }
    // console.log("this.props.currentUser:::::", this.props.currentUser);
    if (
      this.props.currentUser &&
      prevProps.currentUser !== this.props.currentUser
    ) {
      let currentUser = this.props.currentUser;
      if (currentUser == "" || (currentUser.role != "TVH ADMIN" && currentUser.role != "TVH SUPER ADMIN")) {

      }
    }
    if (this.props.editKit && this.props.editKit != prevProps.editKit) {

    }
    if (this.props.kitReport && this.props.kitReport != prevProps.kitReport) {
      this.setState({ kitReport: this.props.kitReport });
    }
  }

  handleChangePage(page) {
    this.setState({ page: page });
    setTimeout(() => {
      this.getKitList();
      window.scrollTo(0, 0)
    }, 100);
  }

  getKitList() {
    let page = this.state.page;
    let perPage = this.state.perPage;

    this.props.fetchKitList(
      page,
      perPage,
      this.state.sortColumn,
      this.state.sortOrder
    );
    this.props.fetchKitStatusReport();
  }

  handleSortModelChange(sortModel) {
    if (sortModel.length > 0) {
      let page = 1;
      let perPage = this.state.perPage;
      let sortColumn = sortModel[0].field;
      let sortOrder = sortModel[0].sort;
      let kitId = this.state.kitId;
      this.setState({
        sortColumn: sortColumn,
        sortOrder: sortOrder,
        page: 1,
      });
      this.props.fetchKitList(page, perPage, sortColumn, sortOrder,kitId);
    }
  }

  handleOpen = () => this.setState({ AddEditKitModal: true });
  handleClose = () =>
    this.setState({ AddEditKitModal: false, mode: "Add", EditElement: null });

  handleOnCellClick = (params) => {
    if (params.field === "actions") {
      this.setState({
        EditElement: params.row,
        mode: "Edit",
      });
      this.handleOpen();
    }
  };
  setSearchKitId = (kitId) => {
    this.setState({ kitId: kitId });

  }
  render() {
    const NoResultsOverlay = () => {
      return <div className="no-result">0 results found</div>;
    };

    const CustomPagination = () => {
      return (
        <Pagination
          count={this.state.totalPages}
          page={this.state.page}
          variant="outlined"
          onChange={(event, value) => this.handleChangePage(value)}
        />
      );
    };

    function RowMenuCell(props) {
      //const { api, id } = props;

      const handleEditClick = (event) => {
        // console.log("props::", props.row);
        //console.log("event::::",event);
        //event.stopPropagation();
        //api.setRowMode(id, 'edit');
      };
      return (
        <div className="">
          <IconButton
            color="inherit"
            className=""
            size="small"
            aria-label="edit"
            onClick={handleEditClick}
          >
            <DriveFileRenameOutlineSharpIcon fontSize="small" />
          </IconButton>
        </div>
      );
    }

    let rows = [];
    const columns = [
      // {field: 'id',headerName: 'Id'},
      {
        field: "kitNumber",
        headerName: "Kit#",
        flex: 1,
        sortingOrder: ["desc", "asc"],
      },
      {
        field: "clinicName",
        headerName: "Clinic Name",
        sortingOrder: ["desc", "asc"],
        flex: 1,
      },
      {
        field: "location",
        headerName: "Location",
        flex: 1,
        sortingOrder: ["desc", "asc"],
      },

      {
        field: "imeiNumber",
        headerName: "IMEI #",
        flex: 1,
        sortingOrder: ["desc", "asc"],
      },
      {
        field: "status",
        headerName: "Status",
        flex: 1,
        sortingOrder: ["desc", "asc"],
      },
      {
        field: "actions",
        headerName: "",
        renderCell: RowMenuCell,
        sortable: false,
        width: 100,
        headerAlign: "center",
        filterable: false,
        align: "center",
        disableColumnMenu: true,
        disableReorder: true,
      },
    ];

    if (this.props.kit != null) {
      let { rowList } = this.props.kit;
      if (rowList && rowList !== null) {
        rowList.forEach((row, index) => {
          rows.push({
            id: row.id,
            kitNumber: row.kitNumber,
            clinicName: row.clinicName,
            location: row.location,
            imeiNumber: row.imeiNumber,
            status: row.status,
          });
        });
      }
    }
    return (
      <div className="p-3">
        <Grid container className="MuiToolbar-gutters align-items-end">
          <Grid item xs={12} md={12} className="mb-3">
            <div className="d-sm-flex justify-content-between w-100 align-items-center pd-top-6 push-mt-30">
              <h2>
                <strong>Kits</strong>
              </h2>
              <div className="d-flex align-items-center count-information">
                <h4 className="color-grey px-2 px-sm-4 boder-right">Tablets:<span className="font-600 font-28 text-theme-gray-dark ml-2">{this.state.kitReport.Tablets}</span></h4>
                <h4 className="color-grey px-2 px-sm-4 boder-right">Deployed:<span className="font-600 font-28 red-color ml-2">{this.state.kitReport.Deployed}</span></h4>
                <h4 className="color-grey pl-2 pl-sm-4">Available:<span className="font-600 font-28 text-secondary-theme ml-2">{this.state.kitReport.Available}</span></h4>
              </div>
            </div>
            <div className="d-flex">
            <div className="col-md-4 pl-0">

              <AutoSearchBarKits
                tableData={this.props.tableData}
                sortColumn={this.state.sortColumn}
                sortOrder={this.state.sortOrder}
                editProvider={[]}
                setKitId={(kitId) => this.setSearchKitId(kitId)}
                />
                </div>
              
                  <button
                    className="ml-auto bg-primary-theme pointer py-2 px-4 border-0 rounded-14 text-white font-figtree font-700 font-18"
                    variant="contained"
                    onClick={() => this.handleOpen()}
                  >
                    Add Tablet
                  </button>
              </div>
          </Grid>          
          {this.state.AddEditKitModal &&
            <AddEditKit
              mode={this.state.mode}
              EditElement={this.state.EditElement}
              handleClose={this.handleClose}
              AddEditKitModal={this.state.AddEditKitModal}
              page={this.state.page}
              perPage={this.state.perPage}
              sortColumn={this.state.sortColumn}
              sortOrder={this.state.sortOrder}
              kitId={this.state.kitId}
            />
          }
          <Grid item xs={12}>
            <Box sx={{ height: "780px", width: "100%" }} className="datatable">
              <DataGrid
                sx={{
                  '.MuiDataGrid-iconButtonContainer': {
                    visibility: 'visible',
                  },
                  '.MuiDataGrid-sortIcon': {
                    opacity: 'inherit !important',
                  },
                }}
                rows={rows}
                rowCount={this.state.totalItems}
                rowsPerPageOptions={this.state.page.perPage}
                pagination
                page={this.state.page}
                pageSize={5}
                paginationMode="server"
                columns={columns}
                disableColumnMenu="true"
                onCellClick={this.handleOnCellClick}
                components={{
                  NoRowsOverlay: NoResultsOverlay,
                  Pagination: CustomPagination,
                }}
                initialState={{
                  sorting: {
                    sortModel: [
                      {
                        field: this.state.sortColumn,
                        sort: this.state.sortOrder,
                      },
                    ],
                  },
                }}
                sortingMode="server"
                onSortModelChange={(newSortModel) =>
                  this.handleSortModelChange(newSortModel)
                }
                disableRowSelectionOnClick={true}
              />
            </Box>
          </Grid>
        </Grid>
      </div>
    );
  }
}

function mapStateToProps(state) {

  return {
    kit: state.kit.kitList,
    currentUser: state.security.user,
    kitReport: state.kit.kitReport
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchKitList,
      fetchKitStatusReport,
    },
    dispatch
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(KitListAdmin);
