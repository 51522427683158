const images = {
  Vestibular: require("./VESTIBULAR.png").default,
  Backpain: require("./BACKPAIN.png").default,
  Compliance: require("./compliance.png").default,
  Asthma: require("./ASTHMA.png").default,
  Overview: require("./overview.png").default,
  Main: require("./MAIN.png").default,
  HeaderImage: require("./Airen HORZ.png").default,
  Background: require("./cfdrcBlueLight.png").default,
  AiImage: require("./AI.png").default,
  IronMain: require("./ironmain.png").default,
};
export default images;
