/*
 * The users reducer will always return an array of users no matter what
 * You need to return something, so if there are no users then just return an empty array
 * */
const initialState = {
    modalType:null,
    modalProps:{}
}

export default function appReducer (state = initialState, action) {

    switch (action.type) {
        case 'SHOW_MODAL':
            return action.payload;
        case 'HIDE_MODAL':
            return initialState
        default:
            return state
    }
}
