import axios from "axios";
import { localhost, handleError } from "../actions/index";
const qualifier = "cap";
export const VERIFY_EMAIL = "VERIFY_EMAIL";
export const VALIDATE_PASSWORD_TOKEN = "VALIDATE_PASSWORD_TOKEN";
export const SET_PASSWORD = "SET_PASSWORD";

export const verifyEmailAction = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: VERIFY_EMAIL,
      payload: {
        data: '',
        token: null
      },
    });
    const FETCH_LOG_URL = `${localhost}/${qualifier}/api/users/forgotPassword?username=${payload}`;
    const url = `${FETCH_LOG_URL}`;
    const res = await axios.put(url);
    dispatch({
      type: VERIFY_EMAIL,
      payload: res,
    });
  } catch (error) {
    dispatch({
      type: VERIFY_EMAIL,
      payload: error,
    });
    handleError(error, dispatch);
  }
};

export const validatePasswordTokenAction = (payload) => async (dispatch) => {
  try {
    const FETCH_LOG_URL = `${localhost}/${qualifier}/api/users/validatePasswordToken?passwordToken=${payload}`;
    const url = `${FETCH_LOG_URL}`;
    const res = await axios.get(url);
    dispatch({
      type: VALIDATE_PASSWORD_TOKEN,
      payload: res,
    });
  } catch (error) {
    dispatch({
      type: VALIDATE_PASSWORD_TOKEN,
      payload: error,
    });
    handleError(error, dispatch);
  }
};

export const setPasswordAction = (payload) => async (dispatch) => {
  try {
    const FETCH_LOG_URL = `${localhost}/${qualifier}/api/users/setPassword`;
    const url = `${FETCH_LOG_URL}`;
    const res = await axios.post(url, payload);
    dispatch({
      type: SET_PASSWORD,
      payload: res,
    });
  } catch (error) {
    dispatch({
      type: SET_PASSWORD,
      payload: error,
    });
    handleError(error, dispatch);
  }
};

export const matchPreviousPassword = (payload) => async (dispatch) => {
  try {
    const FETCH_LOG_URL = `${localhost}/${qualifier}/api/users/matchPreviousPassword`;
    const url = `${FETCH_LOG_URL}`;
    const res = await axios.post(url, payload);
    return res.data.isPasswordMatch;

  } catch (error) {

    handleError(error, dispatch);
  }
};