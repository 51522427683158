import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Slider from "@mui/material/Slider";
import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updatePatientProgramFrequency } from "../../../../actions/patientProgramActions";
import {
  SaveSettingsFirst,
  stopPragram,
} from "../../../../actions/setPatientProgramActions";
import { alertService } from "./../../../GlobalComponent/alertService";
import Progress from "./Progress";
import RightCornerPanel from "./RightCornerPanel";

const style = {
  modal: {
    p: 4,
  },
};

const marks = [
  {
    value: 0,
    label: "0",
  },
  {
    value: 1,
    label: "1",
  },
  {
    value: 2,
    label: "2",
  },
  {
    value: 3,
    label: "3",
  },
];

export class SettingsFirst extends Component {
  constructor(props) {
    super(props);
    this.state = {
      SettingsFirst: {
        Level_of_VORx1_Yaw: 0,
        Level_of_VORx1_Pitch: 0,
        Level_of_Balance_Weight_Shift: "",
        Level_of_Balance_Sitting_Standing: "",
      },
      patientPrescriptionData: [],
      errors: {},
      BWSError: "",
      balanceSingleLegList: [],
      balanceSingleLegList_selected: "",
      balanceWeightShiftList: [],
    };
  }

  async componentDidUpdate(prevProps, prevState) {
    if (
      (this.props.patientProgram &&
        this.props.patientProgram !== {} &&
        this.props.patientProgram !== prevProps.patientProgram) ||
      this.props.ProgramStatus !== prevProps.ProgramStatus ||
      this.props.EditProgramStatus !== prevProps.EditProgramStatus
    ) {
      if (
        this.props.patientProgram &&
        Object.keys(this.props.patientProgram).length > 0
      ) {
        let patientProgramArray = this.props.patientProgram;
        let patientbalanceSingleLegList =
          this.props.patientProgram.prescriptionList
            .filter((m) => m.name === "Balance Sitting/Standing")[0]
            .prescriptionDisplayList.filter(
              (m) => m.id === "Current Assist"
            )[0].choiceVal;

        let patientbalanceSingleLegList_selected =
          await this.props.patientProgram.prescriptionList
            .filter((m) => m.name === "Balance Sitting/Standing")[0]
            .prescriptionDisplayList.filter((m) => m.id === "Current Assist")[0]
            .value;

        let patientbalanceWeightShiftList =
          this.props.patientProgram.prescriptionList
            .filter((m) => m.name === "Balance Weight Shift")[0]
            .prescriptionDisplayList.filter(
              (m) => m.group === "Balance Weight Shift" && m.unit === "boolean"
            );
        //TVH-316 temporary change      
        //TVH-339 add condition to filter Omni-Directional Option - added  && val.id !=="Omni-Directional condition
        patientbalanceWeightShiftList = patientbalanceWeightShiftList.filter((val) => { return val.id !== "Randomized Left-Right and Heel-Toe" && val.id !== "Omni-Directional" })

        let patientbalanceWeightShiftList_selected = '';
        patientbalanceWeightShiftList.forEach((obj, key) => {
          if (obj.value === true) {
            patientbalanceWeightShiftList_selected = key;
          }
        });

        let patient_Level_of_Balance_Sitting_Standing =
          patientbalanceSingleLegList[patientbalanceSingleLegList_selected];

        let patient_Level_of_VORx1_Yaw =
          this.props.patientProgram.prescriptionList.filter(
            (m) => m.name === "VORx1 Yaw"
          )[0].currentLevel;

        let patient_Level_of_VORx1_Pitch =
          this.props.patientProgram.prescriptionList.filter(
            (m) => m.name === "VORx1 Pitch"
          )[0].currentLevel;
        setTimeout(() => {
          this.setState(() => ({
            patientPrescriptionData: patientProgramArray,
            balanceSingleLegList: patientbalanceSingleLegList,
            balanceSingleLegList_selected: patientbalanceSingleLegList_selected,
            balanceWeightShiftList: patientbalanceWeightShiftList,
            SettingsFirst: {
              ...this.state.SettingsFirst,
              Level_of_Balance_Sitting_Standing: patientbalanceSingleLegList_selected,
              Level_of_Balance_Weight_Shift: patientbalanceWeightShiftList_selected,
              Level_of_VORx1_Yaw: patient_Level_of_VORx1_Yaw,
              Level_of_VORx1_Pitch: patient_Level_of_VORx1_Pitch,
            },
          }));
        }, 300);
      }
    }
  }
  valueLabelFormat(value) {
    let selected = marks.findIndex((mark) => mark.value === value);
    return selected;
  }

  onVorxYawSliderValChange = (val) => {
    this.setState({
      SettingsFirst: {
        ...this.state.SettingsFirst,
        Level_of_VORx1_Yaw: val,
      },
    });
  };
  onVorxPitchSliderValChange = (val) => {
    this.setState({
      SettingsFirst: {
        ...this.state.SettingsFirst,
        Level_of_VORx1_Pitch: val,
      },
    });
  };

  handleBalanceSingleLegChange = (e) => {
    this.setState({
      SettingsFirst: {
        ...this.state.SettingsFirst,
        Level_of_Balance_Sitting_Standing: e.target.value,
      },
    });
  };

  handleBalanceWeightShiftChange = (e) => {
    this.setState({
      BWSError: ""
    })
    // const { name, checked } = e.target;
    // let balanceWeightShiftList = this.state.balanceWeightShiftList;
    // let data = balanceWeightShiftList.map((m) => {
    //   if (m.name === name) {
    //     return { ...m, value: !m.value };
    //   } else {
    //     return { ...m };
    //   }
    // });

    // this.setState((prev) => ({
    //   ...prev,
    //   balanceWeightShiftList: data,
    // }));
    let balanceWeightShiftList = this.state.balanceWeightShiftList;
    let data = balanceWeightShiftList.map((m) => {
      return { ...m, value: false };
    });
    this.setState({
      SettingsFirst: {
        ...this.state.SettingsFirst,
        Level_of_Balance_Weight_Shift: e.target.value,
        balanceWeightShiftList: data
      },
    });
  };

  validateData() {
    const {
      Level_of_VORx1_Yaw,
      Level_of_VORx1_Pitch,
      Level_of_Balance_Weight_Shift,
      Level_of_Balance_Sitting_Standing,
    } = this.state.SettingsFirst;
    let errors = {};
    let data = {};

    if (this.props.ExerciseData.VORx1_Yaw) {
      data = {
        ...data,
        "VORx1 Yaw": {
          "Current Level": this.state.SettingsFirst.Level_of_VORx1_Yaw,
        },
      };
    }
    if (this.props.ExerciseData.VORx1_Pitch) {
      data = {
        ...data,
        "VORx1 Pitch": {
          "Current Level": this.state.SettingsFirst.Level_of_VORx1_Pitch,
        },
      };
    }
    if (this.props.ExerciseData.Balance_Sitting_Standing) {
      data = {
        ...data,
        "Balance Sitting/Standing": {
          "Current Assist":
            this.state.SettingsFirst.Level_of_Balance_Sitting_Standing,
        },
      };
    }

    if (this.props.ExerciseData.Balance_Weight_Shift) {
      let isSelectedBWS = false;
      let list = [];
      if (Level_of_Balance_Weight_Shift !== '') {
        isSelectedBWS = true;
        this.state.balanceWeightShiftList.forEach((val, key) => {
          //let weightShiftName = this.state.balanceWeightShiftList[Level_of_Balance_Weight_Shift].name;
          let weightShiftName = val.name;

          if (key == Level_of_Balance_Weight_Shift) {
            list.push({ [weightShiftName]: true });
          } else {
            list.push({ [weightShiftName]: false });
          }
        })

      }
      // const list = this.state.balanceWeightShiftList.map((m) => {
      //   if (m.value == true) {
      //     isSelectedBWS = true;
      //   }
      //   return { [m.name]: m.value };
      // });

      const objList = Object.assign({}, ...list);
      data = { ...data, "Balance Weight Shift": objList };
      if (isSelectedBWS == false) {
        errors = {
          ...errors,
          "Balance_Weight_Shift": false
        }
        this.setState({
          BWSError: "Please select at least one value."
        })
      }
    }
    return { data, errors };
  }

  async OnSaveHandler() {
    const { data, errors } = this.validateData();
    this.setState({ errors: errors });
    if (Object.entries(errors).length !== 0) {
      return;
    } else {
      await this.props.SaveSettingsFirst(data);
      if (this.props.ProgramStatus == true) {
        if (
          this.props.ExerciseData.VORx1_Pitch == false &&
          this.props.ExerciseData.VORx1_Yaw == false
        ) {
          let Exercises = this.props.ExerciseData;
          let ExerciseFrequency = this.props.ExerciseFrequency;
          let Frequency = this.props.Frequency;
          let SetFrequency = this.props.SetFrequency.DataArray;
          let SettingsFirst = this.props.SettingsFirst;
          this.props.handlerAddPatientProgram(
            Exercises,
            ExerciseFrequency,
            Frequency,
            SetFrequency,
            SettingsFirst
          );
          this.props.handleCloseSettingsFirstModal();
        } else {
          this.props.handleCloseSettingsFirstModal();
          this.props.handleOpenSettingsSecondModal();
        }
      } else {
        if (
          this.props.ExerciseData.VORx1_Pitch == false &&
          this.props.ExerciseData.VORx1_Yaw == false
        ) {
          this.saveUpdateData();
          this.props.handleCloseSettingsFirstModal();
        } else {
          this.props.handleCloseSettingsFirstModal();
          this.props.handleOpenSettingsSecondModal();
        }
      }
    }
  }

  saveUpdateData = async () => {
    let Exercises = this.props.ExerciseData;
    let ExerciseFrequency = this.props.ExerciseFrequency;
    let Frequency = this.props.Frequency;
    let SetFrequency = this.props.SetFrequency.DataArray;
    let SettingsFirst = this.props.SettingsFirst;
    let postPlayload = [];
    let obj = {};
    if (this.props.ExerciseAdded) {
      Object.keys(Exercises).map((key) => {
        let name = key.replace(/_/g, " ");
        obj = {
          name: name.replace("Balance Sitting Standing", "Balance Sitting/Standing"),
          value: Exercises[key],
          type: "Object",
        };
        postPlayload.push(obj);
      });
      Object.keys(ExerciseFrequency).map((key) => {
        obj = { name: key, value: ExerciseFrequency[key], type: "Object" };
        postPlayload.push(obj);
      });
      obj = {
        name: "sessionsPerDay",
        value: Frequency.sessionsPerDay ? Frequency.sessionsPerDay : 3,
        type: "Object",
      };
      postPlayload.push(obj);
      Object.keys(SetFrequency).map((key) => {
        let programVal = SetFrequency[key];
        Object.keys(programVal).map((progmKey) => {
          obj = {
            name: progmKey,
            value: programVal[progmKey],
            type: key.replace(/_/g, " ").replace("Balance Sitting Standing", "Balance Sitting/Standing"),
          };
          postPlayload.push(obj);
          if (progmKey == "Current") {
            obj = {
              name: 'At Intake',
              value: programVal[progmKey],
              type: key.replace(/_/g, " ").replace("Balance Sitting Standing", "Balance Sitting/Standing"),
            };
            postPlayload.push(obj);
            obj = {
              name: 'Goal',
              value: programVal[progmKey],
              type: key.replace(/_/g, " ").replace("Balance Sitting Standing", "Balance Sitting/Standing"),
            };
            postPlayload.push(obj);
          }
        });
      });
    }
    Object.keys(SettingsFirst).map((key) => {
      let programVal = SettingsFirst[key];
      Object.keys(programVal).map((progmKey) => {
        obj = {
          name: progmKey,
          value: programVal[progmKey],
          type: key.replace(/_/g, " ").replace("Balance Sitting Standing", "Balance Sitting/Standing"),
        };
        postPlayload.push(obj);
      });
    });
    await this.props.updatePatientProgramFrequency(
      postPlayload,
      this.props.patientUsername
    );
    alertService.success("Record updated successfully!", {
      autoClose: true,
    });
  };

  render() {
    return (
      <Modal
        show={this.props.showSettingsFirstModal}
        size="xl"
        className="theme-modal-1101 pt-5"
      >
        {this.props.patientInfo.newPatient === true &&
          <div className="p-1 absolute w-100 -top-23 bg-white rounded-14">
            <Progress value={83.33} />
          </div>
        }
        <Modal.Body>
          <Box
            sx={style.modal}
            component="form"
            autoComplete="off"
            className="p-3 rounded-14 border-0"
          >
            <div className="d-flex">
              <div className="w-75">
                {(this.props.ProgramStatus == true ||
                  this.props.ExerciseAdded == true) && (
                    <div
                      className="text-primary-theme font-figtree font-700 font-18 pointer d-inline-flex align-items-center mb-3"
                      onClick={() => {
                        this.setState({ errors: {} });
                        this.props.handleCloseSettingsFirstModal();
                        this.props.handleOpenSetFrequencyModal();
                      }}
                    >
                      <ArrowBackIosNewIcon fontSize="small" />
                      Back
                    </div>
                  )}
                <h4 className="font-figtree font-500 font-38 mb-5">Settings</h4>
                <div className="mx-auto ml-2 ml-md-5 pl-0 pl-md-5 pr-5 mb-2 mb-md-5 pb-0 pb-md-5">
                  {this.props.ExerciseData.VORx1_Yaw ||
                    this.props.ExerciseData.VORx1_Pitch ? (
                    <>
                      <h6 className="font-figtree font-600 font-18 text-theme-gray-dark">
                        Select the level for the following exercise(s):
                      </h6>
                      <div>From 0 to 2, Easy to difficult.</div>
                    </>
                  ) : (
                    ""
                  )}
                  {this.props.ExerciseData.VORx1_Yaw ? (
                    <div className="d-flex mt-2">
                      <div className="w-120px mt-2 frequency-section font-figtree font-400 font-16 text-secondary-theme">
                        VORX1 YAW
                      </div>
                      <Slider
                        className="slider-theme"
                        aria-label="Restricted values"
                        value={this.state.SettingsFirst.Level_of_VORx1_Yaw}
                        valueLabelFormat={this.valueLabelFormat}
                        step={1}
                        max={2}
                        valueLabelDisplay="auto"
                        marks={marks}
                        onChange={(e) => {
                          this.onVorxYawSliderValChange(e.target.value);
                        }}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  <p className="text-danger">
                    {this.props.ExerciseData.VORx1_Yaw &&
                      this.state.errors?.Level_of_VORx1_Yaw}
                  </p>
                  {this.props.ExerciseData.VORx1_Pitch ? (
                    <div className="d-flex mt-2">
                      <div className="w-120px mt-2 frequency-section font-figtree font-400 font-16 text-secondary-theme">
                        VORX1 PITCH
                      </div>
                      <Slider
                        className="slider-theme"
                        aria-label="Restricted values"
                        value={this.state.SettingsFirst.Level_of_VORx1_Pitch}
                        valueLabelFormat={this.valueLabelFormat}
                        step={1}
                        max={2}
                        valueLabelDisplay="auto"
                        marks={marks}
                        onChange={(e) => {
                          this.onVorxPitchSliderValChange(e.target.value);
                        }}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  <p className="text-danger">
                    {this.props.ExerciseData.VORx1_Pitch &&
                      this.state.errors?.Level_of_VORx1_Pitch}
                  </p>
                  {this.props.ExerciseData.Balance_Sitting_Standing ||
                    this.props.ExerciseData.Balance_Weight_Shift ? (
                    <div>
                      <h6 className="font-figtree font-600 font-18 text-theme-gray-dark mt-5">
                        Select the instructions for the following exercise(s):
                      </h6>
                    </div>
                  ) : (
                    ""
                  )}
                  {this.props.ExerciseData.Balance_Sitting_Standing ? (
                    <FormControl>
                      <FormLabel
                        className="font-figtree font-400 font-16 text-secondary-theme mb-3"
                        id="demo-controlled-radio-buttons-group"
                      >
                        BALANCE SITTING/STANDING:
                      </FormLabel>
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        className="theme-radio"
                        value={
                          this.state.SettingsFirst.Level_of_Balance_Sitting_Standing
                        }
                        onClick={(e) => this.handleBalanceSingleLegChange(e)}
                      >
                        {Object.keys(this.state.balanceSingleLegList).map(
                          (opt) => {
                            return (
                              <FormControlLabel
                                value={opt}
                                control={<Radio />}
                                label={this.state.balanceSingleLegList[opt]}
                              />
                            );
                          }
                        )}
                      </RadioGroup>
                    </FormControl>
                  ) : (
                    ""
                  )}
                  <p className="text-danger">
                    {this.props.ExerciseData.Balance_Sitting_Standing &&
                      this.state.errors?.Level_of_Balance_Sitting_Standing}
                  </p>
                  {this.props.ExerciseData.Balance_Weight_Shift ? (
                    <>
                      <div className="mt-5">
                        <FormControl>
                          <FormLabel
                            className="font-figtree font-400 font-16 text-secondary-theme mb-3"
                            id="demo-controlled-radio-buttons-group"
                          >
                            BALANCE WEIGHT SHIFT:
                          </FormLabel>
                          <p className="text-danger mb-0">
                            {this.props.ExerciseData.Balance_Weight_Shift &&
                              this.state.errors?.Level_of_Balance_Weight_Shift}
                          </p>
                          <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            className="theme-radio"
                            value={
                              this.state.SettingsFirst.Level_of_Balance_Weight_Shift
                            }
                            onClick={(e) => this.handleBalanceWeightShiftChange(e)}
                          >
                            {Object.keys(this.state.balanceWeightShiftList).map(
                              (opt) => {
                                return (
                                  <FormControlLabel
                                    value={opt}
                                    control={<Radio />}
                                    label={this.state.balanceWeightShiftList[opt].name}
                                  />
                                );
                              }
                            )}
                          </RadioGroup>

                        </FormControl>
                      </div>
                      <div className="text-danger text-left">
                        {this.state.BWSError !== ""
                          ? this.state.BWSError
                          : ""}
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <RightCornerPanel step={5} />
            </div>
            <div className="d-flex my-2 mt-md-3 mb-md-3">
              <button
                className="ml-auto mr-2 btn border border-primary-theme cursor-pointer rounded-14 btn-lg"
                variant="contained"
                onClick={(e) => {
                  e.preventDefault();
                  this.props.handleOpenCancelModal();
                }}
              >
                Cancel
              </button>

              <button
                className="bg-primary-theme text-white pointer rounded-14 btn-lg theme-btn border-0"
                variant="contained"
                onClick={(e) => {
                  e.preventDefault();
                  this.OnSaveHandler();
                }}
              >
                {this.props.ExerciseData.VORx1_Pitch == false &&
                  this.props.ExerciseData.VORx1_Yaw == false
                  ? "Save"
                  : "Next"}
              </button>
            </div>
          </Box>
        </Modal.Body>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ProgramStatus: state.patientProgram.startProgram,
    EditProgramStatus: state.patientProgram.startEditProgram,
    ExerciseAdded: state.patientProgram.ExerciseAdded,
    ExerciseData: state.patientProgram.Exercise,
    ExerciseFrequency: state.patientProgram.ExerciseFrequency,
    Frequency: state.patientProgram.Frequency,
    SetFrequency: state.patientProgram.SetFrequency,
    SettingsFirst: state.patientProgram.SettingsFirst,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      stopPragram,
      SaveSettingsFirst,
      updatePatientProgramFrequency,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsFirst);