import axios from "axios";

import { localhost, handleError, GET_ERRORS } from "../actions/index";
//Admin user
export const FETCH_KIT = "FETCH_KIT";
export const ADD_KIT = "ADD_KIT";
export const EDIT_KIT = "EDIT_KIT";
export const STATUS_LIST = "STATUS_LIST";
export const CLINIC_LIST = "CLINIC_LIST";
export const KIT_STATUS_REPORT = "KIT_STATUS_REPORT";
export const CHANGE_CLINIC_VALUE = "CHANGE_CLINIC_VALUE";



const qualifier = "cap";

export const fetchKitList =
  (page, perPage, sortColumn = "", sortOrder = "", kitId = "", loaderHide="false") =>
    async (dispatch) => {
      try {
        let FETCH_LOG_URL = `${localhost}/${qualifier}/kits/fetchKitsTableData?page=${page}&size=${perPage}&kitId=${kitId}`;
        if (sortColumn != '') {
          FETCH_LOG_URL = FETCH_LOG_URL + `&sort=${sortColumn},${sortOrder}`
        }
        const url = `${FETCH_LOG_URL}`;
        let res;
        res = await axios.get(url,{loaderHide:loaderHide});
        // res = {
        //   "data": {
        //     "overviewKitsData": {
        //       "title": "vestibular",
        //       "subTitle": "",
        //       "columns": {
        //         "columns": [
        //           {
        //             "header": "Kit #",
        //             "accessor": "kitNumber",
        //             "toolText": "kit number"
        //           },
        //           {
        //             "header": "Clinic Name",
        //             "accessor": "clinicName",
        //             "toolText": "clinic name"
        //           },
        //           {
        //             "header": "Location",
        //             "accessor": "location",
        //             "toolText": "location name"
        //           },
        //           {
        //             "header": "IMEI #",
        //             "accessor": "imei",
        //             "toolText": "kit IMEI number"
        //           },
        //           {
        //             "header": "Status",
        //             "accessor": "status",
        //             "toolText": "kit status"
        //           }
        //         ],
        //         "header": "Kits Information"
        //       },
        //       "rowList": [
        //         {
        //           "id": "e0c00b2a-a3f6-4f03-aba0-bded8d0862f3",
        //           "kitNumber": "101-000004",
        //           "clinicName": "Rogers Park Family Medicine",
        //           "location": "Rogers Park",
        //           "imeiNumber": "123456789012344",
        //           "status": "SHIPPED",
        //           "patientName": null,
        //           "mrn": null
        //         },
        //         {
        //           "id": "2f2c60a8-b598-45e0-bced-9889342f3e20",
        //           "kitNumber": "101-000001",
        //           "clinicName": "-",
        //           "location": "-",
        //           "imeiNumber": "123456789012341",
        //           "status": "RECEIVED",
        //           "patientName": null,
        //           "mrn": null
        //         },
        //         {
        //           "id": "345f947f-cacf-4090-be1f-ebe117be9114",
        //           "kitNumber": "101-000002",
        //           "clinicName": "-",
        //           "location": "-",
        //           "imeiNumber": "123456789012342",
        //           "status": "RECEIVED",
        //           "patientName": null,
        //           "mrn": null
        //         },
        //         {
        //           "id": "aadd3ed9-f2e2-4f80-a280-b450fdfcdbde",
        //           "kitNumber": "123-456789",
        //           "clinicName": "-",
        //           "location": "-",
        //           "imeiNumber": "123456789012345",
        //           "status": "RECEIVED",
        //           "patientName": null,
        //           "mrn": null
        //         },
        //         {
        //           "id": "d7d26a50-7d7d-49f5-8868-1768d64589d6",
        //           "kitNumber": "101-000003",
        //           "clinicName": "-",
        //           "location": "-",
        //           "imeiNumber": "123456789012343",
        //           "status": "RECEIVED",
        //           "patientName": null,
        //           "mrn": null
        //         }
        //       ],
        //       "page": {
        //         "totalItems": 5,
        //         "totalPages": 1,
        //         "pageSize": 10,
        //         "currentPage": 1,
        //         "sort": "[status, desc]"
        //       }
        //     },
        //     "selectedUser": {
        //       "id": "a81c7015-ad6c-4262-bbc7-84185954cd2d",
        //       "username": "admin@devdigital.com",
        //       "fullName": "TheraVista, Admin",
        //       "password": "$2a$10$Sn56lsNyr0kasrh6C6l.y.tL5jVPdCpRvurJqGET0mC0AmCSckjxG",
        //       "confirmPassword": null,
        //       "lastName": "TheraVista",
        //       "firstName": "Admin",
        //       "age": 30,
        //       "height": 70.0,
        //       "weight": 0.0,
        //       "gender": "m",
        //       "ethnicity": null,
        //       "role": "TVH ADMIN",
        //       "selectedUser": "a81c7015-ad6c-4262-bbc7-84185954cd2d",
        //       "timezone": null,
        //       "longitude": 0.0,
        //       "latitude": 0.0,
        //       "initialStartTime": 0,
        //       "analyticsAlgorithm": "J48",
        //       "timeCreatedEndTime": 0,
        //       "cycle": -1,
        //       "topic": null,
        //       "create_At": "2023-05-05T06:13:31.000+0000",
        //       "update_At": "2023-05-09T07:39:09.789+0000",
        //       "status": null,
        //       "statusUpdate": 20230505061331,
        //       "email": "admin@devdigital.com",
        //       "pin": null,
        //       "kitNumber": null,
        //       "medicalRecordNumber": null,
        //       "phoneNumber": null,
        //       "passwordToken": null,
        //       "patientNumber": null,
        //       "clinicLocationMapping": null,
        //       "factorDisplayList": [],
        //       "ptName": null,
        //       "ptId": null,
        //       "active": true,
        //       "newPatient": false,
        //       "accountNonLocked": true,
        //       "authorities": null,
        //       "credentialsNonExpired": true,
        //       "accountNonExpired": true
        //     }
        //   }
        // };

        dispatch({
          type: FETCH_KIT,
          payload: res,
        });
      } catch (error) {
        handleError(error, dispatch);
      }
    };

export const searchClinic = (searchString) => async (dispatch) => {
  try {
    const FETCH_LOG_URL = `${localhost}/${qualifier}/searchClinic?searchString=${searchString}`;
    const url = `${FETCH_LOG_URL}`;
    const res = await axios.get(url);
    return res;
  } catch (error) {
    handleError(error, dispatch);
  }
};

export const searchClinicLocations = (searchString) => async (dispatch) => {
  try {
    const FETCH_LOG_URL = `${localhost}/${qualifier}/location?clinicId=${searchString}`;
    const url = `${FETCH_LOG_URL}`;
    const res = await axios.get(url);
    return res;
  } catch (error) {
    handleError(error, dispatch);
  }
};

export const FetchKit = (payload) => async (dispatch) => {
  try {
    const FETCH_LOG_URL = `${localhost}/${qualifier}/kits/fetchKit?kitId=${payload}`;
    const url = `${FETCH_LOG_URL}`;
    let res;
    res = await axios.get(url);
    // res = {
    //   "data":
    //   {
    //     "kit": {
    //       "id": "e0c00b2a-a3f6-4f03-aba0-bded8d0862f3",
    //       "kitNumber": "101-000004",
    //       "imeiNumber": "123456789012344",
    //       "status": "READY_FOR_PATIENT",
    //       "clinic": {
    //         "id": "07567ab1-1fea-4f52-a9c4-84ecd022cb9e",
    //         "name": "Rogers Park Family Medicine",
    //         "clinicNumber": 1002,
    //         "locations": [
    //           {
    //             "id": "3b8370bc-67ff-423f-9d44-19070b42e710",
    //             "name": "Rogers Park",
    //             "locationNumber": 101,
    //             "timezone": "America/New_York"
    //           }
    //         ]
    //       },
    //       "location": {
    //         "id": "3b8370bc-67ff-423f-9d44-19070b42e710",
    //         "name": "Rogers Park",
    //         "locationNumber": 101,
    //         "timezone": "America/New_York"
    //       },
    //       "createdby": "a81c7015-ad6c-4262-bbc7-84185954cd2d",
    //       "createdOn": 1685078394430,
    //       "updatedBy": "3ebb2d0b-fa7d-4f05-8f7f-be18e56fd85d",
    //       "updatedOn": 1685355735049
    //     }
    //   }
    // }
    return res.data.kit;
  } catch (error) {
    handleError(error, dispatch);
  }
};
export const EditKit = (payload) => async (dispatch) => {
  try {
    const FETCH_LOG_URL = `${localhost}/${qualifier}/kits/updateKits`;
    const url = `${FETCH_LOG_URL}`;
    const res = await axios.put(url, payload);
    dispatch({
      type: EDIT_KIT,
      payload: res,
    });
  } catch (err) {
    //handleError(error, dispatch);
    if (err.response && err.response.data) {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    }
  }
};

export const FetchStatusList = (payload) => async (dispatch) => {
  try {

    const FETCH_LOG_URL = `${localhost}/${qualifier}/kits/kitsStatus`;
    const url = `${FETCH_LOG_URL}`;
    let res;
    res = await axios.get(url);
    // res = {
    //   "data": {
    //     "1": "RECEIVED",
    //     "2": "PROVISIONED",
    //     "3": "SHIPPED",
    //     "4": "READY FOR PATIENT",
    //     "5": "WITH PATIENT",
    //     "6": "AWAITING RETURN",
    //     "7": "RETURNED",
    //     "8": "BROKEN",
    //     "9": "MISSING"
    //   }
    // }
    dispatch({
      type: STATUS_LIST,
      payload: res,
    });
    //return res;
  } catch (error) {
    handleError(error, dispatch);
  }
};
export const AddKit = (payload) => async (dispatch) => {
  try {
    const FETCH_LOG_URL = `${localhost}/${qualifier}/kits/createKits`;
    const url = `${FETCH_LOG_URL}`;
    const res = await axios.post(url, payload);
    
    dispatch({
      type: ADD_KIT,
      payload: res,
    });
  } catch (err) {
    //handleError(error, dispatch);
    console.log("err.response.data::::",err.response.data);
    if (err.response && err.response.data) {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    }
  }
};
export const FetchClinicList = (payload) => async (dispatch) => {
  try {

    const FETCH_LOG_URL = `${localhost}/${qualifier}/clinic`;
    const url = `${FETCH_LOG_URL}`;
    let res;
    res = await axios.get(url);
    // res = {
    //   "data": {
    //     "clinics": [
    //       {
    //         "id": "07567ab1-1fea-4f52-a9c4-84ecd022cb9e",
    //         "name": "Rogers Park Family Medicine",
    //         "clinicNumber": 1002,
    //         "locations": [
    //           {
    //             "id": "3b8370bc-67ff-423f-9d44-19070b42e710",
    //             "name": "Rogers Park",
    //             "locationNumber": 101,
    //             "timezone": "America/New_York"
    //           }
    //         ]
    //       },
    //       {
    //         "id": "92ee34e1-ccf1-4cc3-ab53-d813d2e84ddb",
    //         "name": "ACCESS Rogers Park Family Health Center",
    //         "clinicNumber": 1001,
    //         "locations": [
    //           {
    //             "id": "3b8370bc-67ff-423f-9d44-19070b42e710",
    //             "name": "Rogers Park",
    //             "locationNumber": 101,
    //             "timezone": "America/New_York"
    //           }
    //         ]
    //       },
    //       {
    //         "id": "a4f0ed61-63f2-439c-b1fd-66fbc504b3ef",
    //         "name": "Uptown Psych",
    //         "clinicNumber": 1003,
    //         "locations": [
    //           {
    //             "id": "73311bdb-febb-4ab9-a6eb-1ebd7a06e6dc",
    //             "name": "Uptown",
    //             "locationNumber": 103,
    //             "timezone": "America/Chicago"
    //           }
    //         ]
    //       },
    //       {
    //         "id": "de8e9d25-4ebd-4b47-8ac8-d88f4c969d90",
    //         "name": "TheraVista",
    //         "clinicNumber": 1004,
    //         "locations": [
    //           {
    //             "id": "2cdbcca2-e9cf-4561-b265-a780d33c5c33",
    //             "name": "TheraVista",
    //             "locationNumber": 104,
    //             "timezone": "America/Chicago"
    //           }
    //         ]
    //       }
    //     ]
    //   }
    // }
    dispatch({
      type: CLINIC_LIST,
      payload: res,
    });
    //return res;
  } catch (error) {
    handleError(error, dispatch);
  }
};

export const changeClinic = (payload) => async(dispatch) => {
  try{
    dispatch({
      type: CHANGE_CLINIC_VALUE,
      payload: payload
    })
  }catch(error){
    handleError(error, dispatch);
  }
}

export const fetchKitStatusReport = (loaderHide=false) => async (dispatch) => {
  try {
    const FETCH_LOG_URL = `${localhost}/${qualifier}/kits/statusReport`;
    const url = `${FETCH_LOG_URL}`;
    let res;
    res = await axios.get(url,{loaderHide:loaderHide});

    dispatch({
      type: KIT_STATUS_REPORT,
      payload: res,
    });
  } catch (error) {
    handleError(error, dispatch);
  }
};

export const searchKitData = async (searchStr) => {
  const url = `${localhost}/cap/kits/searchKits`; //?searchString=${searchStr}&onlyMe=${onlyMe}
  const parameters = { searchString: searchStr };
  let dataObject = {};
  dataObject = await axios.get(url, { params: parameters });
  let res = {};
  // dataObject = {
  //   data: {
  //     "98a2a842-9b3a-4f61-ac75-f3197f9cf9fc": "825-212122 (545121521212121)",
  //     "f3492a58-c551-4813-bf9e-5b218d6925d7": "778-787888 (565656554121212)",
  //     "fc7fc772-83e0-4339-91ee-b3ba796993cf": "232-323323 (322125555121212)"
  //   },
  // };
  res = Object.keys(dataObject.data).map((key) => {
    const obj = {
      id: key,
      title: dataObject.data[key],
    };
    return obj;
  });

  return res;
};

export const fetchKitListProvider =
  (page, perPage, sortColumn = "", sortOrder = "", kitId = "",loaderHide="false") =>
    async (dispatch) => {
      try {
        let FETCH_LOG_URL = `${localhost}/${qualifier}/kits/fetchKitsTableDataProvider?page=${page}&size=${perPage}&kitId=${kitId}`;
        if (sortColumn != '') {
          FETCH_LOG_URL = FETCH_LOG_URL + `&sort=${sortColumn},${sortOrder}`
        }
        const url = `${FETCH_LOG_URL}`;
        let res;
        res = await axios.get(url,{ loaderHide: loaderHide});
        // res = {
        //   "data": {
        //     "overviewKitsData": {
        //       "title": "vestibular",
        //       "subTitle": "",
        //       "columns": {
        //         "columns": [
        //           {
        //             "header": "Kit #",
        //             "accessor": "kitNumber",
        //             "toolText": "kit number"
        //           },
        //           {
        //             "header": "Clinic Name",
        //             "accessor": "clinicName",
        //             "toolText": "clinic name"
        //           },
        //           {
        //             "header": "Location",
        //             "accessor": "location",
        //             "toolText": "location name"
        //           },
        //           {
        //             "header": "IMEI #",
        //             "accessor": "imei",
        //             "toolText": "kit IMEI number"
        //           },
        //           {
        //             "header": "Status",
        //             "accessor": "status",
        //             "toolText": "kit status"
        //           }
        //         ],
        //         "header": "Kits Information"
        //       },
        //       "rowList": [
        //         {
        //           "id": "e0c00b2a-a3f6-4f03-aba0-bded8d0862f3",
        //           "kitNumber": "101-000004",
        //           "clinicName": "Rogers Park Family Medicine",
        //           "location": "Rogers Park",
        //           "imeiNumber": "123456789012344",
        //           "status": "SHIPPED",
        //           "patientName": null,
        //           "mrn": null
        //         },
        //         {
        //           "id": "2f2c60a8-b598-45e0-bced-9889342f3e20",
        //           "kitNumber": "101-000001",
        //           "clinicName": "-",
        //           "location": "-",
        //           "imeiNumber": "123456789012341",
        //           "status": "RECEIVED",
        //           "patientName": null,
        //           "mrn": null
        //         },
        //         {
        //           "id": "345f947f-cacf-4090-be1f-ebe117be9114",
        //           "kitNumber": "101-000002",
        //           "clinicName": "-",
        //           "location": "-",
        //           "imeiNumber": "123456789012342",
        //           "status": "RECEIVED",
        //           "patientName": null,
        //           "mrn": null
        //         },
        //         {
        //           "id": "aadd3ed9-f2e2-4f80-a280-b450fdfcdbde",
        //           "kitNumber": "123-456789",
        //           "clinicName": "-",
        //           "location": "-",
        //           "imeiNumber": "123456789012345",
        //           "status": "RECEIVED",
        //           "patientName": null,
        //           "mrn": null
        //         },
        //         {
        //           "id": "d7d26a50-7d7d-49f5-8868-1768d64589d6",
        //           "kitNumber": "101-000003",
        //           "clinicName": "-",
        //           "location": "-",
        //           "imeiNumber": "123456789012343",
        //           "status": "RECEIVED",
        //           "patientName": null,
        //           "mrn": null
        //         }
        //       ],
        //       "page": {
        //         "totalItems": 5,
        //         "totalPages": 1,
        //         "pageSize": 10,
        //         "currentPage": 1,
        //         "sort": "[status, desc]"
        //       }
        //     },
        //     "selectedUser": {
        //       "id": "a81c7015-ad6c-4262-bbc7-84185954cd2d",
        //       "username": "admin@devdigital.com",
        //       "fullName": "TheraVista, Admin",
        //       "password": "$2a$10$Sn56lsNyr0kasrh6C6l.y.tL5jVPdCpRvurJqGET0mC0AmCSckjxG",
        //       "confirmPassword": null,
        //       "lastName": "TheraVista",
        //       "firstName": "Admin",
        //       "age": 30,
        //       "height": 70.0,
        //       "weight": 0.0,
        //       "gender": "m",
        //       "ethnicity": null,
        //       "role": "TVH ADMIN",
        //       "selectedUser": "a81c7015-ad6c-4262-bbc7-84185954cd2d",
        //       "timezone": null,
        //       "longitude": 0.0,
        //       "latitude": 0.0,
        //       "initialStartTime": 0,
        //       "analyticsAlgorithm": "J48",
        //       "timeCreatedEndTime": 0,
        //       "cycle": -1,
        //       "topic": null,
        //       "create_At": "2023-05-05T06:13:31.000+0000",
        //       "update_At": "2023-05-09T07:39:09.789+0000",
        //       "status": null,
        //       "statusUpdate": 20230505061331,
        //       "email": "admin@devdigital.com",
        //       "pin": null,
        //       "kitNumber": null,
        //       "medicalRecordNumber": null,
        //       "phoneNumber": null,
        //       "passwordToken": null,
        //       "patientNumber": null,
        //       "clinicLocationMapping": null,
        //       "factorDisplayList": [],
        //       "ptName": null,
        //       "ptId": null,
        //       "active": true,
        //       "newPatient": false,
        //       "accountNonLocked": true,
        //       "authorities": null,
        //       "credentialsNonExpired": true,
        //       "accountNonExpired": true
        //     }
        //   }
        // };

        dispatch({
          type: FETCH_KIT,
          payload: res,
        });
      } catch (error) {
        handleError(error, dispatch);
      }
    };
export const fetchKitStatusReportProvider = (loaderHide=false) => async (dispatch) => {
  try {
    const FETCH_LOG_URL = `${localhost}/${qualifier}/kits/statusReportProvider`;
    const url = `${FETCH_LOG_URL}`;
    let res;
    res = await axios.get(url,{ loaderHide: loaderHide});

    dispatch({
      type: KIT_STATUS_REPORT,
      payload: res,
    });
  } catch (error) {
    handleError(error, dispatch);
  }
};
export const EditKitStatus = (payload) => async (dispatch) => {
  try {
    const FETCH_LOG_URL = `${localhost}/${qualifier}/kits/updateKitStatus`;
    const url = `${FETCH_LOG_URL}`;
    const res = await axios.put(url, payload);
    dispatch({
      type: EDIT_KIT,
      payload: res,
    });
  } catch (error) {
    handleError(error, dispatch);
  }
};