
import React, { Component } from "react";
import { connect } from "react-redux";


import { fetchContext, getImage } from "../../../actions";
class TermsCondition extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {

  }

  componentDidUpdate(prevProps) {
  }


  render() {

    const image = getImage("HeaderImage");
    return (
      <div className="">
        <div className="text-center">
          <figure className="brand-ui">
            <img src="../../images/Logo.png" alt="VestRx" style={{maxHeight:191}} />
          </figure>
          <div>End User License Agreement</div>
        </div>
        <div className="p-5">
          <p className="mb-0 font-bold">
            END USER AGREEMENT FOR THERAVISTA HEALTH
          </p>
          <p>
            This End User Agreement (the "Agreement") is a legal agreement between you ("you" or "Clinician") and TheraVista Health ("TheraVista Health," "we," "us," or "our") governing your use of the TheraVista Health clinician portal available at [URL][Note to client: Is there another name for the software?] (the "Portal") that has been made available to you.
          </p>
          <p className="font-bold">
            IMPORTANT NOTICE: PLEASE READ THIS AGREEMENT CAREFULLY BEFORE ACCESSING OR USING THE PORTAL. BY ACCESSING OR USING THE PORTAL, YOU ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTOOD, AND AGREE TO BE BOUND BY THIS AGREEMENT. IF YOU DO NOT AGREE TO THESE TERMS, YOU ARE NOT AUTHORIZED TO ACCESS OR USE THE PORTAL.
          </p>
          <p><span className="mb-0 font-bold">PORTAL LICENSE:</span> The Portal is licensed, not sold, to you. Subject to the terms of this Agreement, we grant you a limited, non-exclusive, and nontransferable license to use the Portal solely to assist you in the treatment of your physical therapy patients who are using the [APP NAME] (the “App”).</p>
          <p className="mb-0 font-bold">USE OF THE PORTAL</p>
          <p>The Portal is a web-based application that allows physical therapists and other clinicians to register, assign treatment programs, and monitor patient adherence and progress to their treatment programs. The Portal may also collect and use data for research purposes. With respect to your use of the Portal, you shall not copy the Portal or any of its features, create any derivative works or improvements, reverse engineer, decompile or attempt to gain access to the source code, use any manual process to copy any of the material on the Portal, or incorporate any Portal features on any other mobile application, website, or service. By using the Portal, you agree to comply with this Agreement and all applicable laws, rules, and regulations.</p>
          
          <p className="mb-0 font-bold">COMPLIANCE WITH HIPAA</p>
          <p>You will comply with the appropriate safeguards under the Health Insurance Portability and Accountability Act of 1996, as amended ("HIPAA") for Protected Health Information ("PHI") (as defined under the HIPAA Rules) that you may receive, create, maintain, use, or disclose in connection with your use of the Portal.</p>
          
          <p className="mb-0 font-bold">ACCOUNT REGISTRATION</p>
          <p>To access and use the Portal, you must register for an account with TheraVista Health. You must provide accurate and complete information when registering for an account. You are responsible for maintaining the confidentiality of your login credentials and for all activities that occur under your account. You are not permitted to provide any other person access to your login credentials, to your account, or to the Portal. You must immediately notify TheraVista Health of any unauthorized use of your account or any other breach of security.</p>
          
          <p><span className="mb-0 font-bold">OWNERSHIP:</span> You do not acquire any ownership interest in the Portal under this Agreement, or any other rights thereto other than to use the Portal in accordance with, and subject to all terms, conditions, and restrictions, under this Agreement. We reserve and shall retain our entire right, title, and interest in and to the Portal, including all copyrights, trademarks, and other intellectual property rights therein or relating thereto. Clinician shall safeguard the Portal from misuse or unauthorized access.  </p>

          <p><span className="mb-0 font-bold">COLLECTION AND USE OF YOUR INFORMATION:</span> You acknowledge that when you use the Portal, we may use automatic means (including, for example, cookies and web beacons) to collect information about your use of the Portal. You also may be required to provide certain information about yourself as a condition to using the Portal or certain of its features or functionality. All information we collect through or in connection with this Portal is subject to our [Privacy Policy][ Note to client: do you have a privacy policy?] [INSERT LINK TO PRIVACY POLICY]. By using and providing information to or through this Portal, you consent to all actions taken by us with respect to your information in compliance with the Privacy Policy. </p>
          
          <p className="mb-0 font-bold">USE OF DATA</p>
          <p>TheraVista Health may use de-identified data collected through the Portal for research purposes. You agree that TheraVista Health may use such data without restriction or obligation to you, provided that any research conducted adheres to ethical guidelines and patient consent requirements.</p>
          
          <p className="mb-0 font-bold">NO MEDICAL ADVICE OR CLINICAL DECISION SUPPORT</p>
          <p>The Portal is provided for informational purposes only and does not provide medical advice or clinical decision support information. You acknowledge that any reliance on the Portal is at your own risk.</p>
          
          <p><span className="mb-0 font-bold">TERM; TERMINATION:</span> The term of this Agreement commences when you agree to the terms of this Agreement and will continue in effect until terminated by you or us as set forth in this paragraph. You may terminate this Agreement by terminating your use of the Portal, and we may terminate this Agreement, including your use of the Portal, at any time without notice to you in our sole discretion. Termination of this Agreement will not limit any of the Company’s rights or remedies at law or in equity. </p>
          <p><span className="mb-0 font-bold">WARRANTY DISCLAIMER:</span> The Portal is provided to you "as is" and with all faults and defects without warranty of any kind. To the maximum extent permitted under applicable law, the Company, on its own behalf and on behalf of its affiliates and its respective licensors and service providers, expressly disclaims all warranties, whether express, implied, statutory, or otherwise, with respect to the Portal, including all implied warranties of merchantability, fitness for a particular purpose, title, and non-infringement, and warranties that may arise out of course of dealing, course of performance, usage, or trade practice. Without limitation to the foregoing, the Company provides no warranty or undertaking, and makes no representation of any kind that the Portal will meet your requirements, achieve any intended results, be compatible, or work with any other software, applications, systems, or services, operate without interruption, meet any performance or reliability standards, or be error-free, or that any errors or defects can or will be corrected. </p>
          
          <p className="mb-0 font-bold">LIMITATION OF LIABILITY</p>
          
          <p className="mb-0 font-bold">IN NO EVENT SHALL THERAVISTA HEALTH BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING WITHOUT LIMITATION DAMAGES FOR LOSS OF PROFITS, REVENUE, DATA, OR USE, INCURRED BY YOU OR ANY THIRD PARTY, WHETHER IN AN ACTION IN CONTRACT OR TORT, ARISING FROM YOUR ACCESS TO OR USE OF THE PORTAL.</p>
          
          <p><span className="mb-0 font-bold">GOVERNING LAW:</span> This Agreement shall be governed by and construed in accordance with the laws of the State of Delaware, without giving effect to any principles of conflicts of law. Any legal suit, action, or proceeding arising out of or related to this Agreement, the Portal, or the App shall be instituted exclusively in the federal courts of the United States or the courts of the State of Tennessee in each case located in Davidson County. You waive any and all objections to the exercise of jurisdiction over you by such courts and to venue in such courts.</p>
          
          <p><span className="mb-0 font-bold">SEVERABILITY:</span> If any provision of this Agreement is illegal or unenforceable under applicable law, the remainder of the provision will be amended to achieve as closely as possible the effect of the original term and all other provisions of this Agreement will continue in full force and effect. </p>
          
          <p><span className="mb-0 font-bold">ENTIRE AGREEMENT:</span> This Agreement and our Privacy Policy constitute the entire agreement between you and Company with respect to the Portal and supersede all prior or contemporaneous understandings and agreements, whether written or oral, with respect to the Portal.</p>
          
          <p><span className="mb-0 font-bold">WAIVER:</span> No failure to exercise, and no delay in exercising, on the part of either party, any right or any power hereunder shall operate as a waiver thereof, nor shall any single or partial exercise of any right or power hereunder preclude further exercise of that or any other right hereunder. </p>
          
          <p className="mb-0 font-bold">AGREEMENT TO TERMS</p>
          <p>By accessing or using the Portal, you agree to be bound by this Agreement. If you do not agree to the terms of this Agreement, you may not access or use the Portal. By checking the box below, you acknowledge that you have read, understood, and agree to be bound by this End User Agreement.</p>

        </div >
      </div>
    );
  }
}

function mapStateToProps(state) {

}

function mapDispatchToProps(dispatch) {

}
export default connect(mapStateToProps, mapDispatchToProps)(TermsCondition);
