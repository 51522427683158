import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import classnames from "classnames";
import * as routes from "../../../constants/routes";
import {
  validatePasswordTokenAction,
  setPasswordAction,
  matchPreviousPassword,
} from "../../../actions/forgotPasswordActions";
import { FiEye } from "react-icons/fi";
import { FiEyeOff } from "react-icons/fi";
import { FiXCircle } from "react-icons/fi";
import { datas } from '../../../securityUtils/data';
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";

const RestrictedWords = datas;
class SetResetPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      confirmPassword: "",
      confirmErrors: "",
      termsconditions: "no",
      errors: [],
      invalidToken: "",
      loading: false,
      mode: "set",
      token: "",
      showPassword: false,
      showConfirmPassword: false,
      successMessage: "",
    };
  }

  async componentDidMount() {
    let pathname = window.location.pathname;
    let mode = "set";
    if (pathname.includes("/setPassword/token/")) {
      mode = "set";
    } else if (pathname.includes("/resetPassword/token/")) {
      mode = "reset";
    }
    let token = this.props.match.params.token;
    this.setState({ mode: mode, token: token });
    await this.props.validatePasswordTokenAction(token);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.setPassword !== this.props.setPassword) {
      if (
        this.props.setPassword !== undefined &&
        this.props.setPassword.data !== undefined &&
        this.props.setPassword.data?.isPasswordSet === true
      ) {
        this.setState({
          successMessage: `Password saved successfully!`,
        });
        setTimeout(()=>{
          window.location.href = "/login";
        },500)
        
      }
    }
    let userEmail = "";
    if (
      this.props.validPassToken !== prevProps.validPassToken &&
      this.state.email == ""
    ) {
      if (
        this.props.validPassToken !== undefined &&
        this.props.validPassToken.data
      ) {
        userEmail = this.props.validPassToken.data.username;
        this.setState({ email: userEmail });
      } else {
        this.setState({ invalidToken: "Invalid password token" });
      }
    }
  }

  onChange = (e) => {
    if (e.target.name === 'termsconditions') {
      if (e.target.checked) {
        this.setState({ 'termsconditions': "yes" });
      } else {
        this.setState({ 'termsconditions': "no" });
      }
    } else {
      this.setState({
        [e.target.id]: e.target.value,
        errors: [],
        confirmErrors: "",
      });
    }
  };

  handleClickShowPassword = (val) => {
    this.setState({ showPassword: val });
  };

  handleClickShowConfirmPassword = (val) => {
    this.setState({ showConfirmPassword: val });
  };

  getSequentialNumberCount(s) {
    // Check for sequential numerical characters in ascending
    for (var i in s) {
      if (+s[+i + 1] == +s[i] + 1 && +s[+i + 2] == +s[i] + 2) {
        return false;
      }
    }
    // Check for sequential alphabetical characters
    for (var i in s) {
      if (
        String.fromCharCode(s.charCodeAt(i) + 1) == s[+i + 1] &&
        String.fromCharCode(s.charCodeAt(i) + 2) == s[+i + 2]
      ) {
        return false;
      }
    }
    return true;
  }

  duplicateCharCount(str) {
    let len = str.length;
    let count = 0;
    let res = str[0];
    for (let i = 0; i < len; i++) {
      let cur_count = 1;
      for (let j = i + 1; j < len; j++) {
        if (str[i] != str[j]) break;
        cur_count++;
      }
      if (cur_count > count) {
        count = cur_count;
        res = str[i];
      }
    }
    return count;
  }

  validation = async (e) => {
    let arrayOfErrors = [];

    // var validPassword =
    //   /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,64}$/;
    var atLeastOneNumerical = /\d/;
    var atLeastOneLowercase = /[a-z]/;
    var atLeastOneUppaercase = /[A-Z]/;

    //validate password length
    if (this.state.password.length < 8) {
      arrayOfErrors.push("Password must be a minimum of 8 characters");
    }
    //validate password length
    if (this.state.password.length > 64) {
      arrayOfErrors.push("Password must be a maximum of 64 characters");
    }
    //validate password atleast one numerical
    if (
      this.state.password !== "" &&
      !this.state.password.match(atLeastOneNumerical)
    ) {
      arrayOfErrors.push("At least one numerical required");
    }
    //validate password atleast one upper case
    if (
      this.state.password !== "" &&
      !this.state.password.match(atLeastOneUppaercase)
    ) {
      arrayOfErrors.push("At least one upper case alphabet required");
    }
    //validate password atleast one lower case
    if (
      this.state.password !== "" &&
      !this.state.password.match(atLeastOneLowercase)
    ) {
      arrayOfErrors.push("At least one lower case alphabet required");
    }
    //check the use of restricted words
    let usedRestrictedWord = false;
    RestrictedWords.forEach((item) => {
      if (this.state.password.toLowerCase().includes(item.toLowerCase())) {
        usedRestrictedWord = true;
      }
    });
    if (usedRestrictedWord) {
      arrayOfErrors.push(
        "Password must not be commonly used (e.g. p@ssword) or context specific (e.g. name of your site)"
      );
    }
    //check the use of sequential numbers and repetative characters
    let sequentialNumbersCount = await this.getSequentialNumberCount(
      this.state.password.toLowerCase()
    );
    let repetativeCharsCount = await this.duplicateCharCount(
      this.state.password.toLowerCase()
    );

    if (sequentialNumbersCount === false || repetativeCharsCount > 2) {
      arrayOfErrors.push(
        "Password must have non sequential or non repetitive characters (e.g. 12345 or aaaaa)"
      );
    }
    let payload = {
      passwordToken: this.state.token,
      username: this.state.email,
      password: this.state.password,
    };

    if (arrayOfErrors.length == 0) {
      let matchPassword = await this.props.matchPreviousPassword(payload);
      if (matchPassword && matchPassword === true) {
        arrayOfErrors.push(
          "Password must not be previously used."
        );
      }
    }
    if (arrayOfErrors.length > 0) {
      this.setState({ errors: arrayOfErrors, confirmPassword: "" });
      return false;
    } else {
      return true;
    }
  };

  onSubmit = async (e) => {
    let validPassword = await this.validation();
    if (validPassword == false) {
      return false;
    }

    //check if both password and confirm password have equal values
    if (this.state.password !== this.state.confirmPassword) {
      this.setState({
        confirmErrors: "Password and confirmation must match",
      });
      return false;
    }

    let payload = {
      passwordToken: this.state.token,
      username: this.state.email,
      password: this.state.password,
    };

    await this.props.setPasswordAction(payload);
  };

  render() {
    return (
      <div>
        <section className="user-section item-center">
          <div className="wave-canvas"></div>
          <div className="user-container">
            <figure className="brand-ui">
              <img src="../../images/Logo.png" alt="VestRx" style={{maxHeight:191}}/>
            </figure>
            <h2 className="pd-top-5 mb-5 pb-3">
              {this.state.mode == "set"
                ? "Create your password"
                : "Reset your password"}
            </h2>
            {this.state.invalidToken !== "" ? (
              <div className="error-msg-holder text-danger">
                {this.state.invalidToken}
              </div>
            ) : (
              ""
            )}
            {this.state.successMessage !== "" && (
              <div className="error-msg-holder text-success">
                {this.state.successMessage}
              </div>
            )}
            {/* <div>
              Enter your email address below and we'll send you a link to your
              email to reset your password.
            </div> */}
            {this.state.email !== "" && (
              <div className="form-card">
                <label for="exampleInputPassword1"><b>EMAIL</b></label>
                <input
                  type="text"
                  disabled
                  className={classnames("form-control font-18")}
                  value={this.state.email}
                />
              </div>
            )}

            <div className="form-card">
              <label for="exampleInputPassword1" className={classnames("", {
                "text-danger": this.state.errors.length,
              })}>
                <b>PASSWORD</b>{" "}
                {/* {this.state.showPassword ? (
                  <FiEye
                    className="ml-2"
                    onClick={() => this.handleClickShowPassword(false)}
                  />
                ) : (
                  <FiEyeOff
                    className="ml-2"
                    onClick={() => this.handleClickShowPassword(true)}
                  />
                )} */}
              </label>
              <input
                type={this.state.showPassword == true ? "text" : "password"}
                autoComplete="off"
                className={classnames("form-control font-18", {
                  "is-invalid": this.state.errors.length,
                })}
                placeholder="Enter your password"
                id="password"
                name="password"
                value={this.state.password}
                onChange={(e) => this.onChange(e)}
                onBlur={(e) => this.validation(e)}
              />
              {this.state.errors.length > 0 && (
                <div className="mt-1 text-danger p-2 rounded-14 passerrorbox">
                  {this.state.errors.map((item, index) => {
                    return (
                      <p key={index} className="p-0 m-0 error-msg-forg-pass d-flex">
                        <FiXCircle className="error-icon" /> {item}
                      </p>
                    );
                  })}
                </div>
              )}
              {this.state.errors.length == 0 && (
                <div className="font-figtree font-600 font-14 text-theme-gray-3">Password must be a minimum of 8 characters</div>
              )}
            </div>

            <div className="form-card">
              <label for="exampleInputPassword1" className={classnames("", {
                "text-danger": this.state.confirmErrors,
              })}>
                <b>CONFIRM PASSWORD</b>{" "}
                {/* {this.state.showConfirmPassword ? (
                  <FiEye
                    className="ml-2"
                    onClick={() => this.handleClickShowConfirmPassword(false)}
                  />
                ) : (
                  <FiEyeOff
                    className="ml-2"
                    onClick={() => this.handleClickShowConfirmPassword(true)}
                  />
                )} */}
              </label>
              <input
                type={
                  this.state.showConfirmPassword == true ? "text" : "password"
                }
                autoComplete="off"
                className={classnames("form-control font-18", {
                  "is-invalid": this.state.confirmErrors,
                })}
                placeholder="Re-enter your password"
                id="confirmPassword"
                name="confirmPassword"
                value={this.state.confirmPassword}
                onChange={(e) => this.onChange(e)}
              />
              {this.state.confirmErrors && (
                <div className="invalid-feedback">
                  {this.state.confirmErrors}
                </div>
              )}
            </div>
            {this.state.mode === "set" &&
              <div className="">
                
                <FormGroup className="d-flex flex-row align-items-center">
                  <FormControlLabel
                    control={
                      <Checkbox />
                    }
                    name="termsconditions"
                    label="I agree to TheraVista's"
                    className="check-frequency mb-0 mr-0"
                    onChange={(e) => this.onChange(e)}
                  />
                  <strong className="ml-2"><a className="text-dark text-decoration-underline font-16" target='_blank' href='/termscondition'>Terms & Conditions</a></strong>
                </FormGroup>
                <label for="exampleInputPassword1"></label>
              </div>
            }
            <button
              type="submit"
              disabled={
                this.state.password == "" ||
                  this.state.confirmPassword == "" ||
                  this.state.errors.length > 0 ||
                  (this.state.termsconditions == 'no' && this.state.mode === "set")
                  ? true
                  : false
              }
              className={`btn-all btn-color ${this.state.password == "" ||
                this.state.confirmPassword == "" ||
                this.state.errors.length > 0 ||
                (this.state.termsconditions == 'no' && this.state.mode === "set")
                ? ""
                : "active"
                }`}
              onClick={(e) => this.onSubmit(e)}
            >
              {this.state.mode == "set" ? "Create" : "Reset"}
            </button>

            <div
              onClick={() => this.props.history.push(routes.LOGIN)}
              className="text-primary-theme p-3 font-figtree font-400 font-15 pointer"
            >
              Back to login
            </div>
          </div>
        </section>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    setPassword: state.forgotPassword.setPassword,
    validPassToken: state.forgotPassword.validPassToken,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      validatePasswordTokenAction,
      setPasswordAction,
      matchPreviousPassword,
    },
    dispatch
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(SetResetPassword);
