/*************************************
 * author: katherine marschner
 * cfd copyright 2020
 *************************************/

import {
  ADD_PATIENT,
  EDIT_PATIENT,
} from "../actions/index";
const INITIAL_STATE = {
  //serchtxtpatient: [],
  addPatient: {},
  editPatient: {}
};
export default function appReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    //case FETCH_OVERVIEW_DATA:
    //  return action.payload.data.overviewUserData;
    case ADD_PATIENT:
      return {
        ...state,
        addPatient: action.payload.data
      };
    case EDIT_PATIENT:
      //return action.payload.data;
      return {
        ...state,
        editPatient: action.payload.data
      };
    default:
      return state;
  }
}
