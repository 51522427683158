import Box from "@mui/material/Box";
import classnames from "classnames";
import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { PatternFormat } from "react-number-format";
import {
  AddKit,
  EditKit,
  fetchKitList,
  fetchKitStatusReport,
  FetchKit,
  searchClinic,
  searchClinicLocations,
  FetchStatusList,
  FetchClinicList
} from "../../../actions/index";
import { alertService } from "./../../GlobalComponent/alertService";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

class AddEditUser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      kitID: "",
      clinicLocationMappingId: "",
      kitNumber: "",
      imeiNumber: "",
      clinicName: "",
      clinicId: "",
      clinicObj: null,
      locationId: "",
      locationObj: null,

      status: "1",
      ClinicHelperText: "",
      LocationHelperText: "",
      StatusHelperText: "",
      anchorEl: null,
      ClinicsList: [],
      Locations: [],
      statusList: [],
      clinicList: [],
      open: false,
      openAler: false,
      previousData: {},
    };
  }

  async componentDidMount() {

    this.setState({ mode: this.props.mode });
    setTimeout(async () => {
      this.storeKitValues();
      if (this.state.mode === "Edit") {
        await this.props.FetchStatusList('');
        await this.props.FetchClinicList('');
      }

    }, 200);

  }

  componentDidUpdate(prevProps) {
    if (this.props.statusList != prevProps.statusList) {
      this.setState({ statusList: this.props.statusList });

    }
    if (this.props.clinicList != prevProps.clinicList) {
      this.setState({ clinicList: this.props.clinicList });


    }

    if (
      this.props.EditElement !== null &&
      this.props.EditElement !== prevProps.EditElement &&
      this.state.mode === "Edit"
    ) {

      this.storeKitValues();
    }
    if (this.props.editKit !== prevProps.editKit) {
      alertService.success("Kit updated successfully!", { autoClose: true });
      this.setState({
        kitID: "",
        clinicName: "",
        clinicId: "",
        clinicObj: null,
        locationId: "",
        locationObj: null,
        kitNumber: "",
        status: "1",
        ClinicHelperText: "",
        LocationHelperText: "",
        StatusHelperText: "",
        anchorEl: null,
        searchString: null,
        ClinicsList: [],
        Locations: [],
      });
      this.props.handleClose();
      this.props.fetchKitList(
        this.props.page,
        this.props.perPage,
        this.props.sortColumn,
        this.props.sortOrder,
        this.props.kitId
      );
      this.props.fetchKitStatusReport();
    }
    if (this.props.addkit !== prevProps.addkit) {
      alertService.success("Kit added successfully!", { autoClose: true });
      this.setState({
        kitID: "",
        clinicName: "",
        clinicId: "",
        clinicObj: null,
        locationId: "",
        locationObj: null,
        kitNumber: "",
        status: "1",
        ClinicHelperText: "",
        LocationHelperText: "",
        StatusHelperText: "",
        anchorEl: null,
        searchString: null,
        ClinicsList: [],
        Locations: [],
      });
      this.props.handleClose();
      this.props.fetchKitList(
        this.props.page,
        this.props.perPage,
        this.props.sortColumn,
        this.props.sortOrder,
        this.props.kitId
      );
      this.props.fetchKitStatusReport();
    }

    if (this.props.errors && this.props.errors !== prevProps.errors) {
      alertService.error(this.props.errors.errorMessage);
      //this.setState({ AddPatientModal: true });
    }

  }

  async storeKitValues() {

    if (
      this.state.mode === "Edit" &&
      this.props.EditElement.id !== null &&
      this.props.EditElement.id !== undefined
    ) {
      let kitData = await this.props.FetchKit(this.props.EditElement.id);
      this.setState({ previousData: kitData });
      let EditElement = this.props.EditElement;

      if (kitData.clinic !== '' && kitData.clinic !== null) {
        this.setState({ clinicId: kitData.clinic.id });
      }
      if (kitData.location !== '' && kitData.location !== null) {
        this.setState({ locationId: kitData.location.id });
      }
      await this.setState({
        kitID: kitData.id,
        kitNumber: kitData.kitNumber,
        imeiNumber: kitData.imeiNumber,
        status: kitData.statusCode,
        //clinicName: kitData.clinicName,
        //locationID:EditElement.
      });
      setTimeout(() => {
        //console.log("this.state.clinicList::::", this.state.clinicList);
        let clinic = this.state.clinicList.filter(clinic => clinic.name.includes(EditElement.clinicName));
        if (Object.values(clinic).length > 0) {
          let locations = clinic[0].locations;
          this.setState({ Locations: locations });
        }
      }, 800)

    } else {
      this.setState({
        id: "",
        kitNumber: "",
        imeiNumber: "",
        clinicName: "",
        clinicName: "",
        clinicId: "",
        clinicObj: null,
        locationId: "",
        locationObj: null,
        status: "1",
        ClinicHelperText: "",
        LocationHelperText: "",
        StatusHelperText: "",
        anchorEl: null,
        searchString: null,
        ClinicsList: [],
        Locations: [],

      });
    }
  }
  onChangeValueHandler = (e) => {
    let value = e.target.value.trim();
    // if (e.target.id == "kitNumber" || e.target.id == "imeiNumber") {
    //   value = value.replace(/[^a-zA-Z0-9]/g, "");
    // }


    if (e.target.id == "clinicId") {
      if (value == '') {
        this.setState({ Locations: [] });
      } else {
        let clinic = this.state.clinicList.filter(clinic => clinic.id.includes(value));
        let locations = clinic[0].locations;
        this.setState({ Locations: locations });
      }
    }

    if (e.target.id == "locationId") {
      let obj = this.state.Locations.filter(
        (option) => option.id == e.target.value
      );
      this.setState({
        [e.target.id]: value.trim(),
        locationObj: obj[0],
        KitnumberHelperText: "",
        ImeiNumberHelperText: "",
        LocationHelperText: "",
        StatusHelperText: "",
        ClinicHelperText: "",
      });
    } else {
      this.setState({
        [e.target.id]: value.trim(),
        KitnumberHelperText: "",
        ImeiNumberHelperText: "",
        LocationHelperText: "",
        StatusHelperText: "",
        ClinicHelperText: "",
      });
    }
  };



  ValidateData = async (e) => {

    this.setState({
      KitnumberHelperText: "",
      ImeiNumberHelperText: "",
      ClinicHelperText: "",
      LocationHelperText: "",
      StatusHelperText: "",
    });


    let kitNumber = this.state.kitNumber;
    kitNumber = kitNumber.replace("_", "");

    let imeiNumber = this.state.imeiNumber;
    imeiNumber = imeiNumber.replace("_", "");

    if (parseInt(kitNumber.length) != 10) {
      this.setState({ KitnumberHelperText: "Invalid Kit number" });
      return false;
    }
    if (this.state.mode == 'Add' && parseInt(imeiNumber.length) != 15) {
      this.setState({ ImeiNumberHelperText: "Invalid IMEI number" });
      return false;
    }

    if (this.state.mode === "Edit" && this.state.clinicId == "") {
      this.setState({ ClinicHelperText: "Please select clinic name" });
      return false;
    }
    if (this.state.mode === "Edit" && this.state.locationID == "") {
      this.setState({ LocationHelperText: "Please select location" });
      return false;
    }

    if (this.state.mode === "Edit" && this.state.clinicId !== "" && this.state.locationId === "") {
      this.setState({ LocationHelperText: "Please select location." });
      return false;
    }
    if (this.state.mode === "Edit" && (this.state.status == "" || this.state.status == 5)) {
      this.setState({ StatusHelperText: "Please select status" });
      return false;
    }

    let payload = {
      kitNumber: this.state.kitNumber,
      imeiNumber: this.state.imeiNumber,
      status: this.state.status,
      clinicId: this.state.clinicId,
      locationId: this.state.locationId
    };
    // if (this.state.role === "PROVIDER") {
    //   payload.clinicLocationMapping = {
    //     clinic: this.state.clinicObj,
    //     location: this.state.locationObj,
    //   };
    // }

    if (this.state.mode === "Add") {
      await this.AddKitHandler(payload);
    } else {
      console.log("payload:::", payload);

      console.log("previousData:::", this.state.previousData);
      if ((this.state.previousData.statusCode == 6 || this.state.previousData.statusCode == 5) && (
        this.state.previousData.clinic.id != this.state.clinicId ||
        this.state.previousData.location.id != this.state.locationId)) {
        this.setState({ openAlert: true });
      }
      else if (this.state.previousData.patientName !== null && this.state.status != 6 && (this.state.previousData.statusCode != this.state.status ||
        this.state.previousData.clinic.id != this.state.clinicId ||
        this.state.previousData.location.id != this.state.locationId)) {
        this.setState({ open: true });
      }

      else {
        await this.EditKitHandler(payload);
      }

    }

  };

  AddKitHandler = async (payload) => {
    await this.props.AddKit(payload);
    //alertService.success("Kit added successfully!", { autoClose: true });
  };

  EditKitHandler = async (payload) => {
    payload.id = this.state.kitID;
    payload.clinic = {};
    payload.clinic.id = this.state.clinicId;
    payload.location = {};
    payload.location.id = this.state.locationId;
    await this.props.EditKit(payload);

  };

  async autoSearchHandler(e, v) {
    this.setState({ searchString: v });
    if (e !== null && e.type == "click") {
      let res = await this.props.searchClinic(v);
      let name = '';
      let Id = '';
      let clinicObj = '';
      let ClinicsList = [];
      if (res.data.clinics[0]) {
        name = res.data.clinics[0].name;
        Id = res.data.clinics[0].id;
        clinicObj = res.data.clinics[0];
        ClinicsList = res.data.clinics;
      }

      this.setState({
        clinicId: Id,
        clinicName: name,
        clinicObj: clinicObj,
        ClinicsList: ClinicsList,
      });
      this.searchClinicLocations(Id);
    } else {
      if (v.length > 2) {
        let res = await this.props.searchClinic(v);
        if (res.data.clinics.length) {
          this.setState({ ClinicsList: res.data.clinics });
        } else {
          this.setState({ ClinicsList: [] });
        }
      } else {
        this.setState({ ClinicsList: [] });
      }
    }
  }

  async searchClinicLocations(Id) {
    let res = await this.props.searchClinicLocations(Id);
    if (res.data.locations.length) {
      this.setState({
        Locations: res.data.locations,
        locationId: res.data.locations[0].id,
        locationObj: res.data.locations[0],
      });
    } else {
      this.setState({ Locations: [] });
    }
  }

  handleConfimPopupClose() {
    this.setState({ open: false });
  }
  handleAlertPopupClose() {
    this.storeKitValues();
    this.setState({ openAlert: false });
  }
  async handleConfimPopupConfirm() {
    this.setState({ open: false });
    let payload = {
      kitNumber: this.state.kitNumber,
      imeiNumber: this.state.imeiNumber,
      status: this.state.status,
      clinicId: this.state.clinicId,
      locationId: this.state.locationId
    };
    await this.EditKitHandler(payload);
  }

  render() {
    return (
      <Modal
        show={this.props.AddEditKitModal}
        onHide={this.props.handleClose}
        className="theme-modal"
      >
        <Modal.Body>
          <Box
            sx={style.modal}
            component="form"
            autoComplete="off"
            className="p-0 w-100 border-0 max-646">
            <div className="border-solid border-4 border-primary-theme rounded-30 position-relative modal-internal">
              <div className="position-absolute bg-white p-1 -top-23 left-40 text-primary-theme font-figtree font-700 font-24px">
                {this.state.mode === "Add" ? "Add Tablet" : "Edit Tablet"}
              </div>

              <div className="bg-white p-2 position-absolute -top-23 -right-20 rounded-20">
                <img
                  src={
                    this.state.mode === "Add"
                      ? "images/modal-close.svg"
                      : "../images/modal-close.svg"
                  }
                  alt="close"
                  className="pointer"
                  onClick={(e) => {
                    e.preventDefault();
                    this.props.handleClose();
                  }}
                />
              </div>

              <div className="form-card no-border">
                <label
                  for="exampleInputEmail1"
                  className="font-figtree font-700 font-15 mb-1"
                >
                  KIT#
                </label>

                <PatternFormat
                  customInput={TextField}
                  format="###-######"
                  mask="_"
                  className={classnames("form-control font-18 h-auto")}
                  placeholder="Enter Kit #"
                  name="kitNumber"
                  id="kitNumber"
                  value={this.state.kitNumber}
                  onChange={(e) => this.onChangeValueHandler(e)}
                />
                <div className={classnames("custom-error", {
                  "is-invalid": this.state.KitnumberHelperText,
                })}>
                  {this.state.KitnumberHelperText && (
                    <div className="invalid-feedback">
                      {this.state.KitnumberHelperText}
                    </div>
                  )}
                </div>
              </div>

              {this.state.mode === "Edit" &&
                <div className="mb-3 ">
                  <div className="select-custom-arrow">
                    <label
                      for="exampleInputEmail1"
                      className="font-figtree font-700 font-15 mb-1"
                    >
                      CLINIC NAME
                    </label>
                    <select
                      id="clinicId"
                      name="clinicId"
                      value={this.state.clinicId}
                      onChange={(e) => this.onChangeValueHandler(e)}

                      className={classnames("form-control font-18 rounded-14 border-dark border h-auto p-2 custom-error")}
                    >
                      <option value="">-</option>
                      {this.state.clinicList.map((item) => {
                        return <option value={item.id}>{item.name}</option>;
                      })}
                    </select>
                  </div>
                  <div className={classnames("custom-error", {
                    "is-invalid": this.state.ClinicHelperText,
                  })}>
                    {this.state.ClinicHelperText && (
                      <div className="invalid-feedback">
                        {this.state.ClinicHelperText}
                      </div>
                    )}
                  </div>
                </div>
              }
              {this.state.mode === "Edit" &&
                <div className="form-card no-border">
                  <div className="select-custom-arrow">
                    <label
                      for="exampleInputEmail1"
                      className="font-figtree font-700 font-15 mb-1"
                    >
                      LOCATION
                    </label>
                    <select
                      id="locationId"
                      name="locationId"
                      value={this.state.locationId}
                      onChange={(e) => this.onChangeValueHandler(e)}
                      className={classnames("form-control font-18 rounded-14 border-dark border h-auto p-2 custom-error")}
                    >
                      <option value="">-</option>
                      {this.state.Locations.map((item) => {
                        return <option value={item.id}>{item.name}</option>;
                      })}
                    </select>
                  </div>
                  <div className={classnames("custom-error", {
                    "is-invalid": this.state.LocationHelperText,
                  })}>
                    {this.state.LocationHelperText && (
                      <div className="invalid-feedback">
                        {this.state.LocationHelperText}
                      </div>
                    )}
                  </div>
                </div>
              }
              <div className="form-card no-border">
                <label
                  for="exampleInputEmail1"
                  className="font-figtree font-700 font-15 mb-1"
                >
                  IMEI #
                </label>

                <PatternFormat
                  customInput={TextField}
                  format="###############"
                  mask="_"
                  className={classnames("form-control font-18 h-auto", {
                    "is-invalid": this.state.PhoneHelperText,
                  })}
                  placeholder="Enter IMEI #"
                  name="imeiNumber"
                  id="imeiNumber"
                  value={this.state.imeiNumber}
                  onChange={(e) => this.onChangeValueHandler(e)}
                />
                <div className={classnames("custom-error", {
                  "is-invalid": this.state.ImeiNumberHelperText,
                })}>
                  {this.state.ImeiNumberHelperText && (
                    <div className="invalid-feedback">
                      {this.state.ImeiNumberHelperText}
                    </div>
                  )}
                </div>
              </div>

              {this.state.mode === "Edit" &&
                <div className="mb-3 ">
                  <div className="select-custom-arrow">
                    <label
                      className="font-figtree font-700 font-15 mb-1"
                      for="exampleInputEmail1"
                    >
                      STATUS
                    </label>
                    <select
                      id="status"
                      name="status"
                      value={this.state.status}
                      onChange={(e) => this.onChangeValueHandler(e)}

                      className={classnames(
                        "form-control font-18 rounded-14 border-dark border h-auto p-2"
                      )}
                    >
                      <option value="">-</option>
                      {Object.keys(this.state.statusList).length > 0 && Object.keys(this.state.statusList).map((i) => <option value={i}>{this.state.statusList[i]}</option>)}
                    </select>
                  </div>
                  <div className={classnames("custom-error", {
                    "is-invalid": this.state.StatusHelperText,
                  })}>
                    {this.state.StatusHelperText && (
                      <div className="invalid-feedback">
                        {this.state.StatusHelperText}
                      </div>
                    )}
                  </div>
                </div>
              }

              <div className="d-flex mt-5 pt-5">
                <button
                  className="ml-auto mr-2 bg-transparent border cursor-pointer rounded-20 font-figtree font-700 font-26 px-4 py-2 text-primary-theme"
                  variant="contained"
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({
                      kitID: "",
                      clinicName: "",
                      clinicId: "",
                      clinicObj: null,
                      locationId: "",
                      locationObj: null,
                      status: "1",
                      ClinicHelperText: "",
                      LocationHelperText: "",
                      anchorEl: null,
                      searchString: null,
                      ClinicsList: [],
                      Locations: [],
                    });
                    this.props.handleClose();
                  }}
                >
                  Cancel
                </button>
                <button
                  className="bg-primary-theme text-white pointer rounded-20 theme-btn border-0 font-figtree font-700 font-26 px-4 py-2"
                  disabled={
                    this.state.kitNumber == "" ||
                    this.state.imeiNumber == ""
                  }
                  variant="contained"
                  onClick={(e) => {
                    e.preventDefault();
                    this.ValidateData(e);
                  }}
                >
                  {this.state.mode === "Add" ? "Add" : "Save"}
                </button>
              </div>
            </div>
            <Dialog
              open={this.state.open}
              onClose={() => this.handleConfimPopupClose()}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                Confirmation
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  This will unassign the Kit from the patient. Are you sure?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => this.handleConfimPopupClose()}>Cancel</Button>
                <Button onClick={() => this.handleConfimPopupConfirm()} autoFocus>
                  Yes
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              open={this.state.openAlert}
              onClose={() => this.handleAlertPopupClose()}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                Alert
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  To change the clinic or location of the kit, please change the kit status from "AWAITING RETURN" or "WITH PATIENT"
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => this.handleAlertPopupClose()} autoFocus>Ok</Button>
              </DialogActions>
            </Dialog>
          </Box>
        </Modal.Body>
      </Modal>
    );
  }
}

const style = {
  modal: {
    p: 7,
  },
  inputFeild: {
    width: "100%",
    marginBottom: 2,
  },
};

function mapStateToProps(state) {
  return {
    provider: state.provider,
    currentUser: state.security.user,
    statusList: state.kit.statusList,
    clinicList: state.kit.clinicList,
    editKit: state.kit.editKit,
    addkit: state.kit.addkit,
    errors: state.errors,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchKitList,
      fetchKitStatusReport,
      searchClinic,
      searchClinicLocations,
      AddKit,
      EditKit,
      FetchKit,
      FetchStatusList,
      FetchClinicList
    },
    dispatch
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(AddEditUser);
