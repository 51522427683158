import axios from "axios";
import Images from "../images/index";
import moment from "moment";
import Progress from "react-progress-2";
import { alertService } from "../components/GlobalComponent/alertService";
//export const localhost = "https://vestibular-api.health-map.net:443";
//export const localhost = "https://vestibular-dev-api.health-map.net:443";
// export const localhost = "http://localhost:5000";
//export const localhost = "http://10.30.18.3:5000";
//export const localhost = "http://10.30.18.6:5001";
//export const localhost = "http://10.30.16.74:8443";
// export const localhost = "http://dev-ecs-elb-630169440.us-west-2.elb.amazonaws.com";
//export const localhost = "https://theravistaapi.devdigdev.com";
export const localhost = process.env.REACT_APP_APIURL;
//export const timeZone = "America/Chicago";
export const SPREADSHEET = "SPREADSHEET";
export const SEPARATEGRAPHS = "SEPARATEGRAPHS"; //
export const SCROLL = "scroll";
export const PAGE = "page";
export const SCREENWIDTHCHANGER = 650;
export const USER_FETCH_SUCCESS = "USER_FETCH_SUCCESS";
export const USER_UPDATE = "USER_UPDATE";
export const FACTOR_UPDATE = "FACTOR_UPDATE";
export const CHANGE_TIME = "CHANGE_TIME";
export const FETCH_TIME = "FETCH_TIME";
export const FETCH_TIME_CONTROL = "FETCH_TIME_CONTROL";
export const FETCH_TIMELIST = "FETCH_TIMELIST";
export const FETCH_TIMELIST_DUMMY = "FETCH_TIMELIST_DUMMY";
export const FETCH_TIMELIST_RESET = "FETCH_TIMELIST_RESET";
export const FETCH_ALL_DOCTORS = "FETCH_ALL_DOCTORS";
export const FETCH_INTERVAL_NUMBER = "FETCH_INTERVAL_NUMBER";
export const FETCH_ALL_FACTORS = "FETCH_ALL_FACTORS";
export const GOTO_TIME = "GOTO_TIME";
export const TOGGLE_MODAL = "TOGGLE_MODAL";
export const SELECT_DOCTOR = "SELECT_DOCTOR";
export const SELECT_FACTOR = "SELECT_FACTOR";
export const CONFIGURE_FACTOR = "CONFIGURE_FACTOR";
export const TIME_SELECTED = "TIME_SELECTED";
//export const FETCH_HEALTHTIMEMODEL = 'FETCH_HEALTHTIMEMODEL';
export const UPDATE_CONTEXT = "UPDATE_CONTEXT";
export const UPDATE_INTERVAL_INCREMENT_INTERNAL =
  "UPDATE_INTERVAL_INCREMENT_INTERNAL";
export const UPDATE_INTERVAL_INCREMENT = "UPDATE_INTERVAL_INCREMENT";
export const UPDATE_LIST_ITEM_AT_TIME = "UPDATE_LIST_ITEM_AT_TIME";
export const LOG_DATA_AT_TIME = "LOG_DATA_AT_TIME";
export const LOG_LIST_AT_TIME = "LOG_LIST_AT_TIME";
export const UPDATE_INTERVAL_NUMBER = "UPDATE_INTERVAL_NUMBER";
export const UPDATE_INTERVAL_NUMBER_INTERNAL =
  "UPDATE_INTERVAL_NUMBER_INTERNAL";
export const UPDATE_LOCATION = "UPDATE_LOCATION";
export const UPDATE_DIMENSIONS = "UPDATE_DIMENSIONS";
export const UPDATE_EXPLORE_VIEW_OPTION = "UPDATE_EXPLORE_VIEW_OPTION";
export const UPDATE_TIME_CONTROL = "UPDATE_TIME_CONTROL";
export const TOGGLE_LAYOUT = "TOGGLE_LAYOUT";
export const SELECT_USER = "SELECT_USER";
export const FETCH_ALL_USERS = "FETCH_ALL_USERS";
export const CREATE_DUMMY_DATA = "CREATE_DUMMY_DATA";
export const WEB = "WEB";
export const EXPLOREWEB = "EXPLOREWEB";
export const SCROLLWEB = "SCROLLWEB";
export const ALL_LISTS = "*WEB";
export const TEXT = "TEXT";
export const GET_ERRORS = "GET_ERRORS";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const FETCH_SERVICE_LIST = "FETCH_SERVICE_LIST";
export const MANAGE_USERS = "MANAGE_USERS";
//export const MANAGE_PERSCRIPTIONS = "MANAGE_USERS";
export const FETCH_LOG_DATA = "FETCH_LOG_DATA";
export const FETCH_MODEL_DATA = "FETCH_MODEL_DATA";
export const UPDATE_PRESCRIPTION = "UPDATE_PRESCRIPTION";
export const APPROVE_PRESCRIPTION = "APPROVE_PRESCRIPTION";
export const GET_PRESCRIPTIONS = "GET_PRESCRIPTIONS";
export const GET_PENDING_PRESCRIPTIONS = "GET_PENDING_PRESCRIPTIONS";
export const FETCH_USER_TABLE_DATA = "FETCH_USER_TABLE_DATA";
export const FETCH_OVERVIEW_DATA = "FETCH_OVERVIEW_DATA";
export const RESET_TIMEOUT_MODAL = "RESET_TIMEOUT_MODAL";
export const UPDATE_COMPLIANCE = "UPDATE_COMPLIANCE";
export const ADD_PATIENT = "ADD_PATIENT";
export const EDIT_PATIENT = "EDIT_PATIENT";
export const FETCH_ALL_PROVIDERS = "FETCH_ALL_PROVIDERS";
export const FETCH_SELECT_USER = "FETCH_SELECT_USER";
export const FETCH_USER_INFO = "FETCH_USER_INFO";
export const FETCH_PATIEN_PROGRAM_INFO = "FETCH_PATIEN_PROGRAM_INFO";
export const STORE_PATIENT_PROGRAM = "STORE_PATIENT_PROGRAM";
export const SET_COMPLIANCE_DATA = "SET_COMPLIANCE_DATA";
export const SET_DIZZYNESS_DATA = "SET_DIZZYNESS_DATA";
export const ADD_PATIEN_PROGRAM_INFO = "ADD_PATIEN_PROGRAM_INFO";
export const EDIT_PATIEN_PROGRAM_INFO = "EDIT_PATIEN_PROGRAM_INFO";
export const SHOW_NOTIFICATION = "SHOW_NOTIFICATION";
export const FETCH_NOTIFICATIONS = "FETCH_NOTIFICATIONS";
export const UNREAD_NOTIFICATION_COUNT = "UNREAD_NOTIFICATION_COUNT";
export const SHOW_NOTIFICATION_BLOCK = "SHOW_NOTIFICATION_BLOCK";
export const FETCH_NOTIFICATION_STATUS_BLOCK_DATA =
  "FETCH_NOTIFICATION_STATUS_BLOCK_DATA";
export const READ_NOTIFICATION = "READ_NOTIFICATION";
export const FETCH_AUDITLOG = "FETCH_AUDITLOG";
export const SET_EXPORT_DATE_RANGE = "SET_EXPORT_DATE_RANGE";
export const SET_EXPORT_DATA = "SET_EXPORT_DATA";

//let context = "THERAPLAY";
let context = "ASTHMA";
const qualifier = "cap";

export function getConfig() {
  const jwtToken = sessionStorage.getItem("jwtToken");
  const config = {
    headers: {
      Authorization: jwtToken,
    },
  };
  return config;
}

// Add a request interceptor
axios.interceptors.request.use(
  (config) => {
    //console.log("config::",config.loaderHide);
    //alert(sessionStorage.getItem("jwtToken"))
    const token = sessionStorage.getItem("jwtToken");
    if (token) {
      config.headers["Authorization"] = token;
    }
    if(!config.loaderHide || config.loaderHide=="false"){
      Progress.show();
    }
    // config.headers['Content-Type'] = 'application/json';
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
// Add a response interceptor
axios.interceptors.response.use(
  (response) => {
    Progress.hide();
    return response;
  },
  function (error) {
    //const originalRequest = error.config;
    Progress.hide();

    if (error.response && error.response.status === 401) {
      // console.log("response intercepter::::");
      sessionStorage.removeItem("jwtToken");
      //window.location.href = "/login";
      return Promise.reject(error);
    }

    if (error.response && error.response.status === 500) {
      //alert("something went wrong;");
      //alert("in error....");
      if (!error.response.data || !error.response.data.errorMessage) {
        alertService.error("Something went wrong. Please contact Administrator.");
      }
      return Promise.reject(error);
    }
    else if (error.response && error.response.status === 400) {
      //alert("something went wrong;");
      //alert("in error....");
      if (!error.response.data || !error.response.data.errorMessage) {
        alertService.error("Something went wrong. Please contact Administrator.");
      }
      return Promise.reject(error);
    }

    return Promise.reject(error);
  }
);

export function getAveragePrescriptionCompliance(datac, complianceList) {
  let overallCompliance = [];
  let complianceAve = 0;
  complianceList.map((ex) => {
    let exercise = ex.graphItem[0].accessor1;
    let header = ex.graphItem[0].header;
    let totalcompliance = datac.reduce(
      (accum, item) => accum + item[exercise],
      0
    );
    if (datac.length > 0) {
      complianceAve = totalcompliance / datac.length;
    } else {
      complianceAve = 0;
    }
    overallCompliance.push({ name: header, value: complianceAve });
  });
  return overallCompliance;
}

export function getAveragePrescriptionComplianceold(datac, complianceList) {
  let overallCompliance = [];
  let list = complianceList ? complianceList.columns : [];
  list.map((column) => {
    let exercise = column.accessor;
    let header = column.header;
    let totalcompliance = datac.reduce(
      (accum, item) => accum + item[exercise],
      0
    );
    let complianceAve = totalcompliance / datac.length;
    overallCompliance.push({ name: header, value: complianceAve });
  });
  return overallCompliance;
}

export function getOverallAverageCompliance(data) {
  if (data.length == 0) {
    return "gray";
  }
  let totalcompliance = data.reduce((accum, item) => accum + item["value"], 0);
  let compliance = totalcompliance / data.length;
  if (compliance > 65) {
    return "green";
  } else if (compliance > 50) {
    return "yellow";
  } else return "red";
}

export function handleError(error, dispatch) {
  if (error.response) {
    // Request made and server responded
    // console.log(error.response.data);
    // console.log(error.response.status);
    // console.log(error.response.headers);

    dispatch({
      type: GET_ERRORS,
      payload: error.response.data,
    });
  } else if (error) {
    // The request was made but no response was received
    console.log(error);
    console.log("Error", error.message);
  } else if (error.message) {
    // Something happened in setting up the request that triggered an Error
    console.log("Error", error.message);
  }
}

export function getDate(data, timeIncrementProp) {
  let stringTime = "not recognized time Increment in TimeLIneViews ";
  const timeIncrement = timeIncrementProp ? timeIncrementProp : "day";
  if (timeIncrement === "hour") {
    const adate = new Date(data.id.time);
    const sdate = moment(adate).format("hA");
    stringTime = `${sdate}`;
    stringTime = stringTime.slice(0, -1);
  } else if (timeIncrement === "day") {
    const adate = new Date(data.id.time);
    const sdate = moment(adate).format("D");
    stringTime = `${sdate}`;
  } else if (timeIncrement === "month") {
    const adate = new Date(data.id.time);
    const sdate = moment(adate).format("MMM");
    stringTime = `${sdate}`;
  }
  return stringTime;
}

export function updateIntervalNumber(interval) {
  return {
    type: UPDATE_INTERVAL_NUMBER_INTERNAL,
    payload: interval,
  };
}

export function updateDimensions(width, height) {
  const dimensions = { width, height };
  return {
    type: UPDATE_DIMENSIONS,
    payload: dimensions,
  };
}

export function getImage(context) {
  if (context === "Vestibular Rehabilitation") {
    return Images.Vestibular;
  } else if (context === "BACK PAIN") {
    return Images.Backpain;
  } else if (context === "HeaderImage") {
    return Images.HeaderImage;
  } else if (context === "ASTHMA ASSISTANT") {
    return Images.Asthma;
  } else if (context === "Compliance") {
    return Images.Compliance;
  } else if (context === "Overview") {
    return Images.Overview;
  } else if (context === "IRON POINT OF CARE") {
    return Images.IronMain;
  } else {
    return Images.AiImage;
  }
}

export function getBackGroundImage(title) {
  if (title === "graph") {
    return Images.BackgroundGraph;
  } else if (title === "landing") {
    return Images.Background;
  } else {
    return Images.Background;
  }
}

export function toggleModal(ms) {
  return {
    type: ms.type,
    payload: ms,
  };
}

export const fetchAllUsers = () => async (dispatch) => {
  try {
    const FETCH_ALL_USERS_URL = `${localhost}/cap/fetchUsers`;
    const url = `${FETCH_ALL_USERS_URL}?topic=${context}`;
    const res = await axios.get(url, getConfig);
    dispatch({
      type: FETCH_ALL_USERS,
      payload: res,
    });
  } catch (error) {
    handleError(error, dispatch);
  }
};

export const fetchAllDoctors = () => async (dispatch) => {
  try {
    const LIST_ALL_DOCTORS_URL = `${localhost}/cap/fetchAllDoctors`;
    const url = `${LIST_ALL_DOCTORS_URL}?topic=${context}`;
    const res = await axios.get(url, getConfig);
    dispatch({
      type: FETCH_ALL_DOCTORS,
      payload: res,
    });
  } catch (error) {
    handleError(error, dispatch);
  }
};

export const fetchAllFactors = (username) => async (dispatch) => {
  try {
    const LIST_ALL_FACTORS_URL = `${localhost}/cap/list-allfactors`;
    const url = `${LIST_ALL_FACTORS_URL}?topic=${context}&username=${username}`;
    const res = await axios.get(url, getConfig);
    dispatch({
      type: FETCH_ALL_FACTORS,
      payload: res,
    });
  } catch (error) {
    handleError(error, dispatch);
  }
};

export const createDummyData =
  (
    username,
    compliance,
    sessionsPerDay,
    setsPerSession,
    daysPerWeek,
    dataType
  ) =>
  async (dispatch) => {
    try {
      const DUMMY_DATA_URL = `${localhost}/cap/initDummyData`;
      const params1 = `?topic=${context}&username=${username}&compliance=${compliance}`;
      const params2 = `&sessionsPerDay=${sessionsPerDay}&setsPerSession=${setsPerSession}&daysPerWeek=${daysPerWeek}&dataType=${dataType}`;
      const url = `${DUMMY_DATA_URL}${params1}${params2}`;
      const res = await axios.get(url, getConfig);
      dispatch({
        type: FETCH_TIMELIST_DUMMY,
        payload: res,
      });
    } catch (error) {
      handleError(error, dispatch);
    }
  };

export const refreshCompliance = (username) => async (dispatch) => {
  try {
    const REFRESH_COMPLIANCE_URL = `${localhost}/cap/refreshCompliance`;
    const params1 = `?username=${username}`;
    const url = `${REFRESH_COMPLIANCE_URL}${params1}`;
    const res = await axios.get(url, getConfig);
    dispatch({
      type: UPDATE_COMPLIANCE,
      payload: res,
    });
  } catch (error) {
    handleError(error, dispatch);
  }
};

export const resetData = (username) => async (dispatch) => {
  try {
    const RESET_DATA_URL = `${localhost}/cap/resetData`;
    const params1 = `?topic=${context}&username=${username}&listType=${ALL_LISTS}`;
    const url = `${RESET_DATA_URL}${params1}`;
    const res = await axios.get(url, getConfig);
    dispatch({
      type: FETCH_TIMELIST_RESET,
      payload: res,
    });
  } catch (error) {
    handleError(error, dispatch);
  }
};

export const updateListItemAtTime =
  (name, time, value, type) => async (dispatch) => {
    try {
      const UPDATE_LIST_ITEM_AT_TIME_URL = `${localhost}/cap/updateListItemValueAtTime`;
      const params1 = `topic=${context}&name=${name}`;
      const params2 = `&time=${time}&value=${value}&type=${type}`;
      const params3 = `&listType=${ALL_LISTS}`;
      const url = `${UPDATE_LIST_ITEM_AT_TIME_URL}?${params1}${params2}${params3}`;
      const res = await axios.get(url, getConfig);
      dispatch({
        type: UPDATE_LIST_ITEM_AT_TIME,
        payload: res,
      });
    } catch (error) {
      handleError(error, dispatch);
    }
  };

export const updateListItemFromSynchAtTime =
  (name, time, type) => async (dispatch) => {
    try {
      const UPDATE_LIST_ITEM_VALUE_AT_TIME_URL = `${localhost}/cap/updateListItemFromSynchAtTime`;

      const params = `topic=${context}&name=${name}&type=${type}&time=${time}`;
      const params2 = `&listType=${ALL_LISTS}`;
      const url = `${UPDATE_LIST_ITEM_VALUE_AT_TIME_URL}?${params}${params2}`;
      const res = await axios.get(url, getConfig);
      dispatch({
        type: UPDATE_LIST_ITEM_AT_TIME,
        payload: res,
      });
    } catch (error) {
      handleError(error, dispatch);
    }
  };

// select user can be a loggedInUserId with many users or a updateIntervalIncrement
export const selectUser = (selectedUser) => async (dispatch) => {
  try {
    if (!selectedUser) {
      return null;
    }
    const FETCH_USER_URL = `${localhost}/cap/selectUser`;
    const params1 = `topic=${context}&selectedUserId=${selectedUser}`;
    const params2 = `&listType=${ALL_LISTS}`;
    const url = `${FETCH_USER_URL}?${params1}${params2}`;
    const res = await axios.get(url, getConfig);
    dispatch({
      type: SELECT_USER,
      payload: res,
    });
  } catch (error) {
    handleError(error, dispatch);
  }
};

/*******     specific time list   ******/

export const fetchTimeList = (listType) => async (dispatch) => {
  try {
    const TIMELIST_URL = `${localhost}/cap/list-healthInstances`;
    const url = `${TIMELIST_URL}?topic=${context}&listType=${listType}`;
    const res = await axios.get(url, getConfig);

    dispatch({
      type: FETCH_TIMELIST,
      payload: res,
    });
  } catch (error) {
    handleError(error, dispatch);
  }
};

export const fetchOverViewData = (listType) => async (dispatch) => {
  try {
    const OVERVIEW_DATA_URL = `${localhost}/cap/list-overviewHealthInstances`;
    const url = `${OVERVIEW_DATA_URL}?topic=${context}&listType=${listType}`;
    const res = await axios.get(url, getConfig);

    dispatch({
      type: FETCH_OVERVIEW_DATA,
      payload: res,
    });
  } catch (error) {
    handleError(error, dispatch);
  }
};

export const configureFactor =
  (factor, configured, username) => async (dispatch) => {
    try {
      const CONFIGURE_FACTOR_URL = `${localhost}/cap/updateFactorConfigured`;
      const params = `?topic=${context}&factor=${factor}&configured=${configured}&username=${username}&listType=${EXPLOREWEB}`;
      const url = `${CONFIGURE_FACTOR_URL}${params}`;
      const res = await axios.get(url, getConfig);
      dispatch({
        type: CONFIGURE_FACTOR,
        payload: res,
      });
    } catch (error) {
      handleError(error, dispatch);
    }
  };

export const selectFactor =
  (factor, selected, username) => async (dispatch) => {
    try {
      const SELECT_FACTOR_URL = `${localhost}/cap/updateFactorSelected`;
      const params = `?topic=${context}&factor=${factor}&selected=${selected}&username=${username}&listType=${EXPLOREWEB}`;
      const url = `${SELECT_FACTOR_URL}${params}`;
      const res = await axios.get(url, getConfig);
      dispatch({
        type: SELECT_FACTOR,
        payload: res,
      });
    } catch (error) {
      handleError(error, dispatch);
    }
  };

export const selectTime = (time, listType) => async (dispatch) => {
  try {
    const SELECT_TIME_SELECTED_URL = `${localhost}/cap/setTimeSelected`;
    const params2 = `topic=${context}&time=${time}&listType=${listType}`;
    const url = `${SELECT_TIME_SELECTED_URL}?${params2}`;

    const res = await axios.get(url, getConfig);

    dispatch({
      type: TIME_SELECTED,
      payload: res,
    });
  } catch (error) {
    handleError(error, dispatch);
  }
};
export const changeTime = (magnetude) => async (dispatch) => {
  try {
    const CHANGE_TIME_URL = `${localhost}/cap/changeEndTime`;
    const params1 = `topic=${context}&changeMagnetude=${magnetude}`;
    const params2 = `&listType=${EXPLOREWEB}`;
    const url = `${CHANGE_TIME_URL}?${params1}${params2}`;

    const res = await axios.get(url, getConfig);

    dispatch({
      type: CHANGE_TIME,
      payload: res,
    });
  } catch (error) {
    handleError(error, dispatch);
  }
};

export const gotoTime = (time, listType) => async (dispatch) => {
  try {
    const GOTO_TIME_URL = `${localhost}/cap/gotoTime`;
    const mag = `topic=${context}&time=${time}`;
    const params3 = `&listType=${listType}`;
    const url = `${GOTO_TIME_URL}?${mag}${params3}`;
    const res = await axios.get(url, getConfig);
    dispatch({
      type: GOTO_TIME,
      payload: res,
    });
  } catch (error) {
    handleError(error, dispatch);
  }
};
export const updateTimeControl =
  (animationType, incrementType, incrementNumber, displayType, listType) =>
  async (dispatch) => {
    try {
      const UPDATE_INTERVAL_INCREMENT_URL = `${localhost}/cap/updateTimeControl`;
      const params1 = `?topic=${context}&animationType=${animationType}`;
      const params2 = `&incrementType=${incrementType}&incrementNumber=${incrementNumber}`;
      const params3 = `&displayType=${displayType}&listType=${listType}`;
      const url = `${UPDATE_INTERVAL_INCREMENT_URL}${params1}${params2}${params3}`;
      //const request = axios.get(url, getConfig());
      const res = await axios.get(url, getConfig);
      dispatch({
        type: UPDATE_TIME_CONTROL,
        payload: res,
      });
    } catch (error) {
      handleError(error, dispatch);
    }
  };

export const fetchTimeControl = (listType) => async (dispatch) => {
  try {
    const FETCH_TIME_CONTROL_URL = `${localhost}/cap/fetchTimeControl`;
    const params1 = `?topic=${context}`;
    const params2 = `&listType=${listType}`;
    const url = `${FETCH_TIME_CONTROL_URL}${params1}${params2}`;
    const res = await axios.get(url, getConfig);
    dispatch({
      type: FETCH_TIME_CONTROL,
      payload: res,
    });
  } catch (error) {
    handleError(error, dispatch);
  }
};

export const fetchServiceList = (itemListType, name) => async (dispatch) => {
  try {
    const FETCH_SERVICE_LIST_URL = `${localhost}/cap/fetchServiceList`;
    const params = `topic=${context}&listType=${itemListType}&name=${name}`;
    const url = `${FETCH_SERVICE_LIST_URL}?${params}`;
    const res = await axios.get(url, getConfig);
    dispatch({
      type: FETCH_SERVICE_LIST,
      payload: res,
    });
  } catch (error) {
    handleError(error, dispatch);
  }
};

export const createManagedUser =
  (email, name, password, role) => async (dispatch) => {
    try {
      const res = await axios.get(
        `${localhost}/cap/createManagedUser?email=${email}&name=${name}&password=${password}&role=${role}&gender=none`
      );
      dispatch({
        type: MANAGE_USERS,
        payload: res,
      });
    } catch (error) {
      handleError(error, dispatch);
    }
  };

// select user can be a loggedInUserId with many users or a updateIntervalIncrement
export const deleteUser = (username) => async (dispatch) => {
  try {
    if (!username) {
      return null;
    }
    const DELETE_USER_URL = `${localhost}/cap/deleteManagedUser`;
    const params1 = `username=${username}`;
    const url = `${DELETE_USER_URL}?${params1}`;
    const res = await axios.get(url, getConfig);
    dispatch({
      type: MANAGE_USERS,
      payload: res,
    });
  } catch (error) {
    handleError(error, dispatch);
  }
};

export const fetchContext = () => async (dispatch) => {
  try {
    const FETCH_CONTEXT_URL = `${localhost}/cap/api/users/fetchContext`;
    const url = `${FETCH_CONTEXT_URL}`;
    const res = await axios.get(url, getConfig);
    dispatch({
      type: UPDATE_CONTEXT,
      payload: res,
    });
  } catch (error) {
    handleError(error, dispatch);
  }
};

export const logDataAtTime = (name, time, value, game) => async (dispatch) => {
  try {
    const LOG_DATA_URL = `${localhost}/cap/logDataAtTime`;
    const params1 = `time=${time}&name=${name}&value=${value}&game=${game}`;
    //const params1 = `time=${time}&name=${'name'}&value=${value}&game=${game}`;
    const url = `${LOG_DATA_URL}?${params1}`;
    const res = await axios.get(url, getConfig);
    dispatch({
      type: LOG_DATA_AT_TIME,
      payload: res,
    });
  } catch (error) {
    handleError(error, dispatch);
  }
};

// right now we are not starting the machine
// we should start machine and wait for artifacts
// we are currently sending dummy scan info which may change to the next step.
export const logListAtTime =
  (username, time, updateList) => async (dispatch) => {
    let transfer = {
      time: time,
      username: username,
      topic: "vestibular",
      items: updateList,
    };

    const LOG_DATA_URL = `${localhost}/cap/logListAtTime`;
    const configauth = await getConfig();
    const token = configauth.headers.Authorization;
    try {
      const resp = await axios({
        method: "post",
        url: LOG_DATA_URL,
        datatType: "json",
        contentType: "application/json",
        data: transfer,
        config: {
          headers: {
            Authorization: token,
          },
        },
      });
      dispatch({
        type: LOG_LIST_AT_TIME,
        payload: resp,
      });
    } catch (error) {
      handleError(error, dispatch);
    }
  };

//log and data tables below
export const fetchModelTableData = (name) => async (dispatch) => {
  try {
    const FETCH_MODEL_URL = `${localhost}/${qualifier}/fetchTableData?topic=models&subtopic=eyeInfo`;
    const url = `${FETCH_MODEL_URL}`;
    const res = await axios.get(url, getConfig);
    dispatch({
      type: FETCH_MODEL_DATA,
      payload: res,
    });
  } catch (error) {
    handleError(error, dispatch);
  }
};

export const fetchLogTableData = (information) => async (dispatch) => {
  try {
    const FETCH_LOG_URL = `${localhost}/${qualifier}/fetchLogTableDataForUser?topic=information&subtopic=log`;
    const url = `${FETCH_LOG_URL}`;
    const res = await axios.get(url, getConfig);
    dispatch({
      type: FETCH_LOG_DATA,
      payload: res,
    });
  } catch (error) {
    handleError(error, dispatch);
  }
};

export const updatePrescriptionsForm =
  (username, time, values) => async (dispatch) => {
    let transfer = {
      time: time,
      username: username,
      topic: "vestibular",
      items: values,
    };
    const UPDATE_PRESCRIPTIONS_URL = `${localhost}/cap/updatePrescriptions`;
    const configauth = await getConfig();
    const token = configauth.headers.Authorization;
    try {
      const resp = await axios({
        method: "post",
        url: UPDATE_PRESCRIPTIONS_URL,
        datatType: "json",
        contentType: "application/json",
        data: transfer,
        config: {
          headers: {
            Authorization: token,
          },
        },
      });
      dispatch({
        type: UPDATE_PRESCRIPTION,
        payload: resp,
      });
    } catch (error) {
      handleError(error, dispatch);
    }
  };

export const approvePrescriptionsForm =
  (username, time, values) => async (dispatch) => {
    let transfer = {
      time: time,
      username: username,
      topic: "vestibular",
      items: values,
    };
    const APPROVE_PRESCRIPTIONS_URL = `${localhost}/cap/approvePrescriptions`;
    const configauth = await getConfig();
    const token = configauth.headers.Authorization;
    try {
      const resp = await axios({
        method: "post",
        url: APPROVE_PRESCRIPTIONS_URL,
        datatType: "json",
        contentType: "application/json",
        data: transfer,
        config: {
          headers: {
            Authorization: token,
          },
        },
      });
      dispatch({
        type: APPROVE_PRESCRIPTION,
        payload: resp,
      });
    } catch (error) {
      handleError(error, dispatch);
    }
  };

// select user can be a loggedInUserId with many users or a updateIntervalIncrement
export const getPrescriptions = (selectedUsername) => async (dispatch) => {
  if (selectedUsername === "none") {
    alert("fetching prescriptons for none");
    return null;
  }

  try {
    const GET_PRESCRIPTIONS_URL = `${localhost}/cap/getPrescriptions`;
    const params1 = `username=${selectedUsername}`;
    const url = `${GET_PRESCRIPTIONS_URL}?${params1}`;
    const res = await axios.get(url, getConfig);
    dispatch({
      type: GET_PRESCRIPTIONS,
      payload: res,
    });
  } catch (error) {
    handleError(error, dispatch);
  }
};

// select user can be a loggedInUserId with many users or a updateIntervalIncrement
export const getPendingPrescriptions =
  (selectedUsername) => async (dispatch) => {
    try {
      // console.log("In getPendingPrescriptions");
      const GET_PENDING_PRESCRIPTIONS_URL = `${localhost}/cap/getPendingPrescriptions`;
      const params1 = `username=${selectedUsername}`;
      const url = `${GET_PENDING_PRESCRIPTIONS_URL}?${params1}`;
      const res = await axios.get(url, getConfig);
      // console.log(res);
      dispatch({
        type: GET_PENDING_PRESCRIPTIONS,
        payload: res,
      });
    } catch (error) {
      handleError(error, dispatch);
    }
  };
