import React, { Component } from 'react';
import moment from 'moment';

export class pdfPatientInfo extends Component {
  render() {
    return (
      <div className='d-flex align-items-start'>
        <div className='pt-3'>
          <p className="font-figtree font-16 font-600 mb-0">Patient Name: <span className="font-figtree font-16 font-400">{this.props.patientInfo?.firstName+' '+this.props.patientInfo.lastName}</span></p> 
          <p className="font-figtree font-16 font-600 mb-0">Patient MRN: <span className="font-figtree font-16 font-400">{this.props.patientInfo?.medicalRecordNumber}</span></p>
          <p className="font-figtree font-16 font-600 mb-0">Clinic Name: <span className="font-figtree font-16 font-400">{this.props.patientInfo?.clinicName} </span></p>
          <p className="font-figtree font-16 font-600 mb-0">Location Name: <span className="font-figtree font-16 font-400">{this.props.patientInfo?.locationName}</span></p>
          <p className="font-figtree font-16 font-600 mb-0">Provider Name: <span className="font-figtree font-16 font-400">{this.props.patientInfo?.ptName.split(',')[1]+' '+this.props.patientInfo?.ptName.split(',')[0]}</span></p>
          <p className="font-figtree font-16 font-600 mb-0">Report Date: <span className="font-figtree font-16 font-400">{moment(this.props.DateRange?.startDate).format('MM/DD/YYYY') + ' - ' + moment(this.props.DateRange?.endDate).format('MM/DD/YYYY')}</span></p>
        </div>
        <img src="../../images/Logo.png" alt="VestRx" className='pdfLogo ml-auto'  />
      </div>
    )
  }
}

export default pdfPatientInfo