import axios from "axios";

import { localhost, handleError, GET_ERRORS } from "../actions/index";
//Admin user
export const FETCH_PROVIDER = "FETCH_PROVIDER";
export const ADD_USER = "ADD_USER";
export const EDIT_USER = "EDIT_USER";

const qualifier = "cap";
export const fetchProviderList =
  (page, perPage, sortColumn = "", sortOrder = "", userId = "") =>
  async (dispatch) => {
    try {
      const FETCH_LOG_URL = `${localhost}/${qualifier}/viewUsers?page=${page}&size=${perPage}&sort=${sortColumn},${sortOrder}&userId=${userId}`;
      const url = `${FETCH_LOG_URL}`;
      let res;
      res = await axios.get(url);
      dispatch({
        type: FETCH_PROVIDER,
        payload: res.data,
      });
    } catch (error) {
      handleError(error, dispatch);
    }
  };
export const searchProviderData = async (searchStr, onlyMe = false) => {
  const url = `${localhost}/cap/searchUser`; //?searchString=${searchStr}&onlyMe=${onlyMe}
  const parameters = { searchString: searchStr, onlyMe: onlyMe };
  let dataObject = {};
  dataObject = await axios.get(url, { params: parameters });
  let res = {};
  // dataObject = {
  //   data: {
  //     "00f074ee-3a80-4e39-b91e-38671eae931f": "Detroja, Brijesh",
  //   },
  // };
  res = Object.keys(dataObject.data).map((key) => {
    const obj = {
      id: key,
      title: dataObject.data[key],
    };
    return obj;
  });

  return res;
};

export const searchClinic = (searchString) => async (dispatch) => {
  try {
    const FETCH_LOG_URL = `${localhost}/${qualifier}/searchClinic?searchString=${searchString}`;
    const url = `${FETCH_LOG_URL}`;
    const res = await axios.get(url);
    return res;
  } catch (error) {
    handleError(error, dispatch);
  }
};

export const searchClinicLocations = (searchString) => async (dispatch) => {
  try {
    const FETCH_LOG_URL = `${localhost}/${qualifier}/location?clinicId=${searchString}`;
    const url = `${FETCH_LOG_URL}`;
    const res = await axios.get(url);
    return res;
  } catch (error) {
    handleError(error, dispatch);
  }
};

export const AddUser = (payload) => async (dispatch) => {
  try {
    const FETCH_LOG_URL = `${localhost}/${qualifier}/api/users/register`;
    const url = `${FETCH_LOG_URL}`;
    const res = await axios.post(url, payload);
    console.log("add user res", res);
    dispatch({
      type: ADD_USER,
      payload: res,
    });
  } catch (error) {
    handleError(error, dispatch);
  }
};

export const FetchUser = (payload) => async (dispatch) => {
  try {
    const FETCH_LOG_URL = `${localhost}/${qualifier}/fetchUser?userId=${payload}`;
    const url = `${FETCH_LOG_URL}`;
    const res = await axios.get(url);
    return res;
  } catch (error) {
    handleError(error, dispatch);
  }
};

export const EditUser = (payload) => async (dispatch) => {
  try {
    const FETCH_LOG_URL = `${localhost}/${qualifier}/api/user`;
    const url = `${FETCH_LOG_URL}`;
    const res = await axios.put(url, payload);
    dispatch({
      type: EDIT_USER,
      payload: res,
    });
  } catch (err) {
    //handleError(err, dispatch);
    if (err.response && err.response.data) {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    }
  }
};
