import axios from "axios";
import moment from "moment";

import {
  localhost,
  SET_COMPLIANCE_DATA,
  SET_DIZZYNESS_DATA,
  SET_EXPORT_DATE_RANGE,
  SET_EXPORT_DATA
} from "../actions/index";
//import data from "./../components/mainTabs/patient/patientDetail/test";

//const qualifier = "cap";

export const fetchPatientResultData =
  (
    chartType = "both",
    topic = "ASTHMA",
    listType = "SCROLLWEB",
    patientId = "",
    startDate = "",
    endDate = ""
  ) =>
  async (dispatch) => {
    // console.log('PARAM===>',topic,listType)
    let url = `${localhost}/cap/list-complianceFactors`; //?topic=ASTHMA&listType=SCROLLWEB&patientId=ab627198-83ab-4a66-9455-c4a5575a01c2&startDate=2023-02-22&endDate=2023-02-28
    ///cap/list-complianceFactors?topic=ASTHMA&listType=SCROLLWEB&patientId=ab627198-83ab-4a66-9455-c4a5575a01c2&startDate=2023-02-22&endDate=2023-02-28
    let params = {
      topic: "ASTHMA",
      listType: listType,
      patientId: patientId,
      startDate: startDate,
      endDate: endDate,
    };
    let res = await axios.get(url, { params }); //
    //    let res = data;

    // console.log('API Patient Result Data ==>',res.data.compliamceFactors);
    let data = res.data.compliamceFactors;

    if (chartType == "both") {
      dispatch({
        type: SET_COMPLIANCE_DATA,
        payload: data,
      });
      dispatch({
        type: SET_DIZZYNESS_DATA,
        payload: data,
      });
    }
    if (chartType == "compliance") {
      //   console.log("Compliance Params ==> ", params);
      dispatch({
        type: SET_COMPLIANCE_DATA,
        payload: data,
      });
    }
    if (chartType == "dizzyness") {
      //   console.log("Dizzyness Params ==> ", params);
      dispatch({
        type: SET_DIZZYNESS_DATA,
        payload: data,
      });
    }
    return res;
  };

  export const fetchPatientLatestComplianceData =
  (
    chartType = "both",
    topic = "ASTHMA",
    listType = "SCROLLWEB",
    patientId = ""
  ) =>
  async (dispatch) => {
    // console.log('PARAM===>',topic,listType)
    let url = `${localhost}/cap/list-complianceFactors`; //?topic=ASTHMA&listType=SCROLLWEB&patientId=ab627198-83ab-4a66-9455-c4a5575a01c2&startDate=2023-02-22&endDate=2023-02-28
    ///cap/list-complianceFactors?topic=ASTHMA&listType=SCROLLWEB&patientId=ab627198-83ab-4a66-9455-c4a5575a01c2&startDate=2023-02-22&endDate=2023-02-28
    let params = {
      topic: "ASTHMA",
      listType: listType,
      patientId: patientId
    };

    params["startDate"] = moment(Date.now()).subtract(150, 'days').format('YYYY-MM-DD');
    params["endDate"] = moment(Date.now()).format('YYYY-MM-DD');

    let res = await axios.get(url, { params });

    // console.log('API Patient Result Data ==>',res.data.compliamceFactors);
    let data = res.data.compliamceFactors;

    const index = calcCompliance(data);
    data = data.slice(index - 6, index + 1)

    if (chartType == "both") {
      dispatch({
        type: SET_COMPLIANCE_DATA,
        payload: data,
      });
      dispatch({
        type: SET_DIZZYNESS_DATA,
        payload: data,
      });
    }
    if (chartType == "compliance") {
      //   console.log("Compliance Params ==> ", params);
      dispatch({
        type: SET_COMPLIANCE_DATA,
        payload: data,
      });
    }
    if (chartType == "dizzyness") {
      //   console.log("Dizzyness Params ==> ", params);
      dispatch({
        type: SET_DIZZYNESS_DATA,
        payload: data,
      });
    }
    return moment(data.at(-1).stringTime).year(moment(data.at(-1).id.time).get('year'));
  };

const calcCompliance = (complianceArray) => complianceArray.findLastIndex(x => x.compliance > 0)

export const setExportDateRange = (payload) => async(dispatch) => {
  dispatch({
    type:SET_EXPORT_DATE_RANGE,
    payload: payload
  })
}

export const getExportData = ( 
  chartType = "both",
  topic = "ASTHMA",
  listType = "SCROLLWEB",
  patientId = "",
  startDate = "",
  endDate = "") => async (dispatch)=> {
  //Get Data for export
  let url = `${localhost}/cap/list-complianceFactors`; //?topic=ASTHMA&listType=SCROLLWEB&patientId=ab627198-83ab-4a66-9455-c4a5575a01c2&startDate=2023-02-22&endDate=2023-02-28
  ///cap/list-complianceFactors?topic=ASTHMA&listType=SCROLLWEB&patientId=ab627198-83ab-4a66-9455-c4a5575a01c2&startDate=2023-02-22&endDate=2023-02-28
  let params = {
    topic: "ASTHMA",
    listType: listType,
    patientId: patientId,
    startDate: startDate,
    endDate: endDate,
  };

  let res = await axios.get(url, { params }); //
  let exportArrayOfChunks = await chunkArray(res.data.compliamceFactors,7)
  console.log('Compliance Factors Chunk====>',exportArrayOfChunks)
  dispatch({
    type:SET_EXPORT_DATA,
    payload: exportArrayOfChunks
  })
}

// export const clearExportData = () => (dispatch) => {
//   dispatch({
//     type:SET_EXPORT_DATA,
//     payload: []
//   })
// }

const chunkArray = (exportArray, chunk_size=7) => {
  var index = 0;
  var arrayLength = exportArray.length;
  var tempArray = [];
  
  for (index = 0; index < arrayLength; index += chunk_size) {
      let myChunk = exportArray.slice(index, index+chunk_size);
      if(myChunk.length < 7 ){
        let numberOfRecords = 7 - myChunk.length;
        let myNewChunk = adjustArrayRecords(numberOfRecords)
        myChunk = [...myChunk,...myNewChunk];
        // switch(myChunk.length){
        //   case 1 :
        //     // console.log('Record',numberOfRecords)
            
        //     // console.log('Adjusted Chunk Data==>',myNewChunk);
        //     break;
        //     case 2 :
        //     // console.log('Record',numberOfRecords)
        //     let myNewChunk = adjustArrayRecords(numberOfRecords)
        //     myChunk = [...myChunk,...myNewChunk];
        //     // console.log('Adjusted Chunk Data==>',myNewChunk);
        //     break;
        //     case 3 :
        //     // console.log('Record',numberOfRecords)
        //     let myNewChunk = adjustArrayRecords(numberOfRecords)
        //     myChunk = [...myChunk,...myNewChunk];
        //     // console.log('Adjusted Chunk Data==>',myNewChunk);
        //     break;
        //     case 4 :
        //     // console.log('Record',numberOfRecords)
        //     let myNewChunk = adjustArrayRecords(numberOfRecords)
        //     myChunk = [...myChunk,...myNewChunk];
        //     // console.log('Adjusted Chunk Data==>',myNewChunk);
        //     break;
        //     case 5 :
        //     // console.log('Record',numberOfRecords)
        //     let myNewChunk = adjustArrayRecords(numberOfRecords)
        //     myChunk = [...myChunk,...myNewChunk];
        //     // console.log('Adjusted Chunk Data==>',myNewChunk);
        //     break;
        //     case 6 :
        //     // console.log('Record',numberOfRecords)
        //     let myNewChunk = adjustArrayRecords(numberOfRecords)
        //     myChunk = [...myChunk,...myNewChunk];
        //     // console.log('Adjusted Chunk Data==>',myNewChunk);
        //     break;
          
        //   default:
        //     break;
        // }
      }
      // Do something if you want with the group
      tempArray.push(myChunk);
  }

  return tempArray;
}

const adjustArrayRecords = (numberOfRecords) => {
  let newchunk = [];
  let index=0;
  for(index=0;index<numberOfRecords;index++){
    newchunk.push(emptyComplianceRecord);
  };
  return newchunk;
}

const emptyComplianceRecord = {
  "id": {
      "time": 1692590400000,
      "user": "2c5cd5e3-5315-44f0-990b-883895e9933e"
  },
  "sessionId": 0,
  "timezone": "America/New_York",
  "timeInterval": 1,
  "abcScore": 0.0,
  "dhiScore": 0.0,
  "vvasScore": 0.0,
  "pitchScore": 0.0,
  "pitchReps": 0.0,
  "pitchFrequency": 0.0,
  "pitchMaxAngle": 0.0,
  "pitchMinAngle": 0.0,
  "pitchAverageRangeOfMotion": 0.0,
  "postPitchDizzinessScore": 0.0,
  "prePitchDizzinessScore": 0.0,
  "yawScore": 0.0,
  "yawRepetitions": 0.0,
  "yawFrequency": 0.0,
  "yawMaxAngle": 0.0,
  "yawMinAngle": 0.0,
  "yawAverageRangeOfMotion": 0.0,
  "postYawDizzinessScore": 0.0,
  "preYawDizzinessScore": 0.0,
  "weightShiftScore": 0.0,
  "weightShiftInstability": 0.0,
  "weightShiftExcursion": 0.0,
  "balanceScore": 0.0,
  "balanceInstability": 0.0,
  "twoTargetVorxScore": 0.0,
  "compliance_vorx1_pitch": 0.0,
  "compliance_vorx1_pitch_prescribed": 95.0,
  "range_vorx1_pitch_prescribed": 0.0,
  "speed_vorx1_pitch_prescribed": 0.0,
  "compliance_vorx1_yaw": 0.0,
  "compliance_vorx1_yaw_prescribed": 95.0,
  "range_vorx1_yaw_prescribed": 0.0,
  "speed_vorx1_yaw_prescribed": 0.0,
  "compliance_two_target_vor": 0.0,
  "compliance_two_target_vor_prescribed": 95.0,
  "gamescore_two_target_vor_prescribed": 100.0,
  "compliance_balance_weight_shift": 0.0,
  "compliance_balance_weight_shift_prescribed": 100.0,
  "gamescore_weight_shift_prescribed": 100.0,
  "compliance_balance_single_leg": 0.0,
  "compliance_balance_single_leg_prescribed": 100.0,
  "postural_stability_balance_single_leg_prescribed": 100.0,
  "balance_jerk_single_leg_prescribed": 100.0,
  "compliance_vvas": 0.0,
  "compliance_vvas_prescribed": 0.0,
  "compliancePrescribed": 95.0,
  "compliance": 0.0,
  "name": "",
  "setsInSession": 1,
  "stringTime": " ",
  "complianceMap": {},
  "configuredList": [],
  "interventionList": [],
  "overallCompliance": 0.0,
  "compliance_target_vor": 0.0
}