import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  SaveExerciseFrequency,
  stopPragram,
} from "../../../../actions/setPatientProgramActions";
import Progress from "./Progress";
import { updatePatientProgramFrequency } from "../../../../actions/patientProgramActions";

const DaysOfWeek = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

class ExerciseFrequency extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCancelProgramModal: false,
      Frequency: {
        Sunday: true,
        Monday: true,
        Tuesday: true,
        Wednesday: true,
        Thursday: true,
        Friday: true,
        Saturday: true,
      },
    };
  }

  componentDidMount() {
    if (this.props.patientProgram && this.props.patientProgram != "") {
      this.storeDefaultValue();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      (this.props.patientProgram != "" &&
        prevProps.patientProgram !== this.props.patientProgram) ||
      this.props.ProgramStatus !== prevProps.ProgramStatus ||
      this.props.EditProgramStatus !== prevProps.EditProgramStatus
    ) {
      this.storeDefaultValue();
    }
    if (this.props.ExerciseFrequency && prevProps.ExerciseFrequency != this.props.ExerciseFrequency) {
      this.setState({
        ...this.state,
        Frequency: { ...this.props.ExerciseFrequency },
      });
    }
  }

  storeDefaultValue = () => {
    let dataObj = {};
    DaysOfWeek.map((item) => {
      let filteredRecord = this.props.patientProgram?.scheduleList?.filter(
        (option) => option.name == item
      );
      dataObj[item] =
        filteredRecord !== undefined ? filteredRecord[0]?.value : false;
    });
    this.setState({
      ...this.state,
      Frequency: dataObj,
    });
  };

  OnSaveHandler = async () => {
    this.props.SaveExerciseFrequency(this.state.Frequency);
    this.props.handleCloseExerciseFrequencyModal();
    this.props.handleOpenFrequencyModal();
    if (this.props.ProgramStatus == false && this.props.ExerciseAdded == false) {
      await this.CreatePayloadForUpdate();
    }
  };

  CreatePayloadForUpdate = () => {

    let Exercises = this.props.Exercise;
    let ExerciseFrequency = this.state.Frequency;

    //let Frequency = this.props.FrequencyData;
    //let SetFrequency = this.props.SetFrequency.DataArray;
    let postPlayload = [];
    let obj = {};
    console.log("Exercises::::", Exercises);
    if (Exercises) {
      Object.keys(Exercises).map((key) => {
        let name = key.replace(/_/g, " ");
        obj = {
          name:name.replace("Balance Sitting Standing", "Balance Sitting/Standing"),
          value: Exercises[key],
          type: "Object",
        };
        postPlayload.push(obj);
      });
    }
    if (ExerciseFrequency) {
      Object.keys(ExerciseFrequency).map((key) => {
        obj = { name: key, value: ExerciseFrequency[key], type: "Object" };
        postPlayload.push(obj);
      });
    }

    console.log("postPlayload::::", postPlayload);
    this.props.updatePatientProgramFrequency(
      postPlayload,
      this.props.patientUsername
    );
  };

  handleOpenCancelModal = () => this.setState({ showCancelProgramModal: true });
  handleCloseCancelModal = () =>
    this.setState({ showCancelProgramModal: false });

  render() {
    return (
      <Modal
        show={this.props.showExerciseFrequencyeModal}
        onHide={this.props.handleCloseExerciseFrequencyModal}
        backdrop="static"
        className="theme-modal-1101 pt-5"
      >
        {this.props.patientInfo.newPatient === true &&
          <div className="p-1 absolute w-100 -top-23 bg-white rounded-20">
            <Progress value={33.32} />
          </div>
        }
        <Modal.Body>
          <Box
            sx={style.modal}
            component="form"
            autoComplete="off"
            className="p-3 rounded-20 border-0"
          >
            {(this.props.ProgramStatus == true ||
              this.props.ExerciseAdded == true) && (
                <div
                  className="text-primary-theme font-figtree font-700 font-18 pointer d-inline-flex align-items-center mb-3"
                  onClick={() => {
                    this.props.handleCloseExerciseFrequencyModal();
                    this.props.handleOpenExerciseModal();
                  }}
                >
                  <ArrowBackIosNewIcon fontSize="small" className="" />
                  Back
                </div>
              )}
            <h4 className="font-figtree font-500 font-38">Frequency</h4>
            <div className="mx-auto m-2 m-md-5 px-0 px-md-5">
              <p className="font-figtree font-600 font-18 text-theme-gray-dark">Select the weekly frequency of each exercise:</p>
              <FormGroup>
                <FormControlLabel
                // disabled={true}
                  control={
                    <Checkbox defaultChecked={this.state.Frequency.Sunday} />
                  }
                  label="Sunday"
                  value={this.state.Frequency.Sunday}
                  className="check-frequency mb-0 w-130"
                  onChange={(e) => {
                    this.setState({
                      ...this.state,
                      Frequency: {
                        ...this.state.Frequency,
                        Sunday: !this.state.Frequency.Sunday,
                      },
                    });
                  }}
                />
                <FormControlLabel
                // disabled={true}
                  control={
                    <Checkbox defaultChecked={this.state.Frequency.Monday} />
                  }
                  label="Monday"
                  value={this.state.Frequency.Monday}
                  className="check-frequency mb-0 w-130"
                  onChange={(e) => {
                    this.setState({
                      ...this.state,
                      Frequency: {
                        ...this.state.Frequency,
                        Monday: !this.state.Frequency.Monday,
                      },
                    });
                  }}
                />
                <FormControlLabel
                // disabled={true}
                  control={
                    <Checkbox defaultChecked={this.state.Frequency.Tuesday} />
                  }
                  label="Tuesday"
                  value={this.state.Frequency.Tuesday}
                  className="check-frequency mb-0 w-130"
                  onChange={(e) => {
                    this.setState({
                      ...this.state,
                      Frequency: {
                        ...this.state.Frequency,
                        Tuesday: !this.state.Frequency.Tuesday,
                      },
                    });
                  }}
                />
                <FormControlLabel
                // disabled={true}
                  control={
                    <Checkbox defaultChecked={this.state.Frequency.Wednesday} />
                  }
                  label="Wednesday"
                  value={this.state.Frequency.Wednesday}
                  className="check-frequency mb-0 w-130"
                  onChange={(e) => {
                    this.setState({
                      ...this.state,
                      Frequency: {
                        ...this.state.Frequency,
                        Wednesday: !this.state.Frequency.Wednesday,
                      },
                    });
                  }}
                />
                <FormControlLabel
                // disabled={true}
                  control={
                    <Checkbox defaultChecked={this.state.Frequency.Thursday} />
                  }
                  label="Thursday"
                  value={this.state.Frequency.Thursday}
                  className="check-frequency mb-0 w-130"
                  onChange={(e) => {
                    this.setState({
                      ...this.state,
                      Frequency: {
                        ...this.state.Frequency,
                        Thursday: !this.state.Frequency.Thursday,
                      },
                    });
                  }}
                />
                <FormControlLabel
                // disabled={true}
                  control={
                    <Checkbox defaultChecked={this.state.Frequency.Friday} />
                  }
                  label="Friday"
                  value={this.state.Frequency.Friday}
                  className="check-frequency mb-0 w-130"
                  onChange={(e) => {
                    this.setState({
                      ...this.state,
                      Frequency: {
                        ...this.state.Frequency,
                        Friday: !this.state.Frequency.Friday,
                      },
                    });
                  }}
                />
                <FormControlLabel
                // disabled={true}
                  control={
                    <Checkbox defaultChecked={this.state.Frequency.Saturday} />
                  }
                  label="Saturday"
                  value={this.state.Frequency.Saturday}
                  className="check-frequency mb-0 w-130"
                  onChange={(e) => {
                    this.setState({
                      ...this.state,
                      Frequency: {
                        ...this.state.Frequency,
                        Saturday: !this.state.Frequency.Saturday,
                      },
                    });
                  }}
                />
              </FormGroup>
            </div>
            <div className="d-flex mt-2 mt-md-5 pt-0 pt-md-5">
              <button
                className="ml-auto mr-2 btn border border-primary-theme cursor-pointer rounded-14 btn-lg"
                variant="contained"
                onClick={(e) => {
                  e.preventDefault();
                  this.props.handleOpenCancelModal();
                }}
              >
                Cancel
              </button>
              <button
                className="bg-primary-theme text-white pointer rounded-14 btn-lg theme-btn border-0 font-figtree font-700 font-18 border-0"
                variant="contained"
                disabled={
                  this.state.Frequency.Sunday == false &&
                  this.state.Frequency.Monday == false &&
                  this.state.Frequency.Tuesday == false &&
                  this.state.Frequency.Wednesday == false &&
                  this.state.Frequency.Thursday == false &&
                  this.state.Frequency.Friday == false &&
                  this.state.Frequency.Saturday == false
                }
                onClick={(e) => {
                  e.preventDefault();
                  this.OnSaveHandler();
                }}
              >
                Next
              </button>
            </div>
          </Box>
        </Modal.Body>
      </Modal>
    );
  }
}

const style = {
  modal: {
    p: 4,
  },
};

function mapStateToProps(state) {
  return {
    Exercise: state.patientProgram.Exercise,
    ExistingPatientProgram: state.patientProgram.patientProgram,
    ExerciseFrequency: state.patientProgram.ExerciseFrequency,
    ProgramStatus: state.patientProgram.startProgram,
    EditProgramStatus: state.patientProgram.startEditProgram,
    ExerciseAdded: state.patientProgram.ExerciseAdded,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      stopPragram,
      SaveExerciseFrequency,
      updatePatientProgramFrequency
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ExerciseFrequency);
