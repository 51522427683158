import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  fetchAllProviders,
  fetchSelectUser,
  fetchUserDetail,
  fetchUserTableData,
} from "../../../../actions/index";
import PatientAdd from "../patientTable/patientAdd";

class PatientInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      EditPatientModal: false,
      patientInfo: "",
    };
  }

  handleOpen = () => this.setState({ EditPatientModal: true });
  handleClose = () => {
    this.setState({ EditPatientModal: false });
    this.props.fetchUserinfo(this.props.patientId);
  };

  componentDidMount() {
    let patientId = this.props.patientId;
    this.state = {
      patientInfo: this.props.patientInfo,
    };
    
  }

  componentWillReceiveProps(props) {
    this.state = {
      patientInfo: props.patientInfo,
    };
    
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.patientInfo &&
      prevProps.patientInfo !== this.props.patientInfo
    ) {
      let patientInfo = {
        fullName: this.props.patientInfo.fullName,
        firstName: this.props.patientInfo.firstName,
        lastName: this.props.patientInfo.lastName,
        pin: this.props.patientInfo.pin,
        kitNumber: this.props.patientInfo.kitNumber,
        medicalRecordNumber: this.props.patientInfo.medicalRecordNumber,
        phoneNumber: this.props.patientInfo.phoneNumber,
        email: this.props.patientInfo.email,
        ptName: this.props.patientInfo.ptName,
        ptId: this.props.patientInfo.ptId,
        locationName: this.props.patientInfo.locationName,
      };
      this.setState({ patientInfo: patientInfo });
    }
   
  }

  render() {
    return (
      <>
        {this.props.currentUser.role!='TVH SUPER ADMIN' && <PatientAdd
          mode="Edit"
          PatientDetails={this.props.patientInfo}
          AddPatientModal={this.state.EditPatientModal}
          handleClose={this.handleClose}
          page={this.state.page}
          perPage={this.state.perPage}
          sortColumn={this.state.sortColumn}
          sortOrder={this.state.sortOrder}
        />}
        <Grid item xs={12} md={5}>
          <div className="patient-detail-holder cust-card h-100 pl-5 pr-4 py-4">
            {this.state.patientInfo && this.state.patientInfo != "" && (
              <div className="mt-3">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <div className="name-edit-btn d-flex justify-content-between mb-5">
                      <h3 className="font-figtree font-600 font-32 m-0">{this.state.patientInfo.fullName}</h3>
                      {this.props.currentUser.role!='TVH SUPER ADMIN' && <Button
                        variant="outlined"
                        className="border-btn py-2"
                        onClick={() => {
                          this.handleOpen();
                        }}
                      >
                        <img src="../images/edit.svg" alt="Teravista" className="mr-2" /> Edit
                      </Button>}
                    </div>
                  </Grid>
                </Grid>
                <Grid container spacing={2} className="font-figtree font-16">
                  <Grid item xs={6} className="border-right">
                    <div className="">
                      <div className="color-grey font-400">PIN</div>
                      <div className="font-600">{this.state.patientInfo.pin}</div>
                    </div>
                    <div className="mt-3">
                      <div className="color-grey font-400">Kit Number</div>
                      <div className="font-600">{this.state.patientInfo.kit && this.state.patientInfo.kit!==''?this.state.patientInfo.kit.kitNumber:'-'}</div>
                    </div>
                    <div className="mt-3">
                      <div className="color-grey font-400">Medical Record Number (MRN)</div>
                      <div className="font-600">{this.state.patientInfo.medicalRecordNumber}</div>
                    </div>
                    <div className="mt-4">
                      <div className="color-grey font-400">Last Activity</div>
                      <div className="font-600">{this.props.lastActivity ? this.props.lastActivity : "-"}</div>
                    </div>
                  </Grid>
                  <Grid item xs={6} className="pl-4">
                    <div className="">
                      <div className="color-grey font-400">Phone Number</div>
                      <div className="font-600">{this.state.patientInfo.phoneNumber}</div>
                    </div>
                    <div className="mt-3">
                      <div className="color-grey font-400">Location</div>
                      <div className="font-600">{this.state.patientInfo.locationName}</div>
                    </div>
                    {/* <div className="mt-3">
                      <div className="color-grey font-400">Email Address</div>
                      <div className="font-600">{this.state.patientInfo.email}</div>
                    </div> */}
                    <div className="mt-3">
                      <div className="color-grey font-400">Provider Name</div>
                      <div className="font-600">{this.state.patientInfo.ptName}</div>
                    </div>
                  </Grid>
                </Grid>
              </div>
            )}
            {(!this.state.patientInfo || this.state.patientInfo == "") && (
              <div className="font-600">No Record Found</div>
            )}
          </div>
        </Grid>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    patientInfo: state.security.user.role=='TVH SUPER ADMIN'?state.superAdminPatientData.superAdminPatients:state.userTableData,
    notifications : state.notifications,
    currentUser: state.security.user
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchUserTableData,
      fetchAllProviders,
      fetchSelectUser,
      fetchUserDetail,
    },
    dispatch
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(PatientInfo);
