import React, { useState, useEffect, useRef } from "react";
import { alpha, makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Box from "@material-ui/core/Box";
import Avatar from "@material-ui/core/Avatar";
import * as routes from "../../../constants/routes";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
import Badge from "@material-ui/core/Badge";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import SearchIcon from "@material-ui/icons/Search";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MailIcon from "@material-ui/icons/Mail";
import NotificationsIcon from "@material-ui/icons/Notifications";
import MoreIcon from "@material-ui/icons/MoreVert";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import HelpAppIcon from "@material-ui/icons/Help";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import { fetchContext, getImage } from "../../../actions";
import Button from "@material-ui/core/Button";
//import MenuItem from "@material-ui/core/MenuItem";
//import Menu from "@material-ui/core/Menu";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import jwt_decode from "jwt-decode";
import CircleNotificationsIcon from "@mui/icons-material/CircleNotifications";
import { showNotificationList, userLogout, awaituserLogout } from "../../../actions/index";
import setJWTToken from "../../../securityUtils/setJWTToken";
import Divider from "@mui/material/Divider";
import LogoutIcon from "@mui/icons-material/Logout";
import { useHistory } from "react-router-dom";
import classnames from "classnames";

const useStyles = makeStyles((theme) => ({
  toolbarMargin: {
    ...theme.mixins.toolbar,
    marginBottom: "3em",
    [theme.breakpoints.down("lg")]: {
      marginBottom: "1em",
    },
    [theme.breakpoints.down("md")]: {
      marginBottom: "1em",
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: "1.25em",
    },
  },
  grow: {
    flexGrow: 1,
  },
  logo: {
    height: "41px",
  },
  logoContainer: {
    padding: 0,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  tab: {
    textTransform: "none",
    color: "#00000",
    fontSize: "1.5rem",
    minWidth: 10,
    marginLeft: "25px",
  },

  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

export default function Header(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  //dispatch(fetchContext());

  const [anchorElUser, setAnchorElUser] = useState(null);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //setAuth(event.target.checked);
  };
  const ref = useRef(null);
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  //tabs
  const [value, setValue] = useState(0);

  // mobile sets not being used yet
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  //const [anchorEl, setAnchorEl] = React.useState(null);

  // Shw Notifications when clicked on the bell icon
  const ShowNotification = useSelector((state) => state.notifications.notifications);


  const handleOutsideClick = (event) => {
    //console.log("ref.current:::", event.target.parentElement);
    let parentDiv = findParentElement(event.target, 'div#notification_panel');
    if (ref.current && !ref.current.contains(event.target)) {
      //console.log("event.target.attributes.type:::", event.target.tagName)
      if (!event.target.attributes.type && !event.target.attributes.focusable && event.target.tagName != 'SELECT' && event.target.tagName != 'OPTION' && parentDiv != 'DIV' && event.target.tagName != 'BUTTON') {
        dispatch({
          type: "SHOW_NOTIFICATION",
          payload: false
        })
      }
    }
  }
  function findParentElement(el, selector) {
    var parent_container = el;

    if (parent_container !== document.body) {
      do {
        parent_container = parent_container.parentNode;
      }
      while (!parent_container.matches(selector) && parent_container !== document.body);
    }
    return parent_container.tagName;
  }
  useEffect(() => {
    document.addEventListener('click', handleOutsideClick, true);
    return () => {
      document.removeEventListener('click', handleOutsideClick, true);
    }
  }, [])

  const handleProfileMenuOpen = (event) => {
    //setAnchorEl(event.currentTarget);
    history.push("/help");
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = "primary-search-account-menu";

  useEffect(() => {

    if (window.location.pathname === "/patients" && value !== 0) {
      setValue(0);
    } else if (window.location.pathname === "/users" && value !== 2) {
      setValue(1);
    } else if (window.location.pathname === "/logs" && value !== 3) {
      setValue(2);
    } else if (window.location.pathname === "/kit" && value !== 1) {
      setValue(3);
    } else if (window.location.pathname === "/help" && value !== 1) {
      setValue(4);
    }
    
  }, [value]);

  //const [theView, setTheView] = useState("overview");

  const user = useSelector((state) => state.security.user);
  let userFirstName = user.fullName.split(', ');
  let AvatarName = userFirstName[1].slice(0, 1) + user.fullName.slice(0, 1);
  //user.role = 'admin';
  const unreadCount = useSelector((state) => state.notifications.unreadCount);
  //const user = jwt_decode(sessionStorage.getItem("jwtToken"))
  //const validToken = useSelector((state) => state.security.validToken);
  //const validLogin = validToken && user !== null;
  //const context = useSelector((state) => state.context);
  const image = getImage("HeaderImage");
  const mobileMenuId = "primary-search-account-menu-mobile";

  const logout = () => {

    //dispatch(logout());
    awaituserLogout();
    //localStorage.removeItem("jwtToken");
    //setJWTToken(false);
    //setValue(0);
    //window.location.href = "/";
  };

  const viewNotifications = () => {
    dispatch({
      type: "SHOW_NOTIFICATION",
      payload: !ShowNotification,
    });
  };
  const changeTab = (event, newValue) => {
    setValue(newValue);
  };
  const [mobileMenu, setmobileMenu] = useState(true);
  const toggleNavMenu = () => {
    setmobileMenu(!mobileMenu);
  }
  const activeToolbar = (
    <div className="custom-nav w-100">
      <nav className={"navbar" + (mobileMenu ? '' : ' hide')}>
        <Tabs
          className="nav-links"
          //onChange={handleChange}
          value={value}
          style={{ textTransform: "none" }}
          aria-label="simple tabs example"
          onChange={changeTab}
        >

          <Tab
            className={`nav-link-names ${user.role === "PROVIDER" ? "" : "d-none"}`}
            component={Link}
            label="Dashboard"
            to="/patients"
            style={{ textTransform: "none" }}
          />

          <Tab
            className={`nav-link-names ${user.role === "TVH ADMIN" ||  user.role === "TVH SUPER ADMIN"? "" : "d-none"}`}
            component={Link}
            style={{ textTransform: "none" }}
            label="Users"
            to="/users"
          />


          <Tab
            className={`nav-link-names ${user.role === "TVH ADMIN" || user.role === "TVH SUPER ADMIN" ? "" : "d-none"}`}
            component={Link}
            style={{ textTransform: "none" }}
            label="Audit logs"
            to="/logs"
          />
          <Tab
            className={"nav-link-names"}
            component={Link}
            style={{ textTransform: "none" }}
            label="Kits"
            to="/kits"
          />
          <Tab
            className={`nav-link-names ${user.role === "TVH SUPER ADMIN" ? "" : "d-none"}`}
            component={Link}
            style={{ textTransform: "none" }}
            label="Patients"
            to="/superadminpatients"
          />
        </Tabs>
      </nav>
      <div className="d-flex flex-row responsive-nav">
        <div className={classes.grow} />

        {/*<div className={classes.search}>
          <div className={classes.searchIcon}>
            <SearchIcon />
          </div>
          <InputBase
            placeholder="Search…"
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            inputProps={{ "aria-label": "search" }}
          />
          </div>*/}

        <div>
          {user.role === "PROVIDER" ? (
            <Tooltip title="Notification" aria-label="Notification">
              <IconButton aria-label="show 4 new mails" className="notification" color="inherit" ref={ref} onClick={() => { viewNotifications() }}>
                <Badge color="secondary" variant='dot' invisible={unreadCount === 0}>
                  {/* <MailIcon /> */}
                  <CircleNotificationsIcon />{/*onClick={() => { viewNotifications() }}*/}
                </Badge>
              </IconButton>
            </Tooltip>
          ) : null}
          {/*<IconButton aria-label="show 17 new notifications" color="inherit">
            <Badge badgeContent={17} color="secondary">
              <NotificationsIcon />
            </Badge>
        </IconButton>
          <Tooltip title="Account" aria-label="account">
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
          </Tooltip>*/}
          {user.role === "PROVIDER" ? (
            <Tooltip title="Help" aria-label="help">
              <IconButton
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
              >
                <HelpAppIcon />
              </IconButton>
            </Tooltip>
          ) : null}
          {/*<Tooltip title="Exit" aria-label="help">
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={logout}
              color="inherit"
            >
              <ExitToAppIcon />
            </IconButton>
        </Tooltip>*/}
        </div>
        {/* <div className={classes.sectionMobile}>
          <IconButton
            aria-label="show more"
            aria-controls={mobileMenuId}
            aria-haspopup="true"
            onClick={handleMobileMenuOpen}
            color="inherit"
          >
            <MoreIcon />
          </IconButton>
        </div> */}

        <div>
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="">
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenUserMenu}
                sx={{ p: 0 }}
                color="inherit"
                className="p-0 ml-3"
              >
                <AccountCircle className="accountcircle" />
              </IconButton>
            </Tooltip>
            <Menu
              id="menu-appbar"
              className="profile-menu"
              anchorEl={anchorElUser}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              keepMounted
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem>
                <Avatar className="dark-avatar">
                  {AvatarName ? AvatarName : ""}
                </Avatar>
                <div className="profile-user-name ml-2">
                  <h6>{user.fullName}</h6>
                  <h6>{user.firstName}</h6>
                  <p>{user.username}</p>
                </div>
              </MenuItem>
              <Divider />

              <MenuItem className="custom-padding py-2" onClick={logout}>
                <LogoutIcon className="mr-1" />
                Log Out
              </MenuItem>
            </Menu>
          </Box>
        </div>
        <IconButton className="more-menu" aria-label="show more" aria-haspopup="true" color="inherit" onClick={toggleNavMenu}><MoreIcon /></IconButton>
      </div>
    </div>
  );


  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {/* <MenuItem>
        <IconButton aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="secondary">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem> */}



      {user.role === "PROVIDER" ? (
        <MenuItem onClick={() => { viewNotifications() }}>
          <IconButton aria-label="show 11 new notifications" color="inherit"  >
            <Badge color="secondary" variant='dot' invisible={unreadCount === 0}>
              {/* <MailIcon /> */}
              <CircleNotificationsIcon />{/*onClick={() => { viewNotifications() }}*/}
            </Badge>
          </IconButton>
          <p>Notifications</p>
        </MenuItem>
      ) : null}
      {user.role === "PROVIDER" ? (
        <MenuItem onClick={handleProfileMenuOpen}>
          <IconButton aria-label="show 4 new mails" color="inherit"
            aria-controls={menuId}
            aria-haspopup="true"

          >
            <Badge variant='dot' color="secondary">
              <HelpAppIcon />
            </Badge>
          </IconButton>
          <p>Help</p>
        </MenuItem>
      ) : null}


      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  return (
    <React.Fragment>
      <AppBar position="fixed">
        <Toolbar>
          <Button
            component={Link}
            to="/patients"
            className={classes.logoContainer}
            onClick={() => setValue(0)}
            disableRipple
          >
            <img alt="company logo" src={image} className={classes.logo} />
            {/*<Typography variant='h5' align='left'  style={{width:500}}>{context}</Typography>*/}
          </Button>

          {activeToolbar}
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
    </React.Fragment>
  );
}
//<div className={classes.toolbarMargin} />