import React, { Component } from 'react';

// const Error404 = () => (
//   <div style={styles.Mainbody} >
//     {/**<LoginForm />**/}
//     <text> Error404 </text>
//   </div>
// );

class Error404 extends Component {
  render() {
    return (
      <div className='d-flex align-items-center justify-content-center h-100'>
        <img src="images/404-page.png" alt="404 page"/>
      </div>
    )
  }
}


export default Error404;

// const styles = {
//   Mainbody: {
//     height: 800,
//     width: '100%',
//     //overflow: 'auto',
//     backgroundColor: 'green',

//   },

// }
