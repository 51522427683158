import axios from "axios";
import {
  FETCH_ALL_PROVIDERS,
  ADD_PATIENT,
  EDIT_PATIENT,
  FETCH_SELECT_USER,
  FETCH_KIT
} from "../actions/index";
import {
  localhost,
  FETCH_USER_TABLE_DATA,
  handleError,
  FETCH_USER_INFO,
} from "../actions/index";
const qualifier = "cap";
export const fetchUserTableData =
  (
    information,
    page,
    perPage,
    sortColumn = "",
    sortOrder = "",
    onlyMe = "false",
    patientId = "",
    complianceFilter = "all",
    providersFilter = "all providers"
  ) =>
    async (dispatch) => {
      try {
        const FETCH_LOG_URL = `${localhost}/${qualifier}/fetchUserTableData?topic=information&subtopic=log&page=${page}&size=${perPage}&sort=${sortColumn},${sortOrder}&onlyMe=${onlyMe}&patientId=${patientId}&complianceFilter=${complianceFilter}&providersFilter=${providersFilter}`;
        const url = `${FETCH_LOG_URL}`;
        let res = await axios.get(url);

        dispatch({
          type: FETCH_USER_TABLE_DATA,
          payload: res,
        });
      } catch (error) {
        handleError(error, dispatch);
      }
    };
export const filterUserTableData =
  (
    information,
    page,
    perPage,
    sortColumn = "",
    sortOrder = "",
    onlyMe = "false",
    patientId = "",
    complianceFilter = "all",
    providersFilter = "all"
  ) =>
    async (dispatch) => {
      try {
        const FETCH_LOG_URL = `${localhost}/${qualifier}/filterUserTableData?topic=information&subtopic=log&page=${page}&size=${perPage}&sort=${sortColumn},${sortOrder}&onlyMe=${onlyMe}&patientId=${patientId}&compliance=${complianceFilter}&providers=${providersFilter}`;
        const url = `${FETCH_LOG_URL}`;
        let res = await axios.get(url);

        dispatch({
          type: FETCH_USER_TABLE_DATA,
          payload: res,
        });
      } catch (error) {
        handleError(error, dispatch);
      }
    };

export const addPatient = (payload) => async (dispatch) => {
  try {
    const FETCH_LOG_URL = `${localhost}/${qualifier}/createManagedUser?firstName=${payload.firstName}&lastName=${payload.lastName}&pin=${payload.pin}&kitId=${payload.kitId}&medicalRecordNumber=${payload.medicalRecordNumber}&phoneNumber=${payload.phoneNumber}&role=patient`;
    const url = `${FETCH_LOG_URL}`;
    const res = await axios.get(url);
    dispatch({
      type: ADD_PATIENT,
      payload: res,
    });
    // console.log("res:::::::",res);
    return res;
  } catch (error) {
    handleError(error, dispatch);
  }
};

export const editPatient = (payload) => async (dispatch) => {

  try {
    const FETCH_LOG_URL = `${localhost}/${qualifier}/updateManagedUser?email=${payload.email}&firstName=${payload.firstName}&lastName=${payload.lastName}&pin=${payload.pin}&kitId=${payload.kitId}&medicalRecordNumber=${payload.medicalRecordNumber}&phoneNumber=${payload.phoneNumber}&role=patient&doctorId=${payload.doctorId}`;
    const url = `${FETCH_LOG_URL}`;
    const res = await axios.get(url);
    dispatch({
      type: EDIT_PATIENT,
      payload: res,
    });
    return res;
  } catch (error) {
    handleError(error, dispatch);
  }
};

export const fetchAllProviders = () => async (dispatch) => {
  try {
    const FETCH_LOG_URL = `${localhost}/${qualifier}/fetchAllProviders`;
    const url = `${FETCH_LOG_URL}`;
    const res = await axios.get(url);

    dispatch({
      type: FETCH_ALL_PROVIDERS,
      payload: res,
    });
    return res;
  } catch (error) {
    handleError(error, dispatch);
  }
};

export const fetchSelectUser = (payload) => async (dispatch) => {
  try {
    const FETCH_USER_URL = `${localhost}/cap/selectUser?selectedUserId=${payload.email}&topic=${payload.topic}&listType=${payload.listType}`;
    const url = `${FETCH_USER_URL}`;
    const res = await axios.get(url);
    dispatch({
      type: FETCH_SELECT_USER,
      payload: res,
    });
    return res;
  } catch (error) {
    handleError(error, dispatch);
  }
};

export const searchPatientData = async (searchStr, onlyMe = false) => {
  const url = `${localhost}/cap/searchPatient`; //?searchString=${searchStr}&onlyMe=${onlyMe}
  const parameters = { searchString: searchStr, onlyMe: onlyMe };
  const dataObject = await axios.get(url, { params: parameters });
  // console.log('AXIOS Search Patient => ',dataObject.data);
  const res = Object.keys(dataObject.data).map((key) => {
    const obj = {
      id: key,
      title: dataObject.data[key],
    };
    return obj;
  });
  // console.log('Object Entries API Call => ',res)
  return res;
};
export const fetchUserDetail = (patientId) => async (dispatch) => {
  try {
    const FETCH_LOG_URL = `${localhost}/${qualifier}/fetchPatient?patientId=${patientId}`;
    const url = `${FETCH_LOG_URL}`;
    const res = await axios.get(url);
    //const res = {data:{"name":"viral"}}

    dispatch({
      type: FETCH_USER_INFO,
      payload: res,
    });
    return res;
  } catch (error) {
    handleError(error, dispatch);
  }
};

export const fetchKitsByLocation = () => async (dispatch) => {
  try {
    const FETCH_LOG_URL = `${localhost}/${qualifier}/kits/fetchKitsByProviderClinic`;
    const url = `${FETCH_LOG_URL}`;
    let res;
    res = await axios.get(url);
    // res = {
    //   "data": {
    //     "kits ": {
    //       "id": "345f947f-cacf-4090-be1f-ebe117be9114",
    //       "kitNumber": "101-000002",
    //     }
    //   }
    // };
    dispatch({
      type: FETCH_KIT,
      payload: res,
    });
    return res;
  } catch (error) {
    handleError(error, dispatch);
  }
};
