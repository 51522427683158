import { FETCH_AUDITLOG } from "../actions/index";

const INITIAL_STATE = {
  logList: [],
};
export default function logReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_AUDITLOG:
      return {
        ...state,
        logList: action.payload.overAuditData,
      };
    default:
      return state;
  }
}
