import React, { Component } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { connect } from "react-redux";
import { fetchKitListProvider, searchKitData,fetchKitStatusReportProvider } from "../../../actions/index";
import { bindActionCreators } from "redux";

export class AutoSearchBarKitsProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchList: [],
      page: 1,
      perPage: 10,
      totalItems: 10,
      sortColumn: "lastName",
      sortOrder: "desc",
      patientId: "",
      searchDataOptions: [],
      searchString: null,
      editProvider:[],
      addProvider:[],
      kitId:"",
    };
  }

  componentDidMount() {
   
  }

  componentDidUpdate(prevState, prevProps) {
    console.log("this.props.editProvider:::",this.props.editProvider)
    console.log("prevProps.editProvider:::",prevState.editProvider)
    if (this.props.editProvider && this.props.editProvider != prevState.editProvider) {
      this.setState({ searchString: '' });  
    }
    if (this.props.addProvider && this.props.addProvider != prevState.addProvider) {
      this.setState({ searchString: '' });  
    }
  }

  componentWillReceiveProps(props) {
    this.setState({
      sortColumn: props.sortColumn,
      sortOrder: props.sortOrder,
    });
  }

  async autoSearchHandler(e, v) {
    this.setState({ searchString: v });

    if (e !== null && (e.type == "click" || e.key == "Enter")) {
      const kit = this.state.searchList.filter((m) => {
        return m.title == v.trim();
      });
      let kitId = kit && kit.length > 0 ? kit[0].id : "";
      this.setState({kitId:kitId});
      this.props.fetchKitListProvider(
        this.state.page,
        this.state.perPage,
        this.state.sortColumn,
        this.state.sortOrder,
        kitId
      );
      this.props.setKitId(kitId);
      this.setState({ searchList: [] });
      return;
    }
    if (v.length > 2) {
      setTimeout(() => {
        searchKitData(this.state.searchString)
          .then((res) => {

            if (res.length == 0 && this.state.searchString != "") {
              this.props.fetchKitListProvider(
                this.state.page,
                this.state.perPage,
                this.state.sortColumn,
                this.state.sortOrder,
                "undefined"
              );
              return;
            }
            this.setState({ searchList: res });
          })
          .catch((error) => console.log(error));
      }, 200)

    } else {
      this.setState({ searchList: [] });
      if(v.length==0){
        this.props.fetchKitListProvider(
          this.state.page,
          this.state.perPage,
          this.state.sortColumn,
          this.state.sortOrder,
          ""
        );
      }
    }
  }
  async autoSearchChangeHandler(e) {
    if (e !== null && e.type == "change" && e.target.value == "") {
      this.props.fetchKitListProvider(
        this.state.page,
        this.state.perPage,
        this.state.sortColumn,
        this.state.sortOrder
      );
    }
  }

  render() {
    return (
      <div>
        <Stack spacing={2} sx={{ width: "100%", maxWidth: "550px" }}>
          <Autocomplete
            className="custm-autocomplete"
           
            id="free-solo-demo"
            freeSolo
            options={this.state.searchList.map((option) => option.title + ' ')}
            renderInput={(params) => (
              <TextField
                {...params}
                label=""
                placeholder="Search kit # or Patient Name"
                
              />
            )}

            onInputChange={(e, v) => this.autoSearchHandler(e, v)}
            onChange={(e, v) => this.autoSearchChangeHandler(e, v)}
          />
        </Stack>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    editProvider: state.provider.editProvider,
    addProvider: state.provider.addProvider
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchKitListProvider,
      fetchKitStatusReportProvider
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AutoSearchBarKitsProvider);
