import { SET_COMPLIANCE_DATA, SET_DIZZYNESS_DATA, SET_EXPORT_DATE_RANGE, SET_EXPORT_DATA } from './../actions/index';

const INITIAL_STATE = {
    compliance:[],
    dizzyness:[],
    exportData:[],
    exportDateRange:{}
}

const appReducer = (state=INITIAL_STATE,action) => {
    switch(action.type){
        case SET_COMPLIANCE_DATA:
            return{
                ...state,
                compliance:action.payload
            };
        case SET_DIZZYNESS_DATA:
            return{
                ...state,
                dizzyness:action.payload
            }
        case SET_EXPORT_DATE_RANGE:
            return {
                ...state,
                exportDateRange:action.payload
            }
        case SET_EXPORT_DATA:
            return {
                ...state,
                exportData:action.payload
            }
        default:{
            return state
        }
    }
}

export default appReducer;