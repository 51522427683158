import React, { Component } from 'react';
import { Bar } from 'react-chartjs-2';
import {Chart as ChartJS} from 'chart.js/auto';
import ChartDataLabels from "chartjs-plugin-datalabels";


export class BarChart extends Component {
    constructor(props){
        super(props);
        this.state={
            chartData:[]
        }
    }

    componentDidMount(){
      // ChartJS.register(ChartDataLabels);
        this.setState({chartData:this.props.data});
        // console.log('BAR CHART OPTIONS ===> ',this.props.options);
    }
  render() {
    return (
        <div>
          <div>{this.props.title}</div>
          <div>
            <Bar data={this.props.data} options={this.props.options} plugins={[ChartDataLabels]}></Bar>
          </div>
        </div>
    )
  }
}

export default BarChart;