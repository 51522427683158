import React, { Component } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { connect } from "react-redux";
import { fetchProviderList, searchProviderData } from "../../../actions/index";
import { bindActionCreators } from "redux";
const WAIT_INTERVAL = 500
let timerID
export class AutoSearchBarUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchList: [],
      page: 1,
      perPage: 10,
      totalItems: 10,
      sortColumn: "lastName",
      sortOrder: "desc",
      patientId: "",
      searchDataOptions: [],
      searchString: null,
      editProvider: [],
      addProvider: []
    };
  }

  componentDidMount() {

  }

  componentDidUpdate(prevState, prevProps) {
    if (this.props.editProvider && this.props.editProvider != prevState.editProvider) {
      this.setState({ searchString: '' });
    }
    if (this.props.addProvider && this.props.addProvider != prevState.addProvider) {
      this.setState({ searchString: '' });
    }
  }

  componentWillReceiveProps(props) {
    this.setState({
      sortColumn: props.sortColumn,
      sortOrder: props.sortOrder,
    });
  }

  async autoSearchHandler(e, v) {
    clearTimeout(timerID);
    this.setState({ searchString: v });

    if (e !== null && (e.type == "click" || e.key == "Enter")) {
      const user = this.state.searchList.filter((m) => {
        return m.title == v.trim();
      });

      const userId = user && user.length > 0 ? user[0].id : "";
      this.props.fetchProviderList(
        this.state.page,
        this.state.perPage,
        this.state.sortColumn,
        this.state.sortOrder,
        userId
      );
      this.setState({ searchList: [] });
      this.props.setUserId(userId);
      return;
    }
    if (v.length > 2) {
      timerID = setTimeout(() => {
        searchProviderData(this.state.searchString)
          .then((res) => {

            if (res.length == 0 && this.state.searchString != "") {
              this.setState({ searchList: [] });
              this.props.fetchProviderList(
                this.state.page,
                this.state.perPage,
                this.state.sortColumn,
                this.state.sortOrder,
                "undefined"
              );
              return;
            }

            this.setState({ searchList: res });
          })
          .catch((error) => console.log(error));
      }, WAIT_INTERVAL)

    } else {
      this.setState({ searchList: [] });
      if (v.length == 0) {
        this.props.fetchProviderList(
          this.state.page,
          this.state.perPage,
          this.state.sortColumn,
          this.state.sortOrder,
          ""
        );
      }
    }
  }
  async autoSearchChangeHandler(e) {
    if (e !== null && e.type == "change" && e.target.value == "") {
      this.props.fetchProviderList(
        this.state.page,
        this.state.perPage,
        this.state.sortColumn,
        this.state.sortOrder
      );
    }
  }

  render() {
    return (
      <div>
        <Stack spacing={2} sx={{ width: "100%", maxWidth: "550px" }}>
          <Autocomplete
            freeSolo
            id="free-solo-demo"
            value={this.state.searchString}
            className="custm-autocomplete"
            filterOptions={(x) => x}
            options={this.state.searchList.map((option) => option.title + ' ')}
            renderInput={(params) => (
              <TextField
                {...params}
                label=""
                placeholder="Search User"

              />
            )}

            onInputChange={(e, v) => this.autoSearchHandler(e, v)}
            onChange={(e, v) => this.autoSearchChangeHandler(e, v)}
          />
        </Stack>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    editProvider: state.provider.editProvider,
    addProvider: state.provider.addProvider
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchProviderList,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AutoSearchBarUsers);
