import React, { Component } from 'react';
import { addDays } from 'date-fns';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRange } from 'react-date-range';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { connect } from "react-redux";
// import { setExportDateRange } from "../../../actions/index";
import moment from 'moment';
import { bindActionCreators } from 'redux';


export class DateRangeSelector extends Component {
  constructor(props) {
    super(props);
    this.selectorRef = React.createRef(null);
    this.state = {
      showCalendar: false,
      selectionRange: {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection'
      },
      textFieldValues:{
        startDate: '',
        endDate: '',
        key: 'selection'
      }
    }
    this.handleSelect = this.handleSelect.bind(this)
  }


  componentDidMount(){
    document.addEventListener("mousedown", this.handleClickOutside);
  }


  componentDidUpdate(prevState, prevProps) {

  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = event => {
    if(this.selectorRef && !this.selectorRef.current.contains(event.target)){
      this.setState({showCalendar:false})
    }
  }

  handleSelect(ranges) {
    this.setState({ 
      selectionRange: ranges.selection,
      textFieldValues: ranges.selection 
    });
    this.props.setDateRange(ranges.selection);
  }
  toggleCalendar() {
    this.setState({
      showCalendar: !this.state.showCalendar
    })
  }
  render() {
    const {startDate,endDate} = this.state.textFieldValues;
    return (
      <Box component="form" sx={{ '& .MuiTextField-root': { m: 1, width: '25ch' } }} noValidate autoComplete="off" ref={this.selectorRef}>
        <div 
          className='d-flex justify-content-end align-items-center w-100'
        >
          <span>
            <TextField
              id="outlined-read-only-input"
              label="Start"
              className='d-inline-block rounded-30 text-field '
              // value={moment(this.state.selectionRange.startDate).format('MM/DD/YYYY')}
              value={startDate?moment(startDate).format('MM/DD/YYYY'):''}
              placeholder='MM/DD/YYYY'
              onClick={() => { this.toggleCalendar() }}
              InputProps={{
                readOnly: true,
              }}
            />
          </span>
          <span>
            <h3 className=''>-</h3>
          </span>
          <span>

            <TextField
              id="outlined-read-only-input"
              label="End"
              className='rounded-30 text-field'
              // value={moment(this.state.selectionRange.endDate).format('MM/DD/YYYY')}
              value={startDate?moment(endDate).format('MM/DD/YYYY'):''}
              placeholder='MM/DD/YYYY'
              onClick={() => { this.toggleCalendar() }}
              InputProps={{
                readOnly: true,
              }}
            />
          </span>

        </div>
        {this.state.showCalendar &&
          <DateRange
            ranges={[this.state.selectionRange]}
            onChange={this.handleSelect}
            months={2}
            direction='horizontal'
            maxDate={this.props.maxDate}
          />}
      </Box>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    exportDateRange: state.patientResultsCharts.exportDateRange,
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    // setExportDateRange
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(DateRangeSelector);