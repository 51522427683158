import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import _ from "lodash";
import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updatePatientProgramFrequency } from "../../../../actions/patientProgramActions";
import {
  SaveExercise,
  startPragram,
  stopPragram,
  SaveExerciseAdded,
} from "../../../../actions/setPatientProgramActions";
import Progress from "./Progress";

const ExercisesList = [
  "VORx1_Yaw",
  "VORx1_Pitch",
  "Balance_Weight_Shift",
  "Balance_Sitting_Standing",
];
export class Exercise extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCancelProgramModal: false,
      ExerciseChangesMade: false,
      ExerciseAdded: false,
      Exercise: {
        VORx1_Yaw: true,
        VORx1_Pitch: true,
        Balance_Weight_Shift: true,
        Balance_Sitting_Standing: true,
      },
      ExercisePreviousData: {
        VORx1_Yaw: true,
        VORx1_Pitch: true,
        Balance_Weight_Shift: true,
        Balance_Sitting_Standing: true,
      },
    };
  }

  componentDidMount() {
    if (this.props.patientProgram && this.props.patientProgram != "") {
      this.storeDefaultValue();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      (this.props.patientProgram != "" &&
        prevProps.patientProgram !== this.props.patientProgram) ||
      this.props.ProgramStatus !== prevProps.ProgramStatus ||
      this.props.EditProgramStatus !== prevProps.EditProgramStatus
    ) {
      console.log("in did update:::")
      this.storeDefaultValue();
    }
  }

  storeDefaultValue = () => {
    let dataObj = {};
    ExercisesList.map((item) => {
      let filteredRecord = this.props.patientProgram?.prescriptionList?.filter(
        option => {
          if(option.id.prescriptionId.replace("/", " ") == item.replace(/_/g, " ")){
            return option;
          }
        }
      );
      dataObj[item] =
        filteredRecord !== undefined ? filteredRecord[0]?.selected : false;
    });
    this.setState({
      ...this.state,
      Exercise: { ...dataObj },
      ExercisePreviousData: dataObj,
      ExerciseChangesMade: false,
      ExerciseAdded: false,
    });
  };

  OnSaveHandler = async () => {
    if (this.props.ProgramStatus == true) {
      this.setState({ ExercisePreviousData: { ...this.state.Exercise } });
      this.props.SaveExercise(this.state.Exercise);
      this.props.handleCloseExerciseModal();
      this.props.handleOpenExerciseFrequencyModal();
    } else {
      let result = await this.validateChanges(); //checks if changes are actually made

      if (result.changesDone && result.ExerciseAdded) {
        this.props.SaveExercise(this.state.Exercise);
        this.props.SaveExerciseAdded();
        //this.setState({ ExercisePreviousData: { ...this.state.Exercise } });
        this.props.handleCloseExerciseModal();
        this.props.handleOpenExerciseFrequencyModal();
      } else if (result.changesDone) {
        this.props.SaveExercise(this.state.Exercise);
        //this.setState({ ExercisePreviousData: { ...this.state.Exercise } });
        let Exercises = this.state.Exercise;
        let postPlayload = [];
        let obj = {};
        Object.keys(Exercises).map((key) => {
          let name = key.replace(/_/g, " "),
          obj = {
            name: name.replace("Balance Sitting Standing", "Balance Sitting/Standing"),
            value: Exercises[key],
            type: "Object",
          };
          postPlayload.push(obj);
        });
        this.props.updatePatientProgramFrequency(
          postPlayload,
          this.props.patientUsername
        );
        this.props.handleCloseExerciseModal();
      } else {
        this.props.handleCloseExerciseModal();
      }
    }
  };

  validateChanges = () => {

    let changesDone = false;
    let ExerciseAdded = false;
    if (_.isEqual(this.state.ExercisePreviousData, this.state.Exercise)) {
      changesDone = false;
    } else {
      changesDone = true;
      ExercisesList.map((item) => {
        if (
          this.state.ExercisePreviousData[item] === false &&
          this.state.Exercise[item] == true
        ) {
          ExerciseAdded = true;
          return;
        }
      });
    }
    this.setState({
      ...this.state,
      ExerciseAdded: ExerciseAdded,
      ExerciseChangesMade: changesDone,
    });
    let result = {
      changesDone: changesDone,
      ExerciseAdded: ExerciseAdded,
    };
    return result;
  };

  handleOpenCancelModal = () => this.setState({ showCancelProgramModal: true });
  handleCloseCancelModal = () =>
    this.setState({ showCancelProgramModal: false });

  render() {
    return (
      <>
        <Modal
          show={this.props.showExerciseModal}
          onHide={this.props.handleCloseExerciseModal}
          backdrop="static"
          className="theme-modal-1101 pt-5"
        >
          {this.props.patientInfo.newPatient === true &&
            <div className="p-1 absolute w-100 -top-23 bg-white rounded-20">
              {/* Progress calculation : 100 percent/6 pages = 16.66 per page progress */}
              <Progress value={16.66} />
            </div>
          }
          <Modal.Body>
            <Box
              sx={style.modal}
              component="form"
              autoComplete="off"
              className="p-3 rounded-20 border-0"
            >
              <div className="d-flex">
                <h4 className="font-figtree font-500 font-38">Exercise</h4>
                {this.props.ProgramStatus == false &&
                  this.state.ExerciseChangesMade == false ? (
                  <div className="d-flex align-items-center justify-content-end mb-3 ml-auto">
                    <div className="text-primary-theme font-figtree font-700 font-18 pointer d-inline-flex align-items-center">
                      <CloseIcon
                        fontSize="small"
                        onClick={(e) => {
                          e.preventDefault();
                          this.setState({
                            ...this.state,
                            ExerciseChangesMade: false,
                            ExerciseAdded: false,
                            Exercise: this.state.ExercisePreviousData,
                          });
                          this.props.handleCloseExerciseModal();
                        }}
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="max-width-300 d-flex flex-column row-gap-15 mx-auto my-2 my-md-5">
                <p className="font-figtree font-600 font-18 text-theme-gray mb-2">Select/Deselect Exercises below:</p>
                <Button
                  name="VORx1_Yaw"
                  id="VORx1_Yaw"
                  variant={
                    this.state.Exercise.VORx1_Yaw
                      ? "contained"
                      : "outlined selected-exercise"
                  }
                  startIcon={
                    this.state.Exercise.VORx1_Yaw && <CheckCircleOutlineIcon />
                  }
                  className="justify-content-start bg-theme-secondary-blue rounded-22 px-3 py-2 font-figtree font-600 font-16 border-transparent focus-none"
                  onClick={async (e) => {
                    await this.setState({
                      ...this.state,
                      Exercise: {
                        ...this.state.Exercise,
                        VORx1_Yaw: !this.state.Exercise.VORx1_Yaw,
                      },
                    });
                    if (this.props.EditProgramStatus == true) {
                      this.validateChanges();
                    }
                  }}
                >
                  VORx1 Yaw
                </Button>
                <Button
                  name="VORx1_Pitch"
                  id="VORx1_Pitch"
                  variant={
                    this.state.Exercise.VORx1_Pitch
                      ? "contained"
                      : "outlined selected-exercise"
                  }
                  startIcon={
                    this.state.Exercise.VORx1_Pitch && (
                      <CheckCircleOutlineIcon />
                    )
                  }
                  className="justify-content-start bg-theme-secondary-blue rounded-22 px-3 py-2 font-figtree font-600 font-16 border-transparent focus-none"
                  onClick={async (e) => {
                    await this.setState({
                      ...this.state,
                      Exercise: {
                        ...this.state.Exercise,
                        VORx1_Pitch: !this.state.Exercise.VORx1_Pitch,
                      },
                    });
                    if (this.props.EditProgramStatus == true) {
                      this.validateChanges();
                    }
                  }}
                >
                  VORx1 Pitch
                </Button>
                <Button
                  name="Balance_Weight_Shift"
                  id="Balance_Weight_Shift"
                  variant={
                    this.state.Exercise.Balance_Weight_Shift
                      ? "contained"
                      : "outlined selected-exercise"
                  }
                  startIcon={
                    this.state.Exercise.Balance_Weight_Shift && (
                      <CheckCircleOutlineIcon />
                    )
                  }
                  className="justify-content-start bg-theme-secondary-blue rounded-22 px-3 py-2 font-figtree font-600 font-16 border-transparent focus-none"
                  onClick={async (e) => {
                    await this.setState({
                      ...this.state,
                      Exercise: {
                        ...this.state.Exercise,
                        Balance_Weight_Shift:
                          !this.state.Exercise.Balance_Weight_Shift,
                      },
                    });
                    if (this.props.EditProgramStatus == true) {
                      this.validateChanges();
                    }
                  }}
                >
                  Balance weight shift
                </Button>
                <Button
                  name="Balance_Sitting_Standing"
                  id="Balance_Sitting_Standing"
                  variant={
                    this.state.Exercise.Balance_Sitting_Standing
                      ? "contained"
                      : "outlined selected-exercise"
                  }
                  startIcon={
                    this.state.Exercise.Balance_Sitting_Standing && (
                      <CheckCircleOutlineIcon />
                    )
                  }
                  className="justify-content-start bg-theme-secondary-blue rounded-22 px-3 py-2 font-figtree font-600 font-16 border-transparent focus-none"
                  onClick={async (e) => {
                    await this.setState({
                      ...this.state,
                      Exercise: {
                        ...this.state.Exercise,
                        Balance_Sitting_Standing:
                          !this.state.Exercise.Balance_Sitting_Standing,
                      },
                    });
                    if (this.props.EditProgramStatus == true) {
                      this.validateChanges();
                    }
                  }}
                >
                  Balance Sitting/Standing
                </Button>
              </div>

              {(this.props.ProgramStatus == false &&
                this.state.ExerciseChangesMade == true) &&
                <>
                  <div className="d-flex mt-2 mt-md-5 pt-0 pt-md-5">
                    <button
                      className="ml-auto mr-2 btn-theme-white cursor-pointer rounded-14 btn-lg text-theme-gray-2 font-figtree font-700 font-18"
                      variant="contained"
                      onClick={async (e) => {
                        e.preventDefault();
                        this.props.handleOpenCancelModal();
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      className="bg-primary-theme text-white pointer rounded-14 btn-lg theme-btn border-0 font-figtree font-700 font-18 border-0"
                      variant="contained"
                      disabled={
                        this.state.Exercise.VORx1_Yaw == false &&
                        this.state.Exercise.VORx1_Pitch == false &&
                        this.state.Exercise.Balance_Weight_Shift == false &&
                        this.state.Exercise.Balance_Sitting_Standing == false
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        this.OnSaveHandler();
                      }}
                    >
                      {this.state.ExerciseAdded == true ? 'Next' : 'Save'}

                    </button>
                  </div>
                </>
              }
              {(this.props.ProgramStatus == true) &&
                <>
                  <div className="d-flex mt-2 mt-md-5 pt-0 pt-md-5">
                    <button
                      className="ml-auto mr-2 btn-theme-white cursor-pointer rounded-14 btn-lg text-theme-gray-2 font-figtree font-700 font-18"
                      variant="contained"
                      onClick={async (e) => {
                        e.preventDefault();
                        this.props.handleOpenCancelModal();
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      className="bg-primary-theme text-white pointer rounded-14 btn-lg theme-btn border-0 font-figtree font-700 font-18 border-0"
                      variant="contained"
                      disabled={
                        this.state.Exercise.VORx1_Yaw == false &&
                        this.state.Exercise.VORx1_Pitch == false &&
                        this.state.Exercise.Balance_Weight_Shift == false &&
                        this.state.Exercise.Balance_Sitting_Standing == false
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        this.OnSaveHandler();
                      }}
                    >
                      Next
                    </button>
                  </div>
                </>
              }
            </Box>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

const style = {
  modal: {
    p: 4,
  },
};

function mapStateToProps(state) {
  return {
    ProgramStatus: state.patientProgram.startProgram,
    EditProgramStatus: state.patientProgram.startEditProgram,
    ExerciseAdded: state.patientProgram.ExerciseAdded,
    ExerciseData: state.patientProgram.Exercise,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      startPragram,
      stopPragram,
      SaveExercise,
      SaveExerciseAdded,
      updatePatientProgramFrequency,
    },
    dispatch
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(Exercise);
