import {SHOW_NOTIFICATION, FETCH_NOTIFICATIONS, UNREAD_NOTIFICATION_COUNT, SHOW_NOTIFICATION_BLOCK, FETCH_NOTIFICATION_STATUS_BLOCK_DATA, READ_NOTIFICATION } from './../actions/index';

const INITIAL_STATE = {
    notifications:false,
    clickOutsideBox: false,
    notificationData:[],
    unreadCount:0,
    showNotificationBlock:false,
    notificationStatusBlockData:[],
    readNotification:''
};

export default function appReducer  ( state = INITIAL_STATE , action)  {
    switch(action.type){
        // case CLICK_OUTSIDE_BOX :
        //     return {
        //         ...state,
        //         clickOutsideBox:action.payload,
        //     };
        case SHOW_NOTIFICATION :
            return {
                ...state,
                notifications:action.payload,
            };
        case FETCH_NOTIFICATIONS :
            return {
                ...state,
                notificationData:action.payload,
                readNotification:''
            };
        case UNREAD_NOTIFICATION_COUNT :
            return {
                ...state,
                unreadCount:action.payload
            }
        case SHOW_NOTIFICATION_BLOCK :
            return {
                ...state,
                showNotificationBlock:action.payload
            }
        case FETCH_NOTIFICATION_STATUS_BLOCK_DATA:
            return{
                ...state,
                notificationStatusBlockData:action.payload
            }
        case READ_NOTIFICATION : 
            return{
                ...state,
                readNotification:action.payload
            }
        default :
            return state;
    }
}

// export default appReducer;