import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Button from "@mui/material/Button";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { updatePatientProgramFrequency } from "../../../../actions/patientProgramActions";
import {
  SaveFrequency,
  stopPragram,
} from "../../../../actions/setPatientProgramActions";
import { alertService } from "./../../../GlobalComponent/alertService";
import Progress from "./Progress";
import RightCornerPanel from "./RightCornerPanel";

const marks = [
  {
    value: 1,
    label: "1",
  },
  {
    value: 25,
    label: "2",
  },
  {
    value: 50,
    label: "3",
  },
  {
    value: 75,
    label: "4",
  },
  {
    value: 100,
    label: "5",
  },
];
export class Frequency extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCancelProgramModal: false,
      Frequency: {
        NumberOfSessionPerDay: 1,
        sessionsPerDay: 1,
        // ExerciseDurationPerDay: "00:00",
      },
    };
  }

  componentDidMount() {
    if (this.props.patientProgram && this.props.patientProgram != "") {
      this.storeDefaultValue();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      (this.props.patientProgram != "" &&
        prevProps.patientProgram !== this.props.patientProgram) ||
      this.props.ProgramStatus !== prevProps.ProgramStatus ||
      this.props.EditProgramStatus !== prevProps.EditProgramStatus
    ) {
      this.storeDefaultValue();
    }
  }

  storeDefaultValue = () => {
    let filteredRecord = marks.filter(
      (option) =>
        option.label === this.props.patientProgram?.sessionsPerDay?.value
    );
    this.setState({
      ...this.state,
      Frequency: {
        NumberOfSessionPerDay:
          filteredRecord !== undefined ? filteredRecord[0]?.value : 1,
        sessionsPerDay: parseInt(
          this.props.patientProgram?.sessionsPerDay?.value
        ),
      },
    });
  };

  onTimeChange(e) {
    this.setState({
      ...this.state,
      Frequency: {
        ...this.state.Frequency,
        ExerciseDurationPerDay: e.target.value,
      },
    });
  }

  onSliderValChange = (val) => {
    let selectedMark = marks.filter((option) => option.value === val);
    this.setState({
      ...this.state,
      Frequency: {
        ...this.state.Frequency,
        NumberOfSessionPerDay: val,
        sessionsPerDay: selectedMark[0]?.label,
      },
    });
  };

  valueLabelFormat(value) {
    let selected = marks.findIndex((mark) => mark.value === value) + 1;
    return selected;
  }

  OnSaveHandler = async (saveandcontine = "") => {
    this.props.SaveFrequency(this.state.Frequency);
    if (saveandcontine == "") {
      if (this.props.ProgramStatus == true) {
        this.props.handleOpenSetFrequencyModal();
      } else if (
        this.props.EditProgramStatus == true &&
        this.props.ExerciseAdded
      ) {
        this.props.handleOpenSetFrequencyModal();
      } else {
        await this.CreatePayloadForUpdate();
        alertService.success("Record updated successfully!", {
          autoClose: true,
        });
      }
    } else {
      await this.CreatePayloadForUpdate();
      this.props.handleOpenSetFrequencyModal();
    }
    this.props.handleCloseFrequencyModal();
  };

  CreatePayloadForUpdate = () => {
    let Exercises = this.props.Exercise;
    let ExerciseFrequency = this.props.ExerciseFrequency;
    let Frequency = this.props.Frequency;
    let postPlayload = [];
    let obj = {};
    Object.keys(Exercises).map((key) => {
      let name = key.replace(/_/g, " ");
      obj = {
        name:name.replace("Balance Sitting Standing", "Balance Sitting/Standing"),
        value: Exercises[key],
        type: "Object",
      };
      postPlayload.push(obj);
    });
    Object.keys(ExerciseFrequency).map((key) => {
      obj = { name: key, value: ExerciseFrequency[key], type: "Object" };
      postPlayload.push(obj);
    });
    obj = {
      name: "sessionsPerDay",
      value: this.state.Frequency.sessionsPerDay,
      type: "Object",
    };
    postPlayload.push(obj);
    this.props.updatePatientProgramFrequency(
      postPlayload,
      this.props.patientUsername
    );
  };

  handleOpenCancelModal = () => this.setState({ showCancelProgramModal: true });
  handleCloseCancelModal = () =>
    this.setState({ showCancelProgramModal: false });

  render() {
    return (
      <Modal
        show={this.props.showFrequencySecondModal}
        onHide={this.props.handleCloseFrequencyModal}
        backdrop="static"
        className="theme-modal-1101 pt-5"
      >
        {this.props.patientInfo.newPatient === true &&
          <div className="p-1 absolute w-100 -top-23 bg-white rounded-20">
            <Progress value={49.98} />
          </div>
        }
        <Modal.Body>
          <Box
            sx={style.modal}
            component="form"
            autoComplete="off"
            className="p-3 rounded-20 border-0"
          >
            
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <div className="text-primary-theme font-figtree font-700 font-18 pointer d-inline-flex align-items-center"
                    onClick={() => {
                      this.props.handleCloseFrequencyModal();
                      this.props.handleOpenExerciseFrequencyModal();
                    }}
                  >
                    <ArrowBackIosNewIcon fontSize="small" />
                    Back
                  </div>
                  {(this.props.ProgramStatus == false && this.props.ExerciseAdded == false) &&
                    <div className="text-primary-theme font-figtree font-700 font-18 pointer d-inline-flex align-items-center"
                      onClick={(e) => {
                        e.preventDefault();
                        this.OnSaveHandler("saveandcontine");
                      }}
                    >
                      Set Frequency
                      <ArrowForwardIosIcon fontSize="small" />
                    </div>
                  }
                </div>
            <div className="d-flex">
              <div className="w-75">
                <h4 className="font-figtree font-500 font-38">Frequency</h4>
                <div className="mx-auto ml-2 ml-md-5 pl-0 pl-md-5 pr-5 mb-2 mb-md-5 pb-0 pb-md-5">
                  <div className="mt-5">
                    <h6 className="font-figtree font-600 font-18 text-theme-gray-dark mb-4">
                      Select the number of sessions per day:
                    </h6>
                    <Slider
                      // disabled={false}
                      className="slider-theme"
                      aria-label="Restricted values"
                      value={this.state.Frequency.NumberOfSessionPerDay}
                      valueLabelFormat={this.valueLabelFormat}
                      step={null}
                      valueLabelDisplay="auto"
                      marks={marks}
                      onChange={(e) => {
                        this.onSliderValChange(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
              <RightCornerPanel step={3} />
            </div>
            <div className="d-flex my-2 mt-md-3 mb-md-3">
              <button
                className="ml-auto mr-2 btn border border-primary-theme cursor-pointer rounded-14 btn-lg"
                variant="contained"
                onClick={(e) => {
                  e.preventDefault();
                  this.props.handleOpenCancelModal();
                }}
              >
                Cancel
              </button>
              <button
                className="bg-primary-theme text-white pointer rounded-14 btn-lg theme-btn border-0 font-figtree font-700 font-18 border-0"
                variant="contained"
                disabled={this.state.Frequency.sessionsPerDay == 0}
                onClick={(e) => {
                  e.preventDefault();
                  this.OnSaveHandler();
                }}
              >
                {(this.props.ProgramStatus == true || (this.props.ProgramStatus == false && this.props.ExerciseAdded == true)) &&
                  "Next"
                }

                {(this.props.ProgramStatus == false && this.props.ExerciseAdded == false) &&
                  "Save"
                }
              </button>
            </div>
          </Box>
        </Modal.Body>
      </Modal>
    );
  }
}

const style = {
  modal: {
    p: 4,
  },
};

function mapStateToProps(state) {
  return {
    Exercise: state.patientProgram.Exercise,
    ExerciseFrequency: state.patientProgram.ExerciseFrequency,
    Frequency: state.patientProgram.Frequency,
    ProgramStatus: state.patientProgram.startProgram,
    EditProgramStatus: state.patientProgram.startEditProgram,
    ExerciseAdded: state.patientProgram.ExerciseAdded,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      stopPragram,
      SaveFrequency,
      updatePatientProgramFrequency,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Frequency);
