import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Badge from "@material-ui/core/Badge";
import { showNotificationList, fetchNotifications, showNotificationBlock, fetchUserDetail, fetchNotificationStatusBlockData, readNotification, fetchPatientNotifications } from '../../../actions/index';
import { NavLink } from 'react-router-dom';
import moment from 'moment';
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import data from './patientDetail/test-Notifications';
import { withRouter } from "react-router-dom";

export class NotificationManager extends Component {
    constructor(props) {
        super(props);
        this.state = {
            notificationListData: [],
            patient: []
        };

        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }
    componentDidMount() {
        // this.props.fetchNotifications();
        this.setState({
            patient: this.props.patientInfo
        })
        this.setState({ notificationListData: this.props.notificationData });
        document.addEventListener("mousedown", this.handleClickOutside);
    }


    /*  --------------------------------------------------- HANDLE OUTSIDE THE BOX CLICK CLOSE ------------------------------------------  */

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            // this.props.clickOutsideBox(true);
            // this.props.showNotificationList(false);
        }
    }

    /*  --------------------------------------------------- HANDLE OUTSIDE THE BOX CLICK CLOSE ------------------------------------------ */

    componentDidUpdate(prevProps, prevState) {
        if (this.props.patientInfo && this.props.patientInfo !== prevProps.patientInfo) {
            this.setState({
                patient: this.props.patientInfo
            })
        }
    }

    handleNotificationClick(m) {
        const { history } = this.props;
        history.push('/patientdetail/' + m.user);
        // let notificationBlockData = this.state.notificationListData.filter(p=>p.user==m.user);
        // this.props.fetchNotificationStatusBlockData(notificationBlockData);
        this.props.fetchPatientNotifications(m.user);
        // if(this.state.patient.ptId === this.props.loggedInUser.id){
        //     notificationBlockData.map((n)=>{
        //         if(n.notificationRead === false){
        //             this.props.readNotification(n.user,n.id)
        //           }

        //     })
        // }
        this.props.showNotificationBlock(true);
        this.props.showNotificationList(false);
        this.props.fetchUserDetail(m.user);
        // this.props.fetchNotifications();
    }

    render() {
        return (
            <div className='notifications-box rounded-12 border shadow' id="notification_panel"> {/*ref={this.setWrapperRef}*/}
                <div className='p-3'>
                    <h6 className='mb-0 font-weight-bold'> Notifications </h6>
                </div>
                <hr className='m-0'></hr>
                <div className=''>
                    {this.state.notificationListData.map(m => {
                        if (m.notificationType === 'High Dizziness') {
                            return <>
                                <Button
                                    to={'#'}
                                    onClick={(e) => { this.handleNotificationClick(m) }}
                                    disableRipple
                                    className='px-3 py-2 d-inline-block w-100'
                                >
                                    <div id={m.index} className='row'>
                                        <div className='col-md-10'>
                                            <div className='text-dark font-figtree font-14'>
                                                <span className="text-secondary-theme font-600">{m.notificationType}</span> <span className='font-400'>experienced by </span><span className='font-600'>{m.fullName}</span>
                                            </div>
                                            <div className='text-theme-gray-2 font-figtree font-400 font-14'>
                                                {m.notificationDateStr}
                                            </div>
                                        </div>
                                        <div className='col-md-2 '>
                                            <Badge color="secondary" variant='dot' className="" invisible={m.notificationRead} />
                                        </div>
                                    </div>

                                </Button>
                                <hr className='my-0' />
                            </>
                        }
                        if (m.notificationType === 'Low Compliance' || m.notificationType === 'Missing Results') {
                            return <>
                            <Button
                                to={'#'}
                                onClick={(e) => { this.handleNotificationClick(m) }}
                                disableRipple
                                className='px-3 py-2 d-inline-block w-100'
                            >
                                <div className='row'>
                                    <div className='col-md-10'>
                                        <div className='text-dark font-figtree font-14'>
                                            <span className="text-secondary-theme font-600">{m.notificationType}</span> <span className='font-400'>for </span><span className='font-600'>{m.fullName}</span>
                                        </div>
                                        <div className='text-theme-gray-2 font-figtree font-400 font-14'>
                                            {m.notificationDateStr}
                                        </div>
                                    </div>
                                    <div className='col-md-2'>
                                        <Badge color="secondary" variant='dot' className="" invisible={m.notificationRead} />
                                    </div>
                                </div>
                            </Button>
                            <hr className='my-0' />
                            </>
                        }
                    })}
                </div>

            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        notifications: state.notifications.notifications,
        clickOutside: state.notifications.clickOutsideBox,
        notificationData: state.notifications.notificationData,
        loggedInUser: state.security.user,
        readNotificationData: state.notifications.readNotification,
        patientInfo: state.security.user.role=='TVH SUPER ADMIN'?state.superAdminPatientData.superAdminPatients:state.userTableData,
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            showNotificationList,
            fetchNotifications,
            showNotificationBlock,
            fetchUserDetail,
            fetchNotificationStatusBlockData,
            readNotification,
            // clickOutsideBox,
            fetchPatientNotifications
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NotificationManager));