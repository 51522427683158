import EditIcon from "@mui/icons-material/Edit";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Button from "@mui/material/Button";
import moment from "moment";
import "moment-duration-format";
import React, { Component } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { startEditPragram } from "./../../../../actions/setPatientProgramActions";

class PatientProgramInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      patientInfo: "",
      patientUsername: "",
      weeklyFrequency: "Daily",
      numberOfsets: "",
      totalTime: 0,
    };
  }

  componentDidMount() {
    this.storeDefaultValue();
  }
  static getDerivedStateFromProps(props, state) {}

  componentDidUpdate(prevProps) {
    if (
      this.props.patientProgram &&
      prevProps.patientProgram !== this.props.patientProgram
    ) {
      this.storeDefaultValue();
    }
  }
  storeDefaultValue() {
    let patientId = this.props.patientId;
    let patientUsername = this.props.patientUsername;
    this.setState({ patientId: patientId, patientUsername: patientUsername });

    let scheduleList = this.props.patientProgram.scheduleList;
    let totalSessionDays = 0;
    scheduleList?.forEach((element) => {
      if (element.value) {
        totalSessionDays = totalSessionDays + 1;
      }
    });

    if (totalSessionDays < 7) {
      this.setState({
        weeklyFrequency: totalSessionDays + " times per week",
      });
    }else{
      this.setState({
        weeklyFrequency: "Daily",
      });
    }
    let sessionsPerDay = "";
    if (
      this.props.patientProgram.sessionsPerDay &&
      this.props.patientProgram.sessionsPerDay.value
    ) {
      sessionsPerDay = this.props.patientProgram.sessionsPerDay.value;
    }
    this.setState({ sessionsPerDay: sessionsPerDay });
    let prescriptionList = this.props.patientProgram.prescriptionList;
    let prescriptions = [];
    let numberOfsets = [];
    let durationOfSecond = [];
    let programLevel = [];
    let targetHead = [];
    let velocityTarget = [];
    let BSLchoiceVal = [];
    let BSLSelchoiceVal = "";
    let BWSchoiceVal = [];
    prescriptionList?.forEach((ele) => {
      if (ele.selected) {
        prescriptions.push(ele.name);
        numberOfsets[ele.name] = ele.numberOfSetsInSession;
        let prescriptionDisplayList = ele.prescriptionDisplayList;
        prescriptionDisplayList.forEach((val) => {
          if (val.id == "Current") {
            durationOfSecond[ele.name] = val.value;
          }
          if (val.id == "Current Level") {
            programLevel[ele.name] = val.value;
          }
          if (val.id == "Head Left Target") {
            targetHead[ele.name] = val.value;
          }
          if (val.id == "Head Up Target") {
            targetHead[ele.name + "-UP"] = val.value;
          }
          if (val.id == "Head Down Target") {
            targetHead[ele.name + "-DOWN"] = val.value;
          }

          if (
            val.id == "Yaw Velocity Target" ||
            val.id == "Pitch Velocity Target"
          ) {
            velocityTarget[ele.name] = val.value;
          }

          if (ele.name == "Balance Sitting/Standing") {
            if (val.id == "Current Assist") {
              BSLchoiceVal = val.choiceVal;
              BSLSelchoiceVal = val.value;
            }
          }
          if (ele.name == "Balance Weight Shift") {
            if (val.id == "Left-Right") {
              BWSchoiceVal[val.name] = val.value;
            }
            if (val.id == "Heel-Toe") {
              BWSchoiceVal[val.name] = val.value;
            }
            if (val.id == "Omni-Directional") {
              BWSchoiceVal[val.name] = val.value;
            }
            if (val.id == "Randomized Left-Right and Heel-Toe") {
              BWSchoiceVal[val.name] = val.value;
            }
          }
        });
      }
    });
    this.setState({
      prescriptions: prescriptions,
      numberOfsets: numberOfsets,
      durationOfSecond: durationOfSecond,
      programLevel: programLevel,
      targetHead: targetHead,
      velocityTarget: velocityTarget,
      BSLchoiceVal: BSLchoiceVal,
      BSLSelchoiceVal: BSLSelchoiceVal,
      BWSchoiceVal: BWSchoiceVal,
    });

    setTimeout(() => {
      this.calculateTotalDuration();
    }, 500);
  }
  calculateTotalDuration() {
    let numberOfsets = this.state.numberOfsets;
    let durationOfSecond = this.state.durationOfSecond;
    let totalTime = 0;
    Object.keys(numberOfsets).map((key) => {
      if (durationOfSecond[key]) {
        totalTime = totalTime + (numberOfsets[key] * durationOfSecond[key]);
      }
    });
    totalTime = (totalTime * this.state.sessionsPerDay);
    if (totalTime < 60) {
      totalTime = totalTime < 10 ? "00:0" + totalTime : "00:" + totalTime;
    } else {
      totalTime = moment.duration(totalTime, "seconds").format("mm:ss");
    }
    this.setState({ totalTime: totalTime });
  }

  render() {
    return (
      <>
        <h5 className="mt-3 font-figtree font-700 font-22">Exercises</h5>
        <Row className="align-items-center mb-3">
          <Col xs={9}>
            {this.state.prescriptions &&
              this.state.prescriptions.map((prescription) => (
                <Button  variant="contained" style={{"cursor":"default","color":"#fff"}} className="mr-2 mt-1 font-figtree font-600 font-16 rounded-22 bg-second-blue bg-theme-secondary-blue" disabled={true}>{prescription}</Button>
              ))}

            {
              this.props.currentUser.role!='TVH SUPER ADMIN' && 
              <Button
              variant="outlined"
              className="mt-1 font-figtree font-700 font-18 rounded-14 text-primary-theme border-primary-theme text-transform-none ml-4"
              onClick={() => {
                this.props.startEditPragram();
                this.props.handleOpenExerciseModal();
              }}
            >
              <img src="../images/edit.svg" alt="Teravista" className="mr-2" /> Edit Exercises
            </Button>}
          </Col>
          <Col xs={3} className="font-figtree">
            <p className="text-right mb-1 font-400 font-18">
              Exercise Duration per Day (min:sec)
            </p>
            <h4 className="text-right font-600 font-38">{this.state.totalTime}</h4>
          </Col>
        </Row>

        <Row>
          <Col md={4} xs={12} className="mt-3">
            <div className="bg-light-gray p-3 rounded-3">
              <div className="name-edit-btn d-flex justify-content-between mb-4">
                <h5 className="font-figtree font-700 font-22 mb-0">Frequency</h5>
                {this.props.currentUser.role!='TVH SUPER ADMIN' && <a href="javascript:void(0)">
                  <img src="../images/edit.svg" alt="Teravista" className="" onClick={() => {
                      this.props.startEditPragram();
                      this.props.handleOpenExerciseFrequencyModal();
                    }}
                  />
                </a>}
              </div>
              <div className="name-edit-btn d-flex justify-content-between mb-4">
                <div className="mr-2 font-figtree font-500 font-16">Weekly Frequency:</div>
                <div className="font-figtree font-700 font-16">{this.state.weeklyFrequency}</div>
              </div>
              <div className="d-flex justify-content-between">
                <div className="mr-2 font-figtree font-500 font-16">Number of Sessions per Day:</div>
                <div className="font-figtree font-700 font-16">{this.state.sessionsPerDay}</div>
              </div>
            </div>
          </Col>

          <Col md={4} xs={12} className="mt-3 px-4">
            <div className="bg-light-gray p-3 rounded-3 mx-3">
              <div className="name-edit-btn d-flex justify-content-between mb-4">
                <h5 className="font-figtree font-700 font-22 mb-0">Set Frequency</h5>
                {this.props.currentUser.role!='TVH SUPER ADMIN' && <a href="javascript:void(0)">
                  <img src="../images/edit.svg" alt="Teravista" className="" onClick={() => {
                      this.props.startEditPragram();
                      this.props.handleOpenSetFrequencyModal();
                    }} />                  
                </a>}
              </div>
              <div className="mb-2 w-75 mr-2 text-right font-figtree font-600 font-16">Number of sets</div>

              {this.state.numberOfsets &&
                Object.keys(this.state.numberOfsets).map((key) => (
                  <div className="name-edit-btn d-flex justify-content-between mb-3 text-right">
                    <div className="w-75 mr-2 text-light-blue font-figtree font-500 font-16">{key}:</div>
                    <div className="font-figtree font-700 font-16">{this.state.numberOfsets[key]}</div>
                  </div>
                ))}

              <div className="mb-2 mt-4 w-75 mr-2 text-right font-figtree font-600 font-16">Duration of sets (sec):</div>

              {this.state.durationOfSecond &&
                Object.keys(this.state.durationOfSecond).map((key) => (
                  <div className="name-edit-btn d-flex justify-content-between mb-3 text-right">
                    <div className="w-75 mr-2 text-light-blue font-figtree font-500 font-16">{key}:</div>
                    <div className="font-figtree font-700 font-16">{this.state.durationOfSecond[key]}</div>
                  </div>
                ))}
            </div>
          </Col>
          <Col md={4} xs={12} className="mt-3">
            <div className="bg-light-gray p-3 rounded-3">
              <div className="name-edit-btn d-flex justify-content-between mb-4">
                <h5 className="font-figtree font-700 font-22 mb-0">Settings</h5>
               
                {this.props.currentUser.role!='TVH SUPER ADMIN' && <a href="javascript:void(0)">
                  <img src="../images/edit.svg" alt="Teravista" className="" onClick={() => {
                      this.props.startEditPragram();
                      this.props.handleOpenSettingsFirstModal();
                    }}
                  />
                </a>}
              </div>
              
              {this.state.programLevel &&
                Object.keys(this.state.programLevel).length > 0 && (
                  <>
                    <div className="mb-2 mt-2 w-75 mr-2 text-right font-figtree font-600 font-16">Level</div>
                    {Object.keys(this.state.programLevel).map((key) => (
                      <div className="name-edit-btn d-flex justify-content-between mb-3 text-right">
                        <div className="w-75 mr-2 text-light-blue font-figtree font-500 font-16">{key}:</div>
                        <div className="font-figtree font-700 font-16">{this.state.programLevel[key]}</div>
                      </div>
                    ))}
                  </>
                )}
              {this.state.targetHead &&
                Object.keys(this.state.targetHead).length > 0 && (
                  <>
                    <div className="mb-2 mt-4 w-75 mr-2 text-right">
                      <div className="font-figtree font-600 font-16">Target</div>
                      <div className="font-figtree font-400 font-16">Head (deg)</div>
                    </div>

                    {Object.keys(this.state.targetHead).map((key) => (
                      <div className="name-edit-btn d-flex justify-content-between mb-3 text-right">
                        <div className="w-75 mr-2 text-light-blue font-figtree font-500 font-16">{key}:</div>
                        <div className="font-figtree font-700 font-16">{this.state.targetHead[key]}</div>
                      </div>
                    ))}
                  </>
                )}
              {this.state.velocityTarget &&
                Object.keys(this.state.velocityTarget).length > 0 && (
                  <>
                    <div className="mb-2 mt-2 w-75 mr-2 text-right font-figtree font-400 font-16">Velocity (deg/sec)</div>
                    {Object.keys(this.state.velocityTarget).map((key) => (
                      <div className="name-edit-btn d-flex justify-content-between mb-3 text-right">
                        <div className="w-75 mr-2 text-light-blue font-figtree font-500 font-16">{key}:</div>
                        <div className="font-figtree font-700 font-16">{this.state.velocityTarget[key]}</div>
                      </div>
                    ))}
                  </>
                )}
              {((parseInt(this.state.BSLSelchoiceVal)>=0) ||
                (this.state.BWSchoiceVal &&
                  Object.keys(this.state.BWSchoiceVal).length > 0)) && (
                <>
                  <div className="mb-2 mt-4 w-75 mr-2 text-right font-figtree font-600 font-16">Instructions</div>
                  {this.state.BWSchoiceVal &&
                    Object.keys(this.state.BWSchoiceVal).length > 0 && (
                      <div className="name-edit-btn d-flex justify-content-between mb-4 text-right align-items-start">
                        <div className="w-75 mr-2 text-light-blue font-figtree font-500 font-16">Balance Weight Shift:</div>
                        <div className="font-figtree font-700 font-16">
                            {this.state.BWSchoiceVal
                              ? Object.keys(this.state.BWSchoiceVal).map(
                                  (key) => {
                                    if (this.state.BWSchoiceVal[key]) {
                                      return <div>{key.replace("-", " ")}</div>;
                                    }
                                  }
                                )
                              : ""}
                        </div>
                      </div>
                    )}        
                     
                  {parseInt(this.state.BSLSelchoiceVal)>=0 && (
                      <div className="name-edit-btn d-flex justify-content-between mb-4 text-right">
                        <div className="w-75 mr-2 text-light-blue font-figtree font-500 font-16">Balance Sitting/Standing:</div>
                        <div className="font-figtree font-700 font-16">
                            {this.state.BSLchoiceVal
                              ? this.state.BSLchoiceVal[
                                  this.state.BSLSelchoiceVal
                                ]
                              : ""}
                        </div>
                      </div>
                    )}
                </>
              )}
            </div>
          </Col>
        </Row>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.security.user,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      startEditPragram,
    },
    dispatch
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(PatientProgramInfo);
