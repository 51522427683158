import IconButton from "@material-ui/core/IconButton";
import Pagination from "@material-ui/lab/Pagination";
import DriveFileRenameOutlineSharpIcon from "@mui/icons-material/DriveFileRenameOutlineSharp";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { DataGrid } from "@mui/x-data-grid";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchKitList, fetchKitStatusReport } from "../../../actions/index";
import AddEditKit from "./AddEditKit";
import AutoSearchBarKits from "./AutoSearchBarKits";
import * as routes from "../../../constants/routes";
import KitListAdmin from "./KitListAdmin";
import KitListProvider from "./KitListProvider";


class KitList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUserRole: '',

    };
  }

  componentDidMount() {
    let currentUser = this.props.currentUser;

    if (currentUser == '' || Object.keys(currentUser).length == 0) {
      window.location.href = '/';
      return false;
    }
    else {
      let currentUserRole = currentUser.role;
      this.setState({ currentUserRole: currentUserRole });
    }
  }

  componentDidUpdate(prevProps) {

  }

  render() {
    return (
      <div className="p-3">
        {(this.state.currentUserRole.toLowerCase() == 'tvh admin' || this.state.currentUserRole.toLowerCase() == 'tvh super admin') &&
          <KitListAdmin></KitListAdmin>
        }
         {this.state.currentUserRole.toLowerCase() == 'provider' &&
          <KitListProvider></KitListProvider>
        }
      </div>
    );
  }
}

function mapStateToProps(state) {

  return {
    kit: state.kit.kitList,
    currentUser: state.security.user,
    kitReport: state.kit.kitReport
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchKitList,
      fetchKitStatusReport,
    },
    dispatch
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(KitList);
