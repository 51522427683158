import Box from "@mui/material/Box";
import classnames from "classnames";
import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { PatternFormat } from "react-number-format";
import {
  AddKit,
  EditKitStatus,
  fetchKitListProvider,
  fetchKitStatusReportProvider,
  FetchKit,
  searchClinic,
  searchClinicLocations,
  FetchStatusList,
  FetchClinicList
} from "../../../actions/index";
import { alertService } from "./../../GlobalComponent/alertService";

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

class AddEditKit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      kitID: "",
      clinicLocationMappingId: "",
      kitNumber: "",
      patientName: "-",
      medicalRecordNumber: "-",
      imeiNumber: "",
      clinicName: "",
      clinicId: "",
      clinicObj: null,
      locationId: "",
      locationObj: null,

      status: "1",
      ClinicHelperText: "",
      StatusHelperText: "",
      anchorEl: null,
      ClinicsList: [],
      Locations: [],
      statusList: [],
      clinicList: [],
      open: false,
      previousData: {},
    };
  }

  async componentDidMount() {
    if (this.props.mode === "Edit") {
      await this.props.FetchStatusList('');
      await this.props.FetchClinicList('');
    }
    this.storeKitValues();
  }

  componentDidUpdate(prevProps) {
    if (this.props.statusList != prevProps.statusList) {
      this.setState({ statusList: this.props.statusList });
      setTimeout(() => {

      }, 500);

    }
    if (this.props.clinicList != prevProps.clinicList) {
      this.setState({ clinicList: this.props.clinicList });
      setTimeout(() => {
        // console.log("this.state.statuslist:::", this.state.clinicList);
        // console.log("this.state.stat:::", Object.keys(this.state.clinicList).length);
      }, 500);

    }

    if (
      this.props.EditElement !== null &&
      this.props.EditElement !== prevProps.EditElement &&
      this.props.mode === "Edit"
    ) {

      this.storeKitValues();
    }
    if (this.props.editKit !== prevProps.editKit) {
      alertService.success("Kit updated successfully!", { autoClose: true });
      this.setState({
        kitID: "",
        clinicName: "",
        clinicId: "",
        clinicObj: null,
        locationId: "",
        locationObj: null,
        kitNumber: "",
        patientName: "-",
        medicalRecordNumber: "-",
        status: "1",
        ClinicHelperText: "",
        StatusHelperText: "",
        anchorEl: null,
        searchString: null,
        ClinicsList: [],
        Locations: [],
      });
      this.props.handleClose();
      this.props.fetchKitListProvider(
        this.props.page,
        this.props.perPage,
        this.props.sortColumn,
        this.props.sortOrder,
        this.props.kitId
      );
      this.props.fetchKitStatusReportProvider();
    }
    if (this.props.addKit !== prevProps.addKit) {
      alertService.success("Kit added successfully!", { autoClose: true });
      this.setState({
        kitID: "",
        clinicName: "",
        clinicId: "",
        clinicObj: null,
        locationId: "",
        locationObj: null,
        kitNumber: "",
        patientName: "-",
        medicalRecordNumber: "-",
        status: "1",
        ClinicHelperText: "",
        StatusHelperText: "",
        anchorEl: null,
        searchString: null,
        ClinicsList: [],
        Locations: [],
      });
      this.props.handleClose();
      this.props.fetchKitListProvider(
        this.props.page,
        this.props.perPage,
        this.props.sortColumn,
        this.props.sortOrder,
        this.props.kitId
      );
      this.props.fetchKitStatusReportProvider();
    }


  }

  async storeKitValues() {

    if (
      this.props.mode === "Edit" &&
      this.props.EditElement.id !== null &&
      this.props.EditElement.id !== undefined
    ) {
      let kitData = await this.props.FetchKit(this.props.EditElement.id);
      this.setState({ previousData: kitData });
      let EditElement = this.props.EditElement;

      if (kitData.clinic !== '' && kitData.clinic !== null) {
        this.setState({ clinicId: kitData.clinic.id });
      }
      if (kitData.location !== '' && kitData.location !== null) {
        this.setState({ locationId: kitData.location.id });
      }
      await this.setState({
        kitID: kitData.id,
        kitNumber: kitData.kitNumber,
        medicalRecordNumber: kitData.medicalRecordNumber,
        patientName: kitData.patientName,
        status: kitData.statusCode,
        //clinicName: kitData.clinicName,
        //locationID:EditElement.
      });
      //console.log("this.state.clinicList::::",this.state.clinicList);
      let clinic = this.state.clinicList.filter(clinic => clinic.name.includes(EditElement.clinicName));
      console.log("clinic::::", Object.values(clinic).length);
      if (Object.values(clinic).length > 0) {
        let locations = clinic[0].locations;
        this.setState({ Locations: locations });
      }
    } else {
      this.setState({
        id: "",
        kitNumber: "",
        imeiNumber: "",
        clinicName: "",
        clinicName: "",
        clinicId: "",
        clinicObj: null,
        locationId: "",
        locationObj: null,
        status: "1",
        ClinicHelperText: "",
        StatusHelperText: "",
        anchorEl: null,
        searchString: null,
        ClinicsList: [],
        Locations: [],

      });
    }
  }
  onChangeValueHandler = (e) => {
    let value = e.target.value.trim();
    // if (e.target.id == "kitNumber" || e.target.id == "imeiNumber") {
    //   value = value.replace(/[^a-zA-Z0-9]/g, "");
    // }


    if (e.target.id == "clinicId") {
      console.log("this.state.clinicList:::", this.state.clinicList);

      let clinic = this.state.clinicList.filter(clinic => clinic.id.includes(value));
      let locations = clinic[0].locations;
      this.setState({ Locations: locations });
    }

    if (e.target.id == "locationId") {
      let obj = this.state.Locations.filter(
        (option) => option.id == e.target.value
      );
      this.setState({
        [e.target.id]: value.trim(),
        locationObj: obj[0],
        KitnumberHelperText: "",
        ImeiNumberHelperText: "",
        StatusHelperText: "",
      });
    } else {
      this.setState({
        [e.target.id]: value.trim(),
        KitnumberHelperText: "",
        ImeiNumberHelperText: "",
        StatusHelperText: "",
      });
    }
  };



  ValidateData = async (e) => {

    this.setState({
      KitnumberHelperText: "",
      ImeiNumberHelperText: "",
      StatusHelperText: ""
    });


    if (this.state.status == "" || this.state.status < 3 || this.state.status == 5) {
      this.setState({ StatusHelperText: "Please select status" });
      return false;
    }

    let payload = {
      status: this.state.status,
    };
    // if (this.state.role === "PROVIDER") {
    //   payload.clinicLocationMapping = {
    //     clinic: this.state.clinicObj,
    //     location: this.state.locationObj,
    //   };
    // }
    if (this.state.previousData.patientName !== null && this.state.status != 6 && (this.state.previousData.statusCode != this.state.status ||
      this.state.previousData.clinic.id != this.state.clinicId ||
      this.state.previousData.location.id != this.state.locationId)) {
      this.setState({ open: true });
    } else {

      await this.EditKitHandler(payload);
    }

  };

  AddKitHandler = async (payload) => {
    await this.props.AddKit(payload);
    alertService.success("Kit added successfully!", { autoClose: true });
  };

  EditKitHandler = async (payload) => {
    payload.id = this.state.kitID;
    await this.props.EditKitStatus(payload);

  };

  async autoSearchHandler(e, v) {
    this.setState({ searchString: v });
    if (e !== null && e.type == "click") {
      let res = await this.props.searchClinic(v);
      let name = '';
      let Id = '';
      let clinicObj = '';
      let ClinicsList = [];
      if (res.data.clinics[0]) {
        name = res.data.clinics[0].name;
        Id = res.data.clinics[0].id;
        clinicObj = res.data.clinics[0];
        ClinicsList = res.data.clinics;
      }

      this.setState({
        clinicId: Id,
        clinicName: name,
        clinicObj: clinicObj,
        ClinicsList: ClinicsList,
      });
      this.searchClinicLocations(Id);
    } else {
      if (v.length > 2) {
        let res = await this.props.searchClinic(v);
        if (res.data.clinics.length) {
          this.setState({ ClinicsList: res.data.clinics });
        } else {
          this.setState({ ClinicsList: [] });
        }
      } else {
        this.setState({ ClinicsList: [] });
      }
    }
  }

  async searchClinicLocations(Id) {
    let res = await this.props.searchClinicLocations(Id);
    if (res.data.locations.length) {
      this.setState({
        Locations: res.data.locations,
        locationId: res.data.locations[0].id,
        locationObj: res.data.locations[0],
      });
    } else {
      this.setState({ Locations: [] });
    }
  }
  handleConfimPopupClose() {
    this.setState({ open: false });
  }
  async handleConfimPopupConfirm() {
    this.setState({ open: false });
    let payload = {
      kitNumber: this.state.kitNumber,
      imeiNumber: this.state.imeiNumber,
      status: this.state.status,
      clinicId: this.state.clinicId,
      locationId: this.state.locationId
    };
    await this.EditKitHandler(payload);
  }

  render() {
    return (
      <Modal
        show={this.props.AddEditKitModal}
        onHide={this.props.handleClose}
        className="theme-modal"
      >
        <Modal.Body>
          <Box
            sx={style.modal}
            component="form"
            autoComplete="off"
            className="p-0 w-100 border-0 max-646">
            <div className="border-solid border-4 border-primary-theme rounded-30 position-relative modal-internal">
              <div className="position-absolute bg-white p-1 -top-23 left-40 text-primary-theme font-figtree font-700 font-24px">
                {this.props.mode === "Add" ? "Add Tablet" : "Edit Tablet"}
              </div>

              <div className="bg-white p-2 position-absolute -top-23 -right-20 rounded-20">
                <img
                  src={
                    this.props.mode === "Add"
                      ? "images/modal-close.svg"
                      : "../images/modal-close.svg"
                  }
                  alt="close"
                  className="pointer"
                  onClick={(e) => {
                    e.preventDefault();
                    this.props.handleClose();
                  }}
                />
              </div>

              <div className="form-card no-border">
                <label
                  for="exampleInputEmail1"
                  className="font-figtree font-700 font-15 mb-1"
                >
                  KIT#
                </label>

                <PatternFormat
                  customInput={TextField}
                  format="###-######"
                  mask="_"
                  className={classnames("form-control font-18 h-auto")}
                  placeholder="Enter IMEI #"
                  name="kitNumber"
                  id="kitNumber"
                  value={this.state.kitNumber}
                  onChange={(e) => this.onChangeValueHandler(e)}
                  readOnly="readOnly"
                />
                <div className={classnames("custom-error", {
                  "is-invalid": this.state.KitnumberHelperText,
                })}>
                  {this.state.KitnumberHelperText && (
                    <div className="invalid-feedback">
                      {this.state.KitnumberHelperText}
                    </div>
                  )}
                </div>
              </div>
              <div className="form-card no-border">
                <label
                  for="exampleInputEmail1"
                  className="font-figtree font-700 font-15 mb-1"
                >
                  Patient Name
                </label>

                <input
                  customInput={TextField}
                  className={classnames("form-control font-18", {
                    "is-invalid": this.state.PhoneHelperText,
                  })}
                  placeholder="Enter Patitent Name"
                  name="patientName"
                  id="patientName"
                  value={this.state.patientName}
                  onChange={(e) => this.onChangeValueHandler(e)}
                  readOnly="readOnly"
                />

              </div>
              <div className="form-card no-border">
                <label
                  for="exampleInputEmail1"
                  className="font-figtree font-700 font-15 mb-1"
                >
                  MRN
                </label>

                <input
                  customInput={TextField}
                  className={classnames("form-control font-18", {
                    "is-invalid": this.state.PhoneHelperText,
                  })}
                  placeholder="Enter MRN"
                  name="medicalRecordNumber"
                  id="medicalRecordNumber"
                  value={this.state.medicalRecordNumber}
                  onChange={(e) => this.onChangeValueHandler(e)}
                  readOnly="readOnly"
                />

              </div>

              {this.props.mode === "Edit" &&
                <div className="mb-3 ">
                  <div className=" select-custom-arrow">
                    <label
                      className="font-figtree font-700 font-15 mb-1"
                      for="exampleInputEmail1"
                    >
                      STATUS
                    </label>
                    <select
                      id="status"
                      name="status"
                      value={this.state.status}
                      onChange={(e) => this.onChangeValueHandler(e)}

                      className={classnames(
                        "form-control font-18 rounded-14 border-dark border h-auto p-2"
                      )}
                    ><option value="">-</option>
                      {Object.keys(this.state.statusList).length > 0 && Object.keys(this.state.statusList).map((i) => <option value={i}>{this.state.statusList[i]}</option>)}
                    </select>
                  </div>
                  <div className={classnames("custom-error", {
                    "is-invalid": this.state.StatusHelperText,
                  })}>
                    {this.state.StatusHelperText && (
                      <div className="invalid-feedback">
                        {this.state.StatusHelperText}
                      </div>
                    )}
                  </div>

                </div>
              }

              <div className="d-flex mt-5 pt-5">
                <button
                  className="ml-auto mr-2 bg-transparent border cursor-pointer rounded-20 font-figtree font-700 font-26 px-4 py-2 text-primary-theme"
                  variant="contained"
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({
                      kitID: "",
                      clinicName: "",
                      clinicId: "",
                      clinicObj: null,
                      locationId: "",
                      locationObj: null,
                      status: "1",
                      ClinicHelperText: "",
                      StatusHelperText: "",
                      anchorEl: null,
                      searchString: null,
                      ClinicsList: [],
                      Locations: [],
                    });
                    this.props.handleClose();
                  }}
                >
                  Cancel
                </button>
                <button
                  className="bg-primary-theme text-white pointer rounded-20 theme-btn border-0 font-figtree font-700 font-26 px-4 py-2"

                  variant="contained"
                  onClick={(e) => {
                    e.preventDefault();
                    this.ValidateData(e);
                  }}
                >
                  {this.props.mode === "Add" ? "Add" : "Save"}
                </button>
              </div>
            </div>
            <Dialog
              open={this.state.open}
              onClose={() => this.handleConfimPopupClose()}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                Confirmation
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  This will unassign the Kit from the patient. Are you sure?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => this.handleConfimPopupClose()}>Cancel</Button>
                <Button onClick={() => this.handleConfimPopupConfirm()} autoFocus>
                  Yes
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
        </Modal.Body>
      </Modal>
    );
  }
}

const style = {
  modal: {
    p: 7,
  },
  inputFeild: {
    width: "100%",
    marginBottom: 2,
  },
};

function mapStateToProps(state) {
  return {
    provider: state.provider,
    currentUser: state.security.user,
    statusList: state.kit.statusList,
    clinicList: state.kit.clinicList,
    editKit: state.kit.editKit,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchKitListProvider,
      fetchKitStatusReportProvider,
      searchClinic,
      searchClinicLocations,
      AddKit,
      EditKitStatus,
      FetchKit,
      FetchStatusList,
      FetchClinicList
    },
    dispatch
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(AddEditKit);
