import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import moment from "moment";
import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { updatePatientProgramFrequency } from "../../../../actions/patientProgramActions";
import {
  SaveSetFrequency,
  stopPragram,
} from "../../../../actions/setPatientProgramActions";
import { alertService } from "./../../../GlobalComponent/alertService";
import Progress from "./Progress";
import RightCornerPanel from "./RightCornerPanel";
import { PatternFormat, NumericFormat } from "react-number-format";
const NumberOFSetsArray = [
  "VORx1_Yaw",
  "VORx1_Pitch",
  "Balance_Weight_Shift",
  "Balance_Sitting_Standing",
];
export class SetFrequency extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCancelProgramModal: false,
      Frequency: {
        DataArray: {},
        ExerciseDurationPerDay: "00:00",
        Num_Of_sets_VORx1_Yaw: 0,
        Num_Of_sets_VORx1_Pitch: 0,
        Num_Of_sets_Balance_Weight_Sift: 0,
        Num_Of_sets_Balance_Sitting_Standing: 0,
        Duration_VORx1_Yaw: 0,
        Duration_VORx1_Pitch: 0,
        Duration_Balance_Weight_Shift: 0,
        Duration_Balance_Sitting_Standing: 0,
      },
      NumOfExercisePrescribed: 0,
      numSetsError: "",
      durationError: "",
      NumOfSetsErrorElement: [],
      DurationErrorElement: [],
      ApplyAllNumberOfSets: false,
      ApplyAllDurationOfSets: false,
    };
  }

  componentDidMount() {
    if (this.props.patientProgram && this.props.patientProgram != "") {
      this.storeDefaultValue();
    }
  }

  async componentDidUpdate(prevProps) {
    
    if (
      (this.props.patientProgram != "" &&
        prevProps.patientProgram !== this.props.patientProgram) ||
      this.props.ProgramStatus !== prevProps.ProgramStatus ||
      this.props.EditProgramStatus !== prevProps.EditProgramStatus
    ) {
      this.storeDefaultValue();
    }
    if (
      this.props.ExerciseData !== prevProps.ExerciseData ||
      this.props.FrequencyData !== prevProps.FrequencyData
    ) {
      let numbOfExerciseActive = 0;
      NumberOFSetsArray.map((item) => {
        if (this.props.ExerciseData[item] == true) {
          numbOfExerciseActive++;
        }
      });
      await this.setState({
        ...this.state,
        NumOfExercisePrescribed: numbOfExerciseActive,
      });
      this.calculateTotalDuration();
    }
    if (this.props.SetFrequency !== prevProps.SetFrequency) {
      this.setState({
        ...this.state,
        Frequency: { ...this.props.SetFrequency },
      });
    }
  }

  storeDefaultValue = async () => {
    let dataObj = {};
    NumberOFSetsArray.map((item) => {
      let filteredRecord = this.props.patientProgram?.prescriptionList?.filter(
        option =>
        { 
          let programName = item.replace(/_/g, " ");
          let prescriptionId = option.id.prescriptionId
         
          if(prescriptionId.replace('/', " ") == programName.replace('/', " ")){
            return option
          }
          
        }
      );
      
      if (filteredRecord !== undefined) {
        
        let prescriptionDuration =
          filteredRecord[0]?.prescriptionDisplayList?.filter(
            (option) => option.id == "Current"
          );
          
        let prescriptionNumberOfSessions =
          filteredRecord[0]?.prescriptionDisplayList?.filter(
            (option) => option.id == "Number of Sets in Session"
          );

        dataObj[`Num_Of_sets_${item}`] =
          prescriptionNumberOfSessions !== undefined
            ? prescriptionNumberOfSessions[0]?.value
            : 0;
        dataObj[`Duration_${item}`] =
          prescriptionDuration !== undefined
            ? prescriptionDuration[0]?.value
            : 0;
      }
    });
    let totalTime = 0;
    NumberOFSetsArray.forEach((item) => {
      if (this.props.ExerciseData[item] == true) {
        totalTime =
          totalTime +
          dataObj[`Num_Of_sets_${item}`] * dataObj[`Duration_${item}`];
      }
    });
    totalTime = totalTime * this.props.FrequencyData.sessionsPerDay;
    totalTime = moment.duration(totalTime, "seconds").format("mm:ss");
    dataObj.ExerciseDurationPerDay = totalTime;
    await this.setState({
      ...this.state,
      Frequency: {
        ...this.state.Frequency,
        ...dataObj,
      },
    });
  };

  onChangeValueHandler = (e) => {
    this.setState({
      ...this.state,
      numSetsError: "",
      durationError: "",
      NumOfSetsErrorElement: [],
      DurationErrorElement: [],
      Frequency: {
        ...this.state.Frequency,
        [e.target.id]: e.target.value.trim(),
      },
    });
    if (e.target.id.includes("Duration_")) {
      if (this.state.ApplyAllDurationOfSets) {
        this.setState({
          ApplyAllDurationOfSets: !this.state.ApplyAllDurationOfSets,
        });
      }
    } else {
      if (this.state.ApplyAllNumberOfSets) {
        this.setState({
          ApplyAllNumberOfSets: !this.state.ApplyAllNumberOfSets,
        });
      }
    }
  };

  calculateData = () => {
    let data = {};
    NumberOFSetsArray.map((item) => {
      if (this.props.ExerciseData[item] == true) {
        let newObj = {
          [`${item.replace(/_/g, " ")}`]: {
            Current: this.state.Frequency[`Duration_${item}`],
            "Number of Sets in Session":
              this.state.Frequency[`Num_Of_sets_${item}`],
          },
        };
        data[`${item.replace(/_/g, " ")}`] =
          newObj[`${item.replace(/_/g, " ")}`];
      }
    });
    this.setState({
      ...this.state,
      Frequency: {
        ...this.state.Frequency,
        DataArray: data,
      },
    });
    return data;
  };

  async calculateTotalDuration() {
    let totalTime = 0;



    NumberOFSetsArray.forEach((item) => {
      if (this.props.ExerciseData[item] == true) {
        totalTime =
          totalTime +
          this.state.Frequency[`Num_Of_sets_${item}`] *
          this.state.Frequency[`Duration_${item}`];
      }
    });
    totalTime = totalTime * this.props.FrequencyData.sessionsPerDay;
    if (totalTime < 60) {
      totalTime = totalTime < 10 ? "00:0" + totalTime : "00:" + totalTime;
    } else {
      totalTime = moment.duration(totalTime, "seconds").format("mm:ss");
    }

    this.setState({
      Frequency: {
        ...this.state.Frequency,
        ExerciseDurationPerDay: totalTime,
      },
    });

    return totalTime;
  }

  OnSaveHandler = async (saveandcontine = "") => {
    let valid = await this.validateData();
    if (valid) {
      await this.calculateData();
      await this.calculateTotalDuration();
      this.props.SaveSetFrequency(this.state.Frequency);
      if (saveandcontine == "") {
        if (this.props.ProgramStatus == true) {
          this.props.handleOpenSettingsFirstModal();
        } else if (
          this.props.EditProgramStatus == true &&
          this.props.ExerciseAdded
        ) {
          this.props.handleOpenSettingsFirstModal();
        } else if (this.props.ProgramStatus == false) {
          await this.CreatePayloadForUpdate();
          alertService.success("Record updated successfully!", {
            autoClose: true,
          });
        }
        this.props.handleCloseSetFrequencyModal();
      } else {
        await this.CreatePayloadForUpdate();
        this.props.handleOpenSettingsFirstModal();
        this.props.handleCloseSetFrequencyModal();
      }
    } else {
      return;
    }
  };

  CreatePayloadForUpdate = () => {
    let Exercises = this.props.ExerciseData;
    let ExerciseFrequency = this.props.ExerciseFrequency;
    let Frequency = this.props.FrequencyData;
    let SetFrequency = this.props.SetFrequency.DataArray;
    let postPlayload = [];
    let obj = {};
    Object.keys(Exercises).map((key) => {
      let name = key.replace(/_/g, " "),
      obj = {
        name: name.replace("Balance Sitting Standing", "Balance Sitting/Standing"),
        value: Exercises[key],
        type: "Object",
      };
      postPlayload.push(obj);
    });
    Object.keys(ExerciseFrequency).map((key) => {
      obj = { name: key, value: ExerciseFrequency[key], type: "Object" };
      postPlayload.push(obj);
    });
    obj = {
      name: "sessionsPerDay",
      value: Frequency.sessionsPerDay ? Frequency.sessionsPerDay : 3,
      type: "Object",
    };
    postPlayload.push(obj);
    Object.keys(SetFrequency).map((key) => {
      let programVal = SetFrequency[key];
      Object.keys(programVal).map((progmKey) => {
        obj = {
          name: progmKey,
          value: programVal[progmKey],
          type: key.replace(/_/g, " ").replace("Balance Sitting Standing", "Balance Sitting/Standing"),
        };
        postPlayload.push(obj);
        if(progmKey=="Current"){
          obj = {
            name: 'At Intake',
            value: programVal[progmKey],
            type: key.replace(/_/g, " ").replace("Balance Sitting Standing", "Balance Sitting/Standing"),
          };
          postPlayload.push(obj);
          obj = {
            name: 'Goal',
            value: programVal[progmKey],
            type: key.replace(/_/g, " ").replace("Balance Sitting Standing", "Balance Sitting/Standing"),
          };
          postPlayload.push(obj);
        }
      });
    });
    this.props.updatePatientProgramFrequency(
      postPlayload,
      this.props.patientUsername
    );
  };

  onApplyAllDuration = async (e) => {
    this.setState({
      ApplyAllDurationOfSets: !this.state.ApplyAllDurationOfSets,
    });

    if (e.target.checked) {
      if (
        this.state.Frequency.Duration_VORx1_Yaw !== 0 ||
        this.state.Frequency.Duration_VORx1_Pitch !== 0 ||
        this.state.Frequency.Duration_Balance_weight_shift !== 0 ||
        this.state.Frequency.Duration_Balance_Sitting_Standing !== 0
      ) {
        var val =
          this.state.Frequency.Duration_VORx1_Yaw !== 0 &&
            this.props.ExerciseData.VORx1_Yaw == true
            ? this.state.Frequency.Duration_VORx1_Yaw
            : this.state.Frequency.Duration_VORx1_Pitch !== 0 &&
              this.props.ExerciseData.VORx1_Pitch == true
              ? this.state.Frequency.Duration_VORx1_Pitch
              : this.state.Frequency.Duration_Balance_Weight_Shift !== 0 &&
                this.props.ExerciseData.Balance_Weight_Shift == true
                ? this.state.Frequency.Duration_Balance_Weight_Shift
                : this.state.Frequency.Duration_Balance_Sitting_Standing !== 0 &&
                  this.props.ExerciseData.Balance_Sitting_Standing == true
                  ? this.state.Frequency.Duration_Balance_Sitting_Standing
                  : 0;
      }
      
      await this.setState({
        Frequency: {
          ...this.state.Frequency,
          Duration_VORx1_Yaw:
            this.props.ExerciseData.VORx1_Yaw == true ? val : 0,
          Duration_VORx1_Pitch:
            this.props.ExerciseData.VORx1_Pitch == true ? val : 0,
          Duration_Balance_Weight_Shift:
            this.props.ExerciseData.Balance_Weight_Shift == true ? val : 0,
            Duration_Balance_Sitting_Standing:
            this.props.ExerciseData.Balance_Sitting_Standing == true ? val : 0,
        },
      });
    }

    this.calculateTotalDuration();
  };

  onApplyAllNumberOfSets = async (e) => {
    this.setState({
      ApplyAllNumberOfSets: !this.state.ApplyAllNumberOfSets,
    });

    if (e.target.checked) {
      if (
        this.state.Frequency.Num_Of_sets_VORx1_Yaw !== 0 ||
        this.state.Frequency.Num_Of_sets_VORx1_Pitch !== 0 ||
        this.state.Frequency.Num_Of_sets_Balance_weight_shift !== 0 ||
        this.state.Frequency.Num_Of_sets_Balance_Sitting_Standing !== 0
      ) {
        var val =
          this.state.Frequency.Num_Of_sets_VORx1_Yaw !== 0 &&
            this.props.ExerciseData.VORx1_Yaw == true
            ? this.state.Frequency.Num_Of_sets_VORx1_Yaw
            : this.state.Frequency.Num_Of_sets_VORx1_Pitch !== 0 &&
              this.props.ExerciseData.VORx1_Pitch == true
              ? this.state.Frequency.Num_Of_sets_VORx1_Pitch
              : this.state.Frequency.Num_Of_sets_Balance_Weight_Shift !== 0 &&
                this.props.ExerciseData.Balance_Weight_Shift == true
                ? this.state.Frequency.Num_Of_sets_Balance_Weight_Shift
                : this.state.Frequency.Num_Of_sets_Balance_Sitting_Standing !== 0 &&
                  this.props.ExerciseData.Balance_Sitting_Standing == true
                  ? this.state.Frequency.Num_Of_sets_Balance_Sitting_Standing
                  : 0;
      }

      await this.setState({
        Frequency: {
          ...this.state.Frequency,
          Num_Of_sets_VORx1_Yaw:
            this.props.ExerciseData.VORx1_Yaw == true ? val : 0,
          Num_Of_sets_VORx1_Pitch:
            this.props.ExerciseData.VORx1_Pitch == true ? val : 0,
          Num_Of_sets_Balance_Weight_Shift:
            this.props.ExerciseData.Balance_Weight_Shift == true ? val : 0,
            Num_Of_sets_Balance_Sitting_Standing:
            this.props.ExerciseData.Balance_Sitting_Standing == true ? val : 0,
        },
      });
    }
    this.calculateTotalDuration();
  };

  validateData = () => {
    let numSetsError = "";
    let NumOfSetsErrorElement = [];
    let durationError = "";
    let DurationErrorElement = [];
    NumberOFSetsArray.map((item) => {
      if (
        this.props.ExerciseData[item] == true &&
        (this.state.Frequency[`Num_Of_sets_${item}`] < 1 ||
          this.state.Frequency[`Num_Of_sets_${item}`] > 10)
      ) {
        numSetsError =
          "Please enter value between 1 and 10 for all number of sets";
        NumOfSetsErrorElement.push(`Num_Of_sets_${item}`);
      }
    });
    NumberOFSetsArray.map((item) => {
      if (
        this.props.ExerciseData[item] == true &&
        (this.state.Frequency[`Duration_${item}`] < 10 ||
          this.state.Frequency[`Duration_${item}`] > 200)
      ) {
        durationError =
          "Please enter value between 10 and 200 for all duration of sets";
        DurationErrorElement.push(`Duration_${item}`);
      }
    });
    if (numSetsError !== "" || durationError !== "") {
      this.setState({
        ...this.state,
        NumOfSetsErrorElement: NumOfSetsErrorElement,
        DurationErrorElement: DurationErrorElement,
        numSetsError: numSetsError,
        durationError: durationError,
      });
      return false;
    } else {
      return true;
    }
  };

  render() {
    return (
      <Modal
        show={this.props.showSetFrequencyModal}
        onHide={this.props.handleCloseSetFrequencyModal}
        backdrop="static"
        className="theme-modal-1101 pt-5"
      >
        {this.props.patientInfo.newPatient === true &&
          <div className="p-1 absolute w-100 -top-23 bg-white rounded-20">
            <Progress value={66.64} />
          </div>
        }
        <Modal.Body>
          <Box
            sx={style.modal}
            component="form"
            autoComplete="off"
            className="p-3 rounded-20 border-0"
          >
            <div className="d-flex align-items-center justify-content-between mb-3">

                {(this.props.ProgramStatus == true ||
                  this.props.ExerciseAdded == true) && (
                    <div className="d-flex align-items-center justify-content-between mb-3">
                      <div
                        className="text-primary-theme font-figtree font-700 font-18 pointer d-inline-flex align-items-center"
                        onClick={() => {
                          this.props.handleCloseSetFrequencyModal();
                          this.props.handleOpenFrequencyModal();
                        }}
                      >
                        <ArrowBackIosNewIcon fontSize="small" />
                        Back
                      </div>
                    </div>
                  )}
                {this.props.ProgramStatus == false &&
                  this.props.ExerciseAdded == false && (
                    <div className="d-flex align-items-center justify-content-end mb-3 ml-auto">
                      <div
                        className="text-primary-theme font-figtree font-700 font-18 pointer d-inline-flex align-items-center"
                        onClick={(e) => {
                          e.preventDefault();
                          this.OnSaveHandler("saveandcontine");
                        }}
                      >
                        Settings
                        <ArrowForwardIosIcon fontSize="small" />
                      </div>
                    </div>
                  )}
            </div>
            <div className="d-flex">
              <div className="w-75">
                <h4 className="font-figtree font-500 font-38">Set Frequency</h4>
                <div className="mx-auto ml-2 ml-md-5 pl-0 pl-md-5 pr-5 mb-2 mb-md-5 pb-0">
                  <div className="text-center mb-2 mt-4 shadow rounded-20 p-3 max-w-422 mx-auto">
                    <h6 className="mb-0 font-figtree font-400 font-18">
                      Exercise Duration per Day (min:sec):
                    </h6>
                    <div className="font-figtree font-600 font-32 border-0 w-100 text-center focus-none">
                      {this.state.Frequency.ExerciseDurationPerDay}
                    </div>
                  </div>
                  <div className="mt-5 pr-5">
                    <h6 className="font-figtree font-600 font-18 text-theme-gray-dark mb-4 ml-md-5 pl-md-5 ml-auto pl-auto">
                    Select the number of sets (from 1 to 10):
                    </h6>
                    <div className="d-flex">
                      <div className="mr-3 mr-md-5 w-100">
                        {NumberOFSetsArray.map((item) => {
                         
                          return (
                            <>
                              {this.props.ExerciseData[item] == true && (
                                <p className="text-right frequency-section font-figtree font-400 font-16 text-secondary-theme text-uppercase">
                                  {`${item.replace(/_/g, " ").replace("Balance Sitting Standing","Balance Sitting/Standing")} : `}
                                  <PatternFormat
                                    required
                                    format="##"
                                    className={
                                      this.state.NumOfSetsErrorElement.includes(
                                        `Num_Of_sets_${item}`
                                      )
                                        ? "border-danger"
                                        : ""
                                    }
                                    autoComplete="off"
                                    name={`Num_Of_sets_${item}`}
                                    id={`Num_Of_sets_${item}`}
                                    value={
                                      this.state.Frequency[
                                      `Num_Of_sets_${item}`
                                      ]
                                    }

                                    onChange={async (e) => {
                                      await this.onChangeValueHandler(e);
                                      this.calculateTotalDuration();

                                    }}

                                  />
                                </p>
                              )}
                            </>
                          );
                        })}
                      </div>

                      {this.state.NumOfExercisePrescribed > 1 && (
                        <div className="w-120px">
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={this.state.ApplyAllNumberOfSets}
                                onChange={this.onApplyAllNumberOfSets}
                              />
                            }
                            label="Apply to All"
                            value={this.state.ApplyAllNumberOfSets}
                            className="check-frequency mb-0 mr-0"
                          />
                        </div>
                      )}
                    </div>
                    <div className="text-danger text-center mb-3">
                      {this.state.numSetsError !== ""
                        ? this.state.numSetsError
                        : ""}
                    </div>
                    <h6 className="font-figtree font-600 font-18 text-theme-gray-dark mb-4 ml-md-5 pl-md-5 ml-auto pl-auto">
                      Select the duration of the sets (from 10 to 200 sec):
                    </h6>
                    <div className="d-flex">
                      <div className="mr-3 mr-md-5 w-100">
                        {NumberOFSetsArray.map((item) => {
                          return (
                            <>
                              {this.props.ExerciseData[item] == true && (
                                <p className="text-right frequency-section font-figtree font-400 font-16 text-secondary-theme text-uppercase">
                                  {`${item.replace(/_/g, " ").replace("Balance Sitting Standing","Balance Sitting/Standing")} : `}

                                  <PatternFormat
                                    required
                                    format="###"
                                    className={
                                      this.state.DurationErrorElement.includes(
                                        `Duration_${item}`
                                      )
                                        ? "border-danger"
                                        : ""
                                    }
                                    autoComplete="off"
                                    name={`Duration_${item}`}
                                    id={`Duration_${item}`}
                                    value={
                                      this.state.Frequency[`Duration_${item}`]
                                    }
                                    onChange={async (e) => {
                                      await this.onChangeValueHandler(e);
                                      this.calculateTotalDuration();
                                    }}

                                  />
                                </p>
                              )}
                            </>
                          );
                        })}
                      </div>
                      {this.state.NumOfExercisePrescribed > 1 && (
                        <div className="w-120px">
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={this.state.ApplyAllDurationOfSets}
                                onChange={this.onApplyAllDuration}
                              />
                            }
                            label="Apply to All"
                            value={this.state.ApplyAllDurationOfSets}
                            className="check-frequency mb-0 mr-0"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="text-danger text-center">
                    {this.state.durationError !== ""
                      ? this.state.durationError
                      : ""}
                  </div>
                </div>
              </div>
              <RightCornerPanel step={4} />
            </div>

            <div className="d-flex my-2 mt-md-3 mb-md-3">
              <button
                className="ml-auto mr-2 btn border border-primary-theme cursor-pointer rounded-14 btn-lg"
                variant="contained"
                onClick={(e) => {
                  e.preventDefault();
                  this.props.handleOpenCancelModal();
                }}
              >
                Cancel
              </button>

              <button
                className="bg-primary-theme text-white pointer rounded-14 btn-lg theme-btn border-0"
                variant="contained"
                disabled={
                  this.state.Frequency.Num_Of_sets_VORx1_Yaw == 0 &&
                  this.state.Frequency.Num_Of_sets_VORx1_Pitch == 0 &&
                  this.state.Frequency.Num_Of_sets_Balance_Weight_Shift == 0 &&
                  this.state.Frequency.Num_Of_sets_Balance_Sitting_Standing == 0 &&
                  this.state.Frequency.Duration_VORx1_Yaw == 0 &&
                  this.state.Frequency.Duration_VORx1_Pitch == 0 &&
                  this.state.Frequency.Duration_Balance_Weight_Shift == 0 &&
                  this.state.Frequency.Duration_Balance_Sitting_Standing == 0
                }
                onClick={(e) => {
                  e.preventDefault();
                  this.OnSaveHandler();
                }}
              >
                {(this.props.ProgramStatus == true ||
                  (this.props.ProgramStatus == false &&
                    this.props.ExerciseAdded == true)) &&
                  "Next"}

                {this.props.ProgramStatus == false &&
                  this.props.ExerciseAdded == false &&
                  "Save"}
              </button>
            </div>
          </Box>
        </Modal.Body>
      </Modal>
    );
  }
}

const style = {
  modal: {
    p: 4,
  },
};
function mapStateToProps(state) {
  return {
    ProgramStatus: state.patientProgram.startProgram,
    EditProgramStatus: state.patientProgram.startEditProgram,
    ExerciseAdded: state.patientProgram.ExerciseAdded,
    SetFrequency: state.patientProgram.SetFrequency,
    ExerciseData: state.patientProgram.Exercise,
    ExerciseFrequency: state.patientProgram.ExerciseFrequency,
    FrequencyData: state.patientProgram.Frequency,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      stopPragram,
      SaveSetFrequency,
      updatePatientProgramFrequency,
    },
    dispatch
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(SetFrequency);