import Box from "@mui/material/Box";
import classnames from "classnames";
import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
// import Popover from "@mui/material/Popover";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import {
  AddUser,
  EditUser,
  fetchProviderList,
  FetchUser,
  searchClinic,
  searchClinicLocations,
} from "../../../actions/index";
import { alertService } from "./../../GlobalComponent/alertService";

class AddEditUser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userID: "",
      clinicLocationMappingId: "",
      role: "PROVIDER",
      clinicName: "",
      clinicId: "",
      clinicObj: null,
      locationID: "",
      locationObj: null,
      lastName: "",
      firstName: "",
      email: "",
      status: "false",
      EmailHelperText: "",
      ClinicHelperText: "",
      LocationHelperText: "",
      anchorEl: null,
      searchString: null,
      ClinicsList: [],
      Locations: [],
      currentUser: { id: '' },
    };
  }

  componentDidMount() {
    this.storeUserValues();
  }

  componentDidUpdate(prevProps) {
    console.log("this.props.EditElement:::", this.props.EditElement);
    console.log("this.props.prevEditElement:::", prevProps.EditElement);
    if (
      this.props.EditElement !== null &&
      this.props.EditElement !== prevProps.EditElement &&
      this.props.mode === "Edit"
    ) {

      this.storeUserValues();
    }
    if (this.props.editProvider && this.props.editProvider != prevProps.editProvider) {
      alertService.success("User updated successfully!", { autoClose: true });
      this.props.fetchProviderList(
        this.props.page,
        this.props.perPage,
        this.props.sortColumn,
        this.props.sortOrder,
        this.props.userId
      );
      this.setState({
        userID: "",
        role: "PROVIDER",
        clinicName: "",
        clinicId: "",
        clinicObj: null,
        locationID: "",
        locationObj: null,
        lastName: "",
        firstName: "",
        email: "",
        status: "false",
        EmailHelperText: "",
        ClinicHelperText: "",
        LocationHelperText: "",
        anchorEl: null,
        searchString: null,
        ClinicsList: [],
        Locations: [],
      });
      this.props.handleClose();
    }
    if (this.props.addProvider && this.props.addProvider != prevProps.addProvider) {
      alertService.success("User added successfully!", { autoClose: true });
      this.props.fetchProviderList(
        this.props.page,
        this.props.perPage,
        this.props.sortColumn,
        this.props.sortOrder,
        this.props.userId
      );
      this.setState({
        userID: "",
        role: "PROVIDER",
        clinicName: "",
        clinicId: "",
        clinicObj: null,
        locationID: "",
        locationObj: null,
        lastName: "",
        firstName: "",
        email: "",
        status: "false",
        EmailHelperText: "",
        ClinicHelperText: "",
        LocationHelperText: "",
        anchorEl: null,
        searchString: null,
        ClinicsList: [],
        Locations: [],
      });
      this.props.handleClose();
    }

    if (this.props.errors && this.props.errors !== prevProps.errors) {
      alertService.error(this.props.errors.errorMessage);
      //this.setState({ AddPatientModal: true });
    }
  }

  async storeUserValues() {
    let currentUser = this.props.currentUser;
    console.log("currentUser:::", currentUser);
    this.setState({ currentUser: currentUser });
    if (
      this.props.mode === "Edit" &&
      this.props.EditElement.id !== null &&
      this.props.EditElement.id !== undefined
    ) {
      let userData = await this.props.FetchUser(this.props.EditElement.id);
      console.log("userData::::", userData.data.user.id);
      if (userData.data.user.role === "PROVIDER") {
        let clinicObj = userData.data.user.clinicLocationMapping;
        await this.setState({
          userID: userData.data.user.id,
          clinicLocationMappingId: clinicObj.id,
          role: userData.data.user.role,
          clinicName: clinicObj.clinic.name,
          searchString: clinicObj.clinic.name,
          clinicId: clinicObj.clinic.id,
          clinicObj: clinicObj.clinic,
          locationID: clinicObj.location.id,
          locationObj: clinicObj.location,
          Locations: clinicObj.clinic.locations,
          lastName: userData.data.user.lastName,
          firstName: userData.data.user.firstName,
          email: userData.data.user.username,
          status: userData.data.user.active,
        });
      } else {
        await this.setState({
          userID: userData.data.user.id,
          role: userData.data.user.role,
          clinicName: "",
          clinicId: "",
          clinicObj: null,
          locationID: "",
          locationObj: null,
          lastName: userData.data.user.lastName,
          firstName: userData.data.user.firstName,
          email: userData.data.user.username,
          status: userData.data.user.active,
        });
      }
    } else {
      this.setState({
        userID: "",
        clinicLocationMappingId: "",
        role: "PROVIDER",
        clinicName: "",
        clinicId: "",
        clinicObj: null,
        locationID: "",
        locationObj: null,
        lastName: "",
        firstName: "",
        email: "",
        status: "false",
        EmailHelperText: "",
        ClinicHelperText: "",
        LocationHelperText: "",
        anchorEl: null,
        searchString: null,
        ClinicsList: [],
        Locations: [],

      });
    }
  }

  // renderTooltip = () => (
  //   <Popover id="popover-trigger-hover-focus">
  //     <div className="text-left p-2">
  //       Your password must be at least 8 characters long and your password
  //       should contain.
  //       <ol className="list-decimal pl-3">
  //         <li>At least one upper case letter</li>
  //         <li>At least one lower case letter</li>
  //         <li>
  //           At least one digit. Only (! \" # . @ _ ` ~ $ % ^ * : , ; | -)
  //           special characters are allowed in the password.
  //         </li>
  //       </ol>
  //     </div>
  //   </Popover>
  // );

  onChangeValueHandler = (e) => {
    let value = e.target.value.trim();
    if (e.target.id == "firstName" || e.target.id == "lastName") {
      value = value.replace(/[^a-zA-Z0-9]/g, "");
    }
    if (e.target.id == "locationID") {
      let obj = this.state.Locations.filter(
        (option) => option.id == e.target.value
      );
      this.setState({
        [e.target.id]: value.trim(),
        locationObj: obj[0],
        EmailHelperText: "",
        ClinicHelperText: "",
        LocationHelperText: "",
      });
    } else {
      this.setState({
        [e.target.id]: value.trim(),
        EmailHelperText: "",
        ClinicHelperText: "",
        LocationHelperText: "",
      });
    }
  };

  handlePopoverOpen = (event) => {
    this.setState({ ...this.state, anchorEl: event.currentTarget });
  };

  handlePopoverClose = () => {
    this.setState({ ...this.state, anchorEl: null });
  };

  ValidateData = async (e) => {
    this.setState({
      EmailHelperText: "",
      ClinicHelperText: "",
      LocationHelperText: "",
    });

    var validEmailRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (!this.state.email.match(validEmailRegex)) {
      this.setState({ EmailHelperText: "Invalid email" });
      return false;
    }

    if (this.state.role == "PROVIDER" && this.state.clinicId == "") {
      this.setState({ ClinicHelperText: "Invalid clinic name" });
      return false;
    }
    if (this.state.role == "PROVIDER" && this.state.locationID == "") {
      this.setState({ LocationHelperText: "Invalid location name" });
      return false;
    }

    let payload = {
      role: this.state.role,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      username: this.state.email,
      active: this.state.status,
    };
    if (this.state.role === "PROVIDER") {
      payload.clinicLocationMapping = {
        clinic: this.state.clinicObj,
        location: this.state.locationObj,
      };
    }
    if (this.props.mode === "Add") {
      await this.AddUserHandler(payload);
    } else {
      await this.EditUserHandler(payload);
    }

  };

  AddUserHandler = async (payload) => {
    await this.props.AddUser(payload);

  };

  EditUserHandler = async (payload) => {
    payload.id = this.state.userID;
    if (this.state.role == "PROVIDER") {
      payload.clinicLocationMapping.id = this.state.clinicLocationMappingId;
    }
    await this.props.EditUser(payload);

  };

  async autoSearchHandler(e, v) {
    this.setState({ searchString: v });
    if (e !== null && e.type == "click") {
      let res = await this.props.searchClinic(v);
      let name = '';
      let Id = '';
      let clinicObj = '';
      let ClinicsList = [];
      if (res.data.clinics[0]) {
        name = res.data.clinics[0].name;
        Id = res.data.clinics[0].id;
        clinicObj = res.data.clinics[0];
        ClinicsList = res.data.clinics;
      }

      this.setState({
        clinicId: Id,
        clinicName: name,
        clinicObj: clinicObj,
        ClinicsList: ClinicsList,
      });
      this.searchClinicLocations(Id);
    } else {
      if (v.length > 2) {
        let res = await this.props.searchClinic(v);
        if (res.data.clinics.length) {
          this.setState({ ClinicsList: res.data.clinics });
        } else {
          this.setState({ ClinicsList: [] });
        }
      } else {
        this.setState({ ClinicsList: [] });
      }
    }
  }

  async searchClinicLocations(Id) {
    let res = await this.props.searchClinicLocations(Id);
    if (res.data.locations.length) {
      this.setState({
        Locations: res.data.locations,
        locationID: res.data.locations[0].id,
        locationObj: res.data.locations[0],
      });
    } else {
      this.setState({ Locations: [] });
    }
  }

  render() {
    return (
      <Modal
        show={this.props.AddEditUserModal}
        onHide={this.props.handleClose}
        backdrop="static"
        keyboard={false}
        className="theme-modal"
      >
        <Modal.Body>
          <Box
            sx={style.modal}
            component="form"
            autoComplete="off"
            className="p-0 w-100 border-0 max-646">
            <div className="border-solid border-4 border-primary-theme rounded-30 position-relative modal-internal">
              <div className="position-absolute bg-white p-1 -top-23 left-40 text-primary-theme font-figtree font-700 font-24px">
                {this.props.mode === "Add" ? "Add New User" : "Edit User"}
              </div>

              <div className="bg-white p-2 position-absolute -top-23 -right-20 rounded-20">
                <img
                  src={
                    this.props.mode === "Add"
                      ? "images/modal-close.svg"
                      : "../images/modal-close.svg"
                  }
                  alt="close"
                  className="pointer"
                  onClick={(e) => {
                    e.preventDefault();
                    this.props.handleClose();
                  }}
                />
              </div>

              <div className="form-card no-border select-custom-arrow">
                <label
                  className="font-figtree font-700 font-15 mb-1"
                  for="exampleInputEmail1"
                >
                  ROLE
                </label>
                {this.props.mode == "Add" ? (
                  <select
                    id="role"
                    name="role"
                    value={this.state.role}
                    onChange={(e) => this.onChangeValueHandler(e)}
                    className={classnames(
                      "form-control font-18 rounded-14 border-dark border h-auto p-2"
                    )}
                  >
                    <option value="PROVIDER">Provider</option>
                    <option value="TVH ADMIN">TVH Admin</option>
                  </select>
                ) : (
                  <input
                    type="text"
                    autoComplete="off"
                    className={classnames("form-control font-18")}
                    value={this.state.role}
                    disabled={this.props.mode == "Add" ? false : true}
                  />
                )}
              </div>

              {this.state.role === "PROVIDER" && (
                <>
                  <div className="mb-3">
                    <label
                      for="exampleInputEmail1"
                      className="font-figtree font-700 font-15 mb-1"
                    >
                      CLINIC NAME
                    </label>
                    <Autocomplete
                      disablePortal
                      disabled={this.state.userID != '' ? true : false}
                      id="combo-box-demo"
                      // className="p-0"
                      className={classnames("p-0", {
                        "is-invalid": this.state.ClinicHelperText,
                      })}
                      freeSolo
                      value={this.state.searchString}
                      options={this.state.ClinicsList.map(
                        (option) => option.name
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label=""
                          placeholder="Enter clinic name"
                          className={classnames(
                            "form-control font-18 autocomplete-1",
                            {
                              "is-invalid": this.state.ClinicHelperText,
                            }
                          )}
                        // className="form-control font-18 mb-4 autocomplete-1"
                        />
                      )}
                      onInputChange={(e, v) => {
                        this.autoSearchHandler(e, v);
                      }}
                    // onChange={(e, v) => this.autoSearchChangeHandler(e, v)}
                    />
                    {this.state.ClinicHelperText && (
                      <div className="invalid-feedback">
                        {this.state.ClinicHelperText}
                      </div>
                    )}
                  </div>

                  <div className="form-card no-border">
                    <div className="select-custom-arrow">
                      <label
                        for="exampleInputEmail1"
                        className="font-figtree font-700 font-15 mb-1"
                      >
                        LOCATION
                      </label>
                      <select
                        id="locationID"
                        name="locationID"
                        value={this.state.locationID}
                        onChange={(e) => this.onChangeValueHandler(e)}
                        className={classnames("form-control font-18 rounded-14 border-dark border h-auto p-2 custom-error", {
                          "is-invalid": this.state.LocationHelperText,
                        })}
                      >
                        <option value="" selected>Select location</option>
                        {this.state.Locations.map((item) => {
                          return <option value={item.id}>{item.name}</option>;
                        })}
                      </select>
                    </div>
                    <div className={classnames("custom-error", {
                      "is-invalid": this.state.LocationHelperText,
                    })}>
                      {this.state.LocationHelperText && (
                        <div className="invalid-feedback">
                          {this.state.LocationHelperText}
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}

              <div className="form-card no-border">
                <label
                  for="exampleInputEmail1"
                  className="font-figtree font-700 font-15 mb-1"
                >
                  LAST NAME
                </label>
                <input
                  type="text"
                  autoComplete="off"
                  className={classnames("form-control font-18")}
                  placeholder="Enter last name"
                  name="lastName"
                  id="lastName"
                  value={this.state.lastName}
                  onChange={(e) => this.onChangeValueHandler(e)}
                  maxLength="20"
                />
              </div>

              <div className="form-card no-border">
                <label
                  className="font-figtree font-700 font-15 mb-1"
                  for="exampleInputEmail1"
                >
                  FIRST NAME
                </label>
                <input
                  type="text"
                  autoComplete="off"
                  className={classnames("form-control font-18")}
                  placeholder="Enter first name"
                  name="firstName"
                  id="firstName"
                  value={this.state.firstName}
                  onChange={(e) => this.onChangeValueHandler(e)}
                  maxLength="20"
                />
              </div>

              <div
                className={
                  "form-card no-border " +
                  (this.props.mode == "Edit" ? "d-none" : "")
                }
              >
                <label
                  className="font-figtree font-700 font-15 mb-1"
                  for="exampleInputEmail1"
                >
                  EMAIL ADDRESS
                </label>
                <input
                  type="text"
                  autoComplete="off"
                  className={classnames("form-control font-18", {
                    "is-invalid": this.state.EmailHelperText,
                  })}
                  placeholder="Enter email address"
                  name="email"
                  id="email"
                  value={this.state.email}
                  onChange={(e) => this.onChangeValueHandler(e)}
                />
                {this.state.EmailHelperText && (
                  <div className="invalid-feedback">
                    {this.state.EmailHelperText}
                  </div>
                )}
              </div>

              <div className="form-card no-border select-custom-arrow">
                <label
                  className="font-figtree font-700 font-15 mb-1"
                  for="exampleInputEmail1"
                >
                  STATUS
                </label>
                <select
                  id="status"
                  name="status"
                  value={this.state.status}
                  onChange={(e) => this.onChangeValueHandler(e)}
                  disabled={this.state.currentUser.id == this.state.userID ? 'disabled' : false}
                  className={classnames(
                    "form-control font-18 rounded-14 border-dark border h-auto p-2"
                  )}
                >
                  <option value="false">Inactive</option>
                  <option value="true">Active</option>
                </select>
              </div>

              <div className="d-flex mt-5 pt-5">
                <button
                  className="ml-auto mr-2 bg-transparent border cursor-pointer rounded-20 font-figtree font-700 font-26 px-4 py-2 text-primary-theme"
                  variant="contained"
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({
                      userID: "",
                      Role: "PROVIDER",
                      clinicName: "",
                      clinicId: "",
                      clinicObj: null,
                      locationID: "",
                      locationObj: null,
                      lastName: "",
                      firstName: "",
                      email: "",
                      status: "false",
                      EmailHelperText: "",
                      ClinicHelperText: "",
                      LocationHelperText: "",
                      anchorEl: null,
                      searchString: null,
                      ClinicsList: [],
                      Locations: [],
                    });
                    this.props.handleClose();
                  }}
                >
                  Cancel
                </button>
                <button
                  className="bg-primary-theme text-white pointer rounded-20 theme-btn border-0 font-figtree font-700 font-26 px-4 py-2"
                  disabled={
                    this.state.firstName == "" ||
                    this.state.lastName == "" ||
                    this.state.email == ""
                  }
                  variant="contained"
                  onClick={(e) => {
                    e.preventDefault();
                    this.ValidateData(e);
                  }}
                >
                  {this.props.mode === "Add" ? "Add" : "Save"}
                </button>
              </div>
            </div>
          </Box>
        </Modal.Body>
      </Modal>
    );
  }
}

const style = {
  modal: {
    p: 7,
  },
  inputFeild: {
    width: "100%",
    marginBottom: 2,
  },
};

function mapStateToProps(state) {
  return {
    provider: state.provider,
    currentUser: state.security.user,
    editProvider: state.provider.editProvider,
    addProvider: state.provider.addProvider,
    errors: state.errors,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchProviderList,
      searchClinic,
      searchClinicLocations,
      AddUser,
      EditUser,
      FetchUser,
    },
    dispatch
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(AddEditUser);
