import {
    FETCH_SUPERADMIN_PATIENTS_BY_CLINIC,
    FETCH_ALL_CLINICS,
    SET_SELECTED_CLINIC,
    FETCH_ALL_PROVIDERS_BY_CLINIC
} from '../actions/index';

const initialState = {
    allClinicsList:[],
    superAdminPatients:[],
    selectedClinic:{},
    allProviders:[]
}

export default function superAdminPatientDataReducer(state=initialState,action){

    switch(action.type){
        case FETCH_ALL_CLINICS:
            return {
                ...state,
                allClinicsList: action.payload
            }
            case FETCH_SUPERADMIN_PATIENTS_BY_CLINIC:
            // console.log('Action Payload===>',action.payload)
            return{
                ...state,
                superAdminPatients:action.payload
            }
        case SET_SELECTED_CLINIC:
            return{
                ...state,
                selectedClinic:action.payload
            }
        case FETCH_ALL_PROVIDERS_BY_CLINIC:
            return {
                ...state,
                allProviders:action.payload
        }
        default:
            return state;
    }
}